export const mainPageData = [
  " Introducing our ground-breaking app!  Whether it's CSV, PDF, JSON, or data stored in MySQL, Postgres, and Snowflake databases, our app effortlessly deciphers and extracts crucial information.   Meet your personal AI assistant, a data virtuoso!  It comprehends queries and crafts comprehensive answers, converting raw data into valuable insights. ",
  " Seamlessly navigate any document - ask questions and watch our AI guide provide precise responses. Complex data becomes simple to grasp. ",
  "How to use this app:",
  "1.  Pick Document: Choose CSV, PDF, JSON, or connect to MySQL, Postgres, or Snowflake database.",
  "2.  Upload/Connect: Upload your document or establish a database link.",
  "3.  Meet AI Guide: Get introduced to your AI assistant.",
  "4.  Ask Question: Type your question in plain language.",
  "5.  Receive Insights: Instantly get results with valuable insights.",
];
export const mysqlData = [
  ` Query MySQL with Ease:`,
  "Harness the true power of your MySQL data with our app. Simplified querying, instant insights, and enhanced decision-making capabilities are just a few clicks away.Connect your MySQL database effortlessly to our app and the process is simple:",
  "1. Connect to MySQL: Provide your database credentials, and our app securely connects to your MySQL database.",
  "Credentials required:",
  "•	Host name",
  "•	Username",
  "•	Password",
  "•	database name",
  "2. Ask Your Question: Use plain/narural language to type your query. Whether it's filtering, sorting, or complex joint queries, the AI understands.",
  "3. Instant Results: Hit enter and watch the magic unfold. Your AI assistant processes the query instantly and presents clear results.",
];
export const snowflakeData = [
  " Navigate Snowflake's Heights:",
  "Our app paves the way for Snowflake's ultimate potential. Uncomplicate querying, embrace rapid insights, and reshape decision-making. Step into the future of Snowflake data exploration today with our cutting-edge app. Here's how it's done:",
  "1. Snowflake Connection: Provide your Snowflake database credentials, and our app establishes a secure pathway. Credentials required:",
  "•	Account name",
  "•	user name",
  "•	password",
  "•	database name",
  "•	schema name",
  "•	warehouse name",
  "2. Ask Away: Pose your query in everyday language. Complex or simple, our AI comprehends it all.",
  "3. Results in an Instant: Hit enter and watch as our AI races to process your query, unveiling crystal-clear results.",
];
export const postgresqlData = [
  " Embark on a Postgres Adventure:",
  "Embark on an odyssey through your Postgres database, revolutionizing your approach with our extraordinary app. Here's the journey:",
  "1. Postgres Gateway: Share your Postgres database credentials, and our app forges a secure path.",
  "Credentials required:",
  "•	host name",
  "•	user name",
  "•	password",
  "•	database name",
  "•	port",
  "2. Post Your Query: Express your query in everyday language. From intricate to straightforward, our AI deciphers them all.",
  "3. Results Unveiled: Tap enter and watch as our AI races to decode your query, illuminating results that shine.",
];
export const csvData = [
  " CSV Exploration: Uncover Insights from Single or Multiple Files: ",
  "Our app is your guide to a comprehensive CSV expedition. It empowers you to query one or multiple CSV files, extracting insights with seamless ease. Embrace the future of multi-CSV data exploration today!",
  "Here's how:",
  "1. CSV Flexibility: Choose your adventure – query through a single CSV file or make a selection of multiple csv files according to your need.",
  "2. Question Unveiling: Pose your queries with natural language, and our app adapts, ready to decode your intentions across your uploaded CSV files.",
  "3. Data Symphony: With flair, our AI presents your results, weaving together insights from the individual and collective stories within your CSV files.",
  " The Collective Impact:",
  "Experience the extraordinary reach of our multi-CSV querying bot:",
  "1. Seamless Exploration: The complexities of managing single or multiple CSV files dissolve as our AI guides you effortlessly through your queries.",
  "2. Harmonized Responses: Enjoy the convenience of unified insights, regardless of whether you're querying one file or several.",
];
export const jsonData = [
  " JSON Exploration: Unearth Insights with Intuitive Queries:",
  "Embark on a data journey where JSON files hold the key to profound insights. Our app empowers you to query single JSON data file effortlessly, extracting knowledge with unprecedented simplicity. ",
  "Our app takes the data, model is trained at the backend on the data provided and then answer to any kind of query can be fetched using the JSON AI bot.",
  "Here's how:",
  "1. JSON Empowerment: Upload your JSON file, and watch as our app transforms them into a playground of insights.",
  "2. Intuitive Queries: Speak your questions naturally, and our app's AI understands, ready to decode the nuances within your JSON data.",
  "3. Swift Answers: With precision, our app presents your insights, allowing you to visualize the outcomes drawn from the heart of your JSON files.",
];
export const pdfData = [
  " PDF Unveil: Insights from Solo or Ensemble of Files:",
  "Set sail on an extraordinary voyage through PDFs, where our app empowers you to query single or multiple files, extracting wisdom from every page",
  "Our AI takes on the role of a skilled storyteller in this PDF saga. In short we can say it acts like ChatGPT for your own pdf documents.",
  "Here's how it all unfolds:",
  "1. PDF Mosaic: Choose your canvas – opt for a single PDF or curate an exquisite mosaic of multiple PDFs, each harbouring insights.",
  "2. Speak and Decode: Pose your questions in plain words, and let our AI decode the intricacies of your queries, spanning across your selected PDF collection.",
  "3. Unified Discovery: Whether you venture solo or with a symphony of PDFs, our app orchestrates a seamless exploration, delivering insights that resonate.",
  "4. Insight Elegance: Watch as our AI crafts the story of your insights, intricately weaving threads of understanding drawn from both individual and collective PDF tales.",
];
