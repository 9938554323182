import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import { Button, Card, Input, message } from "antd";
import { DotLoading } from "antd-mobile";
import { IP } from "../../../../dynamicIPs";

import axios from "axios";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

export default function YoutubeQABot() {
  const [youtubeUrl, setYoutubeUrl] = useState("");
  const [isResponse, setIsResponse] = useState(false);
  const [question, setQuestion] = useState("");
  const [isQuestionEmpty, setIsQuestionEmpty] = useState(true);
  const [input, setInput] = useState("");
  const [answer, setAnswer] = useState("");
  const [isAskQuestionButtonCilck, setIsAskQuestionButtonClick] =
    useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [answerLoading, setAnswerLoading] = useState(false);
  const location = useLocation();
  const messageContainerRef = useRef(null);
  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [question, answer]);

  const handleSubmit = async () => {
    setSubmitLoading(true);
    const url = `http://${IP.GENAI}:9002/youtube-bot`;
    const params = {
      url: youtubeUrl,
    };
    try {
      const res = await axios.get(url, { params });
      setSubmitLoading(false);
      setIsResponse(true);
    } catch (error) {
      setSubmitLoading(false);
      message.error(
        "We are facing some network issue, please add another url!"
      );
    }
  };
  const handleAskQuestions = async () => {
    setAnswerLoading(true);
    setQuestion("");

    setIsAskQuestionButtonClick(true);
    const ip = localStorage.getItem("ip");
    const url = `http://${IP.GENAI}:9002/youtube-question-answer-bot`;
    const params = {
      question: question,
    };
    try {
      const res = await axios.get(url, { params });
      setAnswerLoading(false);
      setAnswer(res.data);

      setAllMessages([
        ...allMessages,
        {
          text: question,
          isUser: true,
        },
        { text: res.data, isUser: false },
      ]);
    } catch (error) {
      message.error(error);
    }
  };
  const handleQuestion = (e) => {
    setQuestion(e.target.value);
    setInput(e.target.value);
    setIsQuestionEmpty(false);
  };
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls["YT_Q&A"]} type="video/mp4" pdfSrc={pdfUrls["YT_Q&A"]} />
      </div>
      <Card style={{ marginTop: "30px" }}>
        <b>
          <p>Youtube URL:</p>
        </b>
        <Input
          type="text"
          onChange={(e) => setYoutubeUrl(e.target.value)}
          value={youtubeUrl}
        />
        {isResponse ? (
          <div style={{ marginTop: "15px" }}>
            <p>Ask Your Questions:</p>
            <Input type="text" onChange={handleQuestion} value={question} />
            <Button
              type="primary"
              style={{ width: "100%", marginTop: "20px" }}
              onClick={handleAskQuestions}
              disabled={isQuestionEmpty}
              loading={answerLoading}
            >
              Ask Question
            </Button>
          </div>
        ) : (
          <Button
            type="primary"
            style={{ width: "100%", marginTop: "30px" }}
            onClick={handleSubmit}
            loading={submitLoading}
            disabled={youtubeUrl.trim().length < 1}
          >
            Submit
          </Button>
        )}
      </Card>
      {allMessages.length > 0 || isAskQuestionButtonCilck ? (
        <Card style={{ marginTop: "30px" }}>
          <div className="messages" ref={messageContainerRef}>
            {allMessages?.map((message, index) => (
              <div
                key={index}
                className={`message ${message.isUser ? "sender" : "receiver"}`}
              >
                {message.text}
              </div>
            ))}
            {answerLoading ? (
              <>
                <div className="sender">{input}</div>
                <div className="receiver">
                  <DotLoading />
                </div>
              </>
            ) : null}
          </div>
        </Card>
      ) : null}
    </>
  );
}
