import { routes, streamlitRoutes } from "../../../util/constants";
import pptGen from "../../../assests/icon/AI-Powered Presentation Creation.svg";
import videoautomation from "../../../assests/icon/Video Translation.svg";
import imageEnhancement from "../../../assests/icon/Image Enhancement.svg";
import salesdataImg from "../../../assests/icon/Sales Chatbot.svg";
import fra from "../../../assests/img/financial-report-analyzer.jpg";
import virtaulV from "../../../assests/img/virtual-vouge.jpg";
import personalizeAvatar from "../../../assests/img/personalize-avatar.jpg";
import imageCampaign from "../../../assests/img/image-campaign.jpg";
import pptsumarizer from "../../../assests/img/ppt-summaizer.jpg";
import prod from "../../../assests/img/Prod.jpg";

export const salesAndMarketingData = [
  {
    name: "Advertisement & Video Generator",
    icon: videoautomation,
    url: routes.ADVERTISEMENT_AND_VIDEO_TRANSLATION_GENERATOR,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Advertisement%20Content%20Generator%20recording.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Advertisement%20Content%20Generator.pdf",
    awsVideo: true,

    tag: "Productivity",
    desc: "Turn Words into Captivating advertisements with AdGen AI. Our cutting-edge platform allows you to effortlessly generate ad videos, with captions & custom voices using natural language prompts.",
  },
  {
    name: "AI Powered Presentation Creation",
    icon: imageEnhancement,
    url: routes.AI_POWERED_PRESENTATION_CREATION,
    tag: "Productivity",
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/PPT%20maker.mp4",
    awsVideo: true,
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/PPT%20maker.pdf",
    desc: "Say hello to captivating presentations! Choose from readily available templates, mention the topic, number of slides & turn your ideas into professional PowerPoint presentations in few steps.",
  },
  {
    name: "Image Editing",
    icon: pptGen,
    // url: routes.IMAGE_EDITING,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/image_editor_recording.mp4",
    awsVideo: true,

    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/image_editor_prompt.pdf",
    tag: "Creativity",
    desc: "Unlock the power of text-guided image editing with our innovative tool. Imagine a world where you can seamlessly inpaint, outpaint, and edit images with just your words.",
  },
  // {
  //   name: "Financial Report Analyzer",
  //   icon: fra,
  //   url: routes.FINANCIAL_REPORT_ANALYZER,
  //   streamlit: true,
  //   videoSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Financial_report_analyzer-recording.mp4",
  //   awsVideo: true,
  //   pdfSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/financial.pdf",
  //   tag: "Productivity",
  //   desc: "Solution for comprehensive analysis of financial reports. It efficiently extracts pertinent information related to company's performance, empowering users with valuable insights to facilitate informed decision-making processes in a timely manner.",
  // },
  {
    name: "Sales Chatbot",
    icon: salesdataImg,
    url: routes.SALES_CHATBOT,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Sales%20Chatbot.mp4",
    awsVideo: true,
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Sales%20Chatbot.pdf",
    tag: "Chatbot",
    desc: "An agent chatbot which can have a natural sales conversation with a prospect and behaves based on the conversation stage. It helps to automate sales development representative’s activities, such as outbound sales calls.",
  },
  // {
  //   name: "Virtual Vogue",
  //   icon: virtaulV,
  //   url: streamlitRoutes.VIRTUAL_VOGUE,
  //   streamlit: true,
  //   videoSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/VirtualVogue.mp4",
  //   awsVideo: true,
  //   pdfSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/VirtualVogue.pdf",
  //   tag: "Creativity",
  //   desc: "See it before you wear it! Our cutting-edge technology lets you virtually try on clothes using just a picture. Upload an image of yourself, browse through our selection, and see how item looks on you from the comfort of your home and ditch the guesswork before you buy.",
  // },
  {
    name: "Image Campaign Creation",
    icon: imageCampaign,
     url: routes.IMAGE_CAMPAIGN_CREATION,
    streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Image%20campaign%20creation.mp4",

    awsVideo: true,
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Image_campaign_creation_sample_images.pdf",
    tag: "Creativity",
    desc: "Innovative Generative AI utilized to create highly personalized media ads for social and internet platforms. Analyzing user data enhances ad relevance, engagement, and targeting, revolutionizing advertising effectiveness and audience connection.",
  },
  {
    name: "Digital Catalogue Summarizer",
    icon: pptsumarizer,
    url: routes.DIGITAL_CATALOGUE,
    streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/digital%20catalogue%20summarizer.mp4",
    awsVideo: true,
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/digital%20catalogue%20summarizer.pdf",
    tag: "Productivity",
    desc: "The digital catalogue summarizer utilizes Generative AI to condense lengthy presentations into concise summaries, providing users with a quick and efficient way to grasp key information from heterogenous PPTs. ",
  },
  {
    name: "Product Recommendation",
    icon: prod,
    url: routes.PRODUCT_RECOMMENDATION,
    // streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Product_reccomendation_and_support_Recording.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Product%20Reccomendation%20and%20support%20Sample%20Questions.pdf",
    awsVideo: true,
    tag: "Chatbot",
    desc: "This AI chatbot, built with Autogen, Python, AWS Bedrock, and Chroma DB, recommends products and solves issues with past purchases using a multi-agent system for intent, search, and response.",
  },
  {
    name: "Personalized Avatar Generation",
    icon: personalizeAvatar,
    // url: streamlitRoutes.PEZT_AVATAR_GENERATION,
    // streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Avatar.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Avatar%20Generation.pdf",
    awsVideo: true,
    awsVideo: true,
    tag: "MISC",
    desc: "Personalized Avatar Generation using Generative AI - Stable Diffusion model to generate personalized avatars with a simple text prompt.",
  },
  {
    name: "Virtual Tryon",
    icon: personalizeAvatar,
    url: routes.VIRTUALTRYON,
    // streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/virtualTryOn.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Copy%20of%20Virtual%20Try%20on%20simplified.pdf",
    awsVideo: true,
    tag: "Creativity",
    desc: "Search clothes from different ecommerce sites, simply drag and drop what you like to try it on with the power of AI.",
  },
];
