export const IP = {
  GENAI: "51.20.111.19",
  POSEMIMIC: "51.21.20.83",
  DREAMBOOTH: "13.51.58.8",
  VOICECLONING: "16.16.51.245",
  PAINTING: "16.16.223.152",
  GENAILEAP: "18.199.250.92",
  IMAGE_CAMPAIGN_CREATION: "35.155.108.84",
  MEDI_AUTO_AI: "18.206.19.55",
  VIRTUALWARDROBE: "35.160.200.110",
  QUEUEANALYSER: "44.226.240.106",
};

export const domain_url = "https://yashgenai.yashaccelerator.link";
export const virtualTryDomain = "https://virtualtryon.yashaccelerator.link";
export const api_url = "https://gitexevent.yashaccelerator.link";

