import React, { useState } from 'react';
import { Card, Button, Input, Row, Col, Form, Collapse, Spin } from 'antd';
import { api_url } from '../../../../../dynamicIPs';
 
const { Panel } = Collapse;
 
const VisualizationComponent = ({ AdditionalVisualizations }) => {
  const [formData, setFormData] = useState({});
  const [ciddsDataArray, setCiddsDataArray] = useState([]); // To hold multiple CIDDS visualizations
  const [queryDataArray, setQueryDataArray] = useState([]); // To hold multiple query visualizations
  const [loadingCidds, setLoadingCidds] = useState(false); // Track loading state for CIDDS
  const [loadingQuery, setLoadingQuery] = useState(false); // Track loading state for Query
 
  const handleFormChange = (index, changedFields, allFields) => {
    setFormData(prev => ({ ...prev, [index]: allFields }));
  };
 
  const handleSubmit = async (KPI, text) => {
    const formDataToSend = { prompt: KPI, graph_title: text };
    console.log(formDataToSend);
 
    setLoadingCidds(true); // Show loader for CIDDS visualization
    try {
      const res = await fetch(`${api_url}/visualisation/CIDDS_kpi_query_graph_title`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formDataToSend),
      });
      const data = await res.json();
 
      // Append new data to the ciddsDataArray, including prompt and graph title
      setCiddsDataArray(prevCiddsData => [...prevCiddsData, { ...data, prompt: KPI, graph_title: text }]);
      console.log('Visualization Data:', data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadingCidds(false); // Hide loader when CIDDS API call finishes
    }
  };
 
  const handlePromptSubmit = async (prompt) => {
    const formDataToSend = { prompt: prompt };
    console.log(formDataToSend);
 
    setLoadingQuery(true); // Show loader for query visualization
    try {
      const res = await fetch(`${api_url}/visualisation/CIDDS_kpi_query`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formDataToSend),
      });
      const data = await res.json();
 
      // Append new query visualization data to the array, including prompt
      setQueryDataArray(prevQueryData => [...prevQueryData, { ...data, prompt }]);
      console.log('Visualization Data:', data);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setLoadingQuery(false); // Hide loader when Query API call finishes
    }
  };
 
  return (
    <>
      {/* Render CIDDS Visualizations */}
      {loadingCidds ? (
        <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        ciddsDataArray.length > 0 &&
        ciddsDataArray.map((ciddsData, index) => (
          <Card
            key={index}
            style={{
              margin: '20px',
              padding: '20px',
              backgroundColor: '#f0f2f5',
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Form.Item label="Prompt" labelCol={{ span: 24 }} colon={false}>
              <Input value={ciddsData.prompt} readOnly />
            </Form.Item>
 
            <div className="visualization-image">
              {ciddsData?.ImageData?.sendVisuals && (
                <img
                  src={`data:image/png;base64,${ciddsData?.ImageData?.sendVisuals}`}
                  alt={`Visualization`}
                  style={{ width: '60%', height: 'auto', marginBottom: '20px' }}
                />
              )}
            </div>
 
            {ciddsData?.ImageData?.explanationList && (
              <Collapse accordion={false} defaultActiveKey={['0']} expandIconPosition="right">
                <Panel header="See Explanation" key="1">
                  <dl>
                    {ciddsData?.ImageData?.explanationList.map((explanation, idx) => (
                      <div key={idx}>
                        <dt>{explanation.section}</dt>
                        <dd>{explanation.explanation}</dd>
                        <hr />
                      </div>
                    ))}
                  </dl>
                </Panel>
              </Collapse>
            )}
          </Card>
        ))
      )}
 
      {/* Render Query Visualizations */}
      {loadingQuery ? (
        <Spin size="large" style={{ display: 'block', margin: '20px auto' }} />
      ) : (
        queryDataArray.length > 0 &&
        queryDataArray.map((queryData, index) => (
          <Card
            key={index}
            style={{
              margin: '20px',
              padding: '20px',
              backgroundColor: '#f0f2f5',
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          >
            <Form.Item label="Prompt" labelCol={{ span: 24 }} colon={false}>
              <Input value={queryData.prompt} readOnly />
            </Form.Item>
 
            <div className="visualization-image">
              {queryData?.ImageData?.sendVisuals && (
                <img
                  src={`data:image/png;base64,${queryData?.ImageData?.sendVisuals}`}
                  alt={`Visualization`}
                  style={{ width: '60%', height: 'auto', marginBottom: '20px' }}
                />
              )}
            </div>
 
            {queryData?.ImageData?.explanationList && (
              <Collapse accordion={false} defaultActiveKey={['0']} expandIconPosition="right">
                <Panel header="See Explanation" key="1">
                  <dl>
                    {queryData?.ImageData?.explanationList.map((explanation, idx) => (
                      <div key={idx}>
                        <dt>{explanation.section}</dt>
                        <dd>{explanation.explanation}</dd>
                        <hr />
                      </div>
                    ))}
                  </dl>
                </Panel>
              </Collapse>
            )}
          </Card>
        ))
      )}
 
      {/* Buttons for Additional Visualizations */}
      <Card
        title="Additional Visualizations"
        style={{
          margin: '20px',
          padding: '20px',
          backgroundColor: '#f0f2f5',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Row gutter={16}>
          {AdditionalVisualizations.map((visualization, index) => (
            <Col key={index} span={6}>
              <Button onClick={() => handleSubmit(visualization.KPI, visualization.text)}>
                {visualization.text}
              </Button>
            </Col>
          ))}
        </Row>
      </Card>
 
      {/* Input for Custom Prompts */}
      <Card
        title="Custom Visualizations"
        style={{
          margin: '20px',
          padding: '20px',
          backgroundColor: '#f0f2f5',
          borderRadius: '8px',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Input
          placeholder="Enter your custom prompt"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handlePromptSubmit(e.target.value);
            }
          }}
        />
      </Card>
    </>
  );
};
 
export default VisualizationComponent;
 