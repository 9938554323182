import cardImg from "../../../assests/img/emp-card-image.png";
import { routes } from "../../../util/constants";
import posemimic from "../../../assests/icon/Pose Mimic.svg";
import speechImg from "../../../assests/icon/Image generation.svg";
import character from "../../../assests/icon/Character Creation.svg";
import virtualImg from "../../../assests/img/virtual.png";
import imageGen from "../../../assests/img/image-generation.png";
import imageEnhancement from "../../../assests/icon/Image Enhancement.svg";
import virtualAi from "../../../assests/img/virtual-ai.png";
import audioImg from "../../../assests/img/audio.png";

export const imageGenerationData = [
  { name: "Pose Mimic", icon: posemimic, url: routes.POSE_MIMIC },
  // { name: "Virtual Try-On", icon: virtualAi, url: routes.VIRTUALTRYON },
  // {
  //   name: "Speech To Image Generation",
  //   icon: speechImg,
  //   url: routes.SPEECHTOIMAGE,
  // },
  // {
  //   name: "Image Generation For Healthcare ",
  //   icon: imageGen,
  //   url: routes.IMGGENFORHEALTH,
  // },
  {
    name: "Character Creation",
    icon: character,
    url: routes.CHARACTERCREATION,
  },
  {
    name: "Image Editing",
    icon: imageEnhancement,
    url: routes.IMAGE_EDITING,
  },
];
