import { Checkbox, Col, Input, Row, Button, Select, Spin } from "antd";
import React, { useState } from "react";
import { language, elevenlabs, languageArray } from "./data";
import { Upload, message } from "antd";
import { DeleteOutlined, InboxOutlined } from "@ant-design/icons";
import { IP } from "../../../../dynamicIPs";

import axios from "axios";

const { Dragger } = Upload;
const { Option } = Select;

export default function VideoTranslation() {
  const [selectedOption, setSelectedOption] = useState("Youtube link");
  const [youtubeLink, setYoutubeLink] = useState();
  const [textToSpeech, setTextToSpeech] = useState("ElevenLabs(High Quality)");
  const [selectLanguage, setSelectLanguage] = useState();
  const [elevenlabsVoice, setElevenLabsVoice] = useState();
  const [isCaptionVideo, setIsCaptionVideo] = useState(false);
  const [uploadVideo, setUploadVideo] = useState();
  const [videoFileName, setVideoFileName] = useState();
  const [responseVideoUrl, setResponseVideoUrl] = useState();
  const [videoLoading, setVideoLoading] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  const handleVideoError = () => {
    setVideoLoaded(false);
    setErrorMessage(
      "Selected translated language doesn't support the given source video !!!"
    );
  };
  const handleVideoLoad = () => {
    setVideoLoaded(true);
    setErrorMessage();
  };
  const handleCheckboxChange = (event) => {
    setIsCaptionVideo(event.target.checked);
    setResponseVideoUrl();
    setErrorMessage();
  };
  const handleSelectElevenlabs = (event) => {
    setElevenLabsVoice(event.target.value);
    setResponseVideoUrl();
    setErrorMessage();
  };
  const handleOptionLanguage = (event) => {
    setSelectLanguage(event.target.value);
    setResponseVideoUrl();
    setErrorMessage();
  };
  const handleYoutubeLink = (event) => {
    setYoutubeLink(event.target.value);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setResponseVideoUrl();
    setErrorMessage();
  };
  const handleOptionChangeTextToSpeech = (event) => {
    setTextToSpeech(event.target.value);
    setResponseVideoUrl();
    setErrorMessage();
  };

  const handleUpload = (file) => {
    setUploadVideo(file);
    setVideoFileName(file.name);
    const reader = new FileReader();

    reader.onload = (event) => {
      message.success(`${file.name} uploaded successfully.`);
    };

    reader.readAsDataURL(file);
  };
  const handleDeleteVideo = () => {
    setVideoFileName();
  };

  const handleSelectLanguage = (event) => {
    setSelectLanguage(event.toUpperCase());
    setResponseVideoUrl();
  };
  const handleYoutubeVideo = () => {
    const videoType = selectedOption;
    const yt_link = selectedOption === "Youtube link" ? youtubeLink : "";
    const tts_engine = textToSpeech;
    const language_eleven = selectLanguage;
    const language_edge = selectLanguage;
    const use_captions = isCaptionVideo;
    const voice =
      textToSpeech === "ElevenLabs(High Quality)" ? elevenlabsVoice : "";
    const file = selectedOption === "Video file" ? uploadVideo : "";
    setVideoLoading(true);
    setResponseVideoUrl();

    const url = `http://${IP.GENAI}:9001/video-translation`;
    const formData = new FormData();
    formData.append("videoType", videoType);
    formData.append("yt_link", yt_link);
    formData.append("tts_engine", tts_engine);
    formData.append("language_eleven", language_eleven);
    formData.append("language_edge", language_edge);
    formData.append("use_captions", use_captions);
    formData.append("voice", voice);
    formData.append("file", file);
    axios
      .post(url, formData)
      .then((res) => {
        setResponseVideoUrl(res.data[0]);
        setVideoLoading(false);
        setVideoLoaded(true);
      })
      .catch((error) => {
        message.error(error.message);
        setVideoLoading(false);
      });
  };

  return (
    <>
      <div className="content-automation-option" style={{ marginTop: "20px" }}>
        <b>
          <p className="choose-option-text">
            Choose a method of Inputting your Video
          </p>
        </b>
        <Row className="choose-option-content" gutter={[16, 8]}>
          <Col className="choose-one-radio">
            <label style={{ padding: "10px" }}>
              <input
                type="radio"
                value="Youtube link"
                checked={selectedOption === "Youtube link"}
                onChange={handleOptionChange}
              />
              <span style={{ marginLeft: "10px" }}>YouTube Link</span>
            </label>
          </Col>
          <Col className="choose-two-radio">
            <label style={{ padding: "10px" }}>
              <input
                type="radio"
                value="Video file"
                checked={selectedOption === "Video file"}
                onChange={handleOptionChange}
              />
              <span style={{ marginLeft: "10px" }}>Video File</span>
            </label>
          </Col>
        </Row>
      </div>
      <div>
        {selectedOption === "Youtube link" ? (
          <div
            className="content-automation-option"
            style={{ marginTop: "20px" }}
          >
            <b>
              <p style={{ fontSize: "16px" }}>YouTube Link</p>
            </b>
            <Input
              type="text"
              value={youtubeLink}
              placeholder="Youtube link"
              onChange={handleYoutubeLink}
              style={{
                border: "1.5px solid #85cdfd",
                borderRadius: "10px",
                height: "40px",
              }}
            />
          </div>
        ) : (
          <div
            className="content-automation-option"
            style={{ marginTop: "20px" }}
          >
            {videoFileName ? (
              <div className="videofilename">
                <span style={{ fontSize: "16px" }}>{videoFileName}</span>
                <span>
                  <DeleteOutlined
                    onClick={handleDeleteVideo}
                    style={{ color: "red", fontSize: "16px" }}
                  />
                </span>
              </div>
            ) : (
              <Dragger
                multiple={false}
                showUploadList={false}
                style={{ border: "2px dotted #85cdfd" }}
                beforeUpload={(file) => {
                  handleUpload(file);
                  return false; // Prevent default upload behavior
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag video file to this area to upload
                </p>
              </Dragger>
            )}
          </div>
        )}
      </div>
      <div className="content-automation-option" style={{ marginTop: "20px" }}>
        <b>
          <p className="choose-option-text">Text to Speech Engine Type</p>
        </b>
        <Row className="choose-option-content" gutter={[16, 8]}>
          <Col className="choose-one-radio">
            <label style={{ padding: "10px" }}>
              <input
                type="radio"
                value="ElevenLabs(High Quality)"
                checked={textToSpeech === "ElevenLabs(High Quality)"}
                onChange={handleOptionChangeTextToSpeech}
              />
              <span style={{ marginLeft: "10px" }}>
                Elevenlabs(High Quality)
              </span>
            </label>
          </Col>
          <Col className="choose-two-radio">
            <label style={{ padding: "10px" }}>
              <input
                type="radio"
                value="Free EdgeTTS (medium quality)"
                checked={textToSpeech === "Free EdgeTTS (medium quality)"}
                onChange={handleOptionChangeTextToSpeech}
              />
              <span style={{ marginLeft: "10px" }}>
                Free EdgeTTS(medium quality)
              </span>
            </label>
          </Col>
        </Row>
      </div>
      {textToSpeech === "ElevenLabs(High Quality)" ? (
        <>
          <div
            className="content-automation-option"
            style={{ marginTop: "20px" }}
          >
            <b>
              <p className="choose-option-text">Language</p>
            </b>
            <Row className="choose-option-content" gutter={[16, 8]}>
              {language.map((lang) => (
                <Col className="language-radio" key={lang}>
                  <label style={{ padding: "5px" }}>
                    <input
                      type="radio"
                      value={lang}
                      checked={selectLanguage === lang}
                      onChange={handleOptionLanguage}
                    />
                    <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                      {lang}
                    </span>
                  </label>
                </Col>
              ))}
            </Row>
          </div>
          <div
            className="content-automation-option"
            style={{ marginTop: "20px" }}
          >
            <b>
              <p className="choose-option-text">Elevenlabs Voice</p>
            </b>
            <Row className="choose-option-content" gutter={[16, 8]}>
              {elevenlabs.map((ele) => (
                <Col className="language-radio" key={ele}>
                  <label style={{ padding: "5px" }}>
                    <input
                      type="radio"
                      value={ele}
                      checked={elevenlabsVoice === ele}
                      onChange={handleSelectElevenlabs}
                    />
                    <span style={{ marginLeft: "5px", fontSize: "14px" }}>
                      {ele}
                    </span>
                  </label>
                </Col>
              ))}
            </Row>
          </div>
        </>
      ) : (
        <div className="language-dropdown dropdown-parent">
          <Select
            showSearch
            suffixIcon={null}
            bordered={false}
            placeholder={
              <span style={{ color: "black" }}>Select Language</span>
            }
            onChange={handleSelectLanguage}
            className="dropdown-child"
            style={{ width: "100%", textAlign: "center" }}
          >
            {languageArray.map((item) => (
              <Option
                key={item}
                value={item}
                style={{ textAlign: "center", backgroundColor: "#e3f6ff" }}
              >
                {item}
              </Option>
            ))}
          </Select>
        </div>
      )}

      <div className="content-automation-option" style={{ marginTop: "20px" }}>
        <Checkbox onChange={handleCheckboxChange}>Caption Video</Checkbox>
      </div>
      <div
        className="run-button"
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            background: "none",
            border: "none",
            fontSize: "16px",
            width: "100%",
          }}
          onClick={handleYoutubeVideo}
        >
          Run
        </Button>
      </div>
      {responseVideoUrl ? (
        videoLoaded ? (
          <>
            <div className="video-container">
              <video
                width="700px"
                height="500px"
                controls
                onLoad={handleVideoLoad}
                onError={handleVideoError}
              >
                <source
                  src={`http://${IP.GENAI}:9001/get-video?video_path=${responseVideoUrl}`}
                  type="video/mp4"
                />
                your browser does not support the video tag
              </video>
            </div>
          </>
        ) : (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "red",
              margin: "20px",
            }}
          >
            {errorMessage}
          </p>
        )
      ) : null}
      {videoLoading ? (
        <div className="video-loader">
          {" "}
          <Spin />{" "}
        </div>
      ) : null}
      {/* <div
        className="run-button"
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          style={{
            background: "none",
            border: "none",
            color: "white",
            fontSize: "16px",
            width: "100%",
          }}
        >
          📁
        </Button>
      </div> */}
    </>
  );
}
