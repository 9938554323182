import React, { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import {
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  message,
  Typography,
  List,
} from "antd";
import axios from "axios";
import { IP } from "../../../../dynamicIPs";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

const { Text } = Typography;

export default function TextSummarization() {
  const [inputValue, setInputValue] = useState("");
  const [selectValue, setSelectValue] = useState("");
  const [submitDisable, setSubmitDisable] = useState(false);
  const [response, setResponse] = useState(null);
  const [resLoading, setResLoading] = useState(false);
  const { Option } = Select;

  const location = useLocation();
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleSelect = (select) => {
    setSelectValue(select);
  };
  const handleSubmit = async () => {
    setResponse(null);
    setResLoading(true);
    setSubmitDisable(true);
    const url = `http://${IP.GENAI}:9005/chat_summarizations`;
    const body = {
      chat_data: inputValue,
      summarizations_type: selectValue,
    };
    try {
      const res = await axios.post(url, body);
      setInputValue("");
      setResLoading(false);
      setResponse(res.data);
    } catch (error) {
      setResLoading(false);
      message.error(error.message);
    }
  };
  const selectOption = [
    {
      label: "General Key Point Summarization",
      value: "general_summerization",
    },
    { label: "3-Point Summarization", value: "3_point_summerization" },
  ];
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.Delay} type="video/mp4" pdfSrc={pdfUrls.YTPL_Holiday_Calendar_2022} />
      </div>
      <Card style={{ marginTop: "30px" }}>
        <b>
          <p>Text:</p>
        </b>
        <Input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Example:  Please provide an example of a chat conversation between a customer and an agent regarding a customer query."
        />
        <Row style={{ marginTop: "30px" }}>
          <Col span={24}>
            <Select
              placeholder={<span>Please Select</span>}
              onChange={handleSelect}
              className="dropdown-child"
              style={{
                width: "100%",
                textAlign: "center",
                color: "black",
              }}
            >
              {selectOption.map((item, index) => (
                <Option
                  key={index}
                  value={item.value}
                  style={{ textAlign: "center" }}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Button
          type="primary"
          style={{ width: "100%", marginTop: "30px" }}
          onClick={handleSubmit}
          loading={resLoading}
        >
          Submit
        </Button>
      </Card>
      {response !== null ? (
        <Card style={{ marginTop: "30px" }}>
          <Text>{response}</Text>
        </Card>
      ) : null}
    </>
  );
}
