import {  routes, streamlitRoutes } from "../../../util/constants";
import billAnalyzer from "../../../assests/img/bill-analyser.jpg";
import fra from "../../../assests/img/financial-report-analyzer.jpg";
import contarctAnz from "../../../assests/img/contract-anaz.jpg";


export const financeData = [
  {
    name: "Bills Analyzer",
    icon: billAnalyzer,
    url: routes.BILLS_ANALYZER,
    videoSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Invoice%20Analyzer.mp4",
    pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/bills_analyzer_prompts.pdf",
    awsVideo: true,
    streamlit: true,
    tag: "Productivity",
    desc: "Upload your invoices, receipt and POS. Get them analyzed and gain insights within seconds.",
  },
  {
    name: "Financial Report Analyzer",
    icon: fra,
    url: routes.FINANCIAL_REPORT_ANALYZER,
    videoSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Financial_report_analyzer-recording.mp4",
    pdfSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/financial.pdf",
    awsVideo: true,
    streamlit: true,
    tag: "Productivity",
    desc: "Solution for comprehensive analysis of financial reports. It efficiently extracts pertinent information related to company's performance, empowering users with valuable insights to facilitate informed decision-making processes in a timely manner.",
  },
  {
    name: "Contract Analyzer",
    icon: contarctAnz,
    url: routes.CONTRACT_ANALYZER,
    videoSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/contract%20analyzer.mp4",
    pdfSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Contract%20Analyzer.pdf",
    awsVideo: true,

    streamlit: true,
    tag: "Productivity",
    desc: "Contracts are lengthy and difficult to deal with. Check out our contract analyzer which summarizes key points, and offers valuable recommendations.",
  },
];
