import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import { videourls } from "./VideoFiles";
import { Button, Card, Carousel, Input, Modal, Tooltip, message } from "antd";
import { IP } from "../../../../dynamicIPs";
import axios from "axios";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

export default function VoiceCloning() {
  const location = useLocation();
  const [isgeneratedVideoUrl, setIsGeneratedVideoUrl] = useState(false);
  const [generateVideoUrl, setgenerateVideoUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const [allVideoUrls, setAllVideoUrls] = useState([]);
  const [videoLoaded, setVideoLoaded] = useState(true);
  const handleVideoError = () => {
    setVideoLoaded(false);
  };
  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };

  const handleOnClick = async () => {
    setLoading(true);

    const url = `http://${IP.VOICECLONING}:8008/cloning`;
    const params = {
      query_string: inputText,
    };
    try {
      setIsGeneratedVideoUrl(false);
      const res = await axios.get(url, { params });
      setIsGeneratedVideoUrl(true);
      setgenerateVideoUrl(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  return (
    <div>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto", float: "right" }}>
        <VideoTour src={videoUrls.cloning_and_lip_sync} type="video/mp4" pdfSrc={pdfUrls.voice_cloning_prompts} />
      </div>

      {allVideoUrls.length > 0 ? (
        <div style={{ margin: "40px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <b>
              <p>CAROUSEL OF PREVIOUSLY GENERATED VIDEOS</p>
            </b>
          </div>
          {/* <Carousel autoplay>
            {allVideoUrls.map((v) => (
              <div>
                <video
                  width="700px"
                  height="500px"
                  controls
                  onLoad={handleVideoLoad}
                  onError={handleVideoError}
                >
                  <source
                    src={`http://${localStorage.getItem(
                      "ip"
                    )}:8008/cloning?query_string=${generateVideoUrl}`}
                    type="video/mp4"
                  />
                  your browser does not support the video tag
                </video>
              </div>
            ))}
          </Carousel> */}
        </div>
      ) : null}

      <div style={{ margin: "30px" }}>
        <b>
          <p>Input Text:</p>
        </b>

        <Input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
      </div>
      <div style={{ margin: "30px" }}>
        <Button
          type="primary"
          style={{ width: "100%", borderRadius: "10px" }}
          onClick={handleOnClick}
          loading={loading}
        >
          Submit
        </Button>
      </div>
      <div>
        {isgeneratedVideoUrl ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <video
              width="500px"
              height="300px"
              style={{ marginBottom: "20px" }}
              controls
              onLoad={handleVideoLoad}
              onError={handleVideoError}
            >
              <source
                src={`http://${IP.VOICECLONING}:8008/cloning?query_string=${inputText}`}
                type="video/mp4"
              />
              your browser does not support the video tag
            </video>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
