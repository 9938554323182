import textgen from "../assests/icon/Text generation.svg";
import resumeImg from "../assests/img/resume.jpg";
import translationImg from "../assests/icon/Language Translation Bot.svg";
import voicecloningimage from "../assests/icon/Voice Cloning & Lip Synchronization.svg";
import videoautomation from "../assests/icon/Video Translation.svg";
import pptGen from "../assests/icon/AI-Powered Presentation Creation.svg";
import imageEnhancement from "../assests/icon/Image Enhancement.svg";
import tableImg from "../assests/img/sdlc.jpg";
import visualizationGenerationIcon from "../assests/img/lida.jpg";
import genAiChatbotImg from "../assests/icon/Database Agents.svg";
import cardImg from "../assests/icon/Employee Assistance bot.svg";
import salesdataImg from "../assests/icon/Sales Chatbot.svg";
import TextSumImg from "../assests/icon/Text Summarization.svg";
import QA from "../assests/img/Q&A.png";
import langchain from "../assests/img/langchain.jpg";
import dataanalImg from "../assests/icon/Data Analysis Bot.svg";
import musicImg from "../assests/icon/Music Generation Bot.svg";
import posemimic from "../assests/icon/Pose Mimic.svg";
import character from "../assests/icon/Character Creation.svg";
// import imageEnhancement from "../assests/icon/Image Enhancement.svg";
import humanResource from "../assests/img/human-resource.jpg";
import finance from "../assests/img/finance.jpg";
import sales from "../assests/img/sales&marketing.jpg";
import IT from "../assests/img/IT.png";
import operations from "../assests/img/operations.jpg";
import others from "../assests/img/others.png";
import { domainRoutes, routes, streamlitRoutes } from "./constants";
import itDashboard from "../assests/img/itDashboard.jpg";
import virtualtravel from "../assests/img/virtualtravel.jpg";
import nutrition from "../assests/img/nutrition.jpg";
import { pdfData } from "../ui-components/AllPoc/TextGeneration/OpenAiChatBot/Data";
import { videoUrls } from "./videoData";
import { pdfUrls } from "./pdfData";

export const domainsList = [
  {
    name: "Human Resource",
    icon: humanResource,
    url: domainRoutes.HUMAN_RESOURCE,
    desc: "HR in the Gen AI era utilizes AI for streamlined resume screening and summarization, enabling recruiters to quickly identify top talent. Language translation simplifies communication across global teams.",
    categories: [
      {
        name: "Resume Genie",
        // icon: resumeImg,
        url: routes.RESUME_GENIE,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Resume%20Genie%20recording.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Resume%20Genie.pdf",
        awsVideo: true,
        tag: "Virtual Assistant",
        desc: "Revolutionize your recruitment process with AI-powered Resume Genie. This comprehensive toolkit empowers HR professionals to efficiently filter, screen, & analyze large volumes of resumes. ",
      },
      {
        name: "Language Translation",
        // icon: translationImg,
        url: routes.LANGUAGE_TRANSLATION_BOT,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Language_translation.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/language_translation_sample.pdf",
        awsVideo: true,
        tag: "Chatbot",
        desc: "English to German and German to English language tanslation bot",
      },
      {
        name: "Voice Cloning And Lip Syncing",
        // icon: voicecloningimage,
        url: routes.VOICE_CLONING_AND_LIP_SYNCHRONIZATION,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/cloning%20and%20lip%20sync.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/voice_cloning%20prompts.pdf",
        awsVideo: true,
        tag: "Creativity",
        desc: "Generate lip synced videos from a script with a custom cloned voice. Seamlessly create realistic and engaging videos.",
      },
      {
        name: "Ask HR",
        // icon: cardImg,
        url: routes.ASK_HR,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/ask_hr_video.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Ask_hr_sample_questions.pdf",
        awsVideo: true,
        tag: "Chatbot",
        desc: "Confused by company policies? AskHR has you covered! Get instant answers to all your HR questions, from benefits and leave requests to compliance and company procedures.",
      },
      {
        name: "Multilingual, Multi-LLM assistant",
        // icon: multiLLM,
        url: routes.MULTI_LLM_ASSISTANT,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/multilingual_multillm_video.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/multillm_questions_to_try.pdf",
        awsVideo: true,
        streamlit: true,
        tag: "Chatbot",
        desc: "Chat for HR policies with multple LLM. Chat in Arabic or English language.",
      },
      {
        name: "Enterprise ChatGPT",
        // icon: multiLLM,
        url: routes.ENTERPRISE_CHATBOT,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Enterprise+ChatGPT.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Enterprise+ChatGPT.pdf",
        awsVideo: true,
        streamlit: true,
        tag: "Chatbot",
        desc: "Secure AI-powered chatbot tailored to your private data. Seamlessly integrates with SharePoint and other data sources. Enforces granular access controls, ensuring users only retrieve information they're authorized to see.",
      },
      {
        name: "SmartHIRE",
        // icon: smartHireImage,
        url: routes.SMART_HIRE,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Smart%20HIRE%20walkthrough.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/smartHire.pdf",
        awsVideo: true,
        streamlit: true,
        tag: "Virtual Assistant",
        desc: "Find your next perfect talent with AI Video Interview Platform. Screen and interview thousands of candidates at the same time via live AI Interview. Get a curated shortlist of talent with the soft skills and abilities you need. Achieve 90% candidate satisfaction.",
      },
      {
        name: "Gmail GenAI Toolkit",
        // icon: multiLLM,
        url: routes.GMAIL_GENAI_TOOLKIT,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/gmail_genai_toolkit.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Gmail_Genai_Toolkit.pdf",
        awsVideo: true,
        // streamlit: true,
        tag: "Toolkit",
        desc: "Transform your email experience effortlessly! Perform all email actions, fetch and summarize important messages, match job profiles, and more with our integrated solution. Boost productivity and streamline processes for a seamless workflow!",
      },
      // {
      //   name: "Financial Report Analyzer",
      //   icon: cardImg,
      //   url: streamlitRoutes.FINANCIAL_REPORT_ANALYZER,
      //   streamlit: true,
      //   tag: "Productivity",
      //   desc: "Solution for comprehensive analysis of financial reports. It efficiently extracts pertinent information related to company's performance, empowering users with valuable insights to facilitate informed decision-making processes in a timely manner.",
      // },
    ],
  },
  {
    name: "Sales & Marketing",
    icon: sales,
    url: domainRoutes.SALES_AND_MARKETING,
    desc: "Empowers sales & marketing with AI-powered ad creation, captivating presentations, insightful reports, saving time and resources. Sales chatbots engage leads 24/7 to boost customer journeys.",
    categories: [
      {
        name: "Advertisement & Video Generator",
        // icon: videoautomation,
        url: routes.ADVERTISEMENT_AND_VIDEO_TRANSLATION_GENERATOR,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Advertisement%20Content%20Generator%20recording.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Advertisement%20Content%20Generator.pdf",
        awsVideo: true,

        tag: "Productivity",
        desc: "Turn Words into Captivating advertisements with AdGen AI. Our cutting-edge platform allows you to effortlessly generate ad videos, with captions & custom voices using natural language prompts.",
      },
      {
        name: "AI Powered Presentation Creation",
        // icon: imageEnhancement,
        url: routes.AI_POWERED_PRESENTATION_CREATION,
        tag: "Productivity",
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/PPT%20maker.mp4",
        awsVideo: true,
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/PPT%20maker.pdf",
        desc: "Say hello to captivating presentations! Choose from readily available templates, mention the topic, number of slides & turn your ideas into professional PowerPoint presentations in few steps.",
      },
      // {
      //   name: "Image Editing",
      //   // icon: pptGen,
      //   // url: routes.IMAGE_EDITING,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/image_editor_recording.mp4",
      //   awsVideo: true,

      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/image_editor_prompt.pdf",
      //   tag: "Creativity",
      //   desc: "Unlock the power of text-guided image editing with our innovative tool. Imagine a world where you can seamlessly inpaint, outpaint, and edit images with just your words.",
      // },
      // {
      //   name: "Financial Report Analyzer",
      // icon: fra,
      //   url: routes.FINANCIAL_REPORT_ANALYZER,
      //   streamlit: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Financial_report_analyzer-recording.mp4",
      //   awsVideo: true,
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/financial.pdf",
      //   tag: "Productivity",
      //   desc: "Solution for comprehensive analysis of financial reports. It efficiently extracts pertinent information related to company's performance, empowering users with valuable insights to facilitate informed decision-making processes in a timely manner.",
      // },
      {
        name: "Sales Chatbot",
        // icon: salesdataImg,
        url: routes.SALES_CHATBOT,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Sales%20Chatbot.mp4",
        awsVideo: true,
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Sales%20Chatbot.pdf",
        tag: "Chatbot",
        desc: "An agent chatbot which can have a natural sales conversation with a prospect and behaves based on the conversation stage. It helps to automate sales development representative’s activities, such as outbound sales calls.",
      },
      // {
      //   name: "Virtual Vogue",
      // icon: virtaulV,
      //   url: streamlitRoutes.VIRTUAL_VOGUE,
      //   streamlit: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/VirtualVogue.mp4",
      //   awsVideo: true,
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/VirtualVogue.pdf",
      //   tag: "Creativity",
      //   desc: "See it before you wear it! Our cutting-edge technology lets you virtually try on clothes using just a picture. Upload an image of yourself, browse through our selection, and see how item looks on you from the comfort of your home and ditch the guesswork before you buy.",
      // },
      {
        name: "Image Campaign Creation",
        // icon: imageCampaign,
       url: routes.IMAGE_CAMPAIGN_CREATION,
        streamlit: true,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Image%20campaign%20creation.mp4",

        awsVideo: true,
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Image_campaign_creation_sample_images.pdf",
        tag: "Creativity",
        desc: "Innovative Generative AI utilized to create highly personalized media ads for social and internet platforms. Analyzing user data enhances ad relevance, engagement, and targeting, revolutionizing advertising effectiveness and audience connection.",
      },
      {
        name: "Digital Catalogue Summarizer",
        // icon: pptsumarizer,
        url: routes.DIGITAL_CATALOGUE,
        streamlit: true,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/digital%20catalogue%20summarizer.mp4",
        awsVideo: true,
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/digital%20catalogue%20summarizer.pdf",
        tag: "Productivity",
        desc: "The digital catalogue summarizer utilizes Generative AI to condense lengthy presentations into concise summaries, providing users with a quick and efficient way to grasp key information from heterogenous PPTs. ",
      },
      {
        name: "Product Recommendation",
        // icon: prod,
        url: routes.PRODUCT_RECOMMENDATION,
        // streamlit: true,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Product_reccomendation_and_support_Recording.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Product%20Reccomendation%20and%20support%20Sample%20Questions.pdf",
        awsVideo: true,
        tag: "Chatbot",
        desc: "This AI chatbot, built with Autogen, Python, AWS Bedrock, and Chroma DB, recommends products and solves issues with past purchases using a multi-agent system for intent, search, and response.",
      },
      // {
      //   name: "Personalized Avatar Generation",
      //   // icon: personalizeAvatar,
      //   // url: streamlitRoutes.PEZT_AVATAR_GENERATION,
      //   // streamlit: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Avatar.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Avatar%20Generation.pdf",
      //   awsVideo: true,
      //   awsVideo: true,
      //   tag: "MISC",
      //   desc: "Personalized Avatar Generation using Generative AI - Stable Diffusion model to generate personalized avatars with a simple text prompt.",
      // },
      {
        name: "Virtual Tryon",
        // icon: personalizeAvatar,
        url: routes.VIRTUALTRYON,
        // streamlit: true,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/virtualTryOn.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Copy%20of%20Virtual%20Try%20on%20simplified.pdf",
        awsVideo: true,
        tag: "Creativity",
        desc: "Search clothes from different ecommerce sites, simply drag and drop what you like to try it on with the power of AI.",
      },
    ],
  },
  {
    name: "Finance",
    icon: finance,
    url: domainRoutes.FINANCE,
    desc: "Streamlines finance with AI-powered bill analysis, generating accurate reports and identifying savings. Contract analysis tools ensure crucial details are captured, mitigating risks and streamlining workflows.",
    categories: [
      {
        name: "Bills Analyzer",
        // icon: billAnalyzer,
        url: routes.BILLS_ANALYZER,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Invoice%20Analyzer.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/bills_analyzer_prompts.pdf",
        awsVideo: true,
        streamlit: true,
        tag: "Productivity",
        desc: "Upload your invoices, receipt and POS. Get them analyzed and gain insights within seconds.",
      },
      {
        name: "Financial Report Analyzer",
        // icon: fra,
        url: routes.FINANCIAL_REPORT_ANALYZER,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Financial_report_analyzer-recording.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/financial.pdf",
        awsVideo: true,
        streamlit: true,
        tag: "Productivity",
        desc: "Solution for comprehensive analysis of financial reports. It efficiently extracts pertinent information related to company's performance, empowering users with valuable insights to facilitate informed decision-making processes in a timely manner.",
      },
      {
        name: "Contract Analyzer",
        // icon: contarctAnz,
        url: routes.CONTRACT_ANALYZER,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/contract%20analyzer.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Contract%20Analyzer.pdf",
        awsVideo: true,

        streamlit: true,
        tag: "Productivity",
        desc: "Contracts are lengthy and difficult to deal with. Check out our contract analyzer which summarizes key points, and offers valuable recommendations.",
      },
    ],
  },
  {
    name: "Operations",
    icon: operations,
    url: domainRoutes.OPERATIONS,
    desc: "Find our tools to ease out operations across your businesses. Leverage them internally or let your customers know about processes, workflows and similar things.",
    categories: [
      {
        name: "Banking Pal",
        // icon: ibc,
        url: routes.BANKING_PAL,

        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/ibc_app_record_90sec+1.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Banking_pal_sample_questions.pdf",
        awsVideo: true,
        tag: "Chatbot",
        desc: "A gen AI-based solution to assist bank employees in answering questions related to bank processes, procedures etc.",
      },
      {
        name: "Custom Persona GPT",
        // icon: hbf,
        url: routes.HBF,

        videoSrc: videoUrls.custom_persona,
        pdfSrc: pdfUrls.persona_pdf,
        awsVideo: true,
        tag: "Chatbot",
        desc: "Secure AI-powered chatbot tailored to your private data. Seamlessly integrates with SharePoint and other data sources. Enforces granular access controls, ensuring users only retrieve information they're authorized to see.",
      },
      // {
      //   name: "Banking Pal",
      // icon: ibc,
      //   url: routes.BANKING_PAL,
      //   isAws: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Banking_pal.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Banking_pal_sample_questions.pdf",
      //   awsVideo: true,
      //   tag: "Chatbot",
      //   desc: "A gen AI-based solution to assist bank employees in answering questions related to bank processes, procedures etc.",
      // },
      {
        name: "User Manual Bot",
        // icon: usermanualbot,
        url: routes.USER_MANUAL,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/usermanual_usecase_recording.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/user_manual.pdf",
        awsVideo: true,
        // streamlit: true,
        tag: "Chatbot",
        desc: "Text with image responses for sales manual,stepwise how to guides for machine manuals.",
      },
      {
        name: "Legal Doc Summarization",
        // icon: legal,
        url: routes.LEGAL_DOC_SUMMARIZATION,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Legal%20Doc%20Summarizer.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/legaldoc1.pdf",
        awsVideo: true,
        // streamlit: true,
        tag: "Chatbot",
        desc: "The Legal Document Summarizer using RaptorRAG leverages LLMs to generate concise, contextually accurate summaries of lengthy legal documents, streamlining legal reviews and boosting productivity.",
      },
      // {
      //   name: "E-Kyc",
      // icon: kyc,
      //   url: streamlitRoutes.E_KYC,
      //   streamlit: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/KYC-Recording.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/IDP-Prompts.pdf",
      //   awsVideo: true,
      //   tag: "Productivity",
      //   desc: "Get your customers onboarded and make your Sign Up process more secure than just a verification code. Keep Aadhaar, PAN handy and know your customer in seconds.",
      // },
      // {
      //   name: "IDP Processor",
      // icon: idp,
      //   // url: streamlitRoutes.PEZT_AVATAR_GENERATION,
      //   // streamlit: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/IDP.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/IDP-Prompts.pdf",
      //   awsVideo: true,
      //   tag: "MISC",
      //   desc: "Intelligent Document Processing for insurance and public sector",
      // },

      {
        name: "Langchain SQL Agent",
        // icon: langchain,
        url: routes.LANGCHAIN_SQL_AGENT,
        // streamlit: true,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/LangchainSQLAgent-usecase.mp4",
        pdfSrc: pdfUrls.langchain,
        awsVideo: true,
        tag: "MISC",
        desc: "A collaborative multi-agent based system that assists in providing scores to the potential leads based on the data provided thus helping in sales conversions.",
      },

      {
        name: "Auto Visualization",
        // icon: autovisual,
        url: routes.AUTO_VISUALIZATION,
        // streamlit: true,
        videoSrc: videoUrls.auto_visualization,
        pdfSrc: pdfUrls.auto_visualization,
        awsVideo: true,
        tag: "MISC",
        desc: "Business & IT Operations Network Dashboard, powered by LLM, is a comprehensive tool designed for the automatic generation of visualizations and infographics. It seamlessly integrates with various software systems, including Worksoft CTM and Checkmarx, to provide detailed insights and analytics.",
      },
      {
        name: "Sales Lead Automation",
        // icon: salesLeadautomation,
        url: routes.SALES_LEAD_AUTOMATION,
        // streamlit: true,
        videoSrc: videoUrls.sales_lead_automation,
        pdfSrc: pdfUrls.sales,
        awsVideo: true,
        tag: "Agent",
        desc: "An AI powered assistant that provides status on order tracking to the customers and recent updates.",
      },
      {
        name: "Agentic Trip Advisor",
        // icon: trip,
        url: routes.AGENTIC_TRIP_ADVISOR,
        // streamlit: true,
        videoSrc: videoUrls.trip_planner,
        pdfSrc: pdfUrls.trip,
        awsVideo: true,
        tag: "Agent",
        desc: "Discover flights, hotels, attractions, upcoming events, trip advisories and more. Book flights effortlessly and explore your destination's highlights in one convenient place.",
      },
    ],
  },
  {
    name: "IT",
    icon: IT,
    url: domainRoutes.IT,
    desc: "Streamlines software development with AI-powered tools: automating parts of the SDLC, generating code snippets, and deploying database agents for automation. Semantic search empowers IT staff with faster, more relevant information retrieval.",
    categories: [
      {
        name: "SDLC",
        // icon: tableImg,
        url: routes.SDLC,
        tag: "Productivity",
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/SDLC.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/SDLC%20Prompts.pdf",
        awsVideo: true,
        desc: "Code Generation, Bug Detection and Resolution, Summary for the code and Code Comments, Generating Test Cases.",
      },
      // {
      //   name: "Data Visualization",
      //   icon: visualizationGenerationIcon,
      //   url: routes.VISUALIZATION,
      //   tag: "Productivity",
      //   videoSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Visualization.mp4",
      //   pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Visualization%20Prompts.pdf",
      //   awsVideo: true,
      //   desc: "Turn your data into insights instantly. Upload your CSV files and let our intuitive tool generate various interactive graphs based on your queries.",
      // },
      {
        name: "Database Agents",
        // icon: genAiChatbotImg,
        url: routes.DATABASE_AGENTS,
        tag: "Chatbot",
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/DB%20agents%20recording.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Db%20Agents.pdf",
        awsVideo: true,
        desc: "SQL agents are here to help you deal with massive databases of different types. Natural language queries and responses are here for everyone.",
      },
      {
        name: "DevSecOps Chatbot",
        // icon: devSecOps,
        // url: streamlitRoutes.DEVSEC_OPS_CHATBOT,
        // streamlit: true,
        awsVideo: true,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/devsecops_recording.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/devSecOps.pdf",
        tag: "MISC",
        desc: "The DevSecOps Aid is your on-demand security expert, always at your fingertips. If your seeking guidance, or want to stay informed about security threats and vulnerabilities check our tool.",
      },
      {
        name: "LLM Semantic Search",
        // icon: semanticSearch,
        // url: streamlitRoutes.LLM_SEMANTIC_SEARCH,
        // streamlit: true,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Semantic%20Search.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/semanticSearch.pdf",
        awsVideo: true,
        tag: "MISC",
        desc: "Witness the efficiency and accuracy of Semantic Search clubed with Semantic Search.",
      },
      // {
      //   name: "SDLC AI",
      //   icon: sdlcAiImage,
      //   url: routes.SDLC_AI,
      //   streamlit: true,
      //   videoSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/SDLC%20AI%20usecase%20simplified2.mp4",
      //   pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/sdlc%20AI%20bot%20simplified.pdf",
      //   awsVideo: true,
      //   tag: "Toolkit",
      //   desc: "This innovative feature greatly improves development efficiency, shortens development cycles, and reduces communication costs, resulting in higher-quality software delivery.",
      // },
      {
        name: "ExecutorAI",
        // icon: semanticSearch,
        url: routes.EXECUTOR_AI,
        streamlit: true,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/code%20executor.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/executorAi.pdf",
        awsVideo: true,
        tag: "Productivity",
        desc: "Automated AI Bot to generate, execute, and debug Python code for given tasks, facilitating seamless task completion through automated assistance and feedback. ",
      },
      // {
      //   name: "Data Analytics Dashboard powered by GenAI",
      //   icon: itDashboard,
      //   url:routes.DATA_ANALYTICS_DASHBOARD,
      //   streamlit: true,
      //   videoSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Dashboard%20Recording.mp4",
      //   pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/ItDashboard.pdf",
      //   awsVideo: true,
      //   tag: "Toolkit",
      //   desc: "This application is a robust data analysis and visualization platform powered by generative AI. It simplifies data processing, offers customizable KPIs, intuitive visualizations, and facilitates the creation of consolidated reports.",
      // },

      {
        name: "Impact SDLC",
        // icon: impact,
        url: routes.IMPACT_SDLC,
        videoSrc: videoUrls.impact,
        pdfSrc: pdfUrls.impact,
        awsVideo: true,
        tag: "",
        desc: "Manage different phases of software development lifecycle infused with power of Gen AI with an intuitive dashboard and manage different projects, tasks and users efficiently.",
      },
      // {
      //   name: "Virtual Travel Agent",
      //   icon: virtualtravel,
      //   url: routes.VIRTUAL_TRAVEL_AGENT,
      //   videoSrc: videoUrls.travelAgent ,
      //   pdfSrc: pdfUrls.travelAgent,
      //   awsVideo: true,
      //   tag: "ChatBot",
      //   desc: "This streamlit application simulates Virtual Travel Agent that interacts with users by answering travel-related questions and generate only travel related responses.",
      // },

      {
        name: "AWS Guardrails Travel Agent",
        // icon: gta,
        url: routes.GUARDRAILS_TRAVEL_AGENT,
        videoSrc: videoUrls.travelAgent,
        pdfSrc: pdfUrls.travelAgent,
        awsVideo: true,
        tag: "ChatBot",
        desc: "This streamlit application simulates Aws Guardrails Travel Agent that interacts with users by answering travel-related questions and generate only travel related responses.",
      },
      {
        name: "Conversation Summarizer & Analyzer",
        // icon: csa,
        url: routes.CONVERSATION_SUMMARIZER_AND_ANALYZER,
        videoSrc: videoUrls.csa,
        pdfSrc: pdfUrls.csa,
        awsVideo: true,
        tag: "ChatBot",
        desc: " Unlock insights from audio files with our AI-powered analysis tool. Seamlessly transcribe, summarize, analyze sentiment, and extract key issues to drive data-informed business decisions.",
      },
      {
        name: "Code Generator",
        // icon: csa,
        url: routes.CODE_GENERATOR,
        videoSrc: videoUrls.code_generator,
        pdfSrc: pdfUrls.code_generator,
        awsVideo: true,
        tag: "ChatBot",
        desc: " Unlock insights from audio files with our AI-powered analysis tool. Seamlessly transcribe, summarize, analyze sentiment, and extract key issues to drive data-informed business decisions.",
      },
    ],
  },
  {
    name: "Others",
    icon: others,
    url: domainRoutes.OTHERS,
    desc: "Find our other tools ranging from QnA bot for students to creative content creators, photo editors to checking medicines and automobiles for imperfections and using generative AI tools to address different problems.",
    categories: [
      // {
      //   name: "Text Summarization",
      //   icon: TextSumImg,
      //   url: routes.TEXT_SUMMARIZATION,
      //   tag: "Productivity",
      //   desc: "OpenAI is an AI research and deployment company. Our mission is to ensure that artificial general intelligence benefits all of humanity.",
      // },
      // {
      //   name: "Youtube Video Q&A Bot",
      //   // icon: QA,
      //   // url: routes.YOUTUBE_VIDEO_QA_BOT,
      //   awsVideo: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/YT%20Q%26A.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/YT%20Q%26A.pdf",
      //   tag: "Productivity",
      //   desc: "Educators and HR professionals, elevate your learning & development programs! We offer a suite of AI-powered video tools designed to - resynthesize videos in language of your choice with captioning and variety of voices.",
      // },
      // {
      //   name: "Data Analysis Bot",
      //   icon: dataanalImg,
      //   url: routes.DATA_ANALYSIS_BOT,
      //   tag: "Productivity",
      //   desc: "OpenAI is an AI research and deployment company. Our mission is to ensure that artificial general intelligence benefits all of humanity.",
      // },
      // {
      //   name: "Music Generation Bot",
      //   icon: musicImg,
      //   url: routes.MUSICCOMPOSITION,
      //   tag: "Creativity",
      //   desc: "OpenAI is an AI research and deployment company. Our mission is to ensure that artificial general intelligence benefits all of humanity.",
      // },
      // {
      //   name: "Pose Mimic",
      //   // icon: posemimic,
      //   // url: routes.POSE_MIMIC,
      //   awsVideo: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/pose_mimic.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/pose_mimic_prompts.pdf",
      //   tag: "Creativity",
      //   desc: "Witness the prowess of controlling the image generation with your poses and natural language prompts to create Disney Chars and Real humans for different applications.",
      // },
      // {
      //   name: "Character Creation",
      //   // icon: character,
      //   // url: routes.CHARACTER_CREATION,
      //   awsVideo: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/character_creation.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/character_creation_prompts.pdf",
      //   tag: "Creativity",
      //   desc: "Create custom characters and let massive image generation model re-synthesis your subject with natural language prompts. Image generation with endless possibilities.",
      // },
      // {
      //   name: "Pharma Helper",
      //   // icon: pharma,
      //   // url: routes.PHARMA_HELPER,
      //   awsVideo: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Pharma%20helper.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/pharma_helper.pdf",
      //   tag: "Productivity",
      //   desc: "Your friendly Medical assistant. Seeing is believing, feeling is understanding. Ask our image-savvy AI assistant any health question and get insightful information based on your picture. We guide you, not diagnose, for a healthier, more informed you.",
      // },
      // {
      //   name: "Automob-AI",
      //   // icon: automobAI,
      //   // url: routes.AUTOMOBILE_AI,
      //   awsVideo: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/AutomobAI.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/AutomobAI.pdf",
      //   tag: "Productivity",
      //   desc: "Your friend for automobiles. Forget searching manuals, our AI mechanic speaks your language! Just snap a pic of your car and ask any question. We'll diagnose the issue, suggest fixes, and keep your ride running smooth. It's like having a pocket-sized expert by your side.",
      // },
      {
        name: "Ams Ticket Tool",
        // icon: ticket,
        url: routes.AMS_TICKET_TOOL,
        videoSrc: videoUrls.ams,
        pdfSrc: pdfUrls.ams,
        awsVideo: true,
        tag: "ChatBot",
        desc: "AMS tickets often contain repetitive issues that can be resolved by referencing historical data. By using Combination of Azure OpenAI GPT model and RAG, the system can generate list of all possible contextually relevant solutions by retrieving similar past cases and angulating the generated response for incoming new ticket.",
      },
      // {
      //   name: "Gen AI Agents",
      //   // icon: genAI,
      //   // url: streamlitRoutes.GEN_AI_AGENTS,
      //   // streamlit: true,
      //   awsVideo: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Financial%20services%20agent.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/financialservices_agent.pdf",
      //   tag: "MISC",
      //   desc: "AI agent helps users find account info, apply for loans, and answer natural language questions with sources. Developers can use this tech to build chatbots, assistants, and more.",
      // },
      // {
      //   name: "Wildfire Detection",
      //   icon: wildfire,
      //   url: streamlitRoutes.WILDFIRE_DETECTION,
      //   streamlit: true,
      //   awsVideo: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/wildfire_rec.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/wildfire.pdf",
      //   tag: "MISC",
      //   desc: "A solution for accurately identifying forest fire using stationary cameras mounted at a safe location. we achieve early detection of forest fires, protecting both precious ecosystems and human lives.",
      // },
      {
        name: "Manufacturing Catalyst",
        // icon: manufacturing,
        url: routes.MANUFACTURING_CATALYST,
        streamlit: true,
        awsVideo: true,
        videoSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Manufacturing%20Catalyst.mp4",
        pdfSrc:
          "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/ManufacturingCatalyst.pdf",
        tag: "Productivity",
        desc: "Seamlessly extract insights from uploaded manufacturing machinery fault videos, streamlining fault detection and troubleshooting processes with advanced AI-driven analysis. Improve efficiency and accuracy in diagnosing issues, enhancing overall production reliability and minimizing downtime.",
      },
      // {
      //   name: "Queue Analyser",
      //   // icon: manufacturing,
      //   // url: routes.QUEUE_ANALYZER,
      //   streamlit: true,
      //   awsVideo: true,
      //   videoSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Demo_Queue_Analyser.mp4",
      //   pdfSrc:
      //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Queue_Analyzer.pdf",
      //   tag: "MISC",
      //   desc: "The Queue Analyser is an application designed to streamline queue management and enhance customer experience in various settings, from retail stores to healthcare facilities. It empowers businesses to improve operational efficiency, reduce wait times",
      // },
      {
        name: "Nutrition & Fitness Advisor",
        // icon: nutrition,
        url: routes.NUTRITION_AND_FITNESS_ADVISOR,
        streamlit: true,
        awsVideo: true,
        videoSrc: videoUrls.nutrition,
        pdfSrc: pdfUrls.nutrition,
        tag: "Chatbot",
        desc: "This use case leverages AWS Bedrock and Autogen to deliver personalized nutrition and fitness plans, using AI to tailor recommendations based on user preferences, goals.",
      },

      {
        name: "CSR Agent",
        // icon: csr,
        url: routes.CSR,
        streamlit: true,
        awsVideo: true,
        videoSrc: videoUrls.csr,
        pdfSrc: pdfUrls.csr,
        tag: "MISC",
        desc: "CSR Agent Assist provides two key modes:Conversation Mode and Incident Mode, each offering several functionalities to enhance call center operations.",
      },
      {
        name: "Intelligent Document Processing",
        // icon: idpp,
        url: routes.INTELLIGENT_DOCUMENT_PROCESSING,
        videoSrc: videoUrls.idp,
        pdfSrc: pdfUrls.idp,
        awsVideo: true,
        tag: "ChatBot",
        desc: "Streamline your document processing with our AI-powered solution. Effortlessly analyze, translate, and extract insights from PDFs and web content while ensuring data security and multilingual support.",
      },

      {
        name: "AWS Q on YashTechAI",
        // icon: yashtechai,
        url: routes.AWSQ,
        streamlit: true,
        awsVideo: true,
        videoSrc: videoUrls.awqYashTechAI,
        pdfSrc: pdfUrls.awqYashTechAI,
        tag: "MISC",
        desc: "Our new accelerator, powered by AWS Q, enables interactive, AI-driven chat on yashtech.ai. It crawls all content, allowing users to access information through natural conversations, enhancing user experience and making information more accessible.",
      },
      {
        name: "Live Potrait",
       // icon: trip,
        url: routes.LIVE_POTRAIT,
        // streamlit: true,
        videoSrc: videoUrls.lp,
        pdfSrc: pdfUrls.lp,
        awsVideo: true,
        tag: "MISC",
        desc: "Efficient Portrait Animation with Stitching and Retargeting Control",
      },
    ],
  },
];
