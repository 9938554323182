import { InboxOutlined } from "@ant-design/icons";
import { Upload, message } from "antd";
import React, { useEffect, useState } from "react";
const { Dragger } = Upload;

const FileUpload = ({ handleRecieveFiles, height }) => {
  const [files, setFiles] = useState([]);
  const propsForFileUpload = {
    name: "file",
    multiple: true,
    accept: "*",
    beforeUpload: (file) => {
      return false; // Prevent automatic file upload
    },
    onRemove: (file) => {
      const filterList = files.filter(
        (doc) => doc.uid !== file.originFileObj.uid
      );
      setFiles(filterList);
      handleRecieveFiles(filterList);
    },
    onChange(info) {
      if (info.file.status === "removed") {
        message.info("File removed");
      } else {
        const updatedFiles = [...info.fileList];
        setFiles(updatedFiles);
        handleRecieveFiles(updatedFiles);
      }
    },
  };

  return (
    <div>
      <Dragger {...propsForFileUpload} style={{ border: "2px dashed #40a9ff" }}>
        <div
          style={{
            height: height,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click / drag and drop to upload files here
          </p>
          <p className="ant-upload-hint">
            Support for a single or bulk upload files of extention .pdf
          </p>
        </div>
      </Dragger>
    </div>
  );
};

export default FileUpload;
