
import React from "react";

import { Form, Select, DatePicker, Button, Card, Row, Col } from "antd";

import moment from "moment";
import IntrusionDetectionTable from "./IntrusionDetectionTable";
import VisualizationDetails from "./VisualizationDetails";
import VisualizationComponent from "./VisualizationComponent";

const { Option } = Select;

export const CIDDS = ({ tableData, prompts, imageDataResponse, AdditionalVisualizations }) => {



  return (
    <div style={{ padding: 24, minHeight: 360,  }}>
      <h2>CIDDS (Coburg Intrusion Detection Data Sets)</h2>
      <p>
        CIDDS (Coburg Intrusion Detection Data Sets) is a concept to create evaluation data sets for anomaly-based network intrusion detection systems. Since the IT industry is constantly evolving, attackers are forced to adapt and find new ways to penetrate their target of interest
      </p>
      {/* 
      <Card style={{ padding: 10, minHeight: 80 }}>
        <Form
          layout="vertical"
          onFinish=""
          initialValues={{
            application: "CIDDS",

            startDate: moment(),

            endDate: moment(),
          }}
        >
          <Row gutter={22}>
            <Col span={4}>
              <Form.Item label="Select Application" name="application">
                <Select>
                  <Option value="CIDDS">CIDDS</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label="Start Date" name="startDate">
                <DatePicker />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item label="End Date" name="endDate">
                <DatePicker />
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%" }}
                >
                  Submit
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card> */}


      {/* Conditionally render IntrusionDetectionTable if tableData is not null */}

      {tableData && <IntrusionDetectionTable tableData={tableData} />}

      {/* Conditionally render VisualizationDetails if prompts and imageDataResponse are not null */}

      {prompts && imageDataResponse && (
        <VisualizationDetails prompts={prompts} imageDataResponse={imageDataResponse} />

      )}

      {/* Conditionally render VisualizationComponent if AdditionalVisualizations is not null */}

      {AdditionalVisualizations && (
        <VisualizationComponent AdditionalVisualizations={AdditionalVisualizations} />

      )}

    </div>
  );
};

