import React, { useState } from "react";
import BreadCrumbs from "../../../BreadCrumbs";
import { useLocation } from "react-router";
import axios from "axios";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { Form, Input, Button, Row, Col, message, Card } from 'antd';
import { pdfUrls } from "../../../../util/pdfData";
 
function LangchainSqlAgent(props) {
  const location = useLocation();
  const [question, setQuestion] = useState('');
  const [status, setStatus] = useState('');
  
  const handleSubmit = async () => {
    if (!question) {
      message.error('Please enter your question.');
      return;
    }
    
    setStatus("Fetching response...");
 
    try {
      console.log(question+"API CALLED")
      const response = await axios.post('https://18.233.184.112/query', {
        question: question
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
 
      console.log(response)
      setStatus(`${response.data}`);
    } catch (error) {
      console.error("Error fetching response:", error);
      message.error("Error fetching response.");
    }
  };
 
  const handleClear = () => {
    setQuestion('');
    setStatus('');
  };
 
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.langchain} type="video/mp4" pdfSrc={pdfUrls.langchain} />
      </div>
      <Card title="Order Status Tracking" style={{ width: '600px', margin: 'auto' }}>
        <Form layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Query">
                <Input 
                  placeholder="Enter your question."
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Output">
                <Input.TextArea 
                  value={status}
                  readOnly
                  placeholder="Response will appear here."
                  autoSize={{ minRows: 1, maxRows: 3 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Button onClick={handleClear} block>Clear</Button>
            </Col>
            <Col span={6}>
              <Button type="primary" onClick={handleSubmit} block>Submit</Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
}
 
export default LangchainSqlAgent;
 