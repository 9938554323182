import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import BreadCrumbs from "../../../BreadCrumbs";
import { useLocation } from "react-router-dom";
import GermanToEnglishTranslator from "./GermanToEnglishTranslator";
import EnglishToGermanTranslator from "./EnglishToGermanTranslator";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

const LanguageTranslator = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const [query, setQuery] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState(null);
  const [apiMessage, setApiMessage] = useState(null);
  const messageContainerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addIp, setAddIp] = useState("");

  const [isIpAvailavle, setIsIpAvailable] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleIp = (event) => {
    localStorage.setItem("ip", event.target.value);
    const ip = localStorage.getItem("ip");
    setAddIp(ip);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem("ip")) {
      const ip = localStorage.getItem("ip");

      setAddIp(ip);
      setIsIpAvailable(true);
    } else {
      setIsIpAvailable(false);
    }
  }, [addIp]);

  let data = localStorage.getItem("data");
  let msgData = data ? JSON.parse(data) : [];
  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [userMessage, apiMessage]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setInputMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!query) return;
    setUserMessage(query);
    setInputMessage("");

    setLoading(true);

    try {
      let url = `http://${addIp}:8002/pdf/langchain`;

      const params = { query_string: query };

      const response = await axios.get(url, { params });
      setApiMessage(response.data);

      msgData.push(
        {
          text: query,
          isUser: true,
        },
        { text: response.data, isUser: false }
      );

      localStorage.setItem("data", JSON.stringify(msgData));
      setQuery("");
      setLoading(false);
    } catch (error) {
      setUserMessage(query);
      setApiMessage(error.message);
      console.error("Error fetching data:", error);
      msgData.push(
        { text: query, isUser: true },
        { text: error.message, isUser: false }
      );
      localStorage.setItem("data", JSON.stringify(msgData));
      if (
        error instanceof DOMException &&
        error.code === DOMException.QUOTA_EXCEEDED_ERR
      ) {
        localStorage.removeItem("data");
      }
      setLoading(false);
      setQuery("");
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto", float: "right" }}>
        <VideoTour
          src={videoUrls.Language_translation}
          type="video/mp4"
          pdfSrc={pdfUrls.language_translation_sample}
        />
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          maxWidth: "100%",
          paddingTop: "10px",
          marginTop: "20px",
        }}
      >
        <div style={{ width: "49%" }}>
          <GermanToEnglishTranslator />
        </div>

        <div style={{ width: "49%" }}>
          <EnglishToGermanTranslator />
        </div>
      </div>
    </div>
  );
};

export default LanguageTranslator;
