import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Menu, Form, Select, DatePicker, Button, Card, message, Spin, theme } from 'antd';
import moment from 'moment';
import { HomeOutlined, DropboxOutlined } from '@ant-design/icons';
import VideoTour from '../../../video-guide';
import BreadCrumbs from '../../../BreadCrumbs';
import { Plaid } from './Plaid';
import { Home } from './Home';
import { CIDDS } from './CIDDS/CIDDS';
import { videourls } from '../../VideoGeneration/VoiceCloning/VideoFiles';
import { pdfUrls } from '../../../../util/pdfData';
import { videoUrls } from '../../../../util/videoData';
import { api_url } from '../../../../dynamicIPs';
 
const { Option } = Select;
const { Sider, Content } = Layout;
 
const AutoVisualization = () => {
  const [selectedPage, setSelectedPage] = useState('Home');
  const [collapsed, setCollapsed] = useState(false);
  const [ciddsData, setCiddsData] = useState(null); // Step 1: Add state for API response
  const [loading, setLoading] = useState(false); // Step 1: Add loading state for the loader
  const location = useLocation();
 
  const handleSubmit = async (values) => {
    const formData = new FormData();
    formData.append('select_application', values.application);
    formData.append('start_date', values.startDate.format('DD-MM-YYYY'));
    formData.append('end_date', values.endDate.format('DD-MM-YYYY'));
 
    setLoading(true); // Step 2: Show loader when API call starts
 
    try {
      const res = await fetch(`${api_url}/visualisation/CIDDS`, {
        method: 'POST',
        body: formData,
      });
      const data = await res.json(); // Assuming response is JSON
      console.log(data);
      setCiddsData(data); // Step 3: Save the response data
      message.success('Data fetched successfully!');
    } catch (error) {
      console.error('Error:', error);
      message.error('Failed to fetch data');
    } finally {
      setLoading(false); // Step 4: Hide loader when API call finishes
    }
  };
 
  const renderContent = () => {
    switch (selectedPage) {
      case 'Home':
        return <Home />;
      case 'CIDDS':
        return (
          <CIDDS
            tableData={ciddsData?.data_table}
            prompts={ciddsData?.prompts}
            imageDataResponse={ciddsData?.imageDataResponse}
            AdditionalVisualizations={ciddsData?.AdditionalVisualizations}
          />
        );
      default:
        return <Home />;
    }
  };
 
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
 
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.auto_visualization} type="video/mp4" pdfSrc={pdfUrls.auto_visualization} />
      </div>
      <Layout style={{ minHeight: '100vh', marginTop: '10px' }}>
        <Sider trigger={null} width={300} collapsible>
          <div className='demo-logo-vertical' />
          <Menu
            theme='dark'
            defaultSelectedKeys={['1']}
            style={{ height: '30vh' }}
            items={[
              { key: '1', icon: <HomeOutlined />, label: 'Home', onClick: () => setSelectedPage('Home') },
              { key: '3', icon: <DropboxOutlined />, label: 'CIDDS', onClick: () => setSelectedPage('CIDDS') },
            ]}
          />
          {selectedPage !== 'Home' && (
            <Card style={{ padding: 10, margin: '16px' }} title={'Get Data'}>
              <Form layout='vertical' initialValues={{ application: 'CIDDS', startDate: null, endDate: null }} onFinish={handleSubmit}>
                <Form.Item label='Select Application' name='application'>
                  <Select>
                    <Option value='CIDDS'>CIDDS</Option>
                  </Select>
                </Form.Item>
                <Form.Item label='Start Date' name='startDate'>
                  <DatePicker format={'DD-MM-YYYY'} picker='date' />
                </Form.Item>
                <Form.Item label='End Date' name='endDate'>
                  <DatePicker format={'DD-MM-YYYY'} picker='date' />
                </Form.Item>
                <Form.Item>
                  <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          )}
        </Sider>
        <Layout>
          <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280, background: colorBgContainer, borderRadius: borderRadiusLG }}>
            <Spin spinning={loading} tip="Fetching data...">
              {renderContent()}
            </Spin>
          </Content>
        </Layout>
      </Layout>
    </>
  );
};
 
export default AutoVisualization;
 