import React, { useState } from "react";
import { Select } from "antd";

const { Option } = Select;

const options = ["GPT-3.5"];

const SelectDropDown = ({ getModalName }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (value) => {
    console.log("Selected Value:", value);
    getModalName(value);
    setSelectedValue(value);
  };

  return (
    <Select
      style={{ width: 200 }}
      placeholder="Select a model"
      onChange={handleChange}
      value={selectedValue}
    >
      {options.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
};

export default SelectDropDown;
