import React, { useState, useRef } from "react";
import BreadCrumbs from "../../../BreadCrumbs";
import { useLocation } from "react-router-dom";
import { Card, Col, Row, Button, message, Input, Upload, Tabs } from "antd";
import { DeleteOutlined, InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { IP } from "../../../../dynamicIPs";
import axios from "axios";
import { domain_url } from "../../../../dynamicIPs";
import Markdown from "react-markdown";
const { TextArea } = Input;

const { Dragger } = Upload;
const ResumeScreeningAssistance = () => {
  const location = useLocation();
  const [data, setData] = useState("");
  const [files, setFiles] = useState([]);
  const [jobDescription, setJobDescription] = useState("");
  const [keywords, setKeywords] = useState("");
  const [queryResponseLoading, setQueryResponseLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("1");

  const onChange = (key) => {
    setActiveKey(key);
  };

  const propsForFileUpload = {
    name: "file",
    multiple: true,
    accept: "application/pdf",
    // disabled: true,
    beforeUpload: (file) => {
      // callBeforeUpload(file);
      return false; // Prevent automatic file upload
    },
    onRemove: (file) => {
      const filterList = files.filter(
        (doc) => doc.uid !== file.originFileObj.uid
      );
      setFiles(filterList);
    },
    onChange(info) {
      if (info.file.status === "removed") {
        message.info("File removed");
      } else {
        setFiles(info.fileList);
        message.success("File uploaded successfully");
      }
    },
  };
  function IsJsonString(str) {
    try {
      var json = JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  const handleQuerySubmit = async () => {
    setQueryResponseLoading(true);

    const url = `${domain_url}/assistance/resume_screening_assistance`;
    // const url="http://18.199.250.92:9006/assistance/resume_screening_assistance"
    const formData = new FormData();
    formData.append("job_description", jobDescription);
    formData.append("mandatory_keywords", keywords);
    for (let i = 0; i < files.length; i++) {
      formData.append("resume_files", files[i].originFileObj);
    }
    try {
      const response = await fetch(url, {
        method: "POST",

        body: formData,
      });
      if (!response.ok || !response.body) {
        throw response.statusText;
      }
      // Here we start prepping for the streaming response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;

      while (loopRunner) {
        // Here we start reading the stream, until its done.
        const { done, value } = await reader.read();
        if (done) {
          console.log("inside done");
          // setIsTextEnd(true);
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        console.log("realdecodechunk-------------", decodedChunk);
        let data = "";
        if (IsJsonString(decodedChunk)) {
          data = JSON.parse(decodedChunk);
          data = data.replace(/\n/g, "  \n");
          data = data.replace("Label:", "######Suitability:");
          console.log("inside if", true);
        } else {
          data = data.replace(/"/g, "'");
          data = data.replace("Label:", "######Suitability:");

          console.log("inside else", false);
        }

        // console.log("dataaaaaaaaaaaa",data);

        setData((answer) => answer + data);
        setQueryResponseLoading(false);
        setActiveKey("2");
      }
    } catch (error) {
      console.log("error", error);
      setQueryResponseLoading(false);

      message.error(error);
    }
  };

  const items = [
    {
      key: "1",
      label: "Resume Screening",
      children: (
        <>
          <Dragger {...propsForFileUpload}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click / drag and drop to upload files here
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload files of extention .pdf
            </p>
          </Dragger>

          <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
            <Col sx={24} sm={24} md={12} xl={12} lg={12} xxl={12}>
              <TextArea
                placeholder="Job Description..."
                onChange={(e) => setJobDescription(e.target.value)}
                value={jobDescription}
                rows={8}
              />
            </Col>
            <Col sx={24} sm={24} md={12} xl={12} lg={12} xxl={12}>
              <TextArea
                placeholder="Mandatory Keywords..."
                onChange={(e) => setKeywords(e.target.value)}
                value={keywords}
                rows={8}
              />
            </Col>
            <Col span={24} style={{ marginTop: "20px" }}>
              <Button
                type="primary"
                loading={queryResponseLoading}
                onClick={handleQuerySubmit}
                style={{ width: "100%" }}
                disabled={!files.length ? true : false}
              >
                Evaluate Resume
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Evaluation Result",
      disabled: data.length > 0 ? false : true,
      children: <Markdown>{data}</Markdown>,
    },
  ];
  return (
    <>
      <BreadCrumbs location={location} />

      <div style={{ marginTop: "20px" }}>
        <Card>
          <Tabs
            size="small"
            onChange={onChange}
            activeKey={activeKey}
            items={items}
          />
        </Card>
      </div>
    </>
  );
};

export default ResumeScreeningAssistance;
