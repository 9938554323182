import React, { useState, useRef, useEffect } from "react";
import { MessageOutlined } from "@ant-design/icons";
import { Col, Input, Row, Select, message, Spin } from "antd";
import { languageArray } from "./data";
import axios from "axios";
import { DotLoading } from "antd-mobile";
import { IP } from "../../../../dynamicIPs";

export default function DynamicAutomateData() {
  const [chatbotText, setChatbotText] = useState();
  const [landscapeOrVertical, setLandScapeOrVertical] = useState("vertical");
  const [automateOption, setAutomateOption] = useState([]);
  const [elevenOrEdge, setElevenOrEdge] = useState("EdgeTTS");
  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [responseScript, setResponseScript] = useState();
  const [videoOptionResponse, setVideoOptionResponse] = useState([]);
  const [textboxDisable, setTextBoxDisable] = useState(false);
  const [videoUrl, setVideoUrl] = useState();
  const [videoLoading, setVideoLoading] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(true);
  const [isPressEnter, setIsPressEnter] = useState(false);
  const automateContainerRef = useRef();
  const videoSubjectContainerRef = useRef();

  const { Option } = Select;

  useEffect(() => {
    if (videoSubjectContainerRef.current) {
      const containerRef = videoSubjectContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [chatbotText, isPressEnter]);

  const handleVideoError = () => {
    setVideoLoaded(false);
  };
  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setIsPressEnter(true);
    }

    if (event.key === "Enter" && chatbotText.toLowerCase() !== "yes") {
      if (chatbotText.toLowerCase() === "no") {
        setVideoOptionResponse([
          ...videoOptionResponse,
          {
            query: "no",
            response: "📝 Explain me what you want different in the script.📝",
          },
        ]);
        if (chatbotText.toLowerCase() !== "no") {
          setChatbotText();
          const url = `http://${IP.GENAI}:9001/get-corrected-script`;
          setVideoOptionResponse([
            ...videoOptionResponse,
            {
              query: chatbotText,
              response: (
                <DotLoading
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              ),
            },
          ]);
          const get_script_params = {
            script: responseScript,
            message: chatbotText,
          };
          axios
            .get(url, { params: get_script_params })
            .then((res) => {
              setVideoOptionResponse((prevData) => {
                const lastIndex = prevData.length - 1;
                const updatedData = [...prevData];
                updatedData[lastIndex][
                  "response"
                ] = `📝 Here is your corrected script:${res.data}・Are you satisfied with the script and ready to proceed with creating the video? Please respond with YES or No. 👍👎`;

                return updatedData;
              });
            })
            .catch((error) => {
              message.error(error);
              setVideoOptionResponse((prevData) => {
                const lastIndex = prevData.length - 1;
                const updatedData = [...prevData];
                updatedData[lastIndex]["response"] = error.message;

                return updatedData;
              });
            });
        } else {
          setChatbotText();
        }
      } else {
        setTextBoxDisable(true);
        const script_params = {
          message: chatbotText,
          lang_value: selectedLanguage,
        };
        const url = `http://${IP.GENAI}:9001/get-script`;
        setVideoOptionResponse([
          ...videoOptionResponse,
          {
            query: chatbotText,
            response: (
              <DotLoading
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              />
            ),
          },
        ]);

        axios
          .get(url, { params: script_params })
          .then((res) => {
            setResponseScript(res.data);
            setVideoOptionResponse((prevData) => {
              const lastIndex = prevData.length - 1;
              const updatedData = [...prevData];
              updatedData[lastIndex][
                "response"
              ] = `📝 Here is your generated script:${res.data} ・Are you satisfied with the script and ready to proceed with creating the video? Please respond with 'YES' or 'NO'. 👍👎`;

              return updatedData;
            });

            setChatbotText();

            setTextBoxDisable(false);
          })
          .catch((error) => {
            message.error(error.message);
            setVideoOptionResponse((prevData) => {
              const lastIndex = prevData.length - 1;
              const updatedData = [...prevData];
              updatedData[lastIndex]["response"] = error.message;

              return updatedData;
            });
          });
      }
    } else {
      if (event.key === "Enter") {
        if (chatbotText.toLowerCase() === "yes") {
          setVideoLoading(true);
          setVideoUrl();
          const url = `http://${IP.GENAI}:9001/make-video`;
          const make_video_params = {
            message: chatbotText,
            script: responseScript,
            language: selectedLanguage,
            isVertical: landscapeOrVertical === "vertical" ? true : false,
          };
          axios
            .get(url, { params: make_video_params })
            .then((res) => {
              setVideoUrl(res.data[0]);
              setVideoLoading(false);
            })
            .catch((error) => {
              message.error(error.message);
              setVideoLoading(false);
            });
        }
      }
    }
  };

  const handleChangeSubjectText = (event) => {
    setChatbotText(event.target.value);
  };
  const handleChangeLandsacpeOrVertical = (event) => {
    setLandScapeOrVertical(event.target.value);
    setVideoUrl();
  };
  const handleChangeElevenOrEdge = (event) => {
    setElevenOrEdge(event.target.value);
    setVideoUrl();
  };
  const handleSelectLanguage = (language) => {
    setSelectedLanguage(language);
    setVideoUrl();
  };

  useEffect(() => {
    if (automateContainerRef.current) {
      const containerRef = automateContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [automateOption]);

  return (
    <div>
      <div className="content-automation-option" style={{ marginTop: "30px" }}>
        <span
          style={{
            display: "flex",
            padding: "6px",
            border: "1px solid #85cdfd",
            width: "100px",
            borderRadius: "16px 0px 16px 0px",
            margin: "12px",
            backgroundColor: "#85cdfd",
          }}
        >
          <MessageOutlined />
          <h2
            style={{
              fontSize: "14px",
              marginLeft: "10px",
            }}
          >
            Chatbot
          </h2>
        </span>
        <div className="sub-automate-container">
          🤖 Welcome ! 🚀 I'm a python framework aiming to simplify and automate
          your video editing tasks.Let's get started! 🎥🎬 Do you want your
          video to be in landscape or vertical format? (landscape OR vertical)
        </div>
        <Row className="choose-option-content" gutter={[16, 8]}>
          <Col className="choose-one-radio">
            <label style={{ padding: "10px" }}>
              <input
                type="radio"
                value="landscape"
                checked={landscapeOrVertical === "landscape"}
                onChange={handleChangeLandsacpeOrVertical}
              />
              <span style={{ marginLeft: "10px" }}>Landscape</span>
            </label>
          </Col>
          <Col className="choose-two-radio">
            <label style={{ padding: "10px" }}>
              <input
                type="radio"
                value="vertical"
                checked={landscapeOrVertical === "vertical"}
                onChange={handleChangeLandsacpeOrVertical}
              />
              <span style={{ marginLeft: "10px" }}>Vertical</span>
            </label>
          </Col>
        </Row>
      </div>
      <div className="content-automation-option" style={{ marginTop: "30px" }}>
        <h2 className="choose-option-text">Choose an option</h2>
        <Row className="choose-option-content" gutter={[16, 8]}>
          <Col className="choose-one-radio">
            <label style={{ padding: "10px" }}>
              <input
                type="radio"
                value="ElevenLabs"
                checked={elevenOrEdge === "ElevenLabs"}
                onChange={handleChangeElevenOrEdge}
              />
              <span style={{ marginLeft: "10px" }}>ElevenLabs</span>
            </label>
          </Col>
          <Col className="choose-two-radio">
            <label style={{ padding: "10px" }}>
              <input
                type="radio"
                value="EdgeTTS"
                checked={elevenOrEdge === "EdgeTTS"}
                onChange={handleChangeElevenOrEdge}
              />
              <span style={{ marginLeft: "10px" }}>EdgeTTS</span>
            </label>
          </Col>
        </Row>
      </div>
      <div className="language-dropdown dropdown-parent">
        <Select
          // showSearch
          // className="dropdown-child"
          // suffixIcon={null}
          bordered={false}
          placeholder={<span style={{ color: "black" }}>Select Language</span>}
          onChange={handleSelectLanguage}
          className="dropdown-child"
          style={{
            width: "100%",
            textAlign: "center",
            color: "black",
          }}
        >
          {languageArray.map((item) => (
            <Option key={item} value={item} style={{ textAlign: "center" }}>
              {item}
            </Option>
          ))}
        </Select>
      </div>
      <div
        className="content-automation-option "
        style={{ marginTop: "30px" }}
        ref={videoSubjectContainerRef}
      >
        <div className="choose-option-text">
          Amazing 🔥 ! 📝Can you describe thoroughly the subject of your
          video?📝 I will next generate you a script based on that description
        </div>
        {videoOptionResponse.map((data, index) => (
          <>
            <div className="query-text">{data.query}</div>
            <div
              className="query-response"
              style={{
                height:
                  data.response ===
                  "📝 Explain me what you want different in the script.📝"
                    ? "60px"
                    : "none",
                overflowY: "none",
                fontSize:
                  data.response ===
                  "📝 Explain me what you want different in the script.📝"
                    ? "20px"
                    : "none",
              }}
            >
              {data.response}
            </div>
          </>
        ))}
        <div className="chatbot-text-conatiner">
          <Input
            type="text"
            style={{ border: " 1.5px solid #85cdfd", borderRadius: "10px" }}
            placeholder="Please answer above question..."
            value={chatbotText}
            onChange={handleChangeSubjectText}
            className="input-text"
            onKeyDown={handleKeyDown}
            disabled={textboxDisable || videoLoading}
          />
        </div>
      </div>
      {videoUrl ? (
        videoLoaded ? (
          <>
            <div className="video-container">
              <video
                width="700px"
                height="500px"
                controls
                onLoad={handleVideoLoad}
                onError={handleVideoError}
              >
                <source
                  src={`http://${IP.GENAI}:9001/get-video?video_path=${videoUrl}`}
                  type="video/mp4"
                />
                your browser does not support the video tag
              </video>
            </div>
          </>
        ) : (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "red",
              margin: "20px",
            }}
          >
            Error Loading video from server
          </p>
        )
      ) : null}
      {videoLoading ? (
        <div className="video-loader">
          {" "}
          <Spin />{" "}
        </div>
      ) : null}

      {/* <div className="restart-container">
        <Button style={{ background: "none", border: "none", width: "100%" }}>
          Restart
        </Button>
      </div> */}
    </div>
  );
}
