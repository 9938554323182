import React, { useState, useEffect } from "react";
import { Layout, Dropdown, Avatar, Modal, Button, Input } from "antd";
import { Link } from "react-router-dom";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import SiderDrawerPhone from "../dashboard/SiderDrawerPhone";
import { HomeOutlined } from "@ant-design/icons/lib/icons";
import iconSvg from "../../assests/icon/avatar1.gif";
import logo from "../../assests/img/yashlogo.png";
import { domainsList } from "../../util/domainData";
import SearchItems from "../search";
import { routes } from "../../util/constants";

const { Header } = Layout;

export default function HeaderAdmin(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addIp, setAddIp] = useState("");

  const [isIpAvailavle, setIsIpAvailable] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleIp = (event) => {
    localStorage.setItem("ip", event.target.value);
    const ip = localStorage.getItem("ip");
    setAddIp(ip);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (localStorage.getItem("ip")) {
      const ip = localStorage.getItem("ip");

      setAddIp(ip);
      setIsIpAvailable(true);
    } else {
      setIsIpAvailable(false);
    }
  }, [addIp]);

  const items = [
    {
      label: (
        <Link to="/" className="ms-4 fs-4" style={{ textDecoration: "none" }}>
          <HomeOutlined style={{ fontSize: "14px" }} />
          <span
            className="ms-4 menu-item-name"
            style={{ paddingLeft: "5px", fontSize: "14px" }}
          >
            Home
          </span>
        </Link>
      ),
      key: "0",
    },

    // {
    //   label: (
    //     <span className="ms-4 fs-4" onClick={showModal}>
    //       <LogoutOutlined style={{ fontSize: "14px" }} />
    //       <span
    //         className="ms-4 menu-item-name"
    //         style={{ paddingLeft: "5px", fontSize: "14px" }}
    //       >
    //         Add IP
    //       </span>
    //     </span>
    //   ),
    //   key: "1",
    // },
  ];
  return (
    <Header
      className="header-admin site-layout-background"
      style={{ padding: 0 }}
    >
      <Link to={routes.CHATBOT}>
        <img
          src={logo}
          alt="yashlogo"
          width="75px"
          height="50px"
          style={{ margin: "5px 0 0 10px" }}
        />
      </Link>
      <SiderDrawerPhone />

      <span className="header-nav">
        <ul className="navbar-links ">
          {domainsList.map((domain, index) => {
            return (
              <li key={index} className="navbar-dropdown">
                <Link to={domain.url}>{domain.name}</Link>
                <div className="dropdown">
                  {domain.categories.map((category, index) => {
                    return (
                      <Link
                        key={index}
                        to={category.url}
                        state={{category}}
                        // target={category.streamlit ? "_blank" : ""}
                      >
                        {/** <img
                      style={{
                        height: "25px",
                        width: "25px",
                      }}
                      src={category.icon}
                      alt="cardImg"
                    /> */}
                        {category.name}
                      </Link>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </span>
      <SearchItems />
      <Dropdown
        menu={{
          items,
        }}
        trigger={["click"]}
      >
        <div
          className="avatar-user-details"
          onClick={(e) => e.preventDefault()}
        >
          <div className="avatar-box">
            <div className="avatar-img">
              <Avatar
                style={{ backgroundColor: "rgb(214 214 214)" }}
                size={40}
                icon={<img width={40} height={35} src={iconSvg} />}
              ></Avatar>
            </div>
          </div>
        </div>
      </Dropdown>
    </Header>
  );
}
