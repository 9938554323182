import React from "react";
import { Col, Row, Result, Button } from "antd";
import Breadcrumb from "./BreadCrumbs";
import { NavLink, useNavigate } from "react-router-dom";

export default function Dashboard(props) {
  const navigate = useNavigate();
  return (
    <>
      <div className="container">
        {/* <Row className="title-row">
          <Col xs={10} sm={10} md={10} lg={10} xl={10}>
            <Breadcrumb location={props.location} /> */}
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Back Home
            </Button>
          }
        />
        {/* </Col>
        </Row> */}
      </div>
    </>
  );
}
