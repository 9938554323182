import { Tabs } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import MelodyContinuation from "./MelodyContinuation";
import MelodyToMelody from "./MelodyToMelody";
import TextToMelody from "./TextToMelody";
import BreadCrumbs from "../../../BreadCrumbs";
const { TabPane } = Tabs;

export default function MusicComposition() {
  const location = useLocation();
  return (
    <div>
      <BreadCrumbs location={location} />
      <div style={{ marginTop: "20px" }}>
        <Tabs
          defaultActiveKey="content-automation"
          type="card"
          tabBarGutter={12}
          size={"large"}
        >
          <TabPane
            tab={<span>Melody Continuation</span>}
            key="melody-continuation"
          >
            <MelodyContinuation />
          </TabPane>
          {/* <TabPane tab={<span>Melody To Melody</span>} key="melody-to-melody">
            <MelodyToMelody />
          </TabPane> */}
          <TabPane tab={<span>Text To Melody</span>} key="text-to-melody">
            <TextToMelody />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
