
import { Button, Col, Input, Modal, Row, Tabs, Tooltip } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
// import BreadCrumbs from "../../../../BreadCrumbs";
// import ip from "../../../../../assests/img/ip-icon1.png";
import Drumpad  from "./Drumpad";
import PianoPayer from "./PianoPayer";
import happybirthday from "../../../../../assests/Drumpaddata/happy-birthday-to-you-bossa-nova-style-arrangement-21399.mp3";
const { TabPane } = Tabs;

export default function MelodyApp() {
  const location = useLocation();
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const [display, setDisplay] = useState("");

  const playHappyBirthday = () => {

    const audio = new Audio(happybirthday);

audio.play().then(() => {

      setCurrentlyPlaying(audio);

      setDisplay("Happy Birthday!");

    }).catch((error) => {

      console.error("Error playing audio:", error);

    });

  };
 
  return (
    <>
    {/* <div style={{padding: "20px"}}><BreadCrumbs location={location} /></div> */}
      
      <div className="main-container">
        
        <div className="music-tab">
        {/* <Button type="primary" className="music-button "style={{margin: "0px 0px 25px 20px"}} onClick={playHappyBirthday}>Play Happy Birthday</Button> */}
          <Tabs
            defaultActiveKey="automation-analytics"
            type="card"
            tabBarGutter={12}
            size={"large"}
          >
            <TabPane tab={<span>Piano</span>} key="automation-analytics">
              <PianoPayer />
            </TabPane>
            <TabPane tab={<span>Drumpad</span>} key="config">
              
              <Drumpad />
            </TabPane>
          </Tabs>
         
        </div>
      </div>
    </>
  );
}


