import React, { useState, useEffect, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { DotLoading } from "antd-mobile";
import { NavLink } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Tooltip,
  Typography,
} from "antd";
import BreadCrumbs from "../../../BreadCrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../../../util/constants";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";
// import { routes } from "../../../util/constants";
// import logo from "../../../assests/img/chatbot.png";
// import ip from "../../..//assests/img/ip-icon1.png";
// import { useLocation } from "react-router-dom";
// import { MessageFilled, SendOutlined } from "@ant-design/icons";

const PoseMimic = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [img, setImg] = useState(null);
  const webcamRef = useRef(null);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImg(imageSrc);
  }, [webcamRef]);

  //   const navigateToContacts = () => {
  //     // 👇️ navigate to /contacts
  //     navigate('/capture-image');
  //   };

  const capImage = () => {
    // 👇️ navigate to /
    navigate(routes.DISNEY_POSE, { state: { name: "upload" } });
  };
  const navigateToRealsticPose = () => {
    navigate(routes.REALSTIC_POSE, { state: { name: "webcam" } });
  };

  return (
    <div
      style={{ padding: "2%", paddingBottom: "53px" }}
      className="pose-mimic"
    >
      <BreadCrumbs location={location} />
      <div
        style={{
          margin: "-25px 0 0 auto",
          float: "right",
          marginTop: "-30px",
        }}
      >
        <VideoTour src={videoUrls.pose_mimic} type="video/mp4" pdfSrc={pdfUrls.pose_mimic_prompts} />
      </div>
      <div style={{ paddingTop: "14px" }}>
        <Row className="row">
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
            <h6 className="pose-heading">Disney Character Pose Mimic</h6>
            <div className="box1">
              <div className="charactor-img charactor-img1 "></div>

              {/* <div className="app-box" style={{ marginTop: "20px" }}></div> */}

              <div className="app-box" style={{ marginTop: "20px" }}>
                <Button
                  type="primary"
                  className="desney-pose-button "
                  onClick={capImage}
                >
                  Disney Character Pose
                </Button>
              </div>
            </div>
          </Col>
          <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
            <h6 className="pose-heading">Realistic Human Pose Mimic</h6>
            <div className="box2">
              <div className="charactor-img charactor-img2"></div>

              <div className="app-box" style={{ marginTop: "20px" }}>
                <Button
                  type="primary"
                  className="desney-pose-button "
                  onClick={navigateToRealsticPose}
                  //   loading={isPptLoading}
                  //   onClick={handleGeneratePpt}
                >
                  Realistic Human Pose
                </Button>
                {/* <NavLink
                    to={routes.CaptureImage}
                    style={{
                      textDecoration: "none",
                      fontSize: "12px",
                    }}
                    className="desney-pose-button "
                  >
                    CONNECT
                  </NavLink> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PoseMimic;
