export const Home = () => {
  return(
  <>

<div style={{ padding: 24, minHeight: 360, background: '#fff' }}>
        <h2>Business & IT Operations Network Dashboard</h2>
        <p>
      Business & IT Operations Network Dashboard, powered by LLM, is a
      comprehensive tool designed for the automatic generation of visualizations
      and infographics. It seamlessly integrates with various software systems,
      including Worksoft CTM and Checkmarx, to provide detailed insights and
      analytics. This dashboard provides users with a comprehensive view of
      their software testing and security processes. This empowers teams to make
      informed decisions, streamline workflows, and drive continuous improvement
      across their application development lifecycle.
    </p>
   
      </div>
   

   
  </>);
};
