import React from 'react'
import PianoApp from './piano/Piano'

export default function MelodyContinuation() {
    return (
        <div>
            <PianoApp />
        </div>
    )
}
