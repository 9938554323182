import React from "react";

import { Card, Collapse, Input, Form } from "antd";

const { Panel } = Collapse;

const VisualizationDetails = ({ prompts, imageDataResponse }) => {

  return (
    <>
      {prompts.map((prompt, index) => (
        <Card
          key={index}
          style={{
            margin: "20px",
            padding: "20px",
            backgroundColor: "#f0f2f5",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Form.Item label="Prompt" labelCol={{ span: 24 }} colon={false}>
            <Input value={prompt.KPI_text} />
          </Form.Item>

          <div className="visualization-image">
            {imageDataResponse[index]?.ImageData.sendVisuals ? (
              <img
                src={`data:image/png;base64,${imageDataResponse[index].ImageData.sendVisuals}`}
                alt={`Visualization ${index + 1}`}
                style={{ width: "60%", height: "auto", marginBottom: "20px" }}
              />
            ) : (
              <p>No Data Available</p>
            )}
          </div>

          {imageDataResponse[index] && (
            <Collapse
              accordion={false}
              defaultActiveKey={["0"]}
              expandIconPosition="right"
            >
              <Panel header="See Explanation" key="1">
                <dl>
                  {imageDataResponse[index].ImageData.explanationList.map(
                    (explanation, idx) => (
                      <div key={idx}>
                        <dt>{explanation.section}</dt>
                        <dd>{explanation.explanation}</dd>
                        <hr />
                      </div>
                    )
                  )}
                </dl>
              </Panel>
            </Collapse>
          )}
        </Card>
      ))}

    </>
  );
};

export default VisualizationDetails;
