import React, { useState } from "react";
import BreadCrumbs from "../../../BreadCrumbs";
import { useLocation } from "react-router-dom";
import { Button, Card, Col, Input, Row, Select, message } from "antd";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";

import {
  stageData,
  stageDataForInput,
  selectCategoryValue,
  selectStageValue,
  selectLanguageValue,
} from "./sdlcData";
import MonacoEditor from "react-monaco-editor";
import axios from "axios";
import { IP } from "../../../../dynamicIPs";
import VideoTour from "../../../video-guide";
import { domain_url } from "../../../../dynamicIPs";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

const { Option } = Select;

const SDLC = () => {
  const [selectStage, setSelectStage] = useState("");
  const [selectCategory, setSelectCategory] = useState("");
  const [selectSourceLanguage, setSelectSourceLanguage] = useState("");
  const [selectTargetLanguage, setSelectTargetLanguage] = useState("");
  const [codeGenerationInput, setCodeGenerationInput] = useState("");
  const [sourceCode, setSourceCode] = useState("");
  const [codechatinput, setCodeChatInput] = useState("");
  const [partialCode, setPartialCode] = useState("");
  const [completeCodeLoading, setCompleteCodeLoading] = useState(false);
  const [codeChatLoading, setCodeChatLoading] = useState(false);
  const [migrateCodeLoading, setMigrateCodeLoading] = useState(false);
  const [remainingLoading, setRemainingLoading] = useState(false);
  const [codeGenerationLoading, setCodeGenerationLoading] = useState(false);
  const [codeMigrate, setCodeMigrate] = useState("");
  const [remainingCode, setRemainingCode] = useState("");
  const [codeGenerateResponse, setCodeGenerateResponse] = useState("");
  const [codeChatResponse, setCodeChatResponse] = useState("");
  const [codeCompleteResponse, setCodeCompleteResponse] = useState("");
  const [remainingCodeResponse, setRemainingCodeResponse] = useState("");
  const [codeMigrationResponse, setCodeMigrationResponse] = useState("");

  const handleAPI = async (body) => {
    const url = `${domain_url}/SDLC/sdlc`;


    try {
      const data = await axios.post(url, body);
      return data;
    } catch (error) {
      message.error(error.message);
    }
  };

  const handleCodeMigrateChange = (event) => {
    setCodeMigrate(event);
  };

  const handleRemainingCodeChange = (event) => {
    setRemainingCode("");
    setRemainingCode(event);
  };

  const handleSourceCodeChange = (newCode) => {
    setSourceCode(newCode);
  };

  const handlepartialCodeChange = (event) => {
    setPartialCode(event);
  };

  const location = useLocation();

  const handleSelectStage = (feature) => {
    setSelectCategory("");
    setRemainingCode("");
    setCodeGenerateResponse("");
    setCodeChatResponse("");
    setCodeCompleteResponse("");
    setRemainingCode("");
    setCodeMigrationResponse("");
    setSelectStage(feature);
  };
  const handleSelectCategory = (category) => {
    setSelectCategory(category);
    setSourceCode("");
    setPartialCode("");
  };
  const handleSelectSourceLang = (language) => {
    setSelectSourceLanguage(language);
  };
  const handleSelectTargetLang = (language) => {
    setSelectTargetLanguage(language);
  };
  const handlePartialCompleteCode = async () => {
    setCompleteCodeLoading(true);
    const partialCompleteCodePayload = {
      code_completion: partialCode,
      stage: "Code_Generation",
      sub_stage: "Code_Completions",
    };
    try {
      const response = await handleAPI(partialCompleteCodePayload);
      setCompleteCodeLoading(false);
      setCodeCompleteResponse(response.data);
    } catch (error) {
      setCompleteCodeLoading(false);
    }
  };
  const handleCodeChat = async () => {
    setCodeChatLoading(true);
    const codeChatPayload = {
      code_string: codechatinput,
      codebase: sourceCode,
      stage: "Code_Generation",
      sub_stage: "Code_Chat",
    };
    try {
      const response = await handleAPI(codeChatPayload);
      setCodeChatResponse(response.data);
      setCodeChatLoading(false);
    } catch (error) {
      setCodeChatLoading(false);
    }
  };
  const handleMigrateCode = async () => {
    setMigrateCodeLoading(true);
    const codeMigrationPayload = {
      codebase: codeMigrate,
      source_lang: selectSourceLanguage,
      target_lang: selectTargetLanguage,
      stage: "Code_Migration",
    };
    try {
      const response = await handleAPI(codeMigrationPayload);
      setCodeMigrationResponse(response.data);
      setMigrateCodeLoading(false);
    } catch (error) {
      setMigrateCodeLoading(false);
    }
  };
  const handleRemainingClick = async (stageName) => {
    setRemainingLoading(true);
    const bugDetectionPayload = {};
    const summerizationPayload = {};
    const automatedTestingPayload = {};
    let remainingCodePayload;
    if (stageName === "Bug Detection") {
      remainingCodePayload = {};
      bugDetectionPayload["codebase"] = remainingCode;
      bugDetectionPayload["stage"] = "Bug_Detection";
      remainingCodePayload = bugDetectionPayload;
    } else if (
      stageName === "Summarize Technical Documentation and Code Comments"
    ) {
      remainingCodePayload = {};

      summerizationPayload["doc_text"] = remainingCode;
      summerizationPayload["stage"] =
        "Summarize_Technical_Documentation_and_Code_Comments";
      remainingCodePayload = summerizationPayload;
    } else if (stageName === "Automated Testing") {
      remainingCodePayload = {};

      automatedTestingPayload["codebase"] = remainingCode;
      automatedTestingPayload["stage"] = "Automated_Testing";
      remainingCodePayload = automatedTestingPayload;
    }
    try {
      const response = await handleAPI(remainingCodePayload);
      setRemainingCodeResponse(response.data);
      setRemainingLoading(false);
    } catch (error) {
      setRemainingLoading(false);
    }
  };

  const handleCodeGenerationClick = async (name) => {
    setCodeGenerateResponse("");
    setCodeGenerationLoading(true);
    const codeGenerationPayload = {
      code_string: codeGenerationInput,
      stage: "Code_Generation",
      sub_stage: selectCategory,
    };
    try {
      const response = await handleAPI(codeGenerationPayload);

      setCodeGenerateResponse(response.data);
      setCodeGenerationLoading(false);
    } catch (error) {
      setCodeGenerationLoading(false);
    }
  };

  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.SDLC} type="video/mp4" pdfSrc={pdfUrls.SDLC_Prompts} />
      </div>
      <Card style={{ marginTop: "20px" }}>
        <b>
          <p>Select Stage:</p>
        </b>
        <Select
          placeholder={<span style={{ color: "black" }}>Select Stage</span>}
          onChange={handleSelectStage}
          className="dropdown-child"
          value={selectStage}
          style={{
            width: "100%",
            textAlign: "center",
            color: "black",
          }}
        >
          {selectStageValue.map((item, index) => (
            <Option key={index} value={item} style={{ textAlign: "center" }}>
              {item}
            </Option>
          ))}
        </Select>
        {selectStage === "Code Generation" ? (
          <div style={{ marginTop: "20px" }}>
            <b>
              <p>Select Category:</p>
            </b>
            <Select
              placeholder={
                <span style={{ color: "black" }}>Select Category</span>
              }
              onChange={handleSelectCategory}
              className="dropdown-child"
              value={selectCategory}
              style={{
                width: "100%",
                textAlign: "center",
                color: "black",
              }}
            >
              {selectCategoryValue.map((item, index) => (
                <Option
                  key={index}
                  value={item}
                  style={{ textAlign: "center" }}
                >
                  {item}
                </Option>
              ))}
            </Select>
          </div>
        ) : null}
      </Card>

      <Card style={{ marginTop: "20px", marginBottom: "20px" }}>
        <h5>SDLC Enhancement with GENAI</h5>
        <h6>
          Explore how GENAI transforms various stages of the Software
          Development Life Cycle including
        </h6>
        <ul>
          {selectStageValue?.map((value, index) => (
            <li key={index}>{value}</li>
          ))}
        </ul>
        {selectCategory.length === 0 ? (
          <div>
            <h6>{selectStage}</h6>
            <ul>
              {stageData[selectStage]?.map((value, index) => (
                <li key={index}>{value}</li>
              ))}
            </ul>
          </div>
        ) : null}
        {selectCategory.length === 0 && selectStage === "Code Generation"
          ? stageDataForInput[selectStage]?.map((value, index) =>
              index === 0 ? <h6>{value}</h6> : <p>{value}</p>
            )
          : null}
        {selectStage === "Code Generation" && selectCategory.length === 0 ? (
          <>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Input
                  type="text"
                  value={codeGenerationInput}
                  onChange={(e) => setCodeGenerationInput(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginTop: "30px" }}>
                <Button
                  type="primary"
                  loading={codeGenerationLoading}
                  onClick={() => handleCodeGenerationClick("Code Generation")}
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  Generate Code
                </Button>
              </Col>
            </Row>
            {codeGenerateResponse.length > 0 ? (
              <Row>
                <Col span={24} style={{ marginTop: "30px" }}>
                  <SyntaxHighlighter language="python" style={vscDarkPlus}>
                    {codeGenerateResponse}
                  </SyntaxHighlighter>
                </Col>
              </Row>
            ) : null}
          </>
        ) : (
          <div>
            {selectStage === "Bug Detection" ||
            selectStage ===
              "Summarize Technical Documentation and Code Comments" ||
            selectStage === "Automated Testing" ? (
              <>
                <MonacoEditor
                  height="300"
                  key={selectStage}
                  language="javascript"
                  theme="vs-dark"
                  value={remainingCode}
                  options={{
                    automaticLayout: true, // Adjusts the editor layout automatically
                    fontSize: 14,
                    scrollBeyondLastLine: false,
                  }}
                  onChange={handleRemainingCodeChange}
                />
                <Row>
                  <Col span={24} style={{ marginTop: "30px" }}>
                    <Button
                      type="primary"
                      loading={remainingLoading}
                      onClick={() => handleRemainingClick(selectStage)}
                      style={{ width: "100%", borderRadius: "10px" }}
                    >
                      {selectStage === "Bug Detection"
                        ? "Detect Bugs"
                        : selectStage ===
                          "Summarize Technical Documentation and Code Comments"
                        ? "Summerization"
                        : "Generate Test Cases"}
                    </Button>
                  </Col>
                </Row>
                {remainingCodeResponse.length > 0 ? (
                  <Row>
                    <Col span={24} style={{ marginTop: "30px" }}>
                      <SyntaxHighlighter language="python" style={vscDarkPlus}>
                        {remainingCodeResponse}
                      </SyntaxHighlighter>
                    </Col>
                  </Row>
                ) : null}
              </>
            ) : selectStage === "Code Migration" ? (
              <>
                <MonacoEditor
                  height="300"
                  key={selectStage}
                  language={selectSourceLanguage}
                  theme="vs-dark"
                  value={codeMigrate}
                  options={{
                    automaticLayout: true, // Adjusts the editor layout automatically
                    fontSize: 14,
                    scrollBeyondLastLine: false,
                  }}
                  onChange={handleCodeMigrateChange}
                />
                <Card style={{ marginTop: "20px" }}>
                  <b>
                    <p>Select Source Language:</p>
                  </b>
                  <Select
                    placeholder={
                      <span style={{ color: "black" }}>
                        Select Source Language
                      </span>
                    }
                    onChange={handleSelectSourceLang}
                    className="dropdown-child"
                    value={selectSourceLanguage}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {selectLanguageValue.map((item, index) => (
                      <Option
                        key={index}
                        value={item}
                        style={{ textAlign: "center" }}
                      >
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Card>
                <Card style={{ marginTop: "20px" }}>
                  <b>
                    <p>Select Target Language:</p>
                  </b>
                  <Select
                    placeholder={
                      <span style={{ color: "black" }}>Select Category</span>
                    }
                    onChange={handleSelectTargetLang}
                    className="dropdown-child"
                    value={selectTargetLanguage}
                    style={{
                      width: "100%",
                      textAlign: "center",
                      color: "black",
                    }}
                  >
                    {selectLanguageValue.map((item, index) => (
                      <Option
                        key={index}
                        value={item}
                        style={{ textAlign: "center" }}
                      >
                        {item}
                      </Option>
                    ))}
                  </Select>
                </Card>
                <Row>
                  <Col span={24} style={{ marginTop: "30px" }}>
                    <Button
                      type="primary"
                      loading={migrateCodeLoading}
                      onClick={handleMigrateCode}
                      style={{ width: "100%", borderRadius: "10px" }}
                    >
                      Migrate Code
                    </Button>
                  </Col>
                </Row>
                {codeMigrationResponse.length > 0 ? (
                  <Row>
                    <Col span={24} style={{ marginTop: "30px" }}>
                      <SyntaxHighlighter language="python" style={vscDarkPlus}>
                        {codeMigrationResponse}
                      </SyntaxHighlighter>
                    </Col>
                  </Row>
                ) : null}
              </>
            ) : null}
          </div>
        )}
        {selectCategory === "Code Chat" && selectStage === "Code Generation" ? (
          <Card style={{ marginTop: "20px" }}>
            <Row>
              <Col span={24} style={{ marginTop: "30px" }}>
                <b>
                  <p>Paste Your Code here:</p>
                </b>
                <MonacoEditor
                  height="300"
                  key={selectCategory}
                  language="javascript"
                  theme="vs-dark"
                  value={sourceCode}
                  options={{
                    automaticLayout: true,
                    fontSize: 14,
                    scrollBeyondLastLine: false,
                  }}
                  onChange={(e) => handleSourceCodeChange(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ marginTop: "30px" }}>
                <b>
                  <p>Ask the question about the code:</p>
                </b>
                <Input
                  type="text"
                  value={codechatinput}
                  onChange={(e) => setCodeChatInput(e.target.value)}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ marginTop: "30px" }}>
                <Button
                  type="primary"
                  loading={codeChatLoading}
                  onClick={handleCodeChat}
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  Generate Response
                </Button>
              </Col>
            </Row>
            {codeChatResponse.length > 0 ? (
              <Row>
                <Col span={24} style={{ marginTop: "30px" }}>
                  <SyntaxHighlighter language="python" style={vscDarkPlus}>
                    {codeChatResponse}
                  </SyntaxHighlighter>
                </Col>
              </Row>
            ) : null}
          </Card>
        ) : selectCategory === "Code Completions" &&
          selectStage === "Code Generation" ? (
          <Card style={{ marginTop: "20px" }}>
            <Row>
              <Col span={24} style={{ marginTop: "30px" }}>
                <b>
                  <p>Paste Your Partial Code here:</p>
                </b>
                <MonacoEditor
                  key={selectCategory}
                  height="300"
                  language="javascript"
                  theme="vs-dark"
                  value={partialCode}
                  options={{
                    automaticLayout: true,

                    fontSize: 14,
                    scrollBeyondLastLine: false,
                  }}
                  onChange={(e) => handlepartialCodeChange(e)}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24} style={{ marginTop: "30px" }}>
                <Button
                  type="primary"
                  loading={completeCodeLoading}
                  onClick={handlePartialCompleteCode}
                  style={{ width: "100%", borderRadius: "10px" }}
                >
                  Complete Code
                </Button>
              </Col>
            </Row>
            {codeCompleteResponse.length > 0 ? (
              <Row>
                <Col span={24} style={{ marginTop: "30px" }}>
                  <SyntaxHighlighter language="python" style={vscDarkPlus}>
                    {codeChatResponse}
                  </SyntaxHighlighter>
                </Col>
              </Row>
            ) : null}
          </Card>
        ) : null}
      </Card>
    </>
  );
};

export default SDLC;
