import React, { useState } from "react";
import { Collapse, Input, Select, Button, message } from "antd";
import axios from "axios";
import ImageCaraousal from "./ImageCaraousal";
import { ip } from "./Imagedata";
import { virtualTryDomain } from "../../../../dynamicIPs";

const { Panel } = Collapse;
const { Option } = Select;

const CustomCollapse = ({ handleSelectImageSearch }) => {
  const [query, setQuery] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [ecommerceData, setEcommerceData] = useState([
    { name: "Amazon", value: "Amazon" },
    { name: "Ajio", value: "Ajio" },
    { name: "Bewakoof", value: "Bewakoof" },
  ]);
  const [loading, setLoading] = useState(false);
  const [searchQueryResponse, setSearchQueryResponse] = useState([]);

  const handleQueryChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const handleSearch = async () => {
    const params = { search_query: query, website: selectedValue };
    setSearchQueryResponse([]);
    setLoading(true);
    // Perform search logic here
    const url = `${virtualTryDomain}/scrape`;
    try {
      const res = await axios.get(url, { params });
      setSearchQueryResponse(res.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      message.error(error.message);
    }
  };

  const handleClear = () => {
    setQuery("");
    setSelectedValue("");
    setSearchQueryResponse([]);
  };

  const handleImageUrlSearch = (url) => {
    handleSelectImageSearch(url);
  };

  return (
    <Collapse
      defaultActiveKey={[]}
      expandIconPosition="right"
      style={{ width: "94%" }}
      //   style={{ width: "400px" }}
    >
      <Panel header="Search Garment" key="1">
        <h6>Search Query</h6>
        <Input
          placeholder="Enter search query"
          value={query}
          onChange={handleQueryChange}
        />
        <h6 style={{ marginTop: "10px" }}>Site Currentry amazon is working</h6>
        <div style={{ marginTop: "10px" }}>
          <Select
            placeholder="Select value"
            value={selectedValue}
            onChange={handleSelectChange}
            style={{ marginRight: 8, width: "100%" }}
          >
            {ecommerceData.map((data, index) => (
              <Option value={data.value}>{data.name}</Option>
            ))}
          </Select>
        </div>
        <Button
          onClick={handleClear}
          style={{ marginTop: "10px", width: "100%" }}
        >
          Clear
        </Button>

        <Button
          type="primary"
          onClick={handleSearch}
          style={{ marginTop: "10px", width: "100%" }}
          loading={loading}
        >
          Search
        </Button>
        {searchQueryResponse.length > 0 && (
          <div style={{ marginTop: "20px" }}>
            <ImageCaraousal
              imageData={searchQueryResponse}
              isBase64={true}
              isFullWidth={true}
              handleSelectImage={handleImageUrlSearch}
            />
          </div>
        )}
      </Panel>
    </Collapse>
  );
};

export default CustomCollapse;
