import React, { useState, useEffect, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { DotLoading } from "antd-mobile";
import { NavLink } from "react-router-dom";
// import { routes } from "../../util/constants";
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Tooltip,
  Typography,
  Tabs,
  Carousel,
  message,
  Badge,
} from "antd";
// import {Row, Col, Carousel, message } from "antd";
import { DeleteOutlined, EyeFilled, PlusOutlined } from "@ant-design/icons";
import BreadCrumbs from "../../../../BreadCrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { IP } from "../../../../../dynamicIPs";
const { TabPane } = Tabs;

const CaptureImage = (props) => {
  const location = useLocation();
  const [isWebCamModalOpen, setIsWebCamModalOpen] = useState(false);
  const [isViewimageFile, setIsViewimageFile] = useState(false);
  const [webcamImages, setWebcamImages] = useState([]);
  const [trainingLoading, setTraingLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [uploadedImage, setUploadImage] = useState(null);
  const [genImageSrc, setGenImageSrc] = useState(null);
  const [responseLoading, setResponseLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const webcamRef = useRef(null);

  const [imageFile, setimageFile] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const showModalWebcam = () => {
    setIsWebCamModalOpen(true);
    setImageUrls([]);
    setWebcamImages([]);
    if (webcamRef.current) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          webcamRef.current.video.srcObject = stream;
        })
        .catch((error) => {
          console.log("Error accessing webcam", error);
        });
    }
  };
  const handleOkWebcam = () => {
    setIsWebCamModalOpen(false);
  };
  const handleCancelWebcam = () => {
    setIsWebCamModalOpen(false);
    if (webcamRef.current) {
      webcamRef.current.video.srcObject.getTracks().forEach((track) => {
        track.stop();
      });
    }
  };

  const handleViewimageFile = () => {
    setIsViewimageFile(true);
  };
  const removeUploadimageFile = (filename) => {
    const filterList = imageFile.filter((file) => file.name !== filename);
    setimageFile(filterList);
    if (imageFile.length === 1) {
      setIsViewimageFile(false);
    }
  };

  const handleCaptureImage = () => {
    setGenImageSrc(null);
    setImageUrls([]);
    const imgSrc = webcamRef.current.getScreenshot();
    fetch(imgSrc)
      .then((res) => res.blob())
      .then((blob) => {
        setUploadImage(blob);

        // now upload it
      });
    message.success("Image has been captured successfully!");
    setWebcamImages([...webcamImages, imgSrc]);
  };
  const handleimageFileUpload = (file) => {
    setGenImageSrc(null);
    setWebcamImages([]);
    setImageUrls([]);
    const selectedFile = file.target.files[0];
    setUploadImage(selectedFile);
    const imgUrl = URL.createObjectURL(selectedFile);
    setImageUrls([imgUrl]);
    setimageFile([selectedFile]);
    message.success("file uploaded successfully");
  };
  const videoConstraints = {
    width: 450,
    height: 300,
    facingMode: "user",
  };
  const handleSubmit = () => {
    setGenImageSrc(null);
    setResponseLoading(true);
    const url =
      props.location.state.name == "upload"
        ? `http://${IP.POSEMIMIC}:8007/pose_mimic`
        : `http://${IP.POSEMIMIC}:8007/realistic_vision`;
    const formData = new FormData();
    formData.append("query", inputValue);
    formData.append("image", uploadedImage);
    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        return response.blob();
      })

      .then((blob) => {
        const imageUrl = URL.createObjectURL(blob);
        setResponseLoading(false);

        setGenImageSrc(imageUrl);
      })

      .catch((error) => {
        console.error("Error fetching image:", error);
        setResponseLoading(false);
        message.error("facing some network issue try again after some time!");
      });
  };

  return (
    <div>
      <BreadCrumbs location={location} style={{ color: "red" }} />
      <Row style={{ marginTop: "40px" }} gutter={[8, 8]}>
        <Col span={18}>
          <Button
            type="primary"
            onClick={() => {
              setOpen(true);
              setWebcamImages([]);
            }}
          >
            Upload Image
          </Button>
        </Col>
        <Col span={6}>
          <Button type="primary" onClick={showModalWebcam}>
            Capture Through Webcam
          </Button>
        </Col>
      </Row>
      {imageUrls.length > 0 ? (
        <Card
          style={{
            marginTop: "30px",
          }}
        >
          <Row span={[8, 8]}>
            <Col span={12}>
              <img
                src={imageUrls[0]}
                alt="image"
                width={genImageSrc === null ? "950px" : null}
                height={"260px"}
              />
            </Col>
            {genImageSrc !== null ? (
              <Col span={12}>
                <img src={genImageSrc} alt="generated image" height={"260px"} />
              </Col>
            ) : null}
          </Row>
        </Card>
      ) : null}
      {webcamImages.length > 0 ? (
        <Card
          style={{
            marginTop: "30px",
          }}
        >
          <Row span={[8, 8]}>
            <Col span={12}>
              <img
                src={webcamImages[0]}
                alt="image"
                width={genImageSrc === null ? "950px" : null}
                height={"260px"}
              />
            </Col>
            {genImageSrc !== null ? (
              <Col span={12}>
                <img src={genImageSrc} alt="generated image" height={"260px"} />
              </Col>
            ) : null}
          </Row>
        </Card>
      ) : null}

      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Input
            type="text"
            onChange={handleInputChange}
            value={inputValue}
            placeholder="Text Prompt"
          />
        </Col>
        <Col span={24}>
          <Button
            type="primary"
            style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
            onClick={handleSubmit}
            loading={responseLoading}
          >
            Generate Image
          </Button>
        </Col>
      </Row>

      <div>
        <Modal
          title="Upload Image"
          centered
          open={open}
          onOk={() => setOpen(false)}
          onCancel={() => setOpen(false)}
          width={400}
        >
          <Row className="row">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              {isViewimageFile ? (
                <div>
                  {imageFile.map((file, index) => (
                    <Row gutter={[8, 8]} justify="center" align="middle">
                      <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                        <label>
                          <h4 key={index}>{file.name}</h4>
                        </label>
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Button
                          onClick={() => removeUploadimageFile(file.name)}
                          danger
                        >
                          <DeleteOutlined />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </div>
              ) : (
                <div className="text-center">
                  <label
                    for="imageInputFile"
                    style={{
                      fontSize: "25px",
                      textAlign: "-webkit-center",
                    }}
                  >
                    <div className="drag-area ">
                      <h6>Upload</h6> <PlusOutlined />
                      <input
                        className="form-control"
                        type="file"
                        id="imageInputFile"
                        // name="inputFile"
                        // value={inputFile}
                        multiple
                        onChange={handleimageFileUpload}
                        hidden
                        accept="image/png, image/jpeg,image/jpg,application/pdf,video/mp4,application/image"
                      />
                    </div>
                    {imageFile.length > 0 ? (
                      <Tooltip title="View Uploaded File" placement="bottom">
                        <Badge count={imageFile.length} size="small">
                          <EyeFilled
                            onClick={handleViewimageFile}
                            style={{ fontSize: "25px" }}
                          />
                        </Badge>
                      </Tooltip>
                    ) : null}
                  </label>
                </div>
              )}
            </Col>
          </Row>
        </Modal>
        <Modal
          title="Webcam Modal"
          open={isWebCamModalOpen}
          onOk={handleOkWebcam}
          onCancel={handleCancelWebcam}
          footer={null}
        >
          <div>
            <Webcam
              audio={false}
              ref={webcamRef}
              videoConstraints={videoConstraints}
              screenshotFormat="image/jpeg"
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Button onClick={handleCaptureImage} type="primary">
              Capture Image
            </Button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CaptureImage;
