import "./App.css";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import NavRoutes from "./NavRoutes";

function App() {
  useEffect(() => {
    document.body.style.zoom = "90%";
  }, []);

  return (
    <BrowserRouter>
      <NavRoutes />
    </BrowserRouter>
  );
}

export default App;
