export const language = [
  "ENGLISH",
  "SPANISH",
  "FRENCH",
  "ARABIC",
  "GERMAN",
  "POLISH",
  "ITALIAN",
  " PORTUGUESE",
];
export const languageArray = [
  "English",
  "Spanish",
  "French",
  "Arabic",
  "German",
  "Polish",
  "Italian",
  "Portuguese",
  "Afrikaans",
  "Amharic",
  "Azerbaijani",
  "Bulgarian",
  "Bengali",
  "Bosnian",
  "Catalan",
  "Czech",
  "Welsh",
  "Danish",
  "Greek",
  "Estonian",
  "Persian",
  "Finnish",
  "Filipino",
  "Galician",
  "Gujarati",
  "Hebrew",
  "Hindi",
  "Croatian",
  "Hungarian",
  "Indonesian",
  "Icelandic",
  "Japanese",
  "Javanese",
  "Georgian",
  "Kazakh",
  "Khmer",
  "Kannada",
  "Korean",
  "Lao",
  "Lithuanian",
  "Latvian",
  "Macedonian",
  "Malayalam",
  "Mongolian",
  "Marathi",
  "Malay",
  "Maltese",
  "Myanmar",
  "Norwegian",
  "Nepali",
  "Dutch",
  "Norwegian Bokmål",
  "Norwegian Nynorsk",
  "Panjabi",
  "Pashto",
  "Romanian",
  "Russian",
  "Sinhala",
  "Slovak",
  "Slovenian",
  "Somali",
  "Albanian",
  "Serbian",
  "Sundanese",
  "Swedish",
  "Swahili",
  "Tamil",
  "Telugu",
  "Thai",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Chinese",
  "Zulu",
];

export const elevenlabs = [
  "Mimi",
  "Giovanni",
  "Glinda",
  "Sam",
  "Ryan",
  "Jessi",
  "Nicole",
  "Adam",
  "Serena",
  "Daniel",
  "Grace",
  "Freya",
  "Gigi",
  "Ethan",
  "Michael",
  "Jeremy",
  "Joseph",
  "James",
  "Matthew",
  "Matilda",
  "Charlotte",
  "Arnold",
  "Josh",
  "Dorothy",
  "Liam",
  "Harry",
  "Patrick",
  "Callum",
  "Elli",
  "Emily",
  "Charlie",
  "Thomas",
  "Antoni",
  "Bella",
  "Fin",
  "Dave",
  "Domi",
  "Clyde",
  "Rachel",
];
