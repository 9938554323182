import React from 'react';

import { Card, Table } from 'antd';
 
const IntrusionDetectionTable = ({ tableData }) => {

  // Dynamically generate columns based on the keys of the first object in tableData

  const columns = Object.keys(tableData[0] || {}).map((key) => ({

    title: key,

    dataIndex: key,

    key: key,

  }));
 
  return (
<Card

      style={{

        margin: '20px',

        padding: '20px',

        backgroundColor: '#f0f2f5',

        borderRadius: '8px',

        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',

      }}
>
<Table

        dataSource={tableData}

        columns={columns}

        pagination={false}

        style={{ backgroundColor: '#ffffff', borderRadius: '8px' , overflow: 'auto' }}

        rowKey={(record) => `${record['Date first seen']}-${record['Src IP Addr']}-${record['Dst IP Addr']}`}

      />
</Card>

  );

};
 
export default IntrusionDetectionTable;

 