import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DotLoading } from "antd-mobile";
import { Button, Col, Row, Tooltip, Typography, message } from "antd";
import logo from "../../../../assests/img/chatbot.png";
import BreadCrumbs from "../../../BreadCrumbs";
import { useLocation } from "react-router-dom";
import { domain_url } from "../../../../dynamicIPs";

import {
  AudioMutedOutlined,
  AudioOutlined,
  ControlOutlined,
  CopyOutlined,
  DislikeFilled,
  DislikeOutlined,
  LikeFilled,
  LikeOutlined,
  MessageFilled,
  SendOutlined,
} from "@ant-design/icons";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";
import SelectDropDown from "./SelectDropDown";
import Markdown from "react-markdown";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

const EmployeeAssistance = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [query, setQuery] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState(null);
  const [apiMessage, setApiMessage] = useState(null);
  const messageContainerRef = useRef(null);
  const [feedbackMessage, setFeedbackMessage] = useState(null);
  const [clickLike, setClickLike] = useState(false);
  const [clickDisLike, setClickDisLike] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const [fillerMessages, setFillerMessages] = useState([
    "Hmm...let me think",
    "Generating Response!",
    "Here is the answer.",
  ]);
  const [fullResponse, setFullResponse] = useState("");
  const [currentFillerMessage, setCurrentFillerMessage] = useState("");
  const [modalName, setModalName] = useState("");

  const [msgData, setMsgData] = useState([
    {
      text: "Hi, How may I help you?",
      isUser: false,
      like: false,
      dislike: false,
      followUpQuestions: [],
    },
  ]);
  const [isResponseDone, setIsResponseDone] = useState(false);

  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [userMessage, apiMessage]);

  useEffect(() => {
    handleListen();
  }, [isListening]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setInputMessage(event.target.value);
    setUserQuery(event.target.value);
  };

  const getModalName = (name) => {
    setModalName(name);
  };

  const handlClickLikeDisLike = async (event, msg, type, index) => {
    if (type === "like") {
      setMsgData((prevData) => {
        const data = [...prevData];
        data[index].like = true;
        data[index].dislike = false;
        return data;
      });
      // setClickDisLike(false);
      // setClickLike(true);
    } else {
      setMsgData((prevData) => {
        const data = [...prevData];
        data[index].like = false;
        data[index].dislike = true;
        return data;
      });
      setClickLike(false);
      setClickDisLike(true);
    }
    try {
      let url = `${domain_url}/askhr/feedback`;

      const body = {
        prompt: msg.text,
        like: type === "like" ? true : false,
        conversation: [
          { role: "user", content: msg.text },
          { role: "assistance", content: msg.text },
        ],
      };

      const response = await axios.post(url, body);
      setFeedbackMessage(response.data);
      setTimeout(() => {
        setFeedbackMessage(null);
      }, 4000);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleClickRecommendation = (event, recommendation) => {
    setInputMessage(recommendation);
    setQuery(recommendation);
  };

  const getDataPrevious = () => {
    const body = {
      query_string: query,
      model_name: modalName,
      memory_messages: [],
    };

    if (localStorage.getItem("history") === null) {
      const historyMessages = [];

      const obj = {
        role: "user",
        content: query,
      };
      historyMessages.push(obj);
      localStorage.setItem("history", JSON.stringify(historyMessages));
    } else {
      const data = JSON.parse(localStorage.getItem("history"));

      const splitData = data.slice(-10);
      body["memory_message"] = splitData;
      localStorage.setItem("history", JSON.stringify(splitData));
    }
    return body;
  };

  function isJSONParsable(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!query) return;
    const randomIndex = Math.floor(Math.random() * fillerMessages.length);
    setCurrentFillerMessage(fillerMessages[randomIndex]);
    setUserMessage(query);
    setInputMessage("");
    setIsListening(false);
    setLoading(true);
    setFeedbackMessage("");

    try {
      let url = `${domain_url}/askhr/pdf/langchain`;
      const getPreviousData = getDataPrevious();
      const response = await fetch(url, {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(getPreviousData),
      });
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      // Here we start prepping for the streaming response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;
      let isFirstChunk = true;

      while (loopRunner) {
        const { value, done } = await reader.read();
        if (done) {
          setIsResponseDone(true);
          const resObj = {
            role: "assistant",
            content: fullResponse,
          };
          const localData = JSON.parse(localStorage.getItem("history"));
          localData.push(resObj);
          localStorage.setItem("history", JSON.stringify(localData));
          break;
        }
        let decodedChunk = decoder.decode(value, { stream: true });

        if (isFirstChunk) {
          isFirstChunk = false;
          setLoading(false);
          setMsgData([
            ...msgData,
            {
              text: query,
              isUser: true,
              like: false,
              dislike: false,
              followUpQuestions: [],
            },
            {
              text: decodedChunk,
              isUser: false,
              like: false,
              dislike: false,
              followUpQuestions: [],
            },
          ]);
        } else {
          if (isJSONParsable(decodedChunk)) {
            const parsedData = JSON.parse(decodedChunk);
            const originalResponse = parsedData["original_response"];
            const followUpQuestion = parsedData["questions"];
            setMsgData((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[updatedItems.length - 1].text = originalResponse;

              updatedItems[updatedItems.length - 1].followUpQuestions =
                followUpQuestion;

              return updatedItems;
            });
          } else {
            setMsgData((prevItems) => {
              const updatedItems = [...prevItems];
              updatedItems[updatedItems.length - 1].text = decodedChunk;
              return updatedItems;
            });
          }
        }

        setFullResponse(decodedChunk);
      }
    } catch (error) {
      console.log("error", error);
      localStorage.removeItem("history");
      // setQuery("");

      message.error(error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleCopyText = (msg) => {
    navigator.clipboard.writeText(msg.text);
  };

  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {
        mic.start();
      };
    } else {
      mic.stop();
      mic.onend = () => {
        console.log("Stopped Mic on Click");
      };
    }
    mic.onstart = () => {
      console.log("Mics on");
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      setInputMessage(transcript);
      setQuery(transcript);
      mic.onerror = (event) => {
        console.log(event.error);
      };
    };
  };

  return (
    <div
      style={{
        backgroundImage:
          'url("https://png.pngtree.com/background/20220721/original/pngtree-human-resources-and-customer-relationship-management-picture-image_1703645.jpg")',
        backgroundSize: "cover", // or 'contain' depending on your needs
        height: "100vh", // Set height and width as needed
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "10px",
        }}
      >
        <BreadCrumbs location={location} />
        <div>
          <VideoTour
            src={videoUrls.ask_hr_video}
            type="video/mp4"
            pdfSrc={pdfUrls.Ask_hr_sample_questions}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        {isOpen ? (
          <div className="chat-bot-modal-ask-hr">
            <div className="chat-header">
              <span>
                <img className="chatbot-logo" src={logo} alt="Logo" />
              </span>
              <SelectDropDown getModalName={getModalName} />
              <Row gutter={[16, 8]}>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <button
                    style={{
                      margin: "2px 0 0 0",
                      background: "none",
                    }}
                    className="close-button"
                    onClick={handleClose}
                  >
                    &#x2716;
                  </button>
                </Col>
              </Row>
            </div>

            <div className="messages" ref={messageContainerRef}>
              {msgData?.map((message, index) => (
                <>
                  <div
                    key={index}
                    className={`message ${message.isUser ? "user" : "api"}`}
                  >
                    <Markdown>{message.text}</Markdown>
                  </div>
                  {!message.isUser && index !== 0 && isResponseDone && (
                    <div className="like-dislike-conatiner">
                      {message.like ? (
                        <Tooltip title="Liked">
                          <LikeFilled
                            onClick={(e) =>
                              handlClickLikeDisLike(e, message, "like", index)
                            }
                            style={{ fontSize: "20px", color: "blue" }}
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Like">
                          <LikeOutlined
                            style={{ fontSize: "20px" }}
                            onClick={(e) =>
                              handlClickLikeDisLike(e, message, "like", index)
                            }
                          />
                        </Tooltip>
                      )}
                      {message.dislike ? (
                        <Tooltip title="Disliked">
                          <DislikeFilled
                            style={{
                              fontSize: "20px",
                              color: "blue",
                            }}
                            onClick={(e) =>
                              handlClickLikeDisLike(
                                e,
                                message,
                                "dislike",
                                index
                              )
                            }
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Dislike">
                          <DislikeOutlined
                            style={{ fontSize: "20px" }}
                            onClick={(e) =>
                              handlClickLikeDisLike(
                                e,
                                message,
                                "dislike",
                                index
                              )
                            }
                          />
                        </Tooltip>
                      )}
                      <Tooltip title="copy">
                        <CopyOutlined
                          style={{ fontSize: "20px", cursor: "pointer" }}
                          onClick={() => handleCopyText(message)}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {!message.isUser && index !== 0 && feedbackMessage && (
                    <div
                      style={{
                        width: "60%",
                        backgroundColor: "#C7C8CC",
                        marginTop: "10px",
                        textAlign: "center",
                        height: "30px",
                        borderRadius: "10px",
                      }}
                    >
                      {feedbackMessage.like === true ? (
                        <span style={{ marginRight: "5px" }}>😊</span>
                      ) : null}
                      {feedbackMessage.text}
                    </div>
                  )}
                  {!message.isUser &&
                    index !== 0 &&
                    message.followUpQuestions.length > 0 && (
                      <Row gutter={[8, 16]} style={{ marginTop: "20px" }}>
                        {message.followUpQuestions.map(
                          (recommendation, index) => (
                            <Col key={index} sm={24} md={24} lg={24}>
                              <div
                                style={{
                                  textAlign: "center",
                                  border: "1px solid black",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "20px",
                                  boxShadow: "2px 2px 2px rgba(0,0,0,0.5)",
                                  cursor: "pointer",
                                  backgroundColor: "#ADE1FA",
                                  padding: "5px",
                                }}
                                onClick={(e) =>
                                  handleClickRecommendation(e, recommendation)
                                }
                              >
                                {recommendation}
                              </div>
                            </Col>
                          )
                        )}
                      </Row>
                    )}
                </>
              ))}
              {loading ? (
                <>
                  <div className="user message">{query}</div>
                  <div className="message api">
                    {currentFillerMessage}
                    <span className="blinking-cursor">|</span>
                  </div>
                </>
              ) : null}
            </div>
            <hr style={{ margin: "0px" }} />

            <form onSubmit={handleSubmit} className="input-container">
              <input
                disabled={loading || !modalName}
                type="text"
                value={inputMessage}
                onChange={handleInputChange}
                placeholder="Ask a question about your documents..."
              />

              {loading ? (
                <></>
              ) : (
                <>
                  <Button
                    shape="circle"
                    icon={
                      isListening ? (
                        <Tooltip title="Stop listening">
                          <AudioMutedOutlined />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Type by voice">
                          <AudioOutlined />
                        </Tooltip>
                      )
                    }
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      margin: "-4px 0 0 0",

                      color: "black",
                    }}
                    onClick={() => setIsListening((prevState) => !prevState)}
                  />
                  <button
                    type="submit"
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      margin: "-4px 0 0 0",

                      color: "black",
                    }}
                  >
                    <SendOutlined />
                  </button>
                </>
              )}
            </form>
          </div>
        ) : (
          <>
            <div
              style={{
                width: "200px",
                height: "40px",
                border: "2px solid white",
                display: "flex",
                alignItems: "center",
                justifyContent: "content",
                borderRadius: "10px 0px 10px 10px",
              }}
              className="chatbot-text"
            >
              <h4 style={{ color: "white" }}>Chat with Me</h4>
            </div>

            <Typography.Text onClick={handleOpen} className="chatbot-icon">
              <MessageFilled
                style={{ fontSize: "30px", color: "white", padding: "7px" }}
              />
            </Typography.Text>
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeAssistance;
