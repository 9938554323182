import cardImg from "../../../assests/img/emp-card-image.png";
import { routes } from "../../../util/constants";
import codegen from "../../../assests/icon/Code generation.svg";
import dataanalImg from "../../../assests/icon/Data Analysis Bot.svg";
import AuotmationImg from "../../../assests/icon/Automated Advanced Analytics.svg";
import genAiChatbotImg from "../../../assests/icon/Database Agents.svg";

export const codeGenerationData = [
  // {
  //   name: "Code Generation Using GCP",
  //   icon: codegen,
  //   url: routes.CODEGENUSINGGCP,
  // },
  {
    name: "Data Analysis Bot",
    icon: dataanalImg,
    url: routes.DATAANALYSISINCODEGEN,
  },
  // {
  //   name: "Automated Advanced Analytics",
  //   icon: AuotmationImg,
  //   url: routes.AUTOMATIONANALYTICS,
  // },
  {
    name: "Database Agents",
    icon: genAiChatbotImg,
    url: routes.OPENAICHATBOTFORCODE,
  },
];
