import React, { useState } from "react";
import { Table, Form, Input, Button, Typography, Card, Upload, Spin, message } from "antd";
import { UploadOutlined, InfoCircleOutlined, RobotOutlined, UserOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import BreadCrumbs from "../../../BreadCrumbs";
import VideoTour from "../../../video-guide";
import { useLocation } from "react-router-dom";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";
import { api_url } from "../../../../dynamicIPs";
 
const { Title, Text } = Typography;
 
const SalesLeadAutomation = () => {
  const location = useLocation();
  const [dTL, setDTL] = useState([]);
  const [aR, setAR] = useState([]);
  const [dS, setDS] = useState([]);
  const [cb, setCB] = useState([]);
  const [file, setFile] = useState(null); // State to store the uploaded file
  const [loading, setLoading] = useState(false); // Loading state
 
  // Function to handle form submission
  const onFinish = async (values) => {
    console.log("Form values:", values);
 
    if (!file) {
      console.error("No file uploaded");
      return;
    }
 
    // Start loading
    setLoading(true);
 
    // Create form data
    const formData = new FormData();
    formData.append("file", file); // Attach the uploaded file
    formData.append("question", values.question); // Attach the question
 
    try {
      // API call using fetch
      const response = await fetch(`${api_url}/sales/uploadfile`, {
        method: "POST",
        body: formData,
        headers: {
          accept: "application/json",
        },
      });
 
      const result = await response.json();
      console.log(result);
 
      // Assuming the API response contains lead data
      if (result) {
        setDTL(result?.additional_response.top_leads); // Update the state with leads data
        setAR(result?.additional_response.assistant_response);
        setDS(result?.additional_response.descriptions);
        setCB(result?.additional_response.chatbot_conversation);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      // Stop loading
      setLoading(false);
    }
  };
 
  // Handle file selection
  const beforeUpload = (file) => {
    setFile(file);
    return false; // Prevent automatic upload by returning false
  };
 
  // Define the columns for the table
  const columns = [
    { title: "Company Name", dataIndex: "company_name", key: "company_name" },
    { title: "Contact Name", dataIndex: "contact_name", key: "contact_name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Industry", dataIndex: "industry", key: "industry" },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      sorter: (a, b) => a.score - b.score,
    },
  ];
 
  return (
    <>
      {/* Breadcrumbs */}
      <BreadCrumbs location={location} />
 
      {/* VideoTour Section */}
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.sales_lead_automation} type="video/mp4" pdfSrc={pdfUrls.sales} />
      </div>
 
      {/* Centered Form Card */}
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Card style={{ width: "600px", padding: "24px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
          <Title level={2}>Score Sales Leads with Multiagents</Title>
          <Text>Add external data for sales leads CSV analysis.</Text>
 
          {/* Form Section */}
          <Form onFinish={onFinish} layout="vertical" style={{ marginTop: "16px" }}>
            {/* File Upload Input with Ant Design's Upload Component */}
            <Form.Item
              label="Upload your CSV file:"
              name="file"
              rules={[{ required: true, message: "Please upload a CSV file!" }]}
            >
              <Upload
                beforeUpload={beforeUpload} // Custom handler to manage file selection
                accept=".csv" // Only accept CSV files
                maxCount={1} // Limit to one file
              >
                <Button icon={<UploadOutlined />}>Click to Upload CSV</Button>
              </Upload>
            </Form.Item>
 
            {/* Question Input with Icon */}
            <Form.Item
              label="Enter your question about lead scoring:"
              name="question"
              rules={[{ required: true, message: "Please enter a question!" }]}
            >
              <Input placeholder="Score and prioritize the top 10 leads" prefix={<QuestionCircleOutlined />} />
            </Form.Item>
 
            {/* Submit Button */}
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
 
      {/* Loader while fetching data */}
      <Spin spinning={loading} tip="Fetching leads...">
        {/* Displaying the Lead Results */}
        {dTL.length > 0 && (
          <div style={{ marginTop: "24px" }}>
            <Card
              style={{
                backgroundColor: "#f0f0f0",
                position: "relative",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
              }}
              title="Top Leads"
              extra={
                <InfoCircleOutlined
                  style={{ fontSize: "24px", color: "#1890ff", position: "absolute", top: "15px", right: "15px" }}
                />
              }
            >
              {/* Smaller Table */}
              <Table
                dataSource={dTL}
                columns={columns}
                pagination={false}
                size="small" // Set table size to small
                rowClassName="small-row" // Optionally add a class for further customization
                style={{ fontSize: "14px" }} // Optional: Custom font size for table content
              />
            </Card>
 
            {/* Chat Responses Section */}
            <div style={{ margin: "30px", marginTop: "20px" }}>
              <ChatResponse text={aR} icon={<RobotOutlined />} color="yellow" />
              <ChatResponse text={dS} icon={<UserOutlined />} color="red" />
              <ChatBotConversation conversation={cb} />
            </div>
          </div>
        )}
      </Spin>
    </>
  );
};
 
// Chat response component and other components remain unchanged
 
export default SalesLeadAutomation;
 
 
 
// Chat response component
const ChatResponse = ({ text, icon, color }) => {
  const backgroundColor = color === "yellow" ? "#ffbd45" : "#ff6c6c"; // Yellow or red background
 
  // Function to handle newlines and double newlines
  const formatText = (text) => {
    if (!text) return null;
    
    // Replace single `/n` with a single line break and `/n/n` with double line breaks
    return text.split(/\/n\/n|\/n/).map((part, index, arr) => {
      if (part === "") return <br key={index} />; // If empty, insert a line break
      return (
        <>
          <Text key={index} style={chatText}>{part}</Text>
          {index < arr.length - 1 && arr[index + 1] === "" ? <><br /><br /></> : <br />}
        </>
      );
    });
  };
 
  return (
    <div style={chatResponseContainer}>
      {/* Icon section */}
      <div style={{ ...iconContainer, backgroundColor }}>{icon}</div>
      {/* Chat bubble section */}
      <div style={{ ...chatBubble, backgroundColor: "#f5f5f5" }}>
        {/* Light gray background for the bubble */}
        {Array.isArray(text) ? (
          text.map((item, index) => (
            <div key={index}>
              {formatText(item)}
              {index < text.length - 1 && <br />} {/* Add line breaks between items */}
            </div>
          ))
        ) : (
          formatText(text)
        )}
      </div>
    </div>
  );
};
 


 
// Styles
const chatResponseContainer = {
  display: "flex",
  alignItems: "flex-start",
  marginBottom: "16px",
};
 
const iconContainer = {
  padding: "10px",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "12px",
};
 
const chatBubble = {
  padding: "12px",
  borderRadius: "10px", // Rounded corners
  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", // Shadow for the bubble
  maxWidth: "80%", // Keep the bubble width responsive
  wordWrap: "break-word", // Ensure long words break correctly
};
 
const chatText = {
  fontSize: "14px", // Control text size
  color: "#333", // Darker text color
};
  
 


 
const ChatBotConversation = ({ conversation }) => {
  // Function to process conversation and split into lines
  const renderConversation = (conversation) => {
    if (!conversation) return null;
 
    // Split conversation by new lines (`\n`)
    const lines = conversation.split("\n");
 
    return lines.map((line, index) => {
      // Determine if the line is from the Chatbot or the User
      const isChatbot = line.startsWith("Chatbot:");
      const isUser = line.startsWith("User:");
 
      return (
        <div key={index} style={{ display: "flex", marginBottom: "8px" }}>
          {isChatbot ? (
            <RobotOutlined style={{ color: "#ffbd45", marginRight: "8px" }} />
          ) : isUser ? (
            <UserOutlined style={{ color: "#ff6c6c", marginRight: "8px" }} />
          ) : null}
 
          {/* Display message */}
          <Text>{line}</Text>
        </div>
      );
    });
  };
 
  return <div>{renderConversation(conversation)}</div>;
};