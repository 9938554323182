import { Button, Col, Input, Modal, Row, Tabs, Tooltip } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import ip from "../../../assests/img/ip-icon1.png";
import Application from "./Application";
import Config from "./Config";
const { TabPane } = Tabs;

export default function AutomationAnalytics() {
  const location = useLocation();
 
  return (
    <>
      <BreadCrumbs location={location} />
      <div className="main-container">
        
        <div className="app-tab">
          <Tabs
            defaultActiveKey="automation-analytics"
            type="card"
            tabBarGutter={12}
            size={"large"}
          >
            <TabPane tab={<span>Application</span>} key="automation-analytics">
              <Application />
            </TabPane>
            <TabPane tab={<span>Settings</span>} key="config">
              <Config />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
}
