const codeGenerationData = [
  "GENAI significantly speeds up the development process by generating code snippets and entire modules quickly.",
  "This reduces the time developers spend on routine coding tasks, allowing them to focus on more complex and creative aspects of software development.",
];
const bugDetectionData = [
  "GENAI significantly speeds up the process of identifying bugs by quickly analyzing code and pinpointing potential errors, which might be overlooked by human developers.",
  "Generative AI can identify potential bugs or vulnerabilities. It effectively flags problematic code sections, highlights potential errors, and suggests improvements based on insights garnered from previous projects. This significantly reduces the time spent on manual code review and debugging.",
];
const summarizationData = [
  "Generative AI, powered by natural language processing techniques, can understand and summarize technical documentation and code comments. It generates concise and accurate summaries, making it easier for developers to comprehend complex codebases and collaborate more effectively.",
];
const automatedTestingData = [
  "Generative AI facilitates the automation of testing procedures by generating test cases and scenarios. By analyzing the codebase and comprehending its behavior, the AI can generate a wide range of test inputs and expected outputs. This enhances test coverage and uncovers potential edge cases that manual testing might overlook.",
];
const codeMigrationData = [
  "Migrate code from one programming language to another.",
];

const codeGenerationDataForInput = [
  "Generate Code Snippets",
  "Describe the functionality for which you need code:",
];
const bugDetectionDataForInput = [
  "Detect Bugs in Your Code",
  "Enter your code here for bug detection:",
];
const summarizationDataForInput = [
  "Enter documentation or code comments for summarization:",
];
const automatedTestingDataForInput = [
  "Enter your codebase to generate test cases:",
];
const codeMigrationDataForInput = ["Enter the code you want to migrate:"];

export const stageData = {
  "Code Generation": codeGenerationData,
  "Bug Detection": bugDetectionData,
  "Summarize Technical Documentation and Code Comments": summarizationData,
  "Automated Testing": automatedTestingData,
  "Code Migration": codeMigrationData,
};
export const stageDataForInput = {
  "Code Generation": codeGenerationDataForInput,
  "Bug Detection": bugDetectionDataForInput,
  "Summarize Technical Documentation and Code Comments":
    summarizationDataForInput,
  "Automated Testing": automatedTestingDataForInput,
  "Code Migration": codeMigrationDataForInput,
};

export const selectStageValue = [
  "Code Generation",
  "Bug Detection",
  "Summarize Technical Documentation and Code Comments",
  "Automated Testing",
  "Code Migration",
];
export const selectCategoryValue = ["Code Chat", "Code Completions"];
export const selectLanguageValue = [
  "Python",
  "Javascript",
  "Java",
  "C++",
  "ABAP",
];
