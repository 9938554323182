import React from 'react'
import { useLocation } from 'react-router-dom'
import BreadCrumbs from '../../../BreadCrumbs';

export default function CodeGenUsingGcp() {
    const location = useLocation();
    return (
        <>
            <BreadCrumbs location={location} />
            <div>CodeGenUsingGcp</div>

        </>

    )
}
