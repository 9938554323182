import cardImg from "../../../assests/img/emp-card-image.png";
import { routes } from "../../../util/constants";
import videoautomation from "../../../assests/icon/Video Translation.svg";
import voicecloningimage from "../../../assests/icon/Voice Cloning & Lip Synchronization.svg";

export const videoGenerationData = [
  {
    name: "Advertisement & Video Generator",
    icon: videoautomation,
    url: routes.VIDEOAUTOMATION,
  },
  {
    name: "Voice Cloning and Lip Synchronization",
    icon: voicecloningimage,
    url: routes.VOICECLONING,
  },
];
