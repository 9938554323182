import { Col, Row } from "antd";
import React, { useState } from "react";
import DynamicAutomateData from "./DynamicAutomateData";
import VideoTranslation from "./VideoTranslation";

export default function ContentAutomation() {
  const [selectedOption, setSelectedOption] = useState("option2");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <>
      <div className="content-automation-option">
        <b>
          <p className="choose-option-text">Choose an Option</p>
        </b>
        <Row className="choose-option-content" gutter={[16, 8]}>
          <Col className="choose-one-radio">
            <label style={{ padding: "10px" }}>
              <input
                type="radio"
                value="option1"
                checked={selectedOption === "option1"}
                onChange={handleOptionChange}
              />
              <span style={{ marginLeft: "10px" }}>Automate a Video</span>
            </label>
          </Col>
          <Col className="choose-two-radio">
            <label style={{ padding: "10px" }}>
              <input
                type="radio"
                value="option2"
                checked={selectedOption === "option2"}
                onChange={handleOptionChange}
              />
              <span style={{ marginLeft: "10px" }}>
                Automate Video Translation
              </span>
            </label>
          </Col>
        </Row>
      </div>
      <div>
        {selectedOption === "option2" ? (
          <VideoTranslation />
        ) : (
          <DynamicAutomateData />
        )}
      </div>
    </>
  );
}
