import cardImg from "../../../assests/img/emp-card-image.png";
import { routes } from "../../../util/constants";
import musicImg from "../../../assests/icon/Music Generation Bot.svg";
import voicecloningimage from "../../../assests/icon/Voice Cloning & Lip Synchronization.svg";

export const audioGenerationData = [
  {
    name: "Music Generation Bot",
    icon: musicImg,
    url: routes.MUSICCOMPOSITION,
  },
  {
    name: "Voice Cloning and Lip Synchronization",
    icon: voicecloningimage,
    url: routes.VOICECLONINGINVAUDIO,
  },
];
