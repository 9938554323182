import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import { Card, Col, Row } from "antd";
import { audioGenerationData } from "./audiogenerationdata";
import { routes } from "../../../util/constants";

export default function AudioGeneration() {
  const location = useLocation();
  return (
    <>
      <BreadCrumbs location={location} />
      {/* <Row gutter={[16, 16]} style={{

                marginTop: "40px",

                borderRadius: "10px",
            }}

                has context menu>
                {audioGenerationData.map((value, index) => (
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Card
                            style={{ borderRadius: "20px" }}

                        >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <img style={{ margin: '0 0 0 -10px' }} src={value.icon} alt="cardImg" width={150} height={110} />
                                </div>


                                <NavLink
                                    to={value.url ? value.url : routes.EMPASSIS}
                                    style={{
                                        textDecoration: "none",
                                        fontSize: "24px",
                                        paddingTop: "30px",


                                    }
                                    }
                                >
                                    {value.name}
                                </NavLink>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row> */}

      <Row gutter={[16, 24]} style={{ marginTop: "20px" }}>
        {audioGenerationData.map((value, index) => (
          <Col span={8}>
            <Card style={{ borderRadius: "14px" }} className="category-card">
              <div
                style={{
                  display: "flex",
                  direction: "row",
                  alignItems: "center",
                }}
              >
                {/* <div style={{marginRight:"10px"}}>{value.icon}</div> */}
                <img
                  style={{ marginRight: "10px", height: "45px", width: "45px" }}
                  src={value.icon}
                  alt="cardImg"
                />

                <NavLink
                  to={value.url ? value.url : routes.EMPASSIS}
                  style={{
                    textDecoration: "none",
                    fontSize: "16px",
                  }}
                >
                  {value.name}
                </NavLink>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
}
