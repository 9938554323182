import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import FileUpload from "./FileUpload";
import "./LegalDoc.css";
import { Button, Card } from "antd";
import axios from "axios";
import Markdown from "react-markdown";
import Chatbot from "./Chatbot";
import { DotLoading } from "antd-mobile";
import { SendOutlined } from "@ant-design/icons";
import { api_url } from "./../../../../dynamicIPs";
import VideoTour from "../../../video-guide";

const LegalDocSummarization = () => {
  const location = useLocation();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [summaryResponse, setSummaryResponse] = useState(null);
  const [keyPoints, setKeyPoints] = useState(null);
  const [all_texts, setAllTexts] = useState(null);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const messageContainerRef = useRef(null);
  const [msgData, setMsgData] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiMessage, setApiMessage] = useState("");

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setInputMessage(event.target.value);
  };

  const handleRecieveFiles = (file) => {
    setUploadedFile(file);
  };

  const handleGetSummary = async () => {
    try {
      setSummaryLoading(true);
      setSummaryResponse(null);
      setKeyPoints(null);
      setMsgData([]);
      let url = `${api_url}/legal/upload_pdf`;
      console.log("url", url);
      const formData = new FormData();

      formData.append("file", uploadedFile[0].originFileObj);

      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSummaryLoading(false);
      console.log("response", response);
      setSummaryResponse(response.data["summary"]);
      setKeyPoints(response.data["keyPoints"]);
      setAllTexts(response.data["all_texts"]);
    } catch (error) {
      console.log("error from get Summary", error);
      setSummaryLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMsgData([
      ...msgData,
      {
        text: query,
        isUser: true,
      },
      { text: <DotLoading />, isUser: false },
    ]);
    if (!query) return;
    setInputMessage("");
    // setIsListening(false);
    setLoading(true);

    try {
      let url = `${api_url}/legal/chat`;
      const body = { prompt: query, all_texts: all_texts };

      const response = await axios.post(url, body);
      console.log("response from chatbot", response);
      setMsgData([
        ...msgData,
        {
          text: query,
          isUser: true,
        },
        { text: response.data.content, isUser: false },
      ]);

      setQuery("");
      setLoading(false);
    } catch (error) {
      setApiMessage(error.message);
      console.error("Error fetching data:", error);
      setMsgData([
        ...msgData,
        {
          text: query,
          isUser: true,
        },
        { text: error.message, isUser: false },
      ]);

      setLoading(false);
      setQuery("");
    }
  };

  return (
    <div className="main-container">
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto", float: "right" }}>
        <VideoTour
          src="https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Legal%20Doc%20Summarizer.mp4"
          type="video/mp4"
          pdfSrc="https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/legaldoc1.pdf"
        />
      </div>
      <div className="doc-container">
        <p style={{ fontSize: "16px", marginTop: "20px" }}>
          <b>Legal Doc Summarizer using RaptorRAG</b>
        </p>
        <FileUpload
          handleRecieveFiles={handleRecieveFiles}
          height={summaryResponse ? "20vh" : "50vh"}
        />
        {
          <Button
            style={{ width: "100%", marginTop: "20px" }}
            type="primary"
            onClick={handleGetSummary}
            loading={summaryLoading}
          >
            Get Summary
          </Button>
        }
        {summaryResponse && (
          <Card style={{ marginTop: "20px" }}>
            <Markdown>{summaryResponse}</Markdown>
            <Markdown>{keyPoints}</Markdown>
          </Card>
        )}
        {summaryResponse && (
          <div className="user-manual-chatbot" style={{ marginTop: "20px" }}>
            <div
              ref={messageContainerRef}
              style={{
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                maxHeight: "500px",
                overflowY: "auto",
              }}
            >
              {msgData.length > 0 &&
                msgData.map((message, index) => (
                  <Chatbot
                    key={index}
                    text={message.text}
                    isUser={message.isUser}
                  />
                ))}
            </div>

            <div className="text-area" style={{ marginTop: "20px" }}>
              <form
                onSubmit={handleSubmit}
                className="input-container-user-manual"
                style={{ width: "100%" }}
              >
                <input
                  disabled={loading}
                  type="text"
                  value={inputMessage}
                  className="input-user-manual"
                  onChange={handleInputChange}
                  placeholder="Type a new question..."
                />

                {loading ? (
                  <></>
                ) : (
                  <>
                    <Button
                      style={{
                        border: "none",
                        background: "none",
                        boxShadow: "none",
                        alignSelf: "center",
                        color: "black",
                      }}
                      type="primary"
                      icon={<SendOutlined />}
                      size="small"
                      onClick={handleSubmit}
                    />
                  </>
                )}
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default LegalDocSummarization;
