import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DotLoading } from "antd-mobile";
import { useLocation } from "react-router-dom";
import { IP, domain_url } from "../../../../dynamicIPs";

import {
  mainPageData,
  mysqlData,
  snowflakeData,
  postgresqlData,
  csvData,
  pdfData,
  jsonData,
} from "./Data";
import {
  Badge,
  Button,
  Card,
  Cascader,
  Col,
  Dropdown,
  Input,
  message,
  Modal,
  Row,
  Space,
  Tooltip,
  Typography,
} from "antd";
import logo from "../../../../assests/img/chatbot.png";
// import ip from "../../../../assests/img/ip-icon1.png";
// import avatar from "../../../../assests/img/avatar1.jpg";
import {
  DeleteOutlined,
  DownOutlined,
  EyeFilled,
  MessageFilled,
  PlusOutlined,
  SendOutlined,
} from "@ant-design/icons";
import BreadCrumbs from "../../../BreadCrumbs";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";
const OpenAIChatBot = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(true);
  const [jsonFile, setJsonFile] = useState(null);
  const [csvFile, setCsvFile] = useState([]);
  const [pdfFile, setPdfFile] = useState([]);
  const [isMysqlModal, setIsMysqlModal] = useState(false);
  const [query, setQuery] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectDatabase, setSelectDatabase] = useState([]);
  const [userMessage, setUserMessage] = useState(null);
  const [apiMessage, setApiMessage] = useState(null);
  const messageContainerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [addIp, setAddIp] = useState("");
  const [jsonModal, setJsonModal] = useState(false);
  const [csvModal, setCsvModal] = useState(false);
  const [pdfModal, setPdfModal] = useState(false);
  const [postgreSqlModal, setPostgreSqlModal] = useState(false);
  const [snowflakeModal, setSnowflakeModal] = useState(false);

  // const [isIpAvailavle, setIsIpAvailable] = useState(false);
  const [isViewCsvFile, setIsViewCsvFile] = useState(false);
  const [isViewPdfFile, setIsViewPdfFile] = useState(false);
  const [textForBox, settextForBox] = useState(mainPageData);
  const [msgData, setMsgData] = useState([
    { text: "Hi,How may I help you?", isUser: false },
  ]);
  const [fraudDetectionCredential, setFraudDetectionCredential] = useState({
    host: "",
    user_name: "",
    password: "",
  });
  const [movieRecommendCredential, setMovieRecommendCredential] = useState({
    host: "",
    user_name: "",
    password: "",
  });
  const [salesDataCredential, setSalesDataCredential] = useState({
    host: "",
    user_name: "",
    password: "",
  });
  const [cyberSecurityCredential, setCyberSecurityCredential] = useState({
    host: "",
    user_name: "",
    password: "",
  });
  const [movieRecommenderModal, setMovieRecommender] = useState(false);
  const [fraudDetectionModal, setFraudDetectionModal] = useState(false);
  const [salesDataModal, setSalesDataModal] = useState(false);
  const [cyberSecurityModal, setCyberSecurityModal] = useState(false);

  const [mysqlCredential, setMysqlCredential] = useState({
    db_user_name: "",
    db_password: "",
    db_host: "",
    db_name: "",
  });
  const [postgreSqlCredential, setPostgreSqlCredential] = useState({
    db_user_name: "",
    db_password: "",
    db_host: "",
    db_name: "",
    port: "",
  });
  const [snowflakeCredential, setSnowflakeCredential] = useState({
    db_user_name: "",
    db_password: "",
    db_account_name: "",
    db_name: "",
    db_schema: "",
    db_warehouse: "",
  });

  const selectOptions = [
    { value: "MYSQL", label: "MYSQL" },
    { value: "PostgreSQL", label: "PostgreSQL" },
    // { value: "Snowflake", label: "Snowflake" },
    { value: "JSON", label: "JSON" },
    { value: "CSV", label: "CSV" },
    { value: "PDF", label: "PDF" },
    // {
    //   value: "Neo4J",
    //   label: "Neo4J",
    //   children: [
    //     { value: "Fraud Detection", label: "Fraud Detection" },
    //     // { value: "Sales Data", label: "Sales Data" },
    //     // { value: "Cyber Security", label: "Cyber Security" },
    //     { value: "Movie Recommender", label: "Recommendation" },
    //   ],
    // },
  ];

  const handleViewCsvFile = () => {
    setIsViewCsvFile(true);
  };
  const handleViewPdfFile = () => {
    setIsViewPdfFile(true);
  };
  const handleBackPdf = () => {
    setIsViewPdfFile(false);
  };
  const handleBackCsv = () => {
    setIsViewCsvFile(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCsvFileUpload = (file) => {
    const selectedFile = file.target.files[0];
    if (file.target.files[0].type === "text/csv") {
      setCsvFile([...csvFile, selectedFile]);
      message.success("file uploaded successfully");
    } else {
      message.error("please upload csv file");
    }
  };
  const handleJsonFileUpload = (file) => {
    const selectedFile = file.target.files[0];
    if (file.target.files[0].type === "application/json") {
      setJsonFile(selectedFile);
      message.success("file uploaded successfully");
    } else {
      message.error("please upload json file");
    }
  };
  const handlePdfFileUpload = (file) => {
    const selectedFile = file.target.files[0];
    if (file.target.files[0].type === "application/pdf") {
      setPdfFile([...pdfFile, selectedFile]);
      message.success("file uploaded successfully");
    } else {
      message.error("please upload pdf file");
    }
  };
  // const handleIp = (event) => {
  //   localStorage.setItem("ip", event.target.value);
  //   const ip = localStorage.getItem("ip");
  //   setAddIp(ip);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };
  const handleOkMysql = () => {
    setIsMysqlModal(false);
  };
  const handleOkPostgre = () => {
    setPostgreSqlModal(false);
  };
  const handleOkSnowfrake = () => {
    setSnowflakeModal(false);
  };
  const handleOkJson = () => {
    setJsonModal(false);
  };
  const handleOkPdf = () => {
    setPdfModal(false);
  };
  const handleOkCsv = () => {
    setCsvModal(false);
  };
  const handleOkFraud = () => {
    setFraudDetectionModal(false);
  };
  const handleOkMovieRecommend = () => {
    setMovieRecommender(false);
  };
  const handleOkSalesData = () => {
    setSalesDataModal(false);
  };
  const handleOkCyberSecurity = () => {
    setCyberSecurityModal(false);
  };
  const handleMysqlCancel = () => {
    setIsMysqlModal(false);
  };
  const handlePostgreCancel = () => {
    setPostgreSqlModal(false);
  };
  const handleSnowfrakeCancel = () => {
    setSnowflakeModal(false);
  };
  const handleJsonCancel = () => {
    setJsonModal(false);
  };
  const handlePdfCancel = () => {
    setPdfModal(false);
  };
  const handleCsvCancel = () => {
    setCsvModal(false);
  };
  const handleMovieRecommendCancel = () => {
    setMovieRecommender(false);
  };
  const handleSalesDataCancel = () => {
    setSalesDataModal(false);
  };
  const handleCyberSecurityCancel = () => {
    setCyberSecurityModal(false);
  };
  const handleFraudDetectionCancel = () => {
    setFraudDetectionModal(false);
  };
  const removeUploadPdfFile = (filename) => {
    const filterList = pdfFile.filter((file) => file.name !== filename);
    setPdfFile(filterList);
    if (pdfFile.length === 1) {
      setIsViewPdfFile(false);
    }
  };
  const removeUploadCsvFile = (filename) => {
    const filterList = csvFile.filter((file) => file.name !== filename);
    setCsvFile(filterList);
    if (csvFile.length === 1) {
      setIsViewCsvFile(false);
    }
  };
  const removeUploadJsonFile = () => {
    setJsonFile(null);
  };
  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  // useEffect(() => {
  //   if (localStorage.getItem("ip")) {
  //     const ip = localStorage.getItem("ip");

  //     setAddIp(ip);
  //     setIsIpAvailable(true);
  //   } else {
  //     setIsIpAvailable(false);
  //   }
  // }, []);

  // let data = localStorage.getItem("data");
  // let msgData = data
  //   ? JSON.parse(data)
  //   : [{ text: "Hi,How may I help you?", isUser: false }];
  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [userMessage, apiMessage]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setInputMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!query) return;
    setUserMessage(query);
    setInputMessage("");

    setLoading(true);

    try {
      let url;
      if (selectDatabase[0] === "MYSQL") {
        url = `${domain_url}/dbagent/langchain`;
      } else if (selectDatabase[0] === "JSON") {
        url = `${domain_url}/dbagent/json/langchain`;
      } else if (selectDatabase[0] === "CSV") {
        url = `${domain_url}/dbagent/csv/langchain`;
      } else if (selectDatabase[0] === "PDF") {
        url = `${domain_url}/dbagent/pdf/langchain`;
      } else if (selectDatabase[0] === "PostgreSQL") {
        url = `${domain_url}/dbagent/postgresql/langchain`;
      } else if (selectDatabase[0] === "Snowflake") {
        url = `${domain_url}/dbagent/snowflake/langchain`;
      } else if (
        selectDatabase[1] === "Cyber Security" ||
        selectDatabase[1] === "Cyber Security" ||
        selectDatabase[1] === "Sales Data" ||
        selectDatabase[1] === "Fraud Detection" ||
        selectDatabase[1] === "Movie Recommender"
      ) {
        url = `${domain_url}/dbagent/neo4j-database`;
      }

      const params =
        selectDatabase[0] === "MYSQL"
          ? {
              query_string: query,
              db_user_name: mysqlCredential.db_user_name,
              db_password: mysqlCredential.db_password,
              db_host: mysqlCredential.db_host,
              db_name: mysqlCredential.db_name,
            }
          : selectDatabase[0] === "PostgreSQL"
          ? {
              query_string: query,

              db_user_name: postgreSqlCredential.db_user_name,
              db_password: postgreSqlCredential.db_password,
              db_host: postgreSqlCredential.db_host,
              db_port: postgreSqlCredential.port,
              db_name: postgreSqlCredential.db_name,
            }
          : selectDatabase[0] === "Snowflake"
          ? {
              query_string: query,
              db_user_name: snowflakeCredential.db_user_name,
              db_password: snowflakeCredential.db_password,
              db_account_name: snowflakeCredential.db_account_name,
              db_name: snowflakeCredential.db_name,
              db_schema: snowflakeCredential.db_schema,
              db_warehouse: snowflakeCredential.db_warehouse,
            }
          : selectDatabase[1] === "Movie Recommender"
          ? {
              database_name: selectDatabase[1],
              host: movieRecommendCredential.host,
              user_name: movieRecommendCredential.user_name,
              password: movieRecommendCredential.password,
              user_input: query,
            }
          : selectDatabase[1] === "Fraud Detection"
          ? {
              database_name: selectDatabase[1],
              host: fraudDetectionCredential.host,
              user_name: fraudDetectionCredential.user_name,
              password: fraudDetectionCredential.password,
              user_input: query,
            }
          : selectDatabase[1] === "Sales Data"
          ? {
              database_name: selectDatabase[0],
              host: salesDataCredential.host,
              user_name: salesDataCredential.user_name,
              password: salesDataCredential.password,
              user_input: query,
            }
          : selectDatabase[1] === "Cyber Security"
          ? {
              database_name: selectDatabase[0],
              host: cyberSecurityCredential.host,
              user_name: cyberSecurityCredential.user_name,
              password: cyberSecurityCredential.password,
              user_input: query,
            }
          : {
              query_string: query,
            };

      if (
        selectDatabase[0] === "MYSQL" ||
        selectDatabase[0] === "Snowflake" ||
        selectDatabase[0] === "PostgreSQL" ||
        selectDatabase[1] === "Movie Recommender" ||
        selectDatabase[1] === "Fraud Detection" ||
        selectDatabase[1] === "Sales Data" ||
        selectDatabase[1] === "Cyber Security"
      ) {
        const response = await axios.get(url, { params });
        setApiMessage(response.data);
        setMsgData([
          ...msgData,
          { text: query, isUser: true },
          { text: response.data, isUser: false },
        ]);

        // msgData.push(
        //   { text: query, isUser: true, icon: "icon" },
        //   { text: response.data, isUser: false, icon: "icon" }
        // );

        // localStorage.setItem("data", JSON.stringify(msgData));
        setQuery("");
        setLoading(false);
      } else {
        const formData = new FormData();
        if (selectDatabase[0] === "JSON") {
          formData.append("file", jsonFile);
          formData.append("query_string", query);
        } else if (selectDatabase[0] === "CSV") {
          for (let i = 0; i < csvFile.length; i++) {
            formData.append("file", csvFile[i]);
          }

          formData.append("query_string", query);
        } else {
          for (let i = 0; i < pdfFile.length; i++) {
            formData.append("file", pdfFile[i]);
          }
          formData.append("query_string", query);
        }
        // const bodyData = {
        //   query_string: query,
        // };
        // const requestData = Object.assign({}, bodyData, formData);

        const response = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setApiMessage(response.data);
        setMsgData([
          ...msgData,
          { text: query, isUser: true },
          { text: response.data, isUser: false },
        ]);

        // msgData.push(
        //   { text: query, isUser: true, icon: "icon" },
        //   { text: response.data, isUser: false, icon: "icon" }
        // );

        // localStorage.setItem("data", JSON.stringify(msgData));
        setQuery("");
        setLoading(false);
      }
    } catch (error) {
      setUserMessage(query);
      setApiMessage(error.message);
      console.error("Error fetching data:", error);
      setMsgData([
        ...msgData,
        { text: query, isUser: true },
        { text: error.message, isUser: false },
      ]);
      // msgData.push(
      //   { text: query, isUser: true, url: avatar },
      //   { text: error.message, isUser: false, url: avatar }
      // );
      // localStorage.setItem("data", JSON.stringify(msgData));
      // if (
      //   error instanceof DOMException &&
      //   error.code === DOMException.QUOTA_EXCEEDED_ERR
      // ) {
      //   localStorage.removeItem("data");
      // }
      setLoading(false);
      setQuery("");
    }
  };
  const handleMysqlCredential = (e) => {
    const { name, value } = e.target;

    setMysqlCredential({
      ...mysqlCredential,
      [name]: value,
    });
  };
  const handleFraudDetectCredentail = (e) => {
    const { name, value } = e.target;
    setFraudDetectionCredential({
      ...fraudDetectionCredential,
      [name]: value,
    });
  };
  const handleMovieRecommentCredential = (e) => {
    const { name, value } = e.target;
    setMovieRecommendCredential({
      ...movieRecommendCredential,
      [name]: value,
    });
  };
  const handleSalesDataCredential = (e) => {
    const { name, value } = e.target;
    setSalesDataCredential({
      ...salesDataCredential,
      [name]: value,
    });
  };
  const handleCyberSecurityCredentail = (e) => {
    const { name, value } = e.target;
    setCyberSecurityCredential({
      ...cyberSecurityCredential,
      [name]: value,
    });
  };

  const handlePostgreSqlCredential = (e) => {
    const { name, value } = e.target;

    setPostgreSqlCredential({
      ...postgreSqlCredential,
      [name]: value,
    });
  };
  const handleSnowflakeCredential = (e) => {
    const { name, value } = e.target;

    setSnowflakeCredential({
      ...snowflakeCredential,
      [name]: value,
    });
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const handleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour
          src={videoUrls.DB_agents_recording}
          type="video/mp4"
          pdfSrc={pdfUrls.Db_Agents}
        />
      </div>
      <Row gutter={[8, 8]}>
        <Col
          xs={24}
          sm={24}
          md={isOpen ? 13 : 24}
          lg={isOpen ? 13 : 24}
          xl={isOpen ? 13 : 24}
        >
          {/* <div className="additional-text  data-card">
            <h4> Welcome to DataQuest! </h4>
            <Card
              style={{
                // marginTop: "100px",
                boxShadow: "0 0 0 2px #e3f6f",

                marginTop: "16px",
                marginLeft: "16px",
                marginRight: "16px",
              }}
            >
              {textForBox.map((data, index) => (
                <p
                  key={index}
                  style={{
                    fontFamily: "Merriweather Sans",
                    textAlign: "justify",
                    lineHeight: 1.4,
                  }}
                >
                  {(textForBox === csvData && index === 0) ||
                    (textForBox === pdfData && index === 0) ||
                    (textForBox === jsonData && index === 0) ||
                    (textForBox === mysqlData && index === 0) ||
                    (textForBox === snowflakeData && index === 0) ||
                    (textForBox === postgresqlData && index === 0) ? (
                    <strong>
                      <p>{data}</p>
                    </strong>
                  ) : (
                    <p>{data}</p>
                  )}
                </p>
              ))}
            </Card>
          </div> */}
        </Col>
        <Col
          xs={24}
          sm={24}
          md={isOpen ? 10 : 2}
          lg={isOpen ? 10 : 2}
          xl={isOpen ? 10 : 2}
        >
          {isOpen ? (
            <div className="chat-bot-modal">
              <div className="chat-header">
                <span>
                  <img className="chatbot-logo" src={logo} alt="Logo" />
                  {/* ChatBot */}
                </span>
                <Row gutter={[16, 8]}>
                  <Col span={19}>
                    <Cascader
                      placeholder=" Select Database"
                      value={selectDatabase}
                      className="ant-select-selector"
                      options={selectOptions}
                      allowClear={false}
                      onChange={(select) => {
                        if (select[0] === "MYSQL") {
                          setMsgData([]);
                          setIsMysqlModal(true);
                          settextForBox(mysqlData);
                        } else if (select[0] === "CSV") {
                          setMsgData([]);
                          setCsvModal(true);
                          settextForBox(csvData);
                        } else if (select[0] === "JSON") {
                          setMsgData([]);
                          setJsonModal(true);
                          settextForBox(jsonData);
                        } else if (select[0] === "PDF") {
                          setMsgData([]);
                          setPdfModal(true);
                          settextForBox(pdfData);
                        } else if (select[0] === "PostgreSQL") {
                          setMsgData([]);
                          setPostgreSqlModal(true);
                          settextForBox(postgresqlData);
                        } else if (select[0] === "Snowflake") {
                          setMsgData([]);
                          setSnowflakeModal(true);
                          settextForBox(snowflakeData);
                        } else if (select[1] === "Fraud Detection") {
                          setMsgData([]);
                          setFraudDetectionModal(true);
                        } else if (select[1] === "Movie Recommender") {
                          setMsgData([]);
                          setMovieRecommender(true);
                        } else if (select[1] === "Sales Data") {
                          setMsgData([]);
                          setSalesDataModal(true);
                        } else if (select[1] === "Cyber Security") {
                          setMsgData([]);
                          setCyberSecurityModal(true);
                        }

                        select.length > 1
                          ? setSelectDatabase(select)
                          : setSelectDatabase(select);
                        select.length > 1
                          ? localStorage.setItem("dataStorageType", select[1])
                          : localStorage.setItem("dataStorageType", select[0]);
                      }}
                    />

                    <Modal
                      title="Add MySQL Credentials"
                      width="400px"
                      open={isMysqlModal}
                      // onOk={handleOk}
                      onCancel={handleMysqlCancel}
                      footer={[
                        <Button key="back" onClick={handleMysqlCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={handleOkMysql}
                        >
                          Add Credential
                        </Button>,
                      ]}
                    >
                      <Row gutter={[8, 8]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Host"
                            name="db_host"
                            value={mysqlCredential.db_host}
                            onChange={handleMysqlCredential}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Username"
                            name="db_user_name"
                            value={mysqlCredential.db_user_name}
                            onChange={handleMysqlCredential}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Password"
                            type="password"
                            name="db_password"
                            value={mysqlCredential.db_password}
                            onChange={handleMysqlCredential}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Database Name"
                            name="db_name"
                            value={mysqlCredential.db_name}
                            onChange={handleMysqlCredential}
                          />
                        </Col>
                      </Row>
                    </Modal>
                    <Modal
                      title="Add PostgreSql Credentials"
                      width="400px"
                      open={postgreSqlModal}
                      // onOk={handleOk}
                      onCancel={handlePostgreCancel}
                      footer={[
                        <Button key="back" onClick={handlePostgreCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={handleOkPostgre}
                        >
                          Add Credential
                        </Button>,
                      ]}
                    >
                      <Row gutter={[8, 8]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Host"
                            name="db_host"
                            value={postgreSqlCredential.db_host}
                            onChange={handlePostgreSqlCredential}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter User Name"
                            name="db_user_name"
                            value={postgreSqlCredential.db_user_name}
                            onChange={handlePostgreSqlCredential}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Password"
                            type="password"
                            name="db_password"
                            value={postgreSqlCredential.db_password}
                            onChange={handlePostgreSqlCredential}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Database Name"
                            name="db_name"
                            value={postgreSqlCredential.db_name}
                            onChange={handlePostgreSqlCredential}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Input
                            placeholder="Enter Port"
                            name="port"
                            value={postgreSqlCredential.port}
                            onChange={handlePostgreSqlCredential}
                          />
                        </Col>
                      </Row>
                    </Modal>
                    <Modal
                      title="Add Snowflake Credentials"
                      width="400px"
                      open={snowflakeModal}
                      // onOk={handleOk}
                      onCancel={handleSnowfrakeCancel}
                      footer={[
                        <Button key="back" onClick={handleSnowfrakeCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={handleOkSnowfrake}
                        >
                          Add Credential
                        </Button>,
                      ]}
                    >
                      <Row gutter={[8, 8]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Account name"
                            name="db_account_name"
                            value={snowflakeCredential.db_account_name}
                            onChange={handleSnowflakeCredential}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter User Name"
                            name="db_user_name"
                            value={snowflakeCredential.db_user_name}
                            onChange={handleSnowflakeCredential}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Password"
                            type="password"
                            name="db_password"
                            value={snowflakeCredential.db_password}
                            onChange={handleSnowflakeCredential}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Database Name"
                            name="db_name"
                            value={snowflakeCredential.db_name}
                            onChange={handleSnowflakeCredential}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Schema Name"
                            name="db_schema"
                            value={snowflakeCredential.db_schema}
                            onChange={handleSnowflakeCredential}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Warehouse Name"
                            name="db_warehouse"
                            value={snowflakeCredential.db_warehouse}
                            onChange={handleSnowflakeCredential}
                          />
                        </Col>
                      </Row>
                    </Modal>
                    <Modal
                      title="Add Json File"
                      width="500px"
                      open={jsonModal}
                      onCancel={handleJsonCancel}
                      footer={[
                        <Button key="back" onClick={handleJsonCancel}>
                          Cancel
                        </Button>,

                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleOkJson}
                        >
                          Add File
                        </Button>,
                      ]}
                    >
                      {jsonFile ? (
                        <div>
                          <Row gutter={[8, 8]} justify="center" align="middle">
                            <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                              <label>
                                <h4>{jsonFile.name}</h4>
                              </label>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                              <Button
                                onClick={() =>
                                  removeUploadJsonFile(jsonFile.name)
                                }
                                danger
                              >
                                <DeleteOutlined />
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div className="text-center">
                          <label
                            for="jsonInputFile"
                            style={{
                              fontSize: "25px",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <div className="drag-area ">
                              <h6>Upload</h6> <PlusOutlined />
                              <input
                                className="form-control"
                                type="file"
                                id="jsonInputFile"
                                // name="inputFile"
                                // value={inputFile}
                                onChange={handleJsonFileUpload}
                                hidden
                                accept="image/png, image/jpeg,image/jpg,application/pdf,video/mp4,application/json"
                              />
                            </div>
                          </label>
                        </div>
                      )}
                    </Modal>
                    <Modal
                      title={isViewCsvFile ? "View Csv File" : "Add Csv File"}
                      width="500px"
                      open={csvModal}
                      onCancel={handleCsvCancel}
                      footer={[
                        <Button key="back" onClick={handleCsvCancel}>
                          Cancel
                        </Button>,
                        isViewCsvFile ? (
                          <Button key="back" onClick={handleBackCsv}>
                            Back
                          </Button>
                        ) : null,
                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleOkCsv}
                        >
                          Add File
                        </Button>,
                      ]}
                    >
                      {isViewCsvFile ? (
                        <div>
                          {csvFile.map((file, index) => (
                            <Row
                              gutter={[8, 8]}
                              justify="center"
                              align="middle"
                            >
                              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <label>
                                  <h4 key={index}>{file.name}</h4>
                                </label>
                              </Col>
                              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <Button
                                  onClick={() => removeUploadCsvFile(file.name)}
                                  danger
                                >
                                  <DeleteOutlined />
                                </Button>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      ) : (
                        <div className="text-center">
                          <label
                            for="csvInputFile"
                            style={{
                              fontSize: "25px",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <div className="drag-area ">
                              <h6>Upload</h6> <PlusOutlined />
                              <input
                                className="form-control"
                                type="file"
                                id="csvInputFile"
                                // name="inputFile"
                                // value={inputFile}
                                multiple
                                onChange={handleCsvFileUpload}
                                hidden
                                accept="image/png, image/jpeg,image/jpg,application/pdf,video/mp4,application/csv"
                              />
                            </div>
                            {csvFile.length > 0 ? (
                              <Tooltip
                                title="View Uploaded File"
                                placement="bottom"
                              >
                                <Badge count={csvFile.length} size="small">
                                  <EyeFilled
                                    onClick={handleViewCsvFile}
                                    style={{ fontSize: "25px" }}
                                  />
                                </Badge>
                              </Tooltip>
                            ) : null}
                          </label>
                        </div>
                      )}
                    </Modal>
                    <Modal
                      title={isViewPdfFile ? "View Pdf File" : "Add Pdf File"}
                      width="500px"
                      open={pdfModal}
                      onCancel={handlePdfCancel}
                      footer={[
                        <Button key="back" onClick={handlePdfCancel}>
                          Cancel
                        </Button>,
                        isViewPdfFile ? (
                          <Button key="back" onClick={handleBackPdf}>
                            Back
                          </Button>
                        ) : null,
                        <Button
                          key="submit"
                          type="primary"
                          onClick={handleOkPdf}
                        >
                          Add File
                        </Button>,
                      ]}
                    >
                      {isViewPdfFile ? (
                        <div>
                          {pdfFile.map((file, index) => (
                            <Row
                              gutter={[8, 8]}
                              justify="center"
                              align="middle"
                            >
                              <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                                <label>
                                  <h4 key={index}>{file.name}</h4>
                                </label>
                              </Col>
                              <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                <Button
                                  onClick={() => removeUploadPdfFile(file.name)}
                                  danger
                                >
                                  <DeleteOutlined />
                                </Button>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      ) : (
                        <div className="text-center">
                          <label
                            for="pdfInputFile"
                            style={{
                              fontSize: "25px",
                              textAlign: "-webkit-center",
                            }}
                          >
                            <div className="drag-area ">
                              <h6>Upload</h6> <PlusOutlined />
                              <input
                                className="form-control"
                                type="file"
                                id="pdfInputFile"
                                multiple
                                onChange={handlePdfFileUpload}
                                hidden
                                accept="image/png, image/jpeg,image/jpg,application/pdf,video/mp4,application/pdf"
                              />
                            </div>

                            {pdfFile.length > 0 ? (
                              <Tooltip
                                title="View Uploaded File"
                                placement="bottom"
                              >
                                <Badge count={pdfFile.length} size="small">
                                  <EyeFilled
                                    onClick={handleViewPdfFile}
                                    style={{ fontSize: "25px" }}
                                  />
                                </Badge>
                              </Tooltip>
                            ) : null}
                          </label>
                        </div>
                      )}
                    </Modal>
                    <Modal
                      title="Add Fraud Detection Credentials"
                      width="400px"
                      open={fraudDetectionModal}
                      onCancel={handleFraudDetectionCancel}
                      footer={[
                        <Button key="back" onClick={handleFraudDetectionCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={handleOkFraud}
                        >
                          Add Credential
                        </Button>,
                      ]}
                    >
                      <Row gutter={[8, 8]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Host"
                            name="host"
                            value={fraudDetectionCredential.host}
                            onChange={handleFraudDetectCredentail}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Username"
                            name="user_name"
                            value={fraudDetectionCredential.user_name}
                            onChange={handleFraudDetectCredentail}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Input
                            placeholder="Enter Password"
                            type="password"
                            name="password"
                            value={fraudDetectionCredential.password}
                            onChange={handleFraudDetectCredentail}
                          />
                        </Col>
                      </Row>
                    </Modal>
                    <Modal
                      title="Add Sales Data Credentials"
                      width="400px"
                      open={salesDataModal}
                      onCancel={handleSalesDataCancel}
                      footer={[
                        <Button key="back" onClick={handleSalesDataCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={handleOkSalesData}
                        >
                          Add Credential
                        </Button>,
                      ]}
                    >
                      <Row gutter={[8, 8]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Host"
                            name="host"
                            value={salesDataCredential.host}
                            onChange={handleSalesDataCredential}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Username"
                            name="user_name"
                            value={salesDataCredential.user_name}
                            onChange={handleSalesDataCredential}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Input
                            placeholder="Enter Password"
                            type="password"
                            name="password"
                            value={salesDataCredential.password}
                            onChange={handleSalesDataCredential}
                          />
                        </Col>
                      </Row>
                    </Modal>
                    <Modal
                      title="Add Cyber Security Credentials"
                      width="400px"
                      open={cyberSecurityModal}
                      onCancel={handleCyberSecurityCancel}
                      footer={[
                        <Button key="back" onClick={handleCyberSecurityCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={handleOkCyberSecurity}
                        >
                          Add Credential
                        </Button>,
                      ]}
                    >
                      <Row gutter={[8, 8]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Host"
                            name="host"
                            value={cyberSecurityCredential.host}
                            onChange={handleCyberSecurityCredentail}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Username"
                            name="user_name"
                            value={cyberSecurityCredential.user_name}
                            onChange={handleCyberSecurityCredentail}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Input
                            placeholder="Enter Password"
                            type="password"
                            name="password"
                            value={cyberSecurityCredential.password}
                            onChange={handleCyberSecurityCredentail}
                          />
                        </Col>
                      </Row>
                    </Modal>
                    <Modal
                      title="Add Movie Recommend Credentials"
                      width="400px"
                      open={movieRecommenderModal}
                      onCancel={handleMovieRecommendCancel}
                      footer={[
                        <Button key="back" onClick={handleMovieRecommendCancel}>
                          Cancel
                        </Button>,
                        <Button
                          key="submit"
                          type="primary"
                          loading={loading}
                          onClick={handleOkMovieRecommend}
                        >
                          Add Credential
                        </Button>,
                      ]}
                    >
                      <Row gutter={[8, 8]}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Host"
                            name="host"
                            value={movieRecommendCredential.host}
                            onChange={handleMovieRecommentCredential}
                          />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                          <Input
                            placeholder="Enter Username"
                            name="user_name"
                            value={movieRecommendCredential.user_name}
                            onChange={handleMovieRecommentCredential}
                          />
                        </Col>
                      </Row>
                      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                          <Input
                            placeholder="Enter Password"
                            type="password"
                            name="password"
                            value={movieRecommendCredential.password}
                            onChange={handleMovieRecommentCredential}
                          />
                        </Col>
                      </Row>
                    </Modal>
                  </Col>
                  {/* <Col span={5}>
                    <div>
                      <Tooltip title="Add IP">
                        <span>
                          <Button
                            style={{
                              cursor: "pointer",
                              outline: "none",
                              border: "none",
                              background: "none",
                            }}
                            onClick={showModal}
                          >
                            <img
                              src={ip}
                              alt="ip-images"
                              width="25px"
                              height="25px"
                            />
                          </Button>
                        </span>
                      </Tooltip>
                      <Modal
                        title="Add IP Modal"
                        width="400px"
                        open={isModalOpen}
                        // onOk={handleOk}
                        onCancel={handleCancel}
                        footer={[
                          <Button key="back" onClick={handleCancel}>
                            Cancel
                          </Button>,
                          <Button
                            key="submit"
                            type="primary"
                            loading={loading}
                            onClick={handleOk}
                          >
                            Add IP
                          </Button>,
                        ]}
                      >
                        <Input
                          placeholder="Please Enter Ip Address"
                          value={addIp}
                          onChange={handleIp}
                        />
                      </Modal>
                    </div>
                  </Col> */}
                  <Col span={5}>
                    <button
                      style={{
                        margin: "2px 0 0 0",
                        background: "none",
                      }}
                      className="close-button"
                      onClick={handleClose}
                    >
                      &#x2716;
                    </button>
                  </Col>
                </Row>
              </div>

              <div className="messages" ref={messageContainerRef}>
                {msgData?.map((message, index) => (
                  <div
                    key={index}
                    className={`message ${message.isUser ? "user" : "api"}`}
                  >
                    {message.text}
                  </div>
                ))}
                {loading ? (
                  <>
                    <div className="user message">{query}</div>
                    <div className="message api">
                      <DotLoading />
                    </div>
                  </>
                ) : null}
              </div>
              <hr style={{ margin: "0px" }} />

              <form onSubmit={handleSubmit} className="input-container">
                <input
                  type="text"
                  value={inputMessage}
                  onChange={handleInputChange}
                  placeholder="Type your query..."
                />
                {loading ? (
                  <></>
                ) : (
                  <button
                    type="submit"
                    style={{
                      border: "none",
                      background: "none",
                      fontSize: "20px",
                      margin: "-4px 0 0 0",

                      color: "black",
                    }}
                  >
                    <SendOutlined />
                  </button>
                )}
              </form>
            </div>
          ) : (
            <Typography.Text onClick={handleOpen} className="chatbot-icon">
              <MessageFilled
                style={{ fontSize: "30px", color: "white", padding: "7px" }}
              />
            </Typography.Text>
          )}
        </Col>
      </Row>
    </>
  );
};

export default OpenAIChatBot;
