import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DotLoading } from "antd-mobile";
import { Button, Input, Modal, Row, Tooltip, message } from "antd";
import logo from "../../../../assests/img/Salesgptlogo.png";
import ip from "../../../../assests/img/ip-icon1.png";
import { SendOutlined } from "@ant-design/icons";
import { api_url, IP } from "../../../../dynamicIPs";

const EnglishToGermanTranslator = () => {
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const messageContainerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textForTranslate, setTextForTranslate] = useState("");
  const [translateButtonDisable, setTranslateButtonDisable] = useState(false);
  const [messages, setMessages] = useState([
    {
      text: "Hello ! I am a translation bot and I am here to assist you in translating your messages . Please enter the English text you would like me to translate in German!",
      isUser: false,
    },
  ]);
  const [response, setResponse] = useState("");

  // const [addIp, setAddIp] = useState("");

  // const [isIpAvailavle, setIsIpAvailable] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleIp = (event) => {
  //   localStorage.setItem("ip", event.target.value);
  //   const ip = localStorage.getItem("ip");
  //   setAddIp(ip);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  // useEffect(() => {
  //   if (localStorage.getItem("ip")) {
  //     const ip = localStorage.getItem("ip");

  //     setAddIp(ip);
  //     setIsIpAvailable(true);
  //   } else {
  //     setIsIpAvailable(false);
  //   }
  // }, [addIp]);

  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [inputMessage, messages]);

  const handleInputChange = (event) => {
    setTextForTranslate(event.target.value);
    setInputMessage(event.target.value);
  };

  const handleTranslate = async (event) => {
    event.preventDefault();
    if (textForTranslate.trim().length < 1) {
      message.error("Please enter valid text to translate");
      return;
    }
    setTranslateButtonDisable(true);
    setInputMessage("");
    setLoading(true);
    const params = {
      data: textForTranslate,
    };
    const url = `${api_url}/translation/get-length`;
    try {
      const res = await axios.get(url, { params });
      if (res) {
        console.log("resssssssss", res);
      }

      const socket = new WebSocket(`wss://gitexevent.yashaccelerator.link/translation/ws`);
      socket.onopen = () => {
        console.log("WebSocket connection established.");

        socket.send(textForTranslate);
      };

      let count = 0;
      let str = "";

      socket.onmessage = (event) => {
        const newData = event.data;

        str += newData;
        // setLoading(false);

        count++;

        if (res.data === count) {
          setTranslateButtonDisable(false);
          setLoading(false);
          setResponse(str);
          setMessages([
            ...messages,
            { text: textForTranslate, isUser: true },
            { text: str, isUser: false },
          ]);
        }
      };
      socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    } catch (error) {
      setLoading(false);
      const msg=messages.slice(1)
      messages.push(
        ...msg,
        { text: textForTranslate, isUser: true },
        { text: error.message, isUser: false }
      );
    }
  };

  return (
    <div>
      <div className="sales-gpt-modal">
        <div className="chat-header">
          <span>
            <img className="chatbot-logo" src={logo} alt="Logo" />
          </span>
          <h6 style={{ textAlign: "center" }}>English To German Translator</h6>
          {/* <Row gutter={[16, 8]}>
            <Tooltip title="Add IP">
              <span>
                <Button
                  style={{
                    cursor: "pointer",
                    outline: "none",
                    border: "none",
                    background: "none",
                  }}
                  onClick={showModal}
                >
                  <img src={ip} alt="ip-images" width="25px" height="25px" />
                </Button>
              </span>
            </Tooltip>
            <Modal
              title="Add IP Modal"
              width="400px"
              open={isModalOpen}
              // onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleOk}
                >
                  Add IP
                </Button>,
              ]}
            >
              <Input
                placeholder="Please Enter Ip Address"
                value={addIp}
                onChange={handleIp}
              />
            </Modal>
          </Row> */}
        </div>

        <div className="sales-messages-box" ref={messageContainerRef}>
          {messages?.map((message, index) => (
            <div
              key={index}
              className={`message ${message.isUser ? "user" : "api"}`}
            >
              {message.text}
            </div>
          ))}
          {loading ? (
            <>
              <div className="user message">{textForTranslate}</div>
              <div className="message api">
                <DotLoading />
              </div>
            </>
          ) : null}
        </div>
        <hr style={{ margin: "0px" }} />

        <form onSubmit={handleTranslate} className="input-container">
          <input
            type="text"
            value={inputMessage}
            // disabled={loading || !isIpAvailavle}
            onChange={handleInputChange}
            placeholder="Enter a text..."
          />

          {translateButtonDisable ? (
            <></>
          ) : (
            <button
              type="submit"
              style={{
                border: "none",
                background: "none",
                fontSize: "20px",
                margin: "-4px 0 0 0",

                color: "black",
              }}
            >
              <SendOutlined />
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default EnglishToGermanTranslator;
