// melodyPayer
import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import { Piano, KeyboardShortcuts, MidiNumbers } from "react-piano";
import "react-piano/dist/styles.css";
import { Col, Row, Button, Input, message, Spin } from "antd";
import happybirthday from "../../../../../assests/Drumpaddata/happy-birthday-to-you-bossa-nova-style-arrangement-21399.mp3";
import playButton from "../../../../../assests/img/play.png";
// import DimensionsProvider from './DimensionsProvider';
import SoundfontProvider from "./SoundfontProvider";
import PianoWithRecording from "./PianoWithRecording";
import { TextArea } from "antd-mobile";
import MidiWriter from "midi-writer-js";
import { Midi } from "@tonejs/midi";
const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const soundfontHostname = "https://d1pzp51pvbm36p.cloudfront.net";

const noteRange = {
  first: MidiNumbers.fromNote("c3"),
  last: MidiNumbers.fromNote("f4"),
};
const keyboardShortcuts = KeyboardShortcuts.create({
  firstNote: noteRange.first,
  lastNote: noteRange.last,
  keyboardConfig: KeyboardShortcuts.HOME_ROW,
});

class PianoPayer extends React.Component {
  state = {
    recording: {
      mode: "RECORDING",
      events: [],
      currentTime: 0,
      currentEvents: [],
      isPlaying: false,
    },
    progress: 0,
    play: false,
    selectedMelody: "none",
    isQueryEntered: false,
    keyword: "",
    isMelodyLoading: false,
    isMelodyError: false,
    base64: "",
  };

  componentDidMount() {
    this.audio.addEventListener("ended", () => this.setState({ play: false }));
  }

  componentWillUnmount() {
    this.audio.removeEventListener("ended", () =>
      this.setState({ play: false })
    );
  }

  toggleHappyBirthdayPlay = () => {
    this.setState({ play: !this.state.play }, () => {
      if (this.state.play) {
        this.audio.play();
        this.setState({
          selectedMelody: "Birthday Melody",
        });
      } else {
        this.audio.pause();
      }
    });
    this.setState({
      isQueryEntered: false,
    });
  };

  constructor(props) {
    super(props);

    this.scheduledEvents = [];

    this.audio = new Audio(happybirthday);
  }

  getRecordingEndTime = () => {
    if (this.state.recording.events.length === 0) {
      return 0;
    }
    return Math.max(
      ...this.state.recording.events.map((event) => event.time + event.duration)
    );
  };

  setRecording = (value) => {
    this.setState({
      recording: Object.assign({}, this.state.recording, value),
      selectedMelody: "Piano Melody",
    });
  };

  getData(audioFile) {
    fetch(audioFile).then(function (res) {
      res.blob().then(function (blob) {
        var size = blob.size;
        var type = blob.type;

        var reader = new FileReader();
        reader.addEventListener("loadend", function () {
          // 1: play the base64 encoded data directly works
          // audioControl.src = reader.result;

          // 2: Serialize the data to localStorage and read it back then play...
          var base64FileData = reader.result.toString();

          var mediaFile = {
            fileUrl: audioFile,
            size: blob.size,
            type: blob.type,
            src: base64FileData,
          };
        });

        reader.readAsDataURL(blob);
      });
    });
  }

  onClickPlay = () => {
    this.setRecording({
      mode: "PLAYING",
    });
    // Calculate the total duration of the recording
    const totalDuration = this.getRecordingEndTime() * 1000;
    // Start playing and update progress
    this.playAndTrackProgress(totalDuration);

    const startAndEndTimes = _.uniq(
      _.flatMap(this.state.recording.events, (event) => [
        event.time,
        event.time + event.duration,
      ])
    );
    startAndEndTimes.forEach((time) => {
      this.scheduledEvents.push(
        setTimeout(() => {
          const currentEvents = this.state.recording.events.filter((event) => {
            return event.time <= time && event.time + event.duration > time;
          });
          this.setRecording({
            currentEvents,
          });
        }, time * 1000)
      );
    });

    // Stop at the end
    setTimeout(() => {
      this.onClickStop();
    }, this.getRecordingEndTime() * 1000);
  };

  playAndTrackProgress = (totalDuration) => {
    const startTime = Date.now();

    let lastUpdateTime = startTime;

    const updateProgress = () => {
      const currentTime = Date.now();

      const elapsed = currentTime - startTime;

      const newProgress = (elapsed / totalDuration) * 100;

      this.setState({ progress: newProgress });

      // If not finished, schedule the next update

      if (newProgress < 100) {
        const deltaTime = currentTime - lastUpdateTime;

        const delay = Math.max(50, 100 - deltaTime); // Limit updates to every 100ms

        lastUpdateTime = currentTime;

        setTimeout(updateProgress, 10);
      } else {
        // Finished playing

        this.onClickStop();
      }
    };

    // Start updating progress

    updateProgress();
  };

  onClickStop = () => {
    this.scheduledEvents.forEach((scheduledEvent) => {
      clearTimeout(scheduledEvent);
    });
    this.setRecording({
      mode: "RECORDING",
      currentEvents: [],
    });
    this.setState({ progress: 0 }); // Reset progress
  };

  onClickClear = () => {
    this.onClickStop();
    this.setRecording({
      events: [],
      mode: "RECORDING",
      currentEvents: [],
      currentTime: 0,
    });
    this.setState({
      selectedMelody: "none",
    });
  };

  generateContinueMelody = () => {
    if (this.state.selectedMelody == "Birthday Melody") {
      this.callMelodyContinuationAPI("Birthday_Melody");
    } else {
      if (
        this.state.recording.events.length < 60 &&
        this.state.recording.events.length % 4 == 0
      ) {
        message.error(
          `Please add minimum 60 & multiple of 4 piano key notes! Current notes ${this.state.recording.events.length}`
        );
        return;
      }
      this.exportMidiFile();
    }
  };

  callMelodyContinuationAPI = async (baseString) => {
    if (this.state.keyword.length < 1) {
      message.error("Please enter valid keyword!");
      return;
    }
    this.setState({
      isQueryEntered: true,
      base64: baseString,
    });
    return;
  };

  exportMidiFile = () => {
    const { events } = this.state.recording;
    // create a new midi file
    var midi = new Midi();
    // add a track
    const track = midi.addTrack();
    events.forEach((event) => {
      const { duration, time, midiNumber } = event;

      track.addNote({
        midi: midiNumber,
        time: time,
        duration: duration,
      });
    });

    var ascii = new Uint8Array(midi.toArray());
    var b64encoded = btoa(String.fromCharCode.apply(null, ascii));
    const finalURI = `data:audio/midi;base64,${b64encoded}`;
    this.callMelodyContinuationAPI(finalURI);
    return;
    const a = document.createElement("a");
    a.href = finalURI;
    a.download = `recorded_melody_${Date.now()}.mid`;
    a.click();
  };

  render() {
    return (
      <div>
        {/* <Col > */}
        <Button
          type="primary"
          className="music-button "
          style={{
            margin: "0px 0px 25px 20px",
            backgroundColor: this.state.play ? "red" : "",
          }}
          onClick={this.toggleHappyBirthdayPlay}
        >
          {this.state.play ? "Pause" : "Play"} Happy Birthday
        </Button>
        <Row>
          <Col
            className="mt-4"
            span={12}
            xs={24}
            sm={24}
            md={20}
            lg={20}
            xl={20}
          >
            <SoundfontProvider
              instrumentName="acoustic_grand_piano"
              audioContext={audioContext}
              hostname={soundfontHostname}
              render={({ isLoading, playNote, stopNote }) => (
                <PianoWithRecording
                  recording={this.state.recording}
                  setRecording={this.setRecording}
                  noteRange={noteRange}
                  height={232}
                  width={650}
                  playNote={playNote}
                  stopNote={stopNote}
                  disabled={isLoading}
                  // keyboardShortcuts={keyboardShortcuts}
                />
              )}
            />
            <div style={{ margin: "10px 0" }}>
              <progress
                value={this.state.progress}
                max={100}
                style={{
                  height: "20px",
                  width: "85%",
                }}
              />
            </div>
            <div>
              <Button className="music-button " onClick={this.onClickStop}>
                Stop
              </Button>
              <Button className="music-button " onClick={this.onClickClear}>
                Clear
              </Button>
            </div>
          </Col>
        </Row>

        <div
          className="mt-5"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "50%",
            }}
          >
            <p>
              <b>Selected Melody:</b> {this.state.selectedMelody}
            </p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <p>Enter keyword to generate melody</p>

                  <Input
                    style={{ borderRadius: "10px" }}
                    type="text"
                    placeholder="Enter keyword to generate melody"
                    value={this.state.keyword}
                    onChange={(e) => {
                      this.setState({
                        keyword: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <Button
                type="primary"
                // onClick={this.exportMidiFile}
                onClick={this.generateContinueMelody}
                // onClick={()=>{
                //   this.getData(happybirthday,(data)=>{
                //     console.log("happy-birthday-base64",data)
                //   });
                // }}
                style={{ marginTop: "10px", width: "50%" }}
              >
                Generate Music
              </Button>
            </div>
          </div>

          {!this.state.isQueryEntered ? (
            <></>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Play Generated Melody Continuation</p>
              {/* <img
                src={playButton}
                // onClick={this.onClickPlay}
                width="105px"
                height="105px"
                style={{
                  marginTop: "-20px",
                  cursor: "pointer",
                }}
              /> */}
              <audio
                src={`http://3.124.170.239:8009/load-melody?base_string=${this.state.base64}&query_string=${this.state.keyword}`}
                controls
                onLoadStart={() => {
                  this.setState({
                    isMelodyLoading: true,
                  });
                }}
                onCanPlay={() => {
                  this.setState({
                    isMelodyLoading: false,
                  });
                }}
                onError={() => {
                  this.setState({
                    isMelodyError: true,
                  });
                }}
              />
              {!this.state.isMelodyError ? (
                <Spin
                  tip="Melody is loading..."
                  spinning={this.state.isMelodyLoading}
                  style={{ marginTop: "10px" }}
                />
              ) : (
                <></>
              )}
              {this.state.isMelodyError ? (
                <div style={{ color: "#4693ff" }}>
                  Something went wrong! Could not play melody.
                </div>
              ) : (
                <div style={{ color: "#4693ff" }}>
                  {this.state.isMelodyLoading
                    ? "Melody is loading..."
                    : "Melody is ready to play!"}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default PianoPayer;
