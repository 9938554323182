import React, { useEffect } from "react";
import BreadCrumbs from "../../BreadCrumbs";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Row,
  Select,
  Tabs,
  Input,
  Table,
  message,
} from "antd";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { IP } from "../../../dynamicIPs";
import { Collapse } from "antd";

import axios from "axios";
import VideoTour from "../../video-guide";
import { domain_url } from "../../../dynamicIPs";
import { videoUrls } from "../../../util/videoData";
import { pdfUrls } from "../../../util/pdfData";

const { TextArea } = Input;
const { Panel } = Collapse;

function VisualizationGeneration(props) {
  const location = useLocation();
  const [activeKey, setActiveKey] = useState("1");
  const [tenantName, setTenantName] = useState("CIDDS");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [kpi, setKpi] = useState("");
  const [queryResponseLoading, setQueryResponseLoading] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [summery, setSummery] = useState("");
  const [createLoading, setCreateLoading] = useState(false);
  const [tableResponse, setTableResponse] = useState(null);
  const [generateReportLoading, setGenerateReportLoading] = useState(false);
  const [textualSummeries, setTextualSummeries] = useState("");
  const [visualPathData, setVisualPathData] = useState([]);
  const [tabelColumn, setTableColumn] = useState([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [allImageUrls, setAllImageUrls] = useState([]);
  const [isCreateApiCalled, setIsCreateApiCalled] = useState(false);
  const [createApiVisualDesc, setCreateApiVisualDesc] = useState([]);
  const [editLastQueryDisable, setEditLastQueryDisable] = useState(true);
  const [textAreaList, setTextAreaList] = useState([]);
  const [pdfdownloadLink, setPdfDownloadLink] = useState(null);

  useEffect(() => {
    if (tableResponse !== null) {
      const tableDataKeys = Object.keys(JSON.parse(tableResponse));
      const columns = Object.keys(JSON.parse(tableResponse)).map((column) => ({
        title: column,
        dataIndex: column,
        key: column,
      }));
      setTableColumn(columns);
      const dataSource = Object.values(JSON.parse(tableResponse));
      const arr = [];
      for (let i = 0; i < Object.keys(JSON.parse(tableResponse)).length; i++) {
        const data = Object.values(dataSource[i]);
        arr.push(data);
      }
      const rowResponse = [];
      for (let i = 0; i < arr[0].length; i++) {
        const rowObj = {};
        for (
          let j = 0;
          j < Object.keys(JSON.parse(tableResponse)).length;
          j++
        ) {
          rowObj[tableDataKeys[j]] = arr[j][i];
        }
        rowResponse.push(rowObj);
      }
      setTableDataSource(rowResponse);
    }
  }, [tableResponse]);

  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleQuerySubmit = async () => {
    setQueryResponseLoading(true);
    setIsCreateApiCalled(false);
    const body = {
      select_applications: selectedApplication,
      select_tenant_name: tenantName,
      start_date: startDate,
      end_date: endDate,
    };
    const url = `${domain_url}/assistance/lida_assistance`;

    try {
      const res = await axios.post(url, body);
      setDisabledButton(false);
      setQueryResponseLoading(false);
      const summery_data = res.data.summary_data[0].summary;
      const table_data = res.data.table_data[0].table_data;
      const textual_summeries = res.data.textual_summary_data[0].texual_summary;
      const visual_paths = res.data.visual_paths_data;
      const allImageUrls = visual_paths.map((item, index) => item.visual_paths);
      setAllImageUrls(allImageUrls);
      setSummery(summery_data);
      setTableResponse(table_data);
      setTextualSummeries(textual_summeries);
      setVisualPathData(visual_paths);
    } catch (error) {
      message.error(error.message);
      setQueryResponseLoading(false);
    }
  };

  const handleEditLastQuery = () => {
    setEditLastQueryDisable((prev) => !prev);
  };
  const handleCreateApi = async () => {
    const textField = [];
    if (textAreaList.length > 0) {
      let last_string = textAreaList[textAreaList.length - 1];
      let pos = kpi.indexOf(last_string);

      if (pos !== -1) {
        let str = kpi.slice(0, pos) + kpi.slice(pos + last_string.length);

        textField.push(...textAreaList);
        textField.push(str);
        setTextAreaList((prev) => [...prev, str]);
      } else {
        textField.push(...textAreaList);
        textField.push(kpi);

        setTextAreaList((prev) => [...prev, kpi]);
      }
    } else {
      textField.push(kpi);
      setTextAreaList((prev) => [...prev, kpi]);
    }

    setCreateLoading(true);
    const url = `https://yashgenai.yashaccelerator.link/assistance/custom-kpis`;
    const body = {
      input_lines: textField,
      visual_paths_len: allImageUrls.length,
      summary: summery,
    };

    try {
      const res = await axios.post(url, body);
      setCreateApiVisualDesc(res.data.response.visual_desc);
      setEditLastQueryDisable(false);
      setAllImageUrls((prevUrl) => [
        ...prevUrl,
        res.data.response.visualization_paths[0],
      ]);
      setIsCreateApiCalled(true);
      setCreateLoading(false);
    } catch (error) {
      message.error(error.message);
      setCreateLoading(false);
    }
  };

  const handleGenerateReport = async () => {
    setGenerateReportLoading(true);
    const url = `${domain_url}/assistance/generate-report`;
    const body = {
      select_applications: selectedApplication,
      select_tenant_name: tenantName,
      start_date: startDate,
      end_date: endDate,
      textual_summary: textualSummeries,
      visualization_paths_len: allImageUrls.length,
    };

    try {
      const res = await axios.post(url, body);
      setGenerateReportLoading(false);
      setActiveKey("2");
      setPdfDownloadLink(res.data.pdf_url);
    } catch (error) {
      setGenerateReportLoading(false);
      message.error(error.message);
    }
  };

  const handleApplicationChange = (value) => {
    setSelectedApplication(value);
  };

  const handleTenantNameChange = (value) => {
    setTenantName(value);
  };
  const handleStartDateChange = (date, dateString) => {
    setStartDate(dateString);
  };
  const handleEndDateChange = (date, dateString) => {
    setEndDate(dateString);
  };

  const items = [
    {
      key: "1",
      label: "Visualization",
      children: (
        <Row gutter={[8, 16]}>
          <Col sx={12} sm={12} md={12} xl={12} lg={12} xxl={12}>
            <Select
              mode="multiple"
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Select application"
              defaultValue={[]}
              onChange={handleApplicationChange}
              options={[
                {
                  label: "SAP Worksoft",
                  value: "sap-worksoft",
                },
                {
                  label: "Application 2",
                  value: "application-2",
                },
                {
                  label: "Application 3",
                  value: "application-3",
                },
              ]}
            />
          </Col>

          <Col sx={12} sm={12} md={12} xl={12} lg={12} xxl={12}>
            <Select
              allowClear
              style={{
                width: "100%",
              }}
              placeholder="Select tenant name"
              onChange={handleTenantNameChange}
              options={[
                {
                  label: "CIDDS",
                  value: "CIDDS",
                },
              ]}
            />
          </Col>
          <Col sx={12} sm={12} md={12} xl={10} lg={10} xxl={10}>
            <DatePicker
              placeholder="Start Date"
              style={{
                width: "100%",
              }}
              onChange={handleStartDateChange}
            />
          </Col>
          <Col sx={12} sm={12} md={12} xl={10} lg={10} xxl={10}>
            <DatePicker
              placeholder="End Date"
              style={{
                width: "100%",
              }}
              onChange={handleEndDateChange}
            />
          </Col>

          <Col sx={24} sm={24} md={24} xl={4} lg={4} xxl={4}>
            <Button
              type="primary"
              loading={queryResponseLoading}
              onClick={handleQuerySubmit}
              style={{ width: "100%" }}
            >
              Submit
            </Button>
          </Col>

          <Col sx={20} sm={20} md={20} xl={20} lg={20} xxl={20}>
            <TextArea
              placeholder="Enter the custom KPI to generate visualization..."
              disabled={!editLastQueryDisable}
              onChange={(e) => setKpi(e.target.value)}
              value={kpi}
              rows={3}
            />
          </Col>
          <Col sx={4} sm={4} md={4} xl={4} lg={4} xxl={4}>
            <Button
              loading={createLoading}
              onClick={handleCreateApi}
              style={{ width: "100%" }}
              disabled={disabledButton}
            >
              Create
            </Button>
            <Button
              danger
              // loading={queryResponseLoading}
              onClick={handleEditLastQuery}
              disabled={editLastQueryDisable}
              style={{ width: "100%", marginTop: "10px" }}
            >
              Edit Last Query
            </Button>
          </Col>
          <Col sx={24} sm={24} md={24} xl={24} lg={24} xxl={24}>
            <Button
              loading={generateReportLoading}
              disabled={disabledButton}
              onClick={handleGenerateReport}
              style={{ width: "100%" }}
              type="primary"
            >
              Generate Report
            </Button>
          </Col>
        </Row>
      ),
    },
    {
      key: "2",
      label: "Report",
      disabled: pdfdownloadLink === null ? true : false,
      children: (
        <div>
          <h6
            style={{
              fontSize: "1.2rem",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            {pdfdownloadLink !== null ? (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <DownloadOutlined
                  style={{ margin: "2px 10px 0 0", color: "green" }}
                />
                <div dangerouslySetInnerHTML={{ __html: pdfdownloadLink }} />
              </div>
            ) : null}
          </h6>
        </div>
      ),
    },
  ];
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.Visualization} type="video/mp4" pdfSrc={pdfUrls.Visualization_Prompts} />
      </div>

      <div style={{ marginTop: "20px" }}>
        <Card>
          <Tabs
            size="small"
            onChange={onChange}
            activeKey={activeKey}
            items={items}
          />
        </Card>
      </div>
      {tabelColumn.length > 0 && activeKey === "1" ? (
        <div style={{ marginTop: "20px", marginLeft: "5px" }}>
          <Table
            columns={tabelColumn}
            dataSource={tableDataSource}
            scroll={{ x: 400 }}
          />
        </div>
      ) : null}
      {textualSummeries.length > 0 && activeKey === "1" ? (
        <Card>
          <h5>Summary:</h5>
          <div style={{ marginTop: "20px", fontSize: "16px" }}>
            {textualSummeries}
          </div>
        </Card>
      ) : null}
      {visualPathData.length > 0 && !isCreateApiCalled && activeKey === "1" ? (
        <>
          {visualPathData.map((data, index) => (
            <>
              <Card
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <img
                  src={`data:image/png;base64,${data?.visual_paths}`}
                  alt="Base64 Image"
                  height={400}
                  width={700}
                />
              </Card>
              <Card style={{ marginTop: "20px" }}>
                <Collapse accordion>
                  <Panel header="What the Visualization Tells Us" key={index}>
                    {data["What the Visualization Tells Us"].map(
                      (item, index) => (
                        <>
                          <h5 key={index}>{item.section}</h5>
                          <p key={index}> {item.explanation}</p>
                        </>
                      )
                    )}
                  </Panel>
                </Collapse>
              </Card>
            </>
          ))}
        </>
      ) : null}
      {isCreateApiCalled && activeKey === "1" ? (
        <>
          {allImageUrls.map((url, index) => (
            <Card
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <img
                src={`data:image/png;base64,${url}`}
                alt="Base64 Image"
                height={400}
                width={700}
              />
            </Card>
          ))}
          {createApiVisualDesc.length > 0 ? (
            <>
              <Card style={{ marginTop: "20px" }}>
                <Collapse accordion>
                  <Panel header="What the Visualization Tells Us" key={3}>
                    {createApiVisualDesc.map((item, index) => (
                      <>
                        <h5 key={index}>{item.section}</h5>
                        <p key={index}> {item.explanation}</p>
                      </>
                    ))}
                  </Panel>
                </Collapse>
              </Card>
            </>
          ) : null}
        </>
      ) : null}

      {/* <div>
        <img src={`data:image/png;base64,${baseUrl}`} alt="Base64 Image" />
      </div> */}
    </>
  );
}

export default VisualizationGeneration;
