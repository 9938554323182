import React, { useState, useRef, useEffect } from "react";
import Chatbot from "./Chatbot";
import BreadCrumbs from "../../../BreadCrumbs";
import {
  Card,
  Select,
  Row,
  Col,
  Button,
  message,
  Typography,
  Modal,
  Checkbox,
  Form,
  Input,
  Result,
  DatePicker,
} from "antd";
import { useLocation } from "react-router";
import { DotLoading } from "antd-mobile";
import { SendOutlined } from "@ant-design/icons";
import { IP } from "../../../../dynamicIPs";
import axios from "axios";
import VideoTour from "../../../video-guide";
import { domain_url } from "../../../../dynamicIPs";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";
import { api_url } from "../../../../dynamicIPs";
import Markdown from "markdown-to-jsx";

function TripPlanner(props) {
  const location = useLocation();
  const { Title, Text, Paragraph } = Typography;
  const messageContainerRef = useRef(null);
  const [category, setCategory] = useState("General");
  const [msgData, setMsgData] = useState([]);
  const [initialMessage, setInitialMessage] = useState(
    "Hello! \n How can I assist you with your trip planning?"
  );
  const [query, setQuery] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isTicketBook, setIsTicketBook] = useState(false);
  const [userMessage, setUserMessage] = useState(null);
  const [apiMessage, setApiMessage] = useState(null);
  const [flightFormData, setFlightFormData] = useState(null);
  const [fillerMessages, setFillerMessages] = useState([
    "Hmm...let me think",
    "Generating Response!",
    "Here is the answer.",
  ]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setFlightFormData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    console.log("handleCancel", flightFormData);
    setFlightFormData(null);
    setIsTicketBook(false);
    setIsModalOpen(false);
  };
  const onFinish = (values) => {
    // handleOk();
    setIsTicketBook(true);
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  // const messagesEndRef = useRef(null);
  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };
  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [userMessage, apiMessage]);
  const [currentFillerMessage, setCurrentFillerMessage] =
    useState("Hmm...let me think");

  const handleCategoryChange = (value) => {
    localStorage.removeItem("history");
    setMsgData([{ text: "Hi, How may I help you?", isUser: false }]);
    setCategory(value);
  };
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(flightFormData);
  }, [form, flightFormData]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setInputMessage(event.target.value);
  };
  const handleFlightClick = (flight) => {
    console.log("handleFlightClick clicked", flight);
    setFlightFormData(flight);
    setIsModalOpen(true);
  };

  // function extractFlights(from, to, responseString) {
  //   const flightRegex =
  //     /-\s([A-Za-z\s]+),\sdeparting\sat\s([\d:]+)\sand\sarriving\sat\s([\d:]+),\scosting\s(\d+)\swith\s([\w\s]+)\./g;

  //   let flights = [];
  //   let match;

  //   while ((match = flightRegex.exec(responseString)) !== null) {
  //     flights.push({
  //       From: from,
  //       To: to,
  //       Flight: match[1].trim(),
  //       DepartureTime: match[2],
  //       ArrivalTime: match[3],
  //       Cost: match[4],
  //       Stops: match[5].trim(),
  //     });
  //   }
  //   let match2;
  //   let extraString = [];
  //   // while ((match2 != flightRegex.exec(responseString)) !== null) {
  //   //   extraString.push(match2);
  //   // }

  //   console.log("extraString:", extraString);
  //   return flights;
  // }

  function extractFlights(from, to, responseString) {
    const flightRegex =
      /-\s([A-Za-z\s]+),\sdeparting\sat\s([\d:]+)\sand\sarriving\sat\s([\d:]+),\scosting\s(\d+)\swith\s([\w\s]+)\./g;

    let flights = [];
    let remainingString = responseString;
    let match;
    let flag = true;
    let remainingStringBefore;
    while ((match = flightRegex.exec(responseString)) !== null) {
      flights.push({
        From: from,
        To: to,
        Flight: match[1].trim(),
        DepartureTime: match[2],
        ArrivalTime: match[3],
        Cost: match[4],
        Stops: match[5].trim(),
      });
      if (flag) {
        remainingStringBefore = responseString.substring(0, match.index);
      }
      flag = false;
      console.log("remainingBefore:", remainingStringBefore);
      remainingString = responseString.substring(match.index + match[0].length);
    }
    console.log("remainingString:", remainingString);
    return { flights, remainingStringBefore, remainingString };
  }
  const updateResponse = (responseString) => {
    const locationPattern = /flights available from (.*) to (.*) /;
    const locationMatch = responseString.match(locationPattern);
    let fromLocation = "";
    let toLocation = "";
    if (!locationMatch) {
      console.error("Locations not found");
      // return;
    }
    console.log("locationMatch:", locationMatch);
    fromLocation = locationMatch?.[1];
    toLocation = locationMatch?.[2];
    const flightData = extractFlights(fromLocation, toLocation, responseString);

    console.log("JSON DATA:", JSON.stringify(flightData, null, 2));
    return (
      <>
        <div>
          <Markdown>{flightData.remainingStringBefore}</Markdown>
          {flightData.flights.map((flight, index) => (
            <>
              <Button
                style={{ width: "150px", margin: "0px 0px 2px 0px" }}
                key={index}
                onClick={() => handleFlightClick(flight)}
              >
                {flight.Flight}
              </Button>
              <br />
            </>
          ))}
          <Markdown>{flightData.remainingString}</Markdown>
        </div>
      </>
    );
  };

  const handleClearHistroy = () => {
    localStorage.removeItem("history");
    setMsgData([{ text: "Hi, How may I help you?", isUser: false }]);
  };
  const handleSubmit = async (event) => {
    const randomIndex = Math.floor(Math.random() * fillerMessages.length);
    setCurrentFillerMessage(fillerMessages[randomIndex]);
    setMsgData([
      ...msgData,
      {
        text: query,
        isUser: true,
      },
      {
        text: currentFillerMessage,
        isUser: false,
      },
    ]);
    // setMsgData([...msgData, ,]);
    event.preventDefault();

    if (!query) return;
    setUserMessage(query);
    setInputMessage("");

    setLoading(true);
    try {
      let url = `${api_url}/trip/query`;
      let data = {
        query: query,
      };
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("response:", response.data.result);
      let updatedRespose = updateResponse(response.data.result);
      setMsgData([
        ...msgData,
        {
          text: query,
          isUser: true,
        },
        { text: updatedRespose, isUser: false },
      ]);

      setQuery("");
      setLoading(false);
    } catch (error) {
      setUserMessage(query);
      setApiMessage(error.message);
      console.error("Error fetching data:", error);
      setMsgData([
        ...msgData,
        {
          text: query,
          isUser: true,
        },
        { text: error.message, isUser: false },
      ]);

      setLoading(false);
      setQuery("");
    }
  };
  return (
    <div
      style={{ margin: "0 40px 0 40px" }}
      className={msgData.length === 0 ? "user-manual-container" : ""}
    >
      <div style={{display:'flex'}}> 
        <BreadCrumbs location={location} />
        <div style={{ margin: "0px 0 0 auto" }}>
          <VideoTour
            src={videoUrls.trip_planner}
            type="video/mp4"
            pdfSrc={pdfUrls.trip}
          />
        </div>
      </div>
      {msgData.length == 0 && (
        <div style={{ textAlign: "center", marginTop: "10%" }}>
          <Text style={{ margin: "20px 0", fontSize: "xxx-large" }}>
            Hello!
          </Text>
          <br />
          <Text style={{ margin: "20px 0", fontSize: "xx-large" }}>
            How can I assist you with your trip planning?
          </Text>
        </div>
      )}

      <div
        className="user-manual-chatbot"
        style={{
          marginTop: "10px",
          position: "absolute",
          bottom: "16px",
          width: "92%",
        }}
      >
        <div
          ref={messageContainerRef}
          style={{
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          {msgData.map((message, index) => (
            <Chatbot key={index} text={message.text} isUser={message.isUser} />
          ))}
        </div>
        <div className="text-area" style={{ marginTop: "10px" }}>
          <form
            onSubmit={handleSubmit}
            className="input-container-user-manual"
            style={{ width: "100%" }}
          >
            <input
              disabled={loading}
              type="text"
              value={inputMessage}
              className="input-user-manual"
              onChange={handleInputChange}
              placeholder="Type a new question..."
            />
            {loading ? (
              <></>
            ) : (
              <>
                <Button
                  style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                    alignSelf: "center",
                    color: "black",
                  }}
                  type="primary"
                  icon={<SendOutlined />}
                  size="small"
                  onClick={handleSubmit}
                />
              </>
            )}
          </form>
        </div>
        <Modal
          style={{
            top: 20,
          }}
          footer={null}
          title="Book your Flight Ticket"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {isTicketBook && (
            <Result
              status="success"
              title="Your Flight Ticket is Booked"
              subTitle=""
              extra={[<Button onClick={handleCancel}>Cancel</Button>]}
            />
          )}
          {!isTicketBook && (
            <Form
              form={form}
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              style={{
                maxWidth: 600,
              }}
              initialValues={flightFormData}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
              <Form.Item
                label="From"
                name="From"
                // initialValue={flightFormData?.From}
                rules={[
                  {
                    required: true,
                    message: "Please enter origin !",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="To"
                name="To"
                // initialValue={flightFormData?.To}
                rules={[
                  {
                    required: true,
                    message: "Please input destination!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Select Date"
                name="date"
                rules={[
                  {
                    required: true,
                    message: "Please Select a Date",
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
              <Form.Item
                label="Departure Time:"
                name="DepartureTime"
                // initialValue={flightFormData?.DepartureTime}
                rules={[
                  {
                    required: true,
                    message: "Please input departureTime!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Arrival Time"
                name="ArrivalTime"
                // initialValue={flightFormData?.ArrivalTime}
                rules={[
                  {
                    required: true,
                    message: "Please input arrivalTime!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Flight"
                name="Flight"
                // initialValue={flightFormData?.Flight}
                rules={[
                  {
                    required: true,
                    message: "Please input flight!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Cost"
                name="Cost"
                // initialValue={flightFormData?.Cost}
                rules={[
                  {
                    required: true,
                    message: "Please input flight!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Passenger name"
                name="name"
                initialValue="Alice Jones"
                // value=
                rules={[
                  {
                    required: true,
                    message: "Please input your name!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Age"
                name="age"
                initialValue="25"
                rules={[
                  {
                    required: true,
                    message: "Please input your age!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Number"
                name="number"
                initialValue="9876543210"
                rules={[
                  {
                    required: true,
                    message: "Please input your number!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Book Flight Ticket
                </Button>
                &nbsp; &nbsp;
                <Button onClick={handleCancel}>Cancel</Button>
              </Form.Item>
            </Form>
          )}
        </Modal>
      </div>
    </div>
  );
}

export default TripPlanner;
