import React from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

export default function ImgGenHealth() {
  const location = useLocation();
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.Delay} type="video/mp4" pdfSrc={pdfUrls.YTPL_Holiday_Calendar_2022} />
      </div>
      <div>ImgGenHealth</div>
    </>
  );
}
