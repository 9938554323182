import { Card } from "antd";
import React from "react";
import EnterpriseImage from "../../../../assests/img/enterprise.jpg";
import Meta from "antd/es/card/Meta";
import { Link, useLocation } from "react-router-dom";
import { streamlitRoutes } from "../../../../util/constants";
import BreadCrumbs from "../../../BreadCrumbs";
import VideoTour from "../../../video-guide";

const EnterpriseGpt = () => {
  const location = useLocation();
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <BreadCrumbs location={location} />
        <VideoTour src="https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Enterprise+ChatGPT.mp4" type="video/mp4" pdfSrc="https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Enterprise+ChatGPT.pdf" />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Card
          hoverable
          style={{
            width: 440,
          }}
          cover={<img alt="example" src={EnterpriseImage} />}
        >
          <Link
            to={streamlitRoutes.ENTERPRISE_CHATBOT}
            target="_blank"
            style={{ textDecoration: "none", color: "blue" }}
          >
            <Meta
              title="Click here to go to the Enterprise GPT Chatbot"
              description="Secure AI-powered chatbot tailored to your private data"
            />
          </Link>
        </Card>
      </div>
    </>
  );
};

export default EnterpriseGpt;
