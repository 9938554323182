import { object } from "prop-types";
import React, { useEffect, useRef } from "react";
import Markdown from "markdown-to-jsx";
const userImage =
  "https://img.freepik.com/free-psd/3d-illustration-human-avatar-profile_23-2150671122.jpg?t=st=1709891031~exp=1709894631~hmac=2e86218d07e12b90f3e7915d6a2e5602c1cd37f6b1023a78d6541e341ee73564&w=740";
const apiImage =
  "https://img.freepik.com/free-vector/floating-robot_78370-3669.jpg?t=st=1709892439~exp=1709896039~hmac=130665e9167533ff9bcf3489c40c20e067cf11ef0fd76659b321b6737fe85780&w=740";

const Chatbot = ({ text, isUser }) => {
  console.log("text", text);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [text]);
  const ImageOverride = ({ children, ...props }) => {
    const { src, alt } = props;
    const width = props["width"];
    const height = props["height"];

    return <img src={src} alt={alt} style={{ width, height }} />;
  };

  const overrides = {
    img: {
      component: ImageOverride,
    },
  };
  return (
    <div
      className={`chat-message ${isUser ? "user-message" : "api-message"}`}
      style={{
        display: "flex",
        justifyContent: isUser ? "flex-end" : "flex-start",
        alignItems: "center",
        margin: "20px",
      }}
    >
      {!isUser && (
        <div
          className="api-image"
          style={{ marginRight: "15px", marginBottom: "20px" }}
        >
          <img
            src={apiImage}
            alt="API"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
            }}
          />
        </div>
      )}

      <div
        className={`message-content ${isUser ? "user-content" : "api-content"}`}
        style={{
          position: "relative",
          backgroundColor: isUser ? "#205BAC" : "#E1F1FD",
          padding: "10px",
          color: isUser ? "white" : "",
          borderRadius: "10px",
          maxWidth: "70%",
          textAlign: isUser ? "right" : "left",
          margin: isUser ? "10px 0 0 0" : "0 0 10px 0",
        }}
      >
        <div
          className="message-border"
          style={{
            position: "absolute",
            top: "-2px",
            left: "-2px",
            right: "-2px",
            bottom: "-2px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            pointerEvents: "none",
          }}
        />
        {typeof text === "object" ? (
          text
        ) : (
          <Markdown options={{ overrides }}>{text}</Markdown>
        )}
        {/* <div ref={messagesEndRef} /> */}
      </div>
      {isUser && (
        <div className="user-image" style={{ marginLeft: "15px" }}>
          <img
            src={userImage}
            alt="User"
            style={{ width: "40px", height: "40px", borderRadius: "50%" }}
          />
        </div>
      )}
    </div>
  );
};

export default Chatbot;
