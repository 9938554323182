import { routes } from "./constants";
import { pdfUrls } from "./pdfData";
import { videoUrls } from "./videoData";

export const usecaseData = {
  "bill-analyzer": {
    name: "Bills Analyzer",
    // icon: billAnalyzer,
    url: routes.BILLS_ANALYZER,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Invoice%20Analyzer.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/bills_analyzer_prompts.pdf",
    awsVideo: true,
    streamlit: true,
    tag: "Productivity",
    desc: "Upload your invoices, receipt and POS. Get them analyzed and gain insights within seconds.",
  },

  "financial-report-analyzer": {
    name: "Financial Report Analyzer",
    // icon: fra,
    url: routes.FINANCIAL_REPORT_ANALYZER,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Financial_report_analyzer-recording.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/financial.pdf",
    awsVideo: true,
    streamlit: true,
    tag: "Productivity",
    desc: "Solution for comprehensive analysis of financial reports. It efficiently extracts pertinent information related to company's performance, empowering users with valuable insights to facilitate informed decision-making processes in a timely manner.",
  },

  "contract-analyzer": {
    name: "Contract Analyzer",
    // icon: contarctAnz,
    url: routes.CONTRACT_ANALYZER,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/contract%20analyzer.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Contract%20Analyzer.pdf",
    awsVideo: true,

    streamlit: true,
    tag: "Productivity",
    desc: "Contracts are lengthy and difficult to deal with. Check out our contract analyzer which summarizes key points, and offers valuable recommendations.",
  },

  "mutli-llm-assistance": {
    name: "Multilingual, Multi-LLM assistant",
    // icon: multiLLM,
    url: routes.MULTI_LLM_ASSISTANT,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/multilingual_multillm_video.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/multillm_questions_to_try.pdf",
    awsVideo: true,
    streamlit: true,
    tag: "Chatbot",
    desc: "Chat for HR policies with multple LLM. Chat in Arabic or English language.",
  },
  smarthire: {
    name: "SmartHIRE",
    // icon: smartHireImage,
    url: routes.SMART_HIRE,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Smart%20HIRE%20walkthrough.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/smartHire.pdf",
    awsVideo: true,
    streamlit: true,
    tag: "Virtual Assistant",
    desc: "Find your next perfect talent with AI Video Interview Platform. Screen and interview thousands of candidates at the same time via live AI Interview. Get a curated shortlist of talent with the soft skills and abilities you need. Achieve 90% candidate satisfaction.",
  },

  "gmail-genai-toolkit": {
    name: "Gmail GenAI Toolkit",
    // icon: multiLLM,
    url: routes.GMAIL_GENAI_TOOLKIT,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/gmail_genai_toolkit.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Gmail_Genai_Toolkit.pdf",
    awsVideo: true,
    // streamlit: true,
    tag: "Toolkit",
    desc: "Transform your email experience effortlessly! Perform all email actions, fetch and summarize important messages, match job profiles, and more with our integrated solution. Boost productivity and streamline processes for a seamless workflow!",
  },

  "digital-catalogue-summarizer": {
    name: "Digital Catalogue Summarizer",
    // icon: pptsumarizer,
    url: routes.DIGITAL_CATALOGUE,
    streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/digital%20catalogue%20summarizer.mp4",
    awsVideo: true,
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/digital%20catalogue%20summarizer.pdf",
    tag: "Productivity",
    desc: "The digital catalogue summarizer utilizes Generative AI to condense lengthy presentations into concise summaries, providing users with a quick and efficient way to grasp key information from heterogenous PPTs. ",
  },

  "Executor-AI": {
    name: "ExecutorAI",
    // icon: semanticSearch,
    url: routes.EXECUTOR_AI,
    streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/code%20executor.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/executorAi.pdf",
    awsVideo: true,
    tag: "Productivity",
    desc: "Automated AI Bot to generate, execute, and debug Python code for given tasks, facilitating seamless task completion through automated assistance and feedback. ",
  },
  "Impact-SDLC": {
    name: "Impact SDLC",
    // icon: impact,
    url: routes.IMPACT_SDLC,
    videoSrc: videoUrls.impact,
    pdfSrc: pdfUrls.impact,
    awsVideo: true,
    tag: "",
    desc: "Manage different phases of software development lifecycle infused with power of Gen AI with an intuitive dashboard and manage different projects, tasks and users efficiently.",
  },

  "AWS-Guardrails-Travel-Agent": {
    name: "AWS Guardrails Travel Agent",
    // icon: gta,
    url: routes.GUARDRAILS_TRAVEL_AGENT,
    videoSrc: videoUrls.travelAgent,
    pdfSrc: pdfUrls.travelAgent,
    awsVideo: true,
    tag: "ChatBot",
    desc: "This streamlit application simulates Aws Guardrails Travel Agent that interacts with users by answering travel-related questions and generate only travel related responses.",
  },

  "Conversation-Summarizer-Analyzer": {
    name: "Conversation Summarizer & Analyzer",
    // icon: csa,
    url: routes.CONVERSATION_SUMMARIZER_AND_ANALYZER,
    videoSrc: videoUrls.csa,
    pdfSrc: pdfUrls.csa,
    awsVideo: true,
    tag: "ChatBot",
    desc: " Unlock insights from audio files with our AI-powered analysis tool. Seamlessly transcribe, summarize, analyze sentiment, and extract key issues to drive data-informed business decisions.",
  },

  "Ams-Ticket-Tool": {
    name: "Ams Ticket Tool",
    // icon: ticket,
    url: routes.AMS_TICKET_TOOL,
    videoSrc: videoUrls.ams,
    pdfSrc: pdfUrls.ams,
    awsVideo: true,
    tag: "ChatBot",
    desc: "AMS tickets often contain repetitive issues that can be resolved by referencing historical data. By using Combination of Azure OpenAI GPT model and RAG, the system can generate list of all possible contextually relevant solutions by retrieving similar past cases and angulating the generated response for incoming new ticket.",
  },
  "Manufacturing-Catalyst": {
    name: "Manufacturing Catalyst",
    // icon: manufacturing,
    url: routes.MANUFACTURING_CATALYST,
    streamlit: true,
    awsVideo: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Manufacturing%20Catalyst.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/No%20data.pdf",
    tag: "Productivity",
    desc: "Seamlessly extract insights from uploaded manufacturing machinery fault videos, streamlining fault detection and troubleshooting processes with advanced AI-driven analysis. Improve efficiency and accuracy in diagnosing issues, enhancing overall production reliability and minimizing downtime.",
  },

  "csr-agent": {
    name: "CSR Agent",
    // icon: csr,
    url: routes.CSR,
    streamlit: true,
    awsVideo: true,
    videoSrc: videoUrls.csr,
    pdfSrc: pdfUrls.csr,
    tag: "MISC",
    desc: "CSR Agent Assist provides two key modes:Conversation Mode and Incident Mode, each offering several functionalities to enhance call center operations.",
  },

  "idp-processor": {
    name: "Intelligent Document Processing",
    // icon: idpp,
    url: routes.INTELLIGENT_DOCUMENT_PROCESSING,
    videoSrc: videoUrls.idp,
    pdfSrc: pdfUrls.idp,
    awsVideo: true,
    tag: "ChatBot",
    desc: "Streamline your document processing with our AI-powered solution. Effortlessly analyze, translate, and extract insights from PDFs and web content while ensuring data security and multilingual support.",
  },
  "aws-q-yashTech": {
    name: "AWS Q on YashTechAI",
    // icon: yashtechai,
    url: routes.AWSQ,
    streamlit: true,
    awsVideo: true,
    videoSrc: videoUrls.awqYashTechAI,
    pdfSrc: pdfUrls.awqYashTechAI,
    tag: "MISC",
    desc: "Our new accelerator, powered by AWS Q, enables interactive, AI-driven chat on yashtech.ai. It crawls all content, allowing users to access information through natural conversations, enhancing user experience and making information more accessible.",
  },
  "image-campaign-creation":{
    name: "Image Campaign Creation",
    //icon: imageCampaign,
     url: routes.IMAGE_CAMPAIGN_CREATION,
    streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Image%20campaign%20creation.mp4",

    awsVideo: true,
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Image_campaign_creation_sample_images.pdf",
    tag: "Creativity",
    desc: "Innovative Generative AI utilized to create highly personalized media ads for social and internet platforms. Analyzing user data enhances ad relevance, engagement, and targeting, revolutionizing advertising effectiveness and audience connection.",
  },
  "Live-Potrait":
  {
    name: "Live Potrait",
    //icon: trip,
    url: routes.LIVE_POTRAIT,
    // streamlit: true,
    videoSrc: videoUrls.lp,
    pdfSrc: pdfUrls.lp,
    awsVideo: true,
    tag: "MISC",
    desc: "Efficient Portrait Animation with Stitching and Retargeting Control",
  },
};
