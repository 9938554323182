import { routes, streamlitRoutes } from "../../../util/constants";
import tableImg from "../../../assests/img/sdlc.jpg";
import visualizationGenerationIcon from "../../../assests/img/lida.jpg";
import genAiChatbotImg from "../../../assests/icon/Database Agents.svg";
import semanticSearch from "../../../assests/img/semantic-search.jpg";
import devSecOps from "../../../assests/img/devSecOps.jpg";
import sdlcAiImage from "../../../assests/img/sdlcAiImage.png";
import itDashboard from "../../../assests/img/itDashboard.jpg";
import impact from "../../../assests/img/impact.jpg";
import virtualtravel from "../../../assests/img/virtualtravel.jpg";

import gta from "../../../assests/img/gta.jpg";
import csa from "../../../assests/img/csa.jpg";

import { videoUrls } from "../../../util/videoData";
import { pdfUrls } from "../../../util/pdfData";

export const ITData = [
  {
    name: "SDLC",
    icon: tableImg,
    url: routes.SDLC,
    tag: "Productivity",
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/SDLC.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/SDLC%20Prompts.pdf",
    awsVideo: true,
    desc: "Code Generation, Bug Detection and Resolution, Summary for the code and Code Comments, Generating Test Cases.",
  },
  // {
  //   name: "Data Visualization",
  //   icon: visualizationGenerationIcon,
  //   url: routes.VISUALIZATION,
  //   tag: "Productivity",
  //   videoSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Visualization.mp4",
  //   pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Visualization%20Prompts.pdf",
  //   awsVideo: true,
  //   desc: "Turn your data into insights instantly. Upload your CSV files and let our intuitive tool generate various interactive graphs based on your queries.",
  // },
  {
    name: "Database Agents",
    icon: genAiChatbotImg,
    url: routes.DATABASE_AGENTS,
    tag: "Chatbot",
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/DB%20agents%20recording.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Db%20Agents.pdf",
    awsVideo: true,
    desc: "SQL agents are here to help you deal with massive databases of different types. Natural language queries and responses are here for everyone.",
  },
  {
    name: "DevSecOps Chatbot",
    icon: devSecOps,
    // url: streamlitRoutes.DEVSEC_OPS_CHATBOT,
    // streamlit: true,
    awsVideo: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/devsecops_recording.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/devSecOps.pdf",
    tag: "MISC",
    desc: "The DevSecOps Aid is your on-demand security expert, always at your fingertips. If your seeking guidance, or want to stay informed about security threats and vulnerabilities check our tool.",
  },
  {
    name: "LLM Semantic Search",
    icon: semanticSearch,
    // url: streamlitRoutes.LLM_SEMANTIC_SEARCH,
    // streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Semantic%20Search.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/semanticSearch.pdf",
    awsVideo: true,
    tag: "MISC",
    desc: "Witness the efficiency and accuracy of Semantic Search clubed with Semantic Search.",
  },
  // {
  //   name: "SDLC AI",
  //   icon: sdlcAiImage,
  //   url: routes.SDLC_AI,
  //   streamlit: true,
  //   videoSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/SDLC%20AI%20usecase%20simplified2.mp4",
  //   pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/sdlc%20AI%20bot%20simplified.pdf",
  //   awsVideo: true,
  //   tag: "Toolkit",
  //   desc: "This innovative feature greatly improves development efficiency, shortens development cycles, and reduces communication costs, resulting in higher-quality software delivery.",
  // },
  {
    name: "ExecutorAI",
    icon: semanticSearch,
    url: routes.EXECUTOR_AI,
    streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/code%20executor.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/executorAi.pdf",
    awsVideo: true,
    tag: "Productivity",
    desc: "Automated AI Bot to generate, execute, and debug Python code for given tasks, facilitating seamless task completion through automated assistance and feedback. ",
  },
  // {
  //   name: "Data Analytics Dashboard powered by GenAI",
  //   icon: itDashboard,
  //   url:routes.DATA_ANALYTICS_DASHBOARD,
  //   streamlit: true,
  //   videoSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Dashboard%20Recording.mp4",
  //   pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/ItDashboard.pdf",
  //   awsVideo: true,
  //   tag: "Toolkit",
  //   desc: "This application is a robust data analysis and visualization platform powered by generative AI. It simplifies data processing, offers customizable KPIs, intuitive visualizations, and facilitates the creation of consolidated reports.",
  // },

  {
    name: "Impact SDLC",
    icon: impact,
    url: routes.IMPACT_SDLC,
    videoSrc: videoUrls.impact,
    pdfSrc: pdfUrls.impact,
    awsVideo: true,
    tag: "",
    desc: "Manage different phases of software development lifecycle infused with power of Gen AI with an intuitive dashboard and manage different projects, tasks and users efficiently.",
  },
  // {
  //   name: "Virtual Travel Agent",
  //   icon: virtualtravel,
  //   url: routes.VIRTUAL_TRAVEL_AGENT,
  //   videoSrc: videoUrls.travelAgent ,
  //   pdfSrc: pdfUrls.travelAgent,
  //   awsVideo: true,
  //   tag: "ChatBot",
  //   desc: "This streamlit application simulates Virtual Travel Agent that interacts with users by answering travel-related questions and generate only travel related responses.",
  // },

  {
    name: "AWS Guardrails Travel Agent",
    icon: gta,
    url: routes.GUARDRAILS_TRAVEL_AGENT,
    videoSrc: videoUrls.travelAgent,
    pdfSrc: pdfUrls.travelAgent,
    awsVideo: true,
    tag: "ChatBot",
    desc: "This streamlit application simulates Aws Guardrails Travel Agent that interacts with users by answering travel-related questions and generate only travel related responses.",
  },
  {
    name: "Conversation Summarizer & Analyzer",
    icon: csa,
    url: routes.CONVERSATION_SUMMARIZER_AND_ANALYZER,
    videoSrc: videoUrls.csa,
    pdfSrc: pdfUrls.csa,
    awsVideo: true,
    tag: "ChatBot",
    desc: " Unlock insights from audio files with our AI-powered analysis tool. Seamlessly transcribe, summarize, analyze sentiment, and extract key issues to drive data-informed business decisions.",
  },
  {
    name: "Code Generator",
    icon: csa,
    url: routes.CODE_GENERATOR,
    videoSrc: videoUrls.code_generator,
    pdfSrc: pdfUrls.code_generator,
    awsVideo: true,
    tag: "ChatBot",
    desc: " Unlock insights from audio files with our AI-powered analysis tool. Seamlessly transcribe, summarize, analyze sentiment, and extract key issues to drive data-informed business decisions.",
  },
];
