import cardImg from "../../../assests/icon/Employee Assistance bot.svg";
import translationImg from "../../../assests/icon/Language Translation Bot.svg";
import salesdataImg from "../../../assests/icon/Sales Chatbot.svg";
import tableImg from "../../../assests/img/tableicon.png";
import QA from "../../../assests/img/Q&A.png";
import { routes } from "../../../util/constants";
import TextSumImg from "../../../assests/icon/Text Summarization.svg";
import genAiChatbotImg from "../../../assests/icon/Database Agents.svg";
import AuotmationImg from "../../../assests/icon/Automated Advanced Analytics.svg";
import dataanalImg from "../../../assests/icon/Data Analysis Bot.svg";
import resumeImg from "../../../assests/img/resume.jpg";

export const textGenerationData = [
  { name: "Employee Assistance", icon: cardImg, url: routes.EMPASSIS },
  { name: "Database Agents", icon: genAiChatbotImg, url: routes.OPENAICHATBOT },
  {
    name: "Language Translation Bot",
    icon: translationImg,
    url: routes.LANGUAGETRANSLATIOR,
  },
  // {
  //   name: "Automated Advanced Analytics",
  //   icon: AuotmationImg,
  //   url: routes.AUTOMATIONANALYTICS,
  // },
  {
    name: "Sales Chatbot",
    icon: salesdataImg,
    url: routes.SALESGPT,
  },
  {
    name: "Text Summarization",
    icon: TextSumImg,
    url: routes.TEXTSUMMARIZATION,
  },
  {
    name: "Youtube Video Q&A Bot",
    icon: QA,
    url: routes.YOUTUBEQABOT,
  },
  {
    name: "Data Analysis Bot",
    icon: dataanalImg,
    url: routes.DATAANALYSISINTEXT,
  },
  {
    name: "SDLC",
    icon: tableImg,
    url: routes.SDLC,
  },
  {
    name: "Ask HR",
    icon: resumeImg,
    url: routes.RESUME,
  },
  {
    name: "Banking Pal",
    icon: resumeImg,
    url: routes.BANKING_PAL,
  },
];
