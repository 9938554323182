import React, { useEffect, useState } from "react";
import { Card, Col, Empty, Row, Tag } from "antd";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";
import { routes } from "../../util/constants";
import empty from "../../assests/icon/empty.svg";
import VideoTour from "../video-guide";

export default function SearchResults(props) {
  const location = useLocation();

  useEffect(() => {
    document.title = "GenAI | Solutions";
  }, []);

  return (
    <div>
      <BreadCrumbs location={location} />
      <h6 style={{ fontSize: "1.25rem", fontWeight: "400", marginTop: "10px" }}>
        Showing results for:{" "}
        {props.location.state.value === "" ? "All" : props.location.state.value}
      </h6>
      {props.location.state.filteredItems.length ? (
        <Row gutter={[16, 24]} style={{ marginTop: "20px" }}>
          {props.location.state.filteredItems?.map((value, index) => (
            <Col key={index} sx={24} sm={24} md={12} xl={8} lg={8} xxl={8}>
              <Card style={{ borderRadius: "14px" }} className="category-card">
                <div style={{ display: "flex" }}>
                  <div>
                    <div>
                      <Tag
                        className="my-custom-tags"
                        color="#eaeaea"
                        style={{
                          color: "#000000",
                          fontSize: "10px",
                          padding: "0px 4px",
                        }}
                      >
                        {value.tag}
                      </Tag>
                    </div>
                    <img
                      style={{
                        marginRight: "10px",
                        height: "125px",
                        width: "125px",
                      }}
                      src={value.icon}
                      alt="cardImg"
                    />
                  </div>
                  <div>
                    {value.awsVideo ? (
                      <div
                        style={{
                          position: "absolute",
                          left: "90px",
                          top: "5px",
                          marginLeft:
                            value.tag === "Virtual Assistant" ? "14px" : null,
                        }}
                      >
                        <VideoTour
                          src={value.videoSrc}
                          type="video/mp4"
                          pdfSrc={value.pdfSrc}
                        />
                      </div>
                    ) : null}
                    <NavLink
                      // target={value.streamlit ? "_blank" : ""}
                      to={value.url ? value.url : routes.EMPASSIS}
                      disabled={value.url ? false : true}
                      style={{
                        textDecoration: "none",
                        fontSize: "0.9rem",
                      }}
                      state={{value}}
                    >
                      {value.name}{" "}
                    </NavLink>
                    <div style={{ fontSize: "0.75rem" }}>{value.desc}</div>
                  </div>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <Empty
          image={empty}
          imageStyle={{
            height: 60,
          }}
          description={<span>No data found</span>}
        ></Empty>
      )}
    </div>
  );
}
