import React, { useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Button,
  message,
  Input,
  Upload,
  Tabs,
  Select,
  Radio,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import { IP } from "../../../../dynamicIPs";
import Markdown from "react-markdown";
import BreadCrumbs from "../../../BreadCrumbs";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

const { Dragger } = Upload;

const AutomobileAI = () => {
  const location = useLocation();
  const [data, setData] = useState(``);
  const [files, setFiles] = useState([]);
  const [question, setQuestion] = useState("");
  const [queryResponseLoading, setQueryResponseLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [checkOption, setCheckOption] = useState("Custom Questions");

  const [imageUrls, setImageUrls] = useState([]);

  const handleTypeChange = (value) => {
    setQuestion(value);
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  const onCheckChange = ({ target: { value } }) => {
    setCheckOption(value);
    setQuestion("");
  };

  const propsForFileUpload = {
    name: "file",
    multiple: true,
    accept: "image/png,image/jpeg,image/jpg",
    maxCount: 1,
    // disabled: true,
    beforeUpload: (file) => {
      // callBeforeUpload(file);
      return false; // Prevent automatic file upload
    },
    onRemove: (file) => {
      const filterList = files.filter(
        (doc) => doc.uid !== file.originFileObj.uid
      );
      setFiles(filterList);
      setImageUrls([]);
    },
    onChange(info) {
      if (info.file.status === "removed") {
        message.info("File removed");
        setImageUrls([]);
      } else {
        setFiles([info.file]);
        const imgUrl = URL.createObjectURL(info.file);
        setImageUrls([imgUrl]);
        message.success("File uploaded successfully");
      }
    },
  };

  const handleQuerySubmit = async () => {
    setQueryResponseLoading(true);
    const url = `http://${IP.MEDI_AUTO_AI}:9002/automobile-ai`;
    const formData = new FormData();
    formData.append("input_query", question);
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    try {
      const res = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Success");
      setData(res.data);
      setQueryResponseLoading(false);
      setActiveKey("2");
    } catch (error) {
      setQueryResponseLoading(false);
      message.error(error.message);
    }
  };
  const checkBoxOptions = ["Custom Questions", "Predefined Questions"];
  const items = [
    {
      key: "1",
      label: "Automobile AI",
      children: (
        <>
          <Dragger {...propsForFileUpload}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click / drag and drop to upload file here
            </p>
            <p className="ant-upload-hint">
              Support for a single upload file * JPG, JPEG, PNG
            </p>
          </Dragger>
          {imageUrls.length > 0 ? (
            <div style={{ textAlign: "center" }}>
              <img
                src={imageUrls[0]}
                alt="Result Image"
                width={"300px"}
                height={"300px"}
              />
            </div>
          ) : null}
          <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
            <Col sx={24} sm={24} md={24} xl={24} lg={24} xxl={24}>
              <Radio.Group
                options={checkBoxOptions}
                onChange={onCheckChange}
                value={checkOption}
              />
            </Col>
            <Col sx={20} sm={20} md={20} xl={20} lg={20} xxl={20}>
              {checkOption === "Custom Questions" ? (
                <Input
                  placeholder="Enter specific details you're looking from in the image"
                  name="question"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  style={{
                    width: "100%",
                  }}
                />
              ) : (
                <Select
                  //   defaultValue={question}
                  style={{
                    width: "100%",
                  }}
                  onChange={handleTypeChange}
                  placeholder="Select predefined questions"
                  options={[
                    {
                      value: "What is beign displayed in the image?",
                      label: "What is beign displayed in the image?",
                    },
                    {
                      value: "It there a problem with the object in image?",
                      label: "It there a problem with the object in image?",
                    },
                    {
                      value:
                        "Mention the part if any is missing from the given image?",
                      label:
                        "Mention the part if any is missing from the given image?",
                    },
                    {
                      value:
                        "What kind of defects do you see in the image and how can I fix it?",
                      label:
                        "What kind of defects do you see in the image and how can I fix it?",
                    },
                    {
                      value: "Can you mention some vehicles from same brand?",
                      label: "Can you mention some vehicles from same brand?",
                    },
                  ]}
                />
              )}
            </Col>
            <Col sx={4} sm={4} md={4} xl={4} lg={4} xxl={4}>
              <Button
                type="primary"
                loading={queryResponseLoading}
                onClick={handleQuerySubmit}
                style={{ width: "100%" }}
                disabled={!files.length ? true : false}
              >
                Generate details
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Result",
      disabled: data !== "" ? false : true,
      children: <Markdown>{data}</Markdown>,
    },
  ];
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.AutomobAI} type="video/mp4" pdfSrc={pdfUrls.AutomobAI} />
      </div>
      <div style={{ marginTop: "20px" }}>
        <Card>
          <Tabs
            size="small"
            onChange={onChange}
            activeKey={activeKey}
            items={items}
          />
        </Card>
      </div>
    </>
  );
};

export default AutomobileAI;
