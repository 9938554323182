import { routes } from "../../../util/constants";
import cardImg from "../../../assests/img/emp-card-image.png";
import pptGen from "../../../assests/icon/AI-Powered Presentation Creation.svg";
import marketingcamp from "../../../assests/img/market-camp.png";
import advertisement from "../../../assests/icon/Advertisement Content Generator.svg";

export const ContentGenData = [
  // {
  //   name: "Personalized Marketing Campaign",
  //   url: routes.PERSONALIZESMARKETINGCAMPAIGN,
  //   icon: marketingcamp,
  // },
  {
    name: "AI Powered Presentation Creation",
    url: routes.PPTGENERATION,
    icon: pptGen,
  },
  {
    name: "Advertisement and Video Translation Generator",
    url: routes.ADVERTISEMENTCONTENTGEN,
    icon: advertisement,
  },
];
