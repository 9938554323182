import React, { useEffect, useRef, useState } from "react";
import { Button } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { DotLoading } from "antd-mobile";
import axios from "axios";
import FileUpload from "./FileUpload";
import { domain_url } from "../../../../dynamicIPs";
import Chatbot from "./Chatbot";
import BreadCrumbs from "../../../BreadCrumbs";
import { useLocation } from "react-router-dom";
import VideoTour from "../../../video-guide";

const UserManual = () => {
  const [files, setFiles] = useState([]);
  const [query, setQuery] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState(null);
  const [apiMessage, setApiMessage] = useState(null);
  const [msgData, setMsgData] = useState([]);
  const messageContainerRef = useRef(null);
  const [imgUrl, setImgUrl] = useState("");

  const [fillerMessages, setFillerMessages] = useState([
    "Hmm...let me think",
    "Generating Response!",
    "Here is the answer.",
  ]);
  const [currentMessage, setCurrentMessage] = useState("");
  const location = useLocation();
  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [userMessage, apiMessage]);

  const handleRecieveFiles = (files) => {
    console.log("filesssssssssss", files);
    setFiles(files);
  };

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setInputMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setMsgData([
      ...msgData,
      {
        text: query,
        isUser: true,
      },
      { text: <DotLoading />, isUser: false },
    ]);
    if (!query) return;
    setUserMessage(query);
    setInputMessage("");
    // setIsListening(false);
    setLoading(true);

    try {
      let url = `${domain_url}/user-manual/upload-file`;
      const formData = new FormData();
      formData.append("user_query", query);

      formData.append("file", files[0].originFileObj);

      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setMsgData([
        ...msgData,
        {
          text: query,
          isUser: true,
        },
        { text: response.data, isUser: false },
      ]);

      setQuery("");
      setLoading(false);
    } catch (error) {
      setUserMessage(query);
      setApiMessage(error.message);
      console.error("Error fetching data:", error);
      setMsgData([
        ...msgData,
        {
          text: query,
          isUser: true,
        },
        { text: error.message, isUser: false },
      ]);

      setLoading(false);
      setQuery("");
    }
  };

  return (
    <div
      style={{ margin: "0 40px 0 40px" }}
      className={msgData.length === 0 ? "user-manual-container" : ""}
    >
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto", float: "right" }}>
        <VideoTour
          src="https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/usermanual_usecase_recording.mp4"
          type="video/mp4"
          pdfSrc="https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/user_manual.pdf"
        />
      </div>
      <p style={{ fontSize: "16px", marginTop: "20px" }}>
        <b>Upload A Word Document</b>
      </p>
      <FileUpload
        handleRecieveFiles={handleRecieveFiles}
        height={msgData.length > 0 ? "20vh" : "50vh"}
      />

      <div className="user-manual-chatbot" style={{ marginTop: "10px" }}>
        <div
          ref={messageContainerRef}
          style={{
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          {msgData.map((message, index) => (
            <Chatbot key={index} text={message.text} isUser={message.isUser} />
          ))}
        </div>
        {/* {(msgData.length > 0 || loading) && (
          <div
            className=" messages-user-manual"
            ref={messageContainerRef}
            style={{ minHeight: "375px", maxHeight: "500px" }}
          >
            {msgData?.map((message, index) => (
              <div
                key={index}
                className={`message ${message.isUser ? "user" : "api"}`}
              >
                <Markdown>{message.text}</Markdown>
              </div>
            ))}
            {loading ? (
              <>
                <div className="user message">{query}</div>
                <div className="message api">
                  <DotLoading />
                </div>
              </>
            ) : null}
          </div>
        )} */}
        {/* <hr style={{ margin: "0px" }} /> */}
        <div className="text-area" style={{ marginTop: "10px" }}>
          <form
            onSubmit={handleSubmit}
            className="input-container-user-manual"
            style={{ width: "100%" }}
          >
            <input
              disabled={loading}
              type="text"
              value={inputMessage}
              className="input-user-manual"
              onChange={handleInputChange}
              placeholder="Type a new question..."
            />

            {loading ? (
              <></>
            ) : (
              <>
                <Button
                  style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                    alignSelf: "center",
                    color: "black",
                  }}
                  type="primary"
                  icon={<SendOutlined />}
                  size="small"
                  onClick={handleSubmit}
                />
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserManual;
