import React, { useState, useRef } from "react";
import BreadCrumbs from "../../../../BreadCrumbs";
import { useLocation } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Button,
  message,
  Input,
  Upload,
  Tabs,
  Select,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import axios from "axios";
import { IP } from "../../../../../dynamicIPs";
import VideoTour from "../../../../video-guide";
import { domain_url } from "../../../../../dynamicIPs";
import Markdown from "react-markdown";

const { Dragger } = Upload;

const ResumeSummarizer = () => {
  const location = useLocation();
  const [data, setData] = useState("");
  const [files, setFiles] = useState([]);
  const [summaryType, setSummaryType] = useState("Summary-1");
  const [model, setModel] = useState("GPT35");
  const [queryResponseLoading, setQueryResponseLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("1");

  const handleTypeChange = (value) => {
    setSummaryType(value);
  };
  const handleModelChange = (value) => {
    setModel(value);
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  const propsForFileUpload = {
    name: "file",
    multiple: true,
    accept: "application/pdf",
    // disabled: true,
    beforeUpload: (file) => {
      // callBeforeUpload(file);
      return false; // Prevent automatic file upload
    },
    onRemove: (file) => {
      const filterList = files.filter(
        (doc) => doc.uid !== file.originFileObj.uid
      );
      setFiles(filterList);
    },
    onChange(info) {
      if (info.file.status === "removed") {
        message.info("File removed");
      } else {
        setFiles(info.fileList);
        message.success("File uploaded successfully");
      }
    },

    // onDrop(e) {
    //   if (e.dataTransfer.files[0].type === "application/pdf") {
    //     setFiles([...files, e.dataTransfer.files[0]]);
    //   }
    // },
  };

  function IsJsonString(str) {
    try {
      var json = JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  const handleQuerySubmit = async () => {
    setData("");
    setQueryResponseLoading(true);

    const url = `${domain_url}/summarization/resume-summarization`;
    // const url="http://18.199.250.92:8005/summarization/resume-summarization"
    const formData = new FormData();
    formData.append("select_summary_type", summaryType);
    formData.append("select_model", model);

    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i].originFileObj);
    }
    try {
      const response = await fetch(url, {
        method: "POST",

        body: formData,
      });
      if (!response.ok || !response.body) {
        throw response.statusText;
      }
      // Here we start prepping for the streaming response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;

      while (loopRunner) {
        // Here we start reading the stream, until its done.
        const { done, value } = await reader.read();
        console.log("valueeeeeeeeeeeeee", value);
        if (done) {
          console.log("inside done");
          // setIsTextEnd(true);
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        console.log("realdecodechunk-------------", decodedChunk);
        let data = "";
        if (IsJsonString(decodedChunk)) {
          data = JSON.parse(decodedChunk);
          data = data.replace(/\n/g, "  \n");
          console.log("inside if", true);
        } else {
          data = data.replace(/"/g, "'");
          console.log("inside else", false);
        }

        // console.log("dataaaaaaaaaaaa",data);

        setData((answer) => answer + data);
        setQueryResponseLoading(false);
        setActiveKey("2");
      }
    } catch (error) {
      console.log("error", error);
      setQueryResponseLoading(false);

      message.error(error);
    }

    // try {
    //   const res = await axios.post(url, formData, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    //   message.success("Resume summarized successfully");
    //   setData(res.data);
    //   setQueryResponseLoading(false);
    //   setActiveKey("2");
    // } catch (error) {
    //   setQueryResponseLoading(false);
    //   message.error(error.message);
    // }
  };

  const items = [
    {
      key: "1",
      label: "Resume Summarizar",
      children: (
        <>
          <Dragger {...propsForFileUpload}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click / drag and drop to upload files here
            </p>
            <p className="ant-upload-hint">
              Support for a single or bulk upload files of extention .pdf
            </p>
          </Dragger>

          <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
            <Col sx={24} sm={24} md={12} xl={12} lg={12} xxl={12}>
              <Select
                defaultValue={summaryType}
                style={{
                  width: "100%",
                }}
                onChange={handleTypeChange}
                options={[
                  {
                    value: "Summary-1",
                    label: "Summary-1",
                  },
                  {
                    value: "Summary-2",
                    label: "Summary-2",
                  },
                  {
                    value: "Summary-3",
                    label: "Summary-3",
                  },
                ]}
              />
            </Col>
            <Col sx={24} sm={24} md={12} xl={12} lg={12} xxl={12}>
              <Select
                defaultValue={model}
                style={{
                  width: "100%",
                }}
                onChange={handleModelChange}
                options={[
                  {
                    value: "GPT35",
                    label: "GPT35",
                  },
                  {
                    value: "GPT4",
                    label: "GPT4",
                  },
                ]}
              />
            </Col>
            <Col span={24} style={{ marginTop: "20px" }}>
              <Button
                type="primary"
                loading={queryResponseLoading}
                onClick={handleQuerySubmit}
                style={{ width: "100%" }}
                disabled={!files.length ? true : false}
              >
                Summazrize Resume
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Summarized Result",
      disabled: data.length > 0 ? false : true,
      children: (
        <>
          <Markdown>{data}</Markdown>
          {/* {data.map((summary) => {
            return (
              <div>
                <h6>{summary.header}</h6>
                <div>
                  <h6 style={{ fontSize: "0.9rem", fontWeight: "400" }}>
                    {summary.response.split("\n").map((ele, index) => (
                      <div style={{ margin: "0 0 10px 0" }}> {ele}</div>
                    ))}
                  </h6>
                  <hr />
                </div>
              </div>
            );
          })} */}
        </>
      ),
    },
  ];
  return (
    <>
      <BreadCrumbs location={location} />

      <div style={{ marginTop: "20px" }}>
        <Card>
          <Tabs
            size="small"
            onChange={onChange}
            activeKey={activeKey}
            items={items}
          />
        </Card>
      </div>
    </>
  );
};

export default ResumeSummarizer;
