import React from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import CaptureImage from "./CaptureImage/CaptureImage";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

export default function ImageEnhancement() {
  const location = useLocation();
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.image_editor_recording} type="video/mp4" pdfSrc={pdfUrls.image_editor_prompt} />
      </div>
      <div style={{ marginTop: "30px" }}>
        <CaptureImage />
      </div>
    </>
  );
}
