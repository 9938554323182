import { routes, streamlitRoutes } from "../../../util/constants";
import ibc from "../../../assests/img/ibc-assistant.jpg";
import salesLeadautomation from "../../../assests/img/salesLeadautomation.png";
import trip from "../../../assests/img/Trip.jpg";
import usermanualbot from "../../../assests/img/user-manual-bot.jpg";
import kyc from "../../../assests/img/KYC.jpg";
import idp from "../../../assests/img/idp.jpg";
import legal from "../../../assests/img/Legal.jpg";
import hbf from "../../../assests/img/hbf.jpg";
import langchain from "../../../assests/img/langchain.jpg";
import autovisual from "../../../assests/img/autovisual.jpg";
import { pdfUrls } from "../../../util/pdfData";
import { videoUrls } from "../../../util/videoData";

export const operationsData = [
  {
    name: "Banking Pal",
    icon: ibc,
    url: routes.BANKING_PAL,

    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/ibc_app_record_90sec+1.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Banking_pal_sample_questions.pdf",
    awsVideo: true,
    tag: "Chatbot",
    desc: "A gen AI-based solution to assist bank employees in answering questions related to bank processes, procedures etc.",
  },
  {
    name: "Custom Persona GPT",
    icon: hbf,
    url: routes.HBF,

    videoSrc: videoUrls.custom_persona,
    pdfSrc: pdfUrls.persona_pdf,
    awsVideo: true,
    tag: "Chatbot",
    desc: "Secure AI-powered chatbot tailored to your private data. Seamlessly integrates with SharePoint and other data sources. Enforces granular access controls, ensuring users only retrieve information they're authorized to see.",
  },
  // {
  //   name: "Banking Pal",
  //   icon: ibc,
  //   url: routes.BANKING_PAL,
  //   isAws: true,
  //   videoSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Banking_pal.mp4",
  //   pdfSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Banking_pal_sample_questions.pdf",
  //   awsVideo: true,
  //   tag: "Chatbot",
  //   desc: "A gen AI-based solution to assist bank employees in answering questions related to bank processes, procedures etc.",
  // },
  {
    name: "User Manual Bot",
    icon: usermanualbot,
    url: routes.USER_MANUAL,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/usermanual_usecase_recording.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/user_manual.pdf",
    awsVideo: true,
    // streamlit: true,
    tag: "Chatbot",
    desc: "Text with image responses for sales manual,stepwise how to guides for machine manuals.",
  },
  {
    name: "Legal Doc Summarization",
    icon: legal,
    url: routes.LEGAL_DOC_SUMMARIZATION,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Legal%20Doc%20Summarizer.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/legaldoc1.pdf",
    awsVideo: true,
    // streamlit: true,
    tag: "Chatbot",
    desc: "The Legal Document Summarizer using RaptorRAG leverages LLMs to generate concise, contextually accurate summaries of lengthy legal documents, streamlining legal reviews and boosting productivity.",
  },
  // {
  //   name: "E-Kyc",
  //   icon: kyc,
  //   url: streamlitRoutes.E_KYC,
  //   streamlit: true,
  //   videoSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/KYC-Recording.mp4",
  //   pdfSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/IDP-Prompts.pdf",
  //   awsVideo: true,
  //   tag: "Productivity",
  //   desc: "Get your customers onboarded and make your Sign Up process more secure than just a verification code. Keep Aadhaar, PAN handy and know your customer in seconds.",
  // },
  // {
  //   name: "IDP Processor",
  //   icon: idp,
  //   // url: streamlitRoutes.PEZT_AVATAR_GENERATION,
  //   // streamlit: true,
  //   videoSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/IDP.mp4",
  //   pdfSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/IDP-Prompts.pdf",
  //   awsVideo: true,
  //   tag: "MISC",
  //   desc: "Intelligent Document Processing for insurance and public sector",
  // },

  {
    name: "Langchain SQL Agent",
    icon: langchain,
    url: routes.LANGCHAIN_SQL_AGENT,
    // streamlit: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/LangchainSQLAgent-usecase.mp4",
    pdfSrc: pdfUrls.langchain,
    awsVideo: true,
    tag: "MISC",
    desc: "A collaborative multi-agent based system that assists in providing scores to the potential leads based on the data provided thus helping in sales conversions.",
  },

  {
    name: "Auto Visualization",
    icon: autovisual,
    url: routes.AUTO_VISUALIZATION,
    // streamlit: true,
    videoSrc: videoUrls.auto_visualization,
    pdfSrc: pdfUrls.auto_visualization,
    awsVideo: true,
    tag: "MISC",
    desc: "Business & IT Operations Network Dashboard, powered by LLM, is a comprehensive tool designed for the automatic generation of visualizations and infographics. It seamlessly integrates with various software systems, including Worksoft CTM and Checkmarx, to provide detailed insights and analytics.",
  },
  {
    name: "Sales Lead Automation",
    icon: salesLeadautomation,
    url: routes.SALES_LEAD_AUTOMATION,
    // streamlit: true,
    videoSrc: videoUrls.sales_lead_automation,
    pdfSrc: pdfUrls.sales,
    awsVideo: true,
    tag: "Agent",
    desc: "An AI powered assistant that provides status on order tracking to the customers and recent updates.",
  },
  {
    name: "Agentic Trip Advisor",
    icon: trip,
    url: routes.AGENTIC_TRIP_ADVISOR,
    // streamlit: true,
    videoSrc: videoUrls.trip_planner,
    pdfSrc: pdfUrls.trip,
    awsVideo: true,
    tag: "Agent",
    desc: "Discover flights, hotels, attractions, upcoming events, trip advisories and more. Book flights effortlessly and explore your destination's highlights in one convenient place.",
  },
];
