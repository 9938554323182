import React, { useState } from "react";
import { Drawer } from "antd";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { domainsList } from "../../util/domainData";
// import logo from "../../assests/img/open-ai.png";

export default function SiderDrawerPhone() {
  const [visible, setVisible] = useState(false);
  const [placement] = useState("left");
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  return (
    <span>
      <MenuUnfoldOutlined onClick={showDrawer} className="trigger-phone" />
      <Drawer
        placement={placement}
        closable={false}
        onClose={onClose}
        open={visible}
        key={placement}
      >
        <ul className="navbar-links">
          {domainsList.map((domain, index) => {
            return (
              <li key={index} className="navbar-dropdown">
                <Link to={domain.url}>{domain.name}</Link>
                <div className="dropdown">
                  {domain.categories.map((category, index) => {
                    return (
                      <Link key={index} to={category.url}>
                        {/** <img
                      style={{
                        height: "25px",
                        width: "25px",
                      }}
                      src={category.icon}
                      alt="cardImg"
                    /> */}
                        {category.name}
                      </Link>
                    );
                  })}
                </div>
              </li>
            );
          })}
        </ul>
      </Drawer>
    </span>
  );
}
