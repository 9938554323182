import { Input } from "antd";
import React, { useEffect, useState } from "react";
import { financeData } from "../domains/finance/FinanceData";
import { hrData } from "../domains/human-resource/HumanResourceData";
import { ITData } from "../domains/IT/ITData";
import { operationsData } from "../domains/operations/OperationsData";
import { othersData } from "../domains/others/OthersData";
import { salesAndMarketingData } from "../domains/sales and marketing/SalesAndMarketingData";
import { useNavigate } from "react-router-dom";
import { routes } from "../../util/constants";
import { resumeData } from "../AllPoc/TextGeneration/Resume/resumeData";

const { Search } = Input;

function SearchItems(props) {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [searchItem, setSearchItem] = useState("");

  useEffect(() => {
    const combinedData = [
      ...financeData,
      ...hrData,
      ...ITData,
      ...operationsData,
      ...othersData,
      ...salesAndMarketingData,
      ...resumeData,
    ];
    setData(combinedData);
  }, []);

  const removeDuplicates = (arr) => {
    const uniqueSet = new Set();
    const uniqueArr = [];
    for (const item of arr) {
      const key = JSON.stringify(item); // Define a unique key for comparison
      if (!uniqueSet.has(key)) {
        uniqueSet.add(key);
        uniqueArr.push(item);
      }
    }
    return uniqueArr;
  };

  const onSearch = (value, _e, info) => {
    // console.log(info?.source, value);
    setSearchItem("");

    const filteredArray = data.filter(
      (data) =>
        data.name.toLowerCase().includes(value.toLowerCase()) ||
        data.tag.toLowerCase().includes(value.toLowerCase())
    );
    const filteredItems = removeDuplicates(filteredArray);

    setFilteredData(filteredItems);
    navigate(`${routes.SEARCH}?query=${value === "" ? "all" : value}`, {
      state: { filteredItems, value },
    });
  };
  return (
    <div style={{ margin: "13px 0 0 auto" }}>
      <Search
        placeholder="Search by Name / Tag"
        onSearch={onSearch}
        onChange={(e) => setSearchItem(e.target.value)}
        value={searchItem}
        enterButton
        
      />
    </div>
  );
}

export default SearchItems;
