import React from "react";
import { domainRoutes, routes } from "./util/constants";
import { Route, Routes, useLocation } from "react-router-dom";
import AdminDashboard from "./ui-components/dashboard/AdminDashboard";
import Home from "./ui-components/Home";

export default function NavRoutes() {
  let location = useLocation();
  return (
    <Routes location={location}>
      <Route exact path={routes.HOME} element={<Home />} />

      <Route
        exact
        path={routes.CHATBOT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.ASK_HR}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.DATABASE_AGENTS}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.USER_MANUAL}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.LEGAL_DOC_SUMMARIZATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.HBF}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.AI_POWERED_PRESENTATION_CREATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.ADVERTISEMENT_AND_VIDEO_TRANSLATION_GENERATOR}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.LANGUAGE_TRANSLATION_BOT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.AUTOMATIONANALYTICS}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.DATA_ANALYSIS_BOT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.SALES_CHATBOT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.VOICE_CLONING_AND_LIP_SYNCHRONIZATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.MUSIC_GENERATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.TEXTGENERATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.IMAGEGENERATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.VIDEOGENERATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.CODEGENERATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.CONTENTGENERATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.AUDIOGENERATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.MUSICCOMPOSITION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.POSE_MIMIC}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.VIRTUALTRYON}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.SPEECHTOIMAGE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.IMAGE_GENERATION_FOR_HEALTHCARE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.CHARACTER_CREATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.IMAGE_EDITING}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.VIRTUALTRYON}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.TEXT_SUMMARIZATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.TABULARDATAGEN}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.YOUTUBE_VIDEO_QA_BOT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.TABULARDATAGEN}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.CODEGENUSINGGCP}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.DATAANALYSISINCODEGEN}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.PERSONALIZESMARKETINGCAMPAIGN}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.ADVERTISEMENT_AND_VIDEO_TRANSLATION_GENERATOR}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.DISNEY_POSE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.REALSTIC_POSE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.OPENAICHATBOTFORCODE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.AUTOMATIONANALYTICSINCODE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.VOICE_CLONING_AND_LIP_SYNCHRONIZATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.DATAANALYSISINTEXT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.SDLC}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.RESUME_SCREENING_ASSISTANCE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.POWERED_RESUME_RETRIEVER}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.POWERED_RESUME_SUMMARIZAR}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.RESUME_GENIE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.BANKING_PAL}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.LANGCHAIN_SQL_AGENT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.SALES_LEAD_AUTOMATION}
        element={<AdminDashboard location={location} />}
      />

      <Route
        exact
        path={routes.AUTO_VISUALIZATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.PRODUCT_RECOMMENDATION}
        element={<AdminDashboard location={location} />}
      />

      <Route
        exact
        path={routes.AGENTIC_TRIP_ADVISOR}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.VISUALIZATION}
        element={<AdminDashboard location={location} />}
      />
      {/**new routes below*/}
      <Route
        exact
        path={domainRoutes.HUMAN_RESOURCE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={domainRoutes.OPERATIONS}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={domainRoutes.SALES_AND_MARKETING}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={domainRoutes.IT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={domainRoutes.FINANCE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={domainRoutes.OTHERS}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.SEARCH}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.PHARMA_HELPER}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.AUTOMOBILE_AI}
        element={<AdminDashboard location={location} />}
      />

      <Route
        exact
        path={routes.MULTI_LLM_ASSISTANT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.SMART_HIRE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.GMAIL_GENAI_TOOLKIT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.FINANCIAL_REPORT_ANALYZER}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.DIGITAL_CATALOGUE}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.BILLS_ANALYZER}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.CONTRACT_ANALYZER}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.SDLC_AI}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.EXECUTOR_AI}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.DATA_ANALYTICS_DASHBOARD}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.MANUFACTURING_CATALYST}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.QUEUE_ANALYZER}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.IMAGE_CAMPAIGN_CREATION}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.ENTERPRISE_CHATBOT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.IMPACT_SDLC}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.INTELLIGENT_DOCUMENT_PROCESSING}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={ routes.GUARDRAILS_TRAVEL_AGENT}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.CONVERSATION_SUMMARIZER_AND_ANALYZER}
        element={<AdminDashboard location={location} />}
      />

      <Route
        exact
        path={routes.AMS_TICKET_TOOL}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.AWSQ}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.CSR}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.NUTRITION_AND_FITNESS_ADVISOR}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.CODE_GENERATOR}
        element={<AdminDashboard location={location} />}
      />
      <Route
        exact
        path={routes.LIVE_POTRAIT}
        element={<AdminDashboard location={location} />}
      />
    </Routes>
  );
}
