// import React from 'react'

// export default function TextToMelody() {
//     return (
//         <div>TextToMelody</div>
//     )
// }
// PianoPayer
import React from "react";

import ReactDOM from "react-dom";

import _ from "lodash";

import { Piano, KeyboardShortcuts, MidiNumbers } from "react-piano";

import "react-piano/dist/styles.css";

import { Col, Row, Button, Input, message, Spin } from "antd";
import MidiWriter from "midi-writer-js";
import ProgressBar from "@ramonak/react-progress-bar";
import { renderMidi } from "abcjs"; // Import renderMidi from abcjs/midi
import playButton from "../../../../assests/img/play.png";
import axios from "axios";

const audioContext = new (window.AudioContext || window.webkitAudioContext)();

const soundfontHostname = "https://d1pzp51pvbm36p.cloudfront.net";

const noteRange = {
  first: MidiNumbers.fromNote("c3"),

  last: MidiNumbers.fromNote("f4"),
};

const keyboardShortcuts = KeyboardShortcuts.create({
  firstNote: noteRange.first,

  lastNote: noteRange.last,

  keyboardConfig: KeyboardShortcuts.HOME_ROW,
});

class TextToMelody extends React.Component {
  state = {
    recording: {
      mode: "RECORDING",
      events: [],
      currentTime: 0,
      currentEvents: [],
      isPlaying: false,
      progress: 0,
      abcNotation: "",
    },
    currentlyPlaying: null,
    display: "",
    isPlayingBirthday: false,
    keyword: "",
    period: 0,
    isQueryEntered: false,
    isMelodyLoading: false,
  };

  playGeneratedMusic = () => {
    // Use renderMidi to render and play the generated music

    const midiData = renderMidi(
      this.state.abcNotation,
      {},
      { generateDownload: true }
    );

    const audio = new Audio(midiData[0].midiUrl);

    audio.play();

    this.setState({ isPlaying: true });
  };

  handleInputChange = (e) => {
    this.setState({ abcNotation: e.target.value });
  };

  getRecordingEndTime = () => {
    if (this.state.recording.events.length === 0) {
      return 0;
    }
    return Math.max(
      ...this.state.recording.events.map((event) => event.time + event.duration)
    );
  };

  setRecording = (value) => {
    this.setState({
      recording: Object.assign({}, this.state.recording, value),
    });
  };

  onClickPlay = () => {
    this.setRecording({
      mode: "PLAYING",
    });
    // Calculate the total duration of the recording
    const totalDuration = this.getRecordingEndTime() * 1000;
    // Start playing and update progress
    this.playAndTrackProgress(totalDuration);

    const startAndEndTimes = _.uniq(
      _.flatMap(this.state.recording.events, (event) => [
        event.time,
        event.time + event.duration,
      ])
    );
    startAndEndTimes.forEach((time) => {
      this.scheduledEvents.push(
        setTimeout(() => {
          const currentEvents = this.state.recording.events.filter((event) => {
            return event.time <= time && event.time + event.duration > time;
          });
          this.setRecording({
            currentEvents,
          });
        }, time * 1000)
      );
    });

    // Stop at the end
    setTimeout(() => {
      this.onClickStop();
    }, this.getRecordingEndTime() * 1000);
  };

  playAndTrackProgress = (totalDuration) => {
    const startTime = Date.now();

    let lastUpdateTime = startTime;

    const updateProgress = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      const newProgress = (elapsed / totalDuration) * 100;
      this.setState({ progress: newProgress });
      // If not finished, schedule the next update
      if (newProgress < 100) {
        const deltaTime = currentTime - lastUpdateTime;
        const delay = Math.max(50, 100 - deltaTime); // Limit updates to every 100ms
        lastUpdateTime = currentTime;
        setTimeout(updateProgress, delay);
      } else {
        // Finished playing
        this.onClickStop();
      }
    };
    // Start updating progress
    updateProgress();
  };

  onClickStop = () => {
    this.scheduledEvents.forEach((scheduledEvent) => {
      clearTimeout(scheduledEvent);
    });
    this.setRecording({
      mode: "RECORDING",
      currentEvents: [],
    });
    this.setState({ progress: 0 }); // Reset progress
  };

  onClickClear = () => {
    this.onClickStop();
    this.setRecording({
      events: [],
      mode: "RECORDING",
      currentEvents: [],
      currentTime: 0,
    });
  };

  generateContinueMelody = async () => {
    if (this.state.period < 1 || this.state.keyword.length < 1) {
      message.error("Please enter valid duration and keyword!");
      return;
    }
    this.setState({
      isQueryEntered: true,
    });
  };

  onClickPlay = () => {};

  render() {
    return (
      <div className="music-tab">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "50%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <p>Enter duration to continue melody</p>

                  <Input
                    style={{ borderRadius: "10px" }}
                    type="number"
                    min={1}
                    max={60}
                    value={this.state.period}
                    placeholder="Enter duration to continue melody"
                    onChange={(e) => {
                      this.setState({
                        period: e.target.value,
                      });
                    }}
                  />
                </div>
                <div
                  style={{
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <p>Enter keyword to generate melody</p>

                  <Input
                    style={{ borderRadius: "10px" }}
                    type="text"
                    placeholder="Enter keyword to generate melody"
                    value={this.state.keyword}
                    onChange={(e) => {
                      this.setState({
                        keyword: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <Button
                type="primary"
                // onClick={this.exportMidiFile}
                onClick={this.generateContinueMelody}
                // onClick={()=>{
                //   this.getData(happybirthday,(data)=>{
                //     console.log("happy-birthday-base64",data)
                //   });
                // }}
                style={{ marginTop: "10px", width: "50%" }}
              >
                Generate Music
              </Button>
            </div>
          </div>

          {!this.state.isQueryEntered ? (
            <></>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Play Generated Melody Continuation</p>
              {/* <img
                src={playButton}
                // onClick={this.onClickPlay}
                width="105px"
                height="105px"
                style={{
                  marginTop: "-20px",
                  cursor: "pointer",
                }}
              /> */}
              <audio
                src={`http://3.124.170.239
                :8009/text-to-music?query_string=${this.state.keyword}&period=${this.state.period}`}
                controls
                onLoadStart={() => {
                  this.setState({
                    isMelodyLoading: true,
                  });
                }}
                onCanPlay={() => {
                  this.setState({
                    isMelodyLoading: false,
                  });
                }}
              />
              <Spin
                tip="Melody is loading..."
                spinning={this.state.isMelodyLoading}
                style={{ marginTop: "10px" }}
              />
              <div style={{ color: "#4693ff" }}>
                {this.state.isMelodyLoading
                  ? "Melody is loading..."
                  : "Melody is ready to play!"}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TextToMelody;
