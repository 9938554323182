import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import {
  Badge,
  Button,
  Card,
  Col,
  Input,
  Row,
  Select,
  Tooltip,
  message,
} from "antd";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import axios from "axios";
import { DeleteOutlined, EyeFilled, PlusOutlined } from "@ant-design/icons";
import { IP } from "../../../dynamicIPs";
const { Option } = Select;
const languageArray = [
  "C",
  "C++",
  "Java",
  "Ruby",
  "Scala",
  "C#",
  "Objective C",
  "Swift",
  "JavaScript",
  "Kotlin",
  "Python",
  "GO Lang",
];
export default function DataAnalysis() {
  const messageContainerRef = useRef();
  const location = useLocation();
  const [selectApp, setSelectApp] = useState("Code Generator");
  const [query, setQuery] = useState("");
  const [queryData, setQueryData] = useState("");
  const [queryResponse, setQueryResponse] = useState(false);
  const [isViewCsvFile, setIsViewCsvFile] = useState(false);

  const [queryResponseLoading, setQueryResponseLoading] = useState(false);
  const [selectFile, setSelectedFile] = useState([]);
  const [csvFile, setCsvFile] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [codeResponse, setCodeResponse] = useState([]);
  const [plotResponse, setPlotResponse] = useState(false);
  const [plotPath, setPlotPath] = useState("");
  const [fetchPlotUrl, setFetchPlotUrl] = useState(null);
  const handleViewCsvFile = () => {
    setIsViewCsvFile(true);
  };
  const handleBackCsv = () => {
    setIsViewCsvFile(false);
  };
  const removeUploadCsvFile = (filename) => {
    const filterList = csvFile.filter((file) => file.name !== filename);
    setCsvFile(filterList);
    if (csvFile.length === 1) {
      setIsViewCsvFile(false);
    }
  };

  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [query, queryResponse]);
  const handleAppChange = (e) => {
    setCodeResponse([]);
    setSelectApp(e.target.value);
    setPlotResponse(false);
  };
  const handleQueryChange = (e) => {
    setQuery(e.target.value);
    setQueryData(e.target.value);
  };
  const handleSelectLang = (language) => {
    setSelectedLanguage(language);
  };
  const handleCsvFileUpload = (file) => {
    const selectedFile = file.target.files[0];
    if (file.target.files[0].type === "text/csv") {
      setCsvFile([...csvFile, selectedFile]);
      message.success("file uploaded successfully");
    } else {
      message.error("please upload csv file");
    }
  };
  const handleQuerySubmit = async () => {
    setQueryResponseLoading(true);
    setPlotResponse(false);

    setCodeResponse([]);
    // setQuery("");
    setSelectedLanguage("");
    const url = `http://${IP.GENAI}:8006/code-generation-and-data-analysis`;
    const formData = new FormData();
    if (selectApp === "Code Generator") {
      formData.append("work", "code");
      formData.append("code_language", selectedLanguage);
      formData.append("code_prompt", query);
    } else if (selectApp === "Data Analysis") {
      formData.append("work", "dummy");
      formData.append("code_prompt", query);
      for (let i = 0; i < csvFile.length; i++) {
        formData.append("file", csvFile[i]);
      }
    } else {
      formData.append("work", "plot");
      formData.append("code_prompt", query);
      for (let i = 0; i < csvFile.length; i++) {
        formData.append("file", csvFile[i]);
      }
      const queryString = new URLSearchParams(formData).toString();
      setFetchPlotUrl(queryString);
    }
    try {
      const res = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setQueryResponseLoading(false);
      if (selectApp === "Code Generator") {
        setCodeResponse(res.data.code_topic);
      } else if (selectApp === "Data Analysis") {
        setCodeResponse(res.data.output);
      } else {
        setPlotResponse(true);
        setPlotPath(res.data);
      }
    } catch (error) {
      setQueryResponseLoading(false);
      message.error(error.message);
    }
  };

  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ marginTop: "20px" }}>
        <Card>
          <b>
            <p>Choose the Application:</p>
          </b>
          <Row gutter={[8, 8]}>
            <Col sx={24} sm={24} md={8} xl={8} lg={8} xxl={8}>
              <Card style={{ borderRadius: "10px" }}>
                <label style={{ padding: "10px" }}>
                  <input
                    type="radio"
                    value="Code Generator"
                    checked={selectApp === "Code Generator"}
                    onChange={handleAppChange}
                  />
                  <span style={{ marginLeft: "10px" }}>Code Generator</span>
                </label>
              </Card>
            </Col>
            <Col sx={24} sm={24} md={8} xl={8} lg={8} xxl={8}>
              <Card style={{ borderRadius: "10px" }}>
                <label style={{ padding: "10px" }}>
                  <input
                    type="radio"
                    value="Data Analysis"
                    checked={selectApp === "Data Analysis"}
                    onChange={handleAppChange}
                  />
                  <span style={{ marginLeft: "10px" }}>Data Analysis</span>
                </label>
              </Card>
            </Col>
            <Col sx={24} sm={24} md={8} xl={8} lg={8} xxl={8}>
              <Card style={{ borderRadius: "10px" }}>
                <label style={{ padding: "10px" }}>
                  <input
                    type="radio"
                    value="Chat to Plot"
                    checked={selectApp === "Chat to Plot"}
                    onChange={handleAppChange}
                  />
                  <span style={{ marginLeft: "10px" }}>Chat to Plot</span>
                </label>
              </Card>
            </Col>
          </Row>
        </Card>
        {selectApp === "Data Analysis" || selectApp === "Chat to Plot" ? (
          <div className="text-center">
            <Card style={{ marginTop: "20px" }}>
              {isViewCsvFile ? (
                <div>
                  {csvFile.map((file, index) => (
                    <Row gutter={[8, 8]} justify="center" align="middle">
                      <Col xs={20} sm={20} md={20} lg={20} xl={20}>
                        <label>
                          <h4 key={index}>{file.name}</h4>
                        </label>
                      </Col>
                      <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Button
                          onClick={() => removeUploadCsvFile(file.name)}
                          danger
                        >
                          <DeleteOutlined />
                        </Button>
                      </Col>
                    </Row>
                  ))}
                </div>
              ) : (
                <div className="text-center">
                  <label
                    for="csvInputFile"
                    style={{
                      fontSize: "25px",
                      textAlign: "-webkit-center",
                    }}
                  >
                    <div className="drag-area ">
                      <h6>Upload</h6> <PlusOutlined />
                      <input
                        className="form-control"
                        type="file"
                        id="csvInputFile"
                        // name="inputFile"
                        // value={inputFile}
                        multiple
                        onChange={handleCsvFileUpload}
                        hidden
                        accept="image/png, image/jpeg,image/jpg,application/pdf,video/mp4,application/csv"
                      />
                    </div>
                    {csvFile.length > 0 ? (
                      <Tooltip title="View Uploaded File" placement="bottom">
                        <Badge count={csvFile.length} size="small">
                          <EyeFilled
                            onClick={handleViewCsvFile}
                            style={{ fontSize: "25px" }}
                          />
                        </Badge>
                      </Tooltip>
                    ) : null}
                  </label>
                </div>
              )}
            </Card>
          </div>
        ) : (
          <Card style={{ marginTop: "20px" }}>
            <b>
              <p>Select Programming Language:</p>
            </b>
            <Select
              placeholder={
                <span style={{ color: "black" }}>Select Language</span>
              }
              onChange={handleSelectLang}
              className="dropdown-child"
              value={selectedLanguage}
              style={{
                width: "100%",
                textAlign: "center",
                color: "black",
              }}
            >
              {languageArray.map((item, index) => (
                <Option
                  key={index}
                  value={item}
                  style={{ textAlign: "center" }}
                >
                  {item}
                </Option>
              ))}
            </Select>
          </Card>
        )}
        <Card style={{ marginTop: "20px", marginBottom: "15px" }}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <b>
                <p>Ask me a question:</p>
              </b>
              <Input type="text" value={query} onChange={handleQueryChange} />
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{ marginTop: "30px" }}>
              <Button
                type="primary"
                loading={queryResponseLoading}
                onClick={handleQuerySubmit}
                style={{ width: "100%", borderRadius: "10px" }}
              >
                Submit
              </Button>
            </Col>
          </Row>
          {plotResponse ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={`http://${IP.GENAI}:8006/get-image?n=${Math.random()}`}
                width={700}
                height={300}
                alt="img"
              />
            </div>
          ) : null}
          {codeResponse.length > 0 ? (
            <Card style={{ marginTop: "20px" }}>
              <SyntaxHighlighter
                language={
                  selectedLanguage.length === 0 ? "python" : selectedLanguage
                }
                style={vscDarkPlus}
              >
                {codeResponse}
              </SyntaxHighlighter>
            </Card>
          ) : null}
        </Card>
      </div>
    </>
  );
}
