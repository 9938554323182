import { Button, Card, Col, Row } from "antd";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
// import cardImg from "../assets/img/emp-card-image.png";
import { routes } from "../util/constants";
import { landingPageData } from "../util/Data";
import { FileTextOutlined } from "@ant-design/icons";
import { domainsList } from "../util/domainData";

export default function Chatbot() {
  useEffect(() => {
    document.title = "GenAI";
  }, []);
  return (
    <div>
      <Row gutter={[16, 24]} style={{ marginTop: "20px" }}>
        {domainsList.map((value, index) => (
          <Col key={index} sx={24} sm={24} md={12} xl={8} lg={8} xxl={8}>
            <Card style={{ borderRadius: "14px" }} className="category-card">
              <div style={{ display: "flex" }}>
                <div>
                  <img
                    style={{
                      marginRight: "10px",
                      height: "150px",
                      width: "150px",
                      objectFit: "cover",
                    }}
                    src={value.icon}
                    alt="cardImg"
                  />
                </div>
                <div>
                  <NavLink
                    to={value.url ? value.url : routes.EMPASSIS}
                    style={{
                      textDecoration: "none",
                      fontSize: "0.9rem",
                    }}
                  >
                    {value.name}
                  </NavLink>
                  <div style={{ fontSize: "0.75rem" }}>{value.desc}</div>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}
