import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, Input, message, Modal, Row, Tooltip, Select } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import ip from "../../../../assests/img/ip-icon1.png";
import theme1 from "../../../../assests/img/theme_1.png";
import theme2 from "../../../../assests/img/theme_2.png";
import theme3 from "../../../../assests/img/theme_3.png";
import theme4 from "../../../../assests/img/theme_4.png";
import theme5 from "../../../../assests/img/theme_5.png";
import theme6 from "../../../../assests/img/theme_6.png";
import theme7 from "../../../../assests/img/theme_7.png";
import theme8 from "../../../../assests/img/theme_8.png";
import BreadCrumbs from "../../../BreadCrumbs";
import { api_url, IP } from "../../../../dynamicIPs";
import VideoTour from "../../../video-guide";
import { pdfUrls } from "../../../../util/pdfData";
import { videoUrls } from "../../../../util/videoData";
const themeOptions = [
  { value: "theme0.pptx", label: "Theme_1" },
  { value: "theme1.pptx", label: "Theme_2" },
  { value: "theme2.pptx", label: "Theme_3" },
  { value: "theme3.pptx", label: "Theme_4" },
  { value: "theme4.pptx", label: "Theme_5" },
  { value: "theme5.pptx", label: "Theme_6" },
];
const themeValues = [
  { value: "theme0.pptx", label: "Theme_1", image: theme1 },
  { value: "theme1.pptx", label: "Theme_2", image: theme2 },
  { value: "theme2.pptx", label: "Theme_3", image: theme3 },
  { value: "theme3.pptx", label: "Theme_4", image: theme4 },
  { value: "theme4.pptx", label: "Theme_5", image: theme5 },
  { value: "theme5.pptx", label: "Theme_6", image: theme6 },
  { value: "theme6.pptx", label: "Theme_7", image: theme7 },
  // { value: "theme7.pptx", label: "Theme_8", image: theme8 },
];

const { Option } = Select;

export default function PptGeneration() {
  const location = useLocation();
  const [slideValue, setSlideValue] = useState();
  const [ipVal, setIpVal] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [openAiKey, setOpenAiKey] = useState("");
  const [pptTopic, setPptTopic] = useState("");
  const [isPptLoading, setIsPptLoading] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [selectedThemeValue, setSelectedThemeValue] = useState("");
  const [selectedThemeName, setSelectedThemeName] = useState("");

  const handleSelectTheme = (value) => {
    setSelectedTheme(value);
  };

  const handleIp = (event) => {
    localStorage.setItem("ip", event.target.value);
    const ip = localStorage.getItem("ip");
    setIpVal(ip);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSlideValue = (e) => {
    setSlideValue(e.target.value);
  };
  const handlePlusSlide = () => {
    if (slideValue) {
      setSlideValue((prevState) => parseInt(prevState) + 1);
    }
  };
  const handleReset = () => {
    setOpenAiKey("");
    setPptTopic("");
    setSlideValue("");
  };
  const handleMinusSlide = () => {
    if (slideValue) {
      setSlideValue((prevState) => parseInt(prevState) - 1);
    }
  };
  const handleImageClick = (value, name) => {
    setSelectedThemeValue(value);
    setSelectedThemeName(name);
  };

  const handleGeneratePpt = async () => {
    setIsPptLoading(true);

    const params = {
      openai_key: openAiKey,
      ppt_topic: pptTopic,
      slide_count: slideValue,
      theme_name: selectedThemeValue,
    };
    const url = `${api_url}/ppt/presentation`;
    try {
      const path = await axios.get(url, { params });
      setOpenAiKey("");
      setPptTopic("");
      setSlideValue("");
      setSelectedThemeValue("");
      const filename = path.data[1];
      const urlPpt = `${api_url}/ppt/get-ppt?file_path=${path.data[0]}`;
      fetch(urlPpt)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);

          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          message.success("ppt has been downloaded!");

          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });

      setIsPptLoading(false);
    } catch (error) {
      setIsPptLoading(false);
      message.error(error.message);
    }
  };
  return (
    <div>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto", float: "right" }}>
        <VideoTour src={videoUrls.PPT_maker} type="video/mp4" pdfSrc={pdfUrls.PPT_maker}/>
      </div>
      <div className="main_header">
        <b>
          <p
            style={{
              marginTop: "20px",
            }}
          >
            AI Powered Presentation Creation
          </p>
        </b>
      </div>
      <div>
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
            <Row span={16}>
              {themeValues.map((theme, index) => (
                <Col span={12}>
                  <img
                    src={theme.image}
                    key={index}
                    alt={theme.label}
                    width={200}
                    style={{ margin: "10px", cursor: "pointer" }}
                    onClick={() => handleImageClick(theme.value, theme.label)}
                  />
                </Col>
              ))}
            </Row>
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
            <div className="app-container">
              <Row>
                <Col style={{ margin: "20px" }} span={22}>
                  <b>
                    <p style={{ marginTop: "10px" }}>
                      Provide a Name of PPT Topic:
                    </p>
                  </b>
                  <Input
                    type="text"
                    value={pptTopic}
                    onChange={(e) => setPptTopic(e.target.value)}
                  />
                  <br />
                  <b>
                    <p style={{ marginTop: "10px" }}>
                      Number of Slides Required:
                    </p>
                  </b>
                  <div style={{ position: "relative" }}>
                    <Input value={slideValue} onChange={handleSlideValue} />

                    <div
                      style={{
                        position: "absolute",
                        top: "40%",
                        transform: "translateY(-50%)",
                        right: 8,
                      }}
                    >
                      <PlusCircleFilled
                        style={{ color: "#52c41a", marginRight: 8 }}
                        className="plus-icon"
                        onClick={handlePlusSlide}
                      />
                      <MinusCircleFilled
                        style={{ color: "#f5222d" }}
                        className="minus-icon"
                        onClick={handleMinusSlide}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <b>
                <p style={{ marginLeft: "20px" }}>Selected Theme:</p>
              </b>
              <Row style={{ marginLeft: "20px" }}>
                <Col span={24}>
                  {/* <Select
                    style={{
                      width: "94%",
                    }}
                    onChange={handleSelectTheme}
                  >
                    {themeOptions.map((item) => {
                      return <Option value={item.value}>{item.label}</Option>;
                    })}
                  </Select> */}
                  <Input type="text" value={selectedThemeName} />
                </Col>
                <Col span={24}>
                  <div className="app-box" style={{ marginTop: "20px" }}>
                    <Button
                      type="primary"
                      className="generate-button"
                      loading={isPptLoading}
                      onClick={handleGeneratePpt}
                      style={{ marginLeft: "-10px" }}
                    >
                      Generate PPT
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
