import React from "react";
import { Card, Col, Row, Tag } from "antd";
import { NavLink } from "react-router-dom";
import { routes } from "../../../../util/constants";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import { resumeData } from "./resumeData";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

export default function ResumeLists() {
  const location = useLocation();
  return (
    <div>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto", float: "right" }}>
        <VideoTour src={videoUrls.Resume_Genie_recording} type="video/mp4" pdfSrc={pdfUrls.Resume_Genie} />
      </div>
      <Row gutter={[16, 24]} style={{ marginTop: "20px" }}>
        {resumeData.map((value, index) => (
          <Col key={index} sx={24} sm={24} md={12} xl={8} lg={8} xxl={8}>
            <Card style={{ borderRadius: "14px" }} className="category-card">
              <div style={{ display: "flex" }}>
                <div>
                  <div>
                    <Tag
                      className="my-custom-tags"
                      color="#eaeaea"
                      style={{
                        color: "#000000",
                        fontSize: "10px",
                        padding: "0px 4px",
                      }}
                    >
                      {value.tag}
                    </Tag>
                    <VideoTour
                      src={value.videoSrc}
                      type="video/mp4"
                      pdfSrc={value.pdfSrc}
                    />
                  </div>
                  <img
                    style={{
                      marginRight: "10px",
                      height: "125px",
                      width: "125px",
                    }}
                    src={value.icon}
                    alt="cardImg"
                  />
                </div>
                <div>
                  <NavLink
                    to={value.url ? value.url : routes.EMPASSIS}
                    style={{
                      textDecoration: "none",
                      fontSize: "0.9rem",
                    }}
                  >
                    {value.name}
                  </NavLink>
                  <div style={{ fontSize: "0.75rem" }}>{value.desc}</div>
                </div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}
