import { IP, api_url, domain_url } from "../dynamicIPs";

export const routes = {
  CHATBOT: "/",
  //HR
  RESUME_GENIE: "/human-resource/resume-genie",
  MULTI_LLM_ASSISTANT: "/human-resource/mutli-llm-assistance",
  SMART_HIRE: "/human-resource/smarthire",
  ENTERPRISE_CHATBOT: "/human-resource/Enterprise-chatbot",
  GMAIL_GENAI_TOOLKIT: "/human-resource/gmail-genai-toolkit",
  RESUME_SCREENING_ASSISTANCE:
    "/human-resource/resume-genie/resume-screening-assistance",
  POWERED_RESUME_RETRIEVER:
    "/human-resource/resume-genie/AI-powered-resume-retriever",
  POWERED_RESUME_SUMMARIZAR:
    "/human-resource/resume-genie/AI-powered-resume-summarizar",
  LANGUAGE_TRANSLATION_BOT: "/human-resource/Language-Translation-Bot",
  VOICE_CLONING_AND_LIP_SYNCHRONIZATION:
    "/human-resource/Voice-Cloning-and-Lip-Synchronization",
  ASK_HR: "/human-resource/ask-hr",

  //OPERATIONS
  BANKING_PAL: "/operations/banking-pal",
  USER_MANUAL: "/operations/user-manual",
  LANGCHAIN_SQL_AGENT: "/operations/langchain-sql-agent",
  LEGAL_DOC_SUMMARIZATION: "/operations/legal-doc-summarization",
  PRODUCT_RECOMMENDATION: "/operations/product-recommendation",
  AGENTIC_TRIP_ADVISOR: "/operations/agentic-trip-advisor",

  HBF: "/operations/Custom-Persona-GPT",
  AUTO_VISUALIZATION: "/operations/auto-visualization",
  SALES_LEAD_AUTOMATION: "/operations/sales-lead-automation",

  //SALES & MARKETING
  ADVERTISEMENT_AND_VIDEO_TRANSLATION_GENERATOR:
    "/sales-and-marketing/advertisement-and-Video-Translation-Generator",
  AI_POWERED_PRESENTATION_CREATION:
    "/sales-and-marketing/AI-Powered-Presentation-Creation",
  IMAGE_EDITING: "/sales-and-marketing/Image-Editing",
  SALES_CHATBOT: "/sales-and-marketing/Sales-Chatbot",
  DIGITAL_CATALOGUE: "/sales-and-marketing/digital-catalogue-summarizer",
  IMAGE_CAMPAIGN_CREATION: "/sales-and-marketing/image-campaign-creation",
  PRODUCT_RECOMMENDATION: "/sales-and-marketing/product-recommendation",

  //IT
  SDLC: "/IT/SDLC",
  // VISUALIZATION: "/IT/visualization",
  DATABASE_AGENTS: "/IT/Database-Agents",
  SDLC_AI: "/IT/SDLC-AI",
  EXECUTOR_AI: "/IT/Executor-AI",
  // DATA_ANALYTICS_DASHBOARD: "/IT/Data-Analytics-Dashboard",
  IMPACT_SDLC: "/IT/Impact-SDLC",
  VIRTUAL_TRAVEL_AGENT: "/IT/Virtual-Travel-Agent",
  
  GUARDRAILS_TRAVEL_AGENT:"/IT/AWS-Guardrails-Travel-Agent",
  CONVERSATION_SUMMARIZER_AND_ANALYZER:"/IT/Conversation-Summarizer-Analyzer",
  CODE_GENERATOR : "/IT/Code-Generator",

  //FINANCE
  FINANCIAL_REPORT_ANALYZER: "/finance/financial-report-analyzer",
  BILLS_ANALYZER: "/finance/bill-analyzer",
  CONTRACT_ANALYZER: "/finance/contract-analyzer",

  //OTHERS

  TEXT_SUMMARIZATION: "/others/Text-Summarization",
  YOUTUBE_VIDEO_QA_BOT: "/others/Youtube-Video-QA-Bot",
  DATA_ANALYSIS_BOT: "/others/Data-Analysis-Bot",
  MUSIC_GENERATION: "/others/Music-Generation",
  POSE_MIMIC: "/others/Pose-Mimic",
  CHARACTER_CREATION: "/others/Character-Creation",
  IMAGE_GENERATION_FOR_HEALTHCARE: "/others/Image-Generation-For-Healthcare",
  DISNEY_POSE: "/others/Pose-Mimic/Disney-Pose",
  REALSTIC_POSE: "/others/Pose-Mimic/Realstic-Pose",
  PHARMA_HELPER: "/others/pharma-helper",
  AUTOMOBILE_AI: "/others/automob-ai",
  MANUFACTURING_CATALYST: "/others/Manufacturing-Catalyst",
  QUEUE_ANALYZER: "/others/Queue-Analyzer",
  NUTRITION_AND_FITNESS_ADVISOR: "/other/Nurition-&-Fitness-Advisor",
  CSR: "/others/csr-agent",
  AWSQ: "/others/aws-q-yashTech",
  INTELLIGENT_DOCUMENT_PROCESSING:"/others/idp-processor",
  AMS_TICKET_TOOL: "/others/Ams-Ticket-Tool",
  LIVE_POTRAIT : "/others/Live-Potrait",

  //end of new routes
  SEARCH: "/search",
  // OPENAICHATBOTFORCODE: "/Code-Generation/Database-Agents",
  // AUTOMATIONANALYTICS: "/Text-Generation/Automated-Advanced-Analytics",
  // AUTOMATIONANALYTICSINCODE: "/Code-Generation/Automated-Advanced-Analytics",
  // DATAANALYSIS: "/Data-Analysis",

  // VOICECLONING: "/Video-Generation/Voice-Cloning-and-Lip-Synchronization",
  // VOICECLONINGINVAUDIO:
  //   "/Audio-Generation/Voice-Cloning-and-Lip-Synchronization",
  // MUSICGENERATION: "/Music-Generation",
  // TEXTGENERATION: "/Text-Generation",
  // IMAGEGENERATION: "/Image-Generation",
  // VIDEOGENERATION: "/Video-Generation",
  // AUDIOGENERATION: "/Audio-Generation",
  // CODEGENERATION: "/Code-Generation",
  // CONTENTGENERATION: "/Content-Generation",

  // SPEECHTOIMAGE: "/Image-Generation/Speech-To-Image-Generation",
  VIRTUALTRYON: "/sales-and-marketing/Virtual-Try-on",

  // TABULARDATAGEN: "/Text-Generation/Tabular-Data-Generation",
  // CODEGENUSINGGCP: "/Code-Generation/Code-Generation-Using-GCP",
  // DATAANALYSISINCODEGEN: "/Code-Generation/Data-Analysis-Bot",

  // PERSONALIZESMARKETINGCAMPAIGN:
  //   "/Content-Generation/Personalized-Market-Campaign",
  // ADVERTISEMENTCONTENTGEN:
  //   "/Content-Generation/Advertisement-and-Video-Translation-Generator",
};

export const streamlitRoutes = {
  CONTRACT_ANALYZER: `${domain_url}/contract`,
  GMAIL_GENAI_TOOLKIT: `${domain_url}/gmail-genAI-toolkit`,
  PPT_SUMMERIZER: `${domain_url}/digital-catalogue`,
  SMART_HIRE: "https://hiresmart.vercel.app/",
  USER_MANUAL_BOT: `${domain_url}/user-manual`,
  BILLS_ANALYZER: `${domain_url}/bills-analyzer`,
  MULTI_LLM_ASSISTANT: `${domain_url}/multilingual`,
  FINANCIAL_REPORT_ANALYZER: `${domain_url}/financial-report-analyser`, //tweek-templates
  HYPER_PERSONALIZATION: `http://${IP.IMAGE_CAMPAIGN_CREATION}:9005/image-campaign-creation`, //Image Campaign
  PEZT_AVATAR_GENERATION: `http://${IP.GENAILEAP}:9004`,
  DEVSEC_OPS_CHATBOT: `http://${IP.GENAILEAP}:9004`,
  LLM_SEMANTIC_SEARCH: `http://${IP.GENAILEAP}:9004`,
  GEN_AI_AGENTS: `http://${IP.GENAILEAP}:9004`,
  MANUFACTURING_CATALYST: `${domain_url}/manufacturing-catalyst`,
  VIRTUAL_VOGUE: `http://${IP.MEDI_AUTO_AI}:7860/virtual-vogue`,
  E_KYC: "http://3.82.72.38:8080/",
  SDLC_AI: "http://13.53.105.233:8080/",
  WILDFIRE_DETECTION:
    "https://detectingwildfires-sssbqteun2zakbvjcd3amo.streamlit.app/",
  EXEXUTOR_AI: `${domain_url}/auto-code-executor`,
  VIRTUAL_WARDROBE: `http://${IP.VIRTUALWARDROBE}:8000`,
  QUEUE_ANALYZER: `http://${IP.QUEUEANALYSER}:8000`,
  IT_DASHBOARD: `${domain_url}/auto-visualization`,
  DIGITAL_CATALOGUE: `${domain_url}/digital-catalogue`,
  HBF: `${api_url}/hbf/`,
  ENTERPRISE_CHATBOT:
    "https://msdigitalcoe.sharepoint.com/sites/Powerapps_POC/SitePages/YashChatGPT.aspx",

  VIRTUAL_TRAVEL_AGENT : `${api_url}/travel-agent/`,

  IMPACT_SDLC: "http://54.189.55.151:3000/",

  CSR:"http://3.17.233.210:3000/",

  AWSQ: "https://y8tvuni0.chat.qbusiness.us-east-1.on.aws/",

  AMS_TICKET_TOOL:"http://3.224.53.210:8501/",


  IDP:"http://34.226.242.206:8501/",

  GTA:"http://34.226.242.206:8502/",

  CSA:"http://34.226.242.206:8503/",

  CODE_GENERATOR: "https://devcodegenerator.z13.web.core.windows.net/",
  IMAGE_CAMPAIGN_CREATION : "http://34.226.242.206:7860/",

  LIVE_POTRAIT : "http://13.60.128.21:8000/live_potrait"

};
export const domainRoutes = {
  HUMAN_RESOURCE: "/human-resource",
  SALES_AND_MARKETING: "/sales-and-marketing",
  FINANCE: "/finance",
  OPERATIONS: "/operations",
  IT: "/IT",
  OTHERS: "/others",
};
