import React, { useState, useRef } from "react";
import BreadCrumbs from "../../../BreadCrumbs";
import { Card, Select, Row, Col, Button, message } from "antd";
import { useLocation } from "react-router";
import { DotLoading } from "antd-mobile";
import { SendOutlined } from "@ant-design/icons";
import { api_url ,IP } from "../../../../dynamicIPs";
import axios from "axios";
import VideoTour from "../../../video-guide";
import { domain_url } from "../../../../dynamicIPs";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

function IBCAssistant(props) {
  const location = useLocation();
  const API_URL = `${api_url}/ibc/get_answer`;

  const messageContainerRef = useRef(null);

  const [category, setCategory] = useState("General");
  const [msgData, setMsgData] = useState([
    { text: "Hi, How may I help you?", isUser: false },
  ]);
  const [query, setQuery] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState(null);
  const [apiMessage, setApiMessage] = useState(null);

  const handleCategoryChange = (value) => {
    localStorage.removeItem("history");
    setMsgData([{ text: "Hi, How may I help you?", isUser: false }]);
    setCategory(value);
  };
  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setInputMessage(event.target.value);
  };

  const handleClearHistroy = () => {
    localStorage.removeItem("history");
    setMsgData([{ text: "Hi, How may I help you?", isUser: false }]);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!query) return;
    setUserMessage(query);
    setInputMessage("");

    setLoading(true);
    try {
      const body = {
        user_query: query,
        azure_cognitive_search_index: category,
        previous_data_list: [],
      };
      const awsBody = {
        query: query,
        category: category,
        selected_model: "amazon.titan-tg1-large",
      };

      if (localStorage.getItem("history") === null) {
        const historyMessages = [];

        const obj = {
          role: "user",
          content: query,
        };
        historyMessages.push(obj);
        localStorage.setItem("history", JSON.stringify(historyMessages));
        body["previous_data_list"] = historyMessages;
      } else {
        const data = JSON.parse(localStorage.getItem("history"));
        const obj = {
          role: "user",
          content: query,
        };
        data.push(obj);

        const splitData = data.slice(-10);
        body["previous_data_list"] = splitData;
        localStorage.setItem("history", JSON.stringify(splitData));
      }

      const payload =  awsBody ;

      const response = await axios.post(API_URL, payload);
      const final_res =  response.data.result;
      const localData = JSON.parse(localStorage.getItem("history"));
      const resObj = {
        role: "assistant",
        content: final_res,
      };
      localData.push(resObj);
      localStorage.setItem("history", JSON.stringify(localData));
      setApiMessage(final_res);
      setMsgData([
        ...msgData,
        {
          text: query,
          isUser: true,
        },
        { text: final_res, isUser: false },
      ]);

      setQuery("");
      setLoading(false);
    } catch (error) {
      setUserMessage(query);
      setApiMessage(error.message);
      console.error("Error fetching data:", error);
      setMsgData([
        ...msgData,
        { text: query, isUser: true },
        { text: error.message, isUser: false },
      ]);
      localStorage.removeItem("history");

      setLoading(false);
      setQuery("");
    }
  };
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour
          src={videoUrls.ibc}
          type="video/mp4"
          pdfSrc={pdfUrls.Banking_pal_sample_questions}
        />
      </div>
      <div style={{ marginTop: "15px" }}>
        <Row gutter={[8, 8]} style={{ margin: "0 0 15px 0" }}>
          <Col sx={24} sm={24} md={20} xl={20} lg={20} xxl={20}>
            <Select
              defaultValue="General"
              style={{
                width: "100%",
              }}
              onChange={handleCategoryChange}
              options={[
                {
                  value: "General",
                  label: "General",
                },
                {
                  value: "Operations",
                  label: "Operations",
                },
                {
                  value: "Quality_Control",
                  label: "Quality Control",
                },
                {
                  value: "Consumer_Regs",
                  label: "Consumer Regs",
                },
                {
                  value: "BSA_AML",
                  label: "BSA-AML",
                },
                {
                  value: "BSA_Regs",
                  label: "BSA-Regs",
                },
                {
                  value: "Free_Checking",
                  label: "Free Checking",
                },
              ]}
            />
          </Col>
          <Col sx={24} sm={24} md={4} xl={4} lg={4} xxl={4}>
            <Button
              type="primary"
              onClick={handleClearHistroy}
              style={{ width: "100%" }}
            >
              Clear History
            </Button>
          </Col>
        </Row>

        <div
          className="messages"
          ref={messageContainerRef}
          style={{ minHeight: "375px", maxHeight: "500px" }}
        >
          {msgData?.map((message, index) => (
            <div
              key={index}
              className={`message ${message.isUser ? "user" : "api"}`}
            >
              {message.text}
            </div>
          ))}
          {loading ? (
            <>
              <div className="user message">{query}</div>
              <div className="message api">
                <DotLoading />
              </div>
            </>
          ) : null}
        </div>
        <hr style={{ margin: "0px" }} />
        <form onSubmit={handleSubmit} className="input-container">
          <input
            type="text"
            value={inputMessage}
            onChange={handleInputChange}
            placeholder="Ask a question about your documents..."
          />

          {loading ? (
            <></>
          ) : (
            <button
              type="submit"
              style={{
                border: "none",
                background: "none",
                fontSize: "20px",
                margin: "-4px 0 0 0",

                color: "black",
              }}
            >
              <SendOutlined />
            </button>
          )}
        </form>
      </div>
    </>
  );
}

export default IBCAssistant;
