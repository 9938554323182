import { routes, streamlitRoutes } from "../../../util/constants";
import resumeImg from "../../../assests/img/resume.jpg";
import translationImg from "../../../assests/icon/Language Translation Bot.svg";
import voicecloningimage from "../../../assests/icon/Voice Cloning & Lip Synchronization.svg";
import cardImg from "../../../assests/icon/Employee Assistance bot.svg";
import multiLLM from "../../../assests/img/Multilingual-Multi-LLM assistant.jpg";

import smartHireImage from "../../../assests/img/smartHireImage.png";

export const hrData = [
  {
    name: "Resume Genie",
    icon: resumeImg,
    url: routes.RESUME_GENIE,
    videoSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Resume%20Genie%20recording.mp4",
    pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Resume%20Genie.pdf",
    awsVideo: true,
    tag: "Virtual Assistant",
    desc: "Revolutionize your recruitment process with AI-powered Resume Genie. This comprehensive toolkit empowers HR professionals to efficiently filter, screen, & analyze large volumes of resumes. ",
  },
  {
    name: "Language Translation",
    icon: translationImg,
    url: routes.LANGUAGE_TRANSLATION_BOT,
    videoSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Language_translation.mp4",
    pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/language_translation_sample.pdf",
    awsVideo: true,
    tag: "Chatbot",
    desc: "English to German and German to English language tanslation bot",
  },
  {
    name: "Voice Cloning And Lip Syncing",
    icon: voicecloningimage,
    url: routes.VOICE_CLONING_AND_LIP_SYNCHRONIZATION,
    videoSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/cloning%20and%20lip%20sync.mp4",
    pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/voice_cloning%20prompts.pdf",
    awsVideo: true,
    tag: "Creativity",
    desc: "Generate lip synced videos from a script with a custom cloned voice. Seamlessly create realistic and engaging videos.",
  },
  {
    name: "Ask HR",
    icon: cardImg,
    url: routes.ASK_HR,
    videoSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/ask_hr_video.mp4",
    pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Ask_hr_sample_questions.pdf",
    awsVideo: true,
    tag: "Chatbot",
    desc: "Confused by company policies? AskHR has you covered! Get instant answers to all your HR questions, from benefits and leave requests to compliance and company procedures.",
  },
  {
    name: "Multilingual, Multi-LLM assistant",
    icon: multiLLM,
    url: routes.MULTI_LLM_ASSISTANT,
    videoSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/multilingual_multillm_video.mp4",
    pdfSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/multillm_questions_to_try.pdf",
    awsVideo: true,
    streamlit: true,
    tag: "Chatbot",
    desc: "Chat for HR policies with multple LLM. Chat in Arabic or English language.",
  },
  {
    name: "Enterprise ChatGPT",
    icon: multiLLM,
    url: routes.ENTERPRISE_CHATBOT,
    videoSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Enterprise+ChatGPT.mp4",
    pdfSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Enterprise+ChatGPT.pdf",
    awsVideo: true,
    streamlit: true,
    tag: "Chatbot",
    desc: "Secure AI-powered chatbot tailored to your private data. Seamlessly integrates with SharePoint and other data sources. Enforces granular access controls, ensuring users only retrieve information they're authorized to see.",
  },
  {
    name: "SmartHIRE",
    icon: smartHireImage,
    url: routes.SMART_HIRE,
    videoSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Smart%20HIRE%20walkthrough.mp4",
    pdfSrc:"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/smartHire.pdf",
    awsVideo: true,
    streamlit: true,
    tag: "Virtual Assistant",
    desc: "Find your next perfect talent with AI Video Interview Platform. Screen and interview thousands of candidates at the same time via live AI Interview. Get a curated shortlist of talent with the soft skills and abilities you need. Achieve 90% candidate satisfaction.",
  },
  {
    name: "Gmail GenAI Toolkit",
    icon: multiLLM,
    url: routes.GMAIL_GENAI_TOOLKIT,
    videoSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/gmail_genai_toolkit.mp4",
    pdfSrc: "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Gmail_Genai_Toolkit.pdf",
    awsVideo: true,
    // streamlit: true,
    tag: "Toolkit",
    desc: "Transform your email experience effortlessly! Perform all email actions, fetch and summarize important messages, match job profiles, and more with our integrated solution. Boost productivity and streamline processes for a seamless workflow!",
  },
];
