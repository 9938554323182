import React, { useEffect, useState } from "react";
import { Col, Row, Button, Input, message, Spin } from "antd";
// import "./App.css"; // Add your own CSS for styling

// Import your drum sound files
// import OpenHH from './Drumpaddata/amen-break-no-copyright-remake-120bpm-25924.mp3';
// import ClosedHH from './Drumpaddata/bass-loops-006-with-drums-long-loop-120-bpm-6111.mp3';
// import KickandHat from './Drumpaddata/drums-roll-137317.mp3';
// import SideStick from './Drumpaddata/timpani-roll-kevinsticks-6453.mp3';
import playButton from "../../../../../assests/img/play.png";

import Kick from "../../../../../assests/Drumpaddata/amen-break-no-copyright-remake-120bpm-25924.mp3";
// import Snare from "../../../../../assests/Drumpaddata/bass-loops-006-with-drums-long-loop-120-bpm-6111.mp3";
// import Clap from "../../../../../assests/Drumpaddata/loop-black-box-exciting-bass-loop-130bpm-13888.mp3";
import hihatSound from "../../../../../assests/Drumpaddata/typical-trap-loop-140bpm-129880.mp3";
import kickSound from "../../../../../assests/Drumpaddata/typical-trap-loop-2b-130751.mp3";
import drumRolls from "../../../../../assests/Drumpaddata/drums-roll-137317.mp3";
import sunFlower from "../../../../../assests/Drumpaddata/sunflower-street-drumloop-85bpm-163900.mp3";
import timpaniRoll from "../../../../../assests/Drumpaddata/timpani-roll-kevinsticks-6453.mp3";
import tribeDrumRoll from "../../../../../assests/Drumpaddata/tribe-drum-loop-103173.mp3";
import A from "../../../../../assests/Drumpaddata/A.mp3";
import S from "../../../../../assests/Drumpaddata/S.mp3";
import Z from "../../../../../assests/Drumpaddata/Z.mp3";
import X from "../../../../../assests/Drumpaddata/X.mp3";
import axios from "axios";
// import happybirthday from "../../../../../assests/Drumpaddata/happy-birthday-to-you-bossa-nova-style-arrangement-21399.mp3";

const DrumPad = ({ text, audioSrc, onClick }) => {
  const playSound = () => {
    const audio = new Audio(audioSrc);
    audio.play();
    // audio.play().catch((error) => {
    //   // Handle any errors that may occur when playing the audio

    //   console.error("Error playing audio:", error);
    // });

    onClick(text, audio); // Pass the audio element to handleDrumPadClick
  };

  return (
    <div className="drum-pad" onClick={playSound}>
      {text}
    </div>
  );
};

const DrumMachine = () => {
  const [display, setDisplay] = useState("");

  const [currentlyPlaying, setCurrentlyPlaying] = useState(new Audio());
  const [period, setPeriod] = useState(0);

  const [isQueryEntered, setIsQueryEntered] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [isMelodyLoading, setIsMelodyLoading] = useState(false);
  const [isMelodyError, setIsMelodyError] = useState(false);

  const handleDrumPadClick = (text, audio) => {
    setDisplay(text);

    if (currentlyPlaying) {
      currentlyPlaying.pause();

      currentlyPlaying.currentTime = 0;
    }

    audio
      .play()
      .then(() => {
        setCurrentlyPlaying(audio);
      })
      .catch((error) => {
        console.error("Error playing audio:", error);
      });
  };

  const stopSound = () => {
    if (currentlyPlaying) {
      currentlyPlaying.pause();

      currentlyPlaying.currentTime = 0;

      setCurrentlyPlaying(new Audio());

      setDisplay("");
    }
  };

  const getData = (audioFile, callback) => {
    fetch(audioFile).then(function (res) {
      res.blob().then(function (blob) {
        var size = blob.size;
        var type = blob.type;

        var reader = new FileReader();
        reader.addEventListener("loadend", function () {
          // console.log('reader.result:', reader.result);

          // 1: play the base64 encoded data directly works
          // audioControl.src = reader.result;

          // 2: Serialize the data to localStorage and read it back then play...
          var base64FileData = reader.result.toString();

          var mediaFile = {
            fileUrl: audioFile,
            size: blob.size,
            type: blob.type,
            src: base64FileData,
          };
          callback(mediaFile);
        });

        reader.readAsDataURL(blob);
      });
    });
  };

  const generateContinueMelody = () => {
    if (currentlyPlaying.src.length < 1) {
      message.error("Please select a drum sound");
      return;
    }
    if (period < 1) {
      message.error("Please select valid duration");
      return;
    }
    getData(currentlyPlaying.src, (fileURI) => {
      callMelodyContinuationAPI(fileURI);
    });
  };

  const callMelodyContinuationAPI = async (mediaFile) => {
    setKeyword(display);
    setIsQueryEntered(true);
  };

  useEffect(() => {
    currentlyPlaying.addEventListener("ended", () => stopSound());
    return () => {
      currentlyPlaying.removeEventListener("ended", () => {
        stopSound();
      });
    };
  }, []);

  const drumData = [
    // { text: 'Q', audioSrc: OpenHH },
    // { text: 'W', audioSrc: ClosedHH },
    // { text: 'D', audioSrc: SideStick },
    // { text: 'E', audioSrc: KickandHat },
    { text: "A", audioSrc: A },

    { text: "S", audioSrc: S },

    { text: "Z", audioSrc: Z },

    { text: "X", audioSrc: X },

    { text: "C", audioSrc: hihatSound },

    // Add more drum elements with their corresponding sound file paths
  ];
  return (
    <Row id="drum-machine">
      <Col>
        {/* <h4 >Play Drum Pad</h4> */}

        <div className="drum-pads">
          {drumData.map((drum, index) => (
            <DrumPad
              key={index}
              text={drum.text}
              audioSrc={drum.audioSrc}
              onClick={handleDrumPadClick}
            />
          ))}
        </div>

        <div style={{ marginBottom: "20px" }} id="display">
          {display}
        </div>

        {currentlyPlaying.src.length ? (
          <Button type="primary" className="music-button " onClick={stopSound}>
            Stop
          </Button>
        ) : (
          <div>No sound playing</div>
        )}
        <div
          className="mt-5"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            <p style={{ marginBottom: "5px" }}>
              Enter duration to continue melody
            </p>
            <div
              style={{
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Input
                style={{ borderRadius: "10px" }}
                type="number"
                min={1}
                max={60}
                placeholder="Enter duration to continue melody"
                value={period}
                onChange={(e) => {
                  setPeriod(e.target.value);
                }}
              />
              <Button
                type="primary"
                // onClick={this.exportMidiFile}
                onClick={generateContinueMelody}
                // onClick={()=>{
                //   this.getData(happybirthday,(data)=>{
                //     console.log("happy-birthday-base64",data)
                //   });
                // }}
                style={{ marginTop: "10px" }}
              >
                Generate Music
              </Button>
            </div>
          </div>
          {!isQueryEntered ? (
            <></>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>Play Generated Melody Continuation</p>
              <audio
                src={`http://18.195.105.34:8009/music-continuation?base_string=${keyword}&duration=${period}`}
                controls
                onLoadStart={() => {
                  setIsMelodyLoading(true);
                }}
                onCanPlay={() => {
                  setIsMelodyLoading(false);
                }}
                onError={() => {
                  setIsMelodyError(true);
                }}
              />
              {!isMelodyError ? (
                <Spin
                  tip="Melody is loading..."
                  spinning={isMelodyLoading}
                  style={{ marginTop: "10px" }}
                />
              ) : (
                <></>
              )}
              {isMelodyError ? (
                <div style={{ color: "#4693ff" }}>
                  Something went wrong! Could not play melody.
                </div>
              ) : (
                <div style={{ color: "#4693ff" }}>
                  {isMelodyLoading
                    ? "Melody is loading..."
                    : "Melody is ready to play!"}
                </div>
              )}
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

function Drumpad() {
  return (
    <div className="drum-pad-container">
      <DrumMachine />
    </div>
  );
}

export default Drumpad;
