import React, { useState, useRef, useEffect } from "react";
import Chatbot from "./Chatbot";
import BreadCrumbs from "../../../BreadCrumbs";
import { Typography, Button } from "antd";
import { useLocation } from "react-router-dom";
import { SendOutlined } from "@ant-design/icons";
import axios from "axios";
import { api_url } from "../../../../dynamicIPs";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

function NutritonFitnessAdvisor(props) {
  const location = useLocation();
  const { Text } = Typography;
  const messageContainerRef = useRef(null);
  const [category, setCategory] = useState("General");
  const [msgData, setMsgData] = useState([]);
  const [query, setQuery] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [preferences, setPreferences] = useState("vegetarian"); // Default preference

  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [msgData]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setInputMessage(event.target.value);
  };

  const handlePreferencesChange = (event) => {
    setPreferences(event.target.value); // Update the dietary preference
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!query) return; // If query is empty, do nothing.

    // Optimistically set user's message
    setMsgData((prev) => [
      ...prev,
      { text: query, isUser: true },
      { text: "Hmm...let me think", isUser: false }, // Add a filler message
    ]);

    setInputMessage(""); // Clear the input field
    setLoading(true); // Set loading state

    try {
      let url = `${api_url}/nutrition-app/Nutrition`;
      let data = {
        user_query: query,
        user_prefrences: preferences, // Use the preference from the state
      };
      console.log(data);

      // Make the API call
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Update the message data after getting the response
      setMsgData((prev) => [
        ...prev.slice(0, -1), // Remove the filler message
        { text: response.data, isUser: false }, // Add API response
      ]);
      setQuery(""); // Clear the query state
    } catch (error) {
      // Handle error and update message data accordingly
      setMsgData((prev) => [
        ...prev.slice(0, -1), // Remove the filler message
        { text: error.message, isUser: false }, // Show error message
      ]);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div
      style={{
        margin: "0 40px",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
      }}
    >
      {/* Breadcrumbs at the top */}
      {/* <BreadCrumbs location={location} /> */}
      <div style={{ display: "flex" }}>
        <BreadCrumbs location={location} />
        <div style={{ margin: "0px 0 0 auto" }}>
          <VideoTour
            src={videoUrls.nutrition}
            type="video/mp4"
            pdfSrc={pdfUrls.nutrition}
          />
        </div>
      </div>

      <div style={{ display: "flex", flex: "1", marginTop: "20px" }}>
        {/* Sidebar for Preferences */}
        <div
          style={{
            padding: "20px",
            borderRight: "1px solid #ccc",
            width: "250px",
            marginTop: "50px",
          }}
        >
          <Text strong style={{ fontSize: "16px" }}>
            Preferences
          </Text>
          <input
            type="text"
            value={preferences}
            onChange={handlePreferencesChange}
            placeholder="Enter your preference..."
            style={{
              width: "100%",
              marginTop: "10px",
              padding: "8px",
              borderRadius: "14px",
              border: "1px solid #ccc",
            }}
          />
        </div>

        {/* Main Chat Content */}
        <div
          style={{
            flex: "1",
            display: "flex",
            flexDirection: "column",
            padding: "20px",
          }}
        >
          {msgData.length === 0 && (
            <div style={{ textAlign: "center", marginTop: "10%" }}>
              <Text style={{ margin: "20px 0", fontSize: "xxx-large" }}>
                Hello!
              </Text>
              <br />
              <Text style={{ margin: "20px 0", fontSize: "xx-large" }}>
                How can I support your wellness journey today?{" "}
              </Text>
            </div>
          )}
          <div
            className="user-manual-chatbot"
            style={{
              marginTop: "10px",
              position: "absolute",
              bottom: "16px",
              width: "80%",
            }}
          >
            <div
              ref={messageContainerRef}
              style={{
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                maxHeight: "500px",
                overflowY: "auto",
                padding: "10px",
              }}
            >
              {msgData.map((message, index) => (
                <Chatbot
                  key={index}
                  text={message.text}
                  isUser={message.isUser}
                />
              ))}
            </div>
            <div className="text-area" style={{ marginTop: "10px" }}>
              <form
                onSubmit={handleSubmit}
                className="input-container-user-manual"
                style={{ width: "100%" }}
              >
                <input
                  disabled={loading}
                  type="text"
                  value={inputMessage}
                  onChange={handleInputChange}
                  className="input-user-manual"
                  placeholder="Type a new question..."
                  style={{ width: "80%", padding: "8px" }}
                />
                <Button
                  style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                    alignSelf: "center",
                    color: "black",
                  }}
                  type="primary"
                  icon={<SendOutlined />}
                  size="small"
                  onClick={handleSubmit}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NutritonFitnessAdvisor;
