import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";
import CaptureImage from "./CaptureImage";
import {
  Col,
  Row,
  Checkbox,
  Select,
  Button,
  Carousel,
  Radio,
  Card,
  message,
} from "antd";
import ImageCaraousal from "./ImageCaraousal";
import CustomCollapse from "./Collapse";
import { humanImages, clothImages, dummyImageUrl, ip } from "./Imagedata";
import axios, { formToJSON } from "axios";
import { virtualTryDomain } from "../../../../dynamicIPs";
import VideoTour from '../../../video-guide';

const CheckboxGroup = Checkbox.Group;
const { Group } = Radio;
const { Option } = Select;

// Usage example:

export default function VirtualTryOn() {
  const [humanImage, setHumanImage] = useState(null);
  const [uploadGarmentImage, setUploadGarmentImage] = useState(null);
  const [selectedHumanImage, setSelectedHumanImage] = useState(null);
  const [selectedGarmentImage, setSelectedGarmentImage] = useState(null);
  const [radioValue, setRadioValue] = useState("Upper Body");
  const [loadingTryon, setLoadingTryOn] = useState(false);
  const [resUrl, setResUrl] = useState(null);
  const [selectBase64Human, setSelectBase64Human] = useState("");
  const [selectBase64Cloth, setSelectBase64Cloth] = useState("");
  const [selectBase64AmazonSearch, setSelectBase64AmazonSearch] = useState("");
  const [humanPath, setHumanPath] = useState("");
  const [clothPath, setClothPath] = useState("");

  const location = useLocation();

  const handleRecieveFile = (files) => {
    setHumanPath("");
    setHumanImage(files);
  };
  const handleRecieveGarmentImage = (image) => {
    setClothPath("");
    setUploadGarmentImage(image);
  };
  const bodyOptions = ["Upper Body", "Lower Body", "Full Body"];

  useEffect(() => {
    if (selectBase64AmazonSearch) {
      setSelectedGarmentImage(selectBase64AmazonSearch);
      setClothPath("");
      setUploadGarmentImage(null);
    }
  }, [selectBase64AmazonSearch]);

  const handleSelectImage = (image) => {
    setHumanImage("");
    const pathArr = image.url.split("/");
    const imagePath = pathArr[pathArr.length - 1].split(".");
    imagePath[1] = ".";
    const imageName = imagePath.join("");

    setHumanPath(imageName);

    // const file = handleImageToFIleConversion(image, true);
    fetch(image)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = reader.result;
          setSelectBase64Human(base64);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => console.error(error));
    setSelectedHumanImage(image);
    // setHumanImage(file);
  };
  const handleImageToFIleConversion = async (imageUrl, isHuman) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const file = new File([blob], "image/jpeg", { type: "image/jpeg" });
    console.log("@@@crausal file", file);
    if (isHuman) {
      setHumanImage(file);
    } else {
      setUploadGarmentImage(file);
    }

    // Use the file object as needed (e.g., upload to server or display preview)
  };

  const handleSelectGarmentImage = (image) => {
    handleImageToFIleConversion(image, false);
    const pathArr = image.url.split("/");
    const imagePath = pathArr[pathArr.length - 1].split(".");
    imagePath[1] = ".";
    const imageName = imagePath.join("");
    setUploadGarmentImage("");
    setClothPath(imageName);
    fetch(image)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64 = reader.result;
          setSelectBase64Cloth(base64);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => console.error(error));

    setSelectedGarmentImage(image);
  };

  const handleCheckbox = (e) => {
    setRadioValue(e.target.value);
  };

  const handleSelectImageSearch = (url) => {
    setSelectBase64AmazonSearch(url);
  };

  const handleClickTryOn = async () => {
    setLoadingTryOn(true);
    setResUrl("");
    const formData = new FormData();
    if (humanPath) {
      formData.append("img_dict", humanPath);
    } else if (humanImage) {
      formData.append("img_dict", humanImage);
    }
    if (clothPath) {
      formData.append("garm_img", clothPath);
    } else if (uploadGarmentImage) {
      formData.append("garm_img", uploadGarmentImage);
    } else if (selectBase64AmazonSearch) {
      formData.append("cloth_base64", selectBase64AmazonSearch);
    }

    // if (humanImage) {
    //   formData.append("img_dict", humanImage);
    // } else if (clothPath) {
    //   formData.append("garm_img", clothPath);
    // }
    // if (humanPath) {
    //   console.log("inside human path", humanPath);
    //   formData.append("img_dict", humanPath);
    // } else if (selectBase64AmazonSearch) {
    //   formData.append("cloth_base64", selectBase64AmazonSearch);
    // } else if (uploadGarmentImage) {
    //   formData.append("garm_img", uploadGarmentImage);
    // }

    if (radioValue === "Upper Body") {
      formData.append("is_checked", true);
      formData.append("is_checked_lower", false);
      formData.append("is_checked_full", false);
    } else if (radioValue === "Lower Body") {
      formData.append("is_checked_lower", true);
      formData.append("is_checked", false);
      formData.append("is_checked_full", false);
    } else {
      formData.append("is_checked", false);
      formData.append("is_checked_lower", false);
      formData.append("is_checked_full", true);
    }
    formData.append("denoise_steps", 30);
    formData.append("seed", 42);
    const url = `${virtualTryDomain}/tryon`;
    try {
      const res = await axios.post(url, formData);
      setResUrl(res.data);
      setLoadingTryOn(false);
    } catch (error) {
      console.log("error", error);
      message.error(error.message);
      setLoadingTryOn(false);
    }
  };
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto", float: "right" }}>
        <VideoTour

          src="https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/virtualTryOn.mp4"
          type="video/mp4"
          pdfSrc="https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Copy%20of%20Virtual%20Try%20on%20simplified.pdf"
        />
      </div>
      <Row style={{ marginLeft: "50px", marginTop: "20px" }} gutter={[8, 16]}>
        <Col sx={24} sm={24} md={8} lg={8}>
          <CaptureImage
            handleRecieveFile={handleRecieveFile}
            tag="Human"
            isCameraRequired={true}
            selectedHuman={
              selectedHumanImage !== null ? selectedHumanImage : false
            }
          />
        </Col>
        <Col sx={24} sm={24} md={8} lg={8}>
          <CaptureImage
            handleRecieveFile={handleRecieveGarmentImage}
            tag="Garment"
            isCameraRequired={false}
            selectedHuman={
              selectedGarmentImage !== null
                ? selectedGarmentImage
                : selectBase64AmazonSearch
                ? selectBase64AmazonSearch
                : false
            }
            isCrop={true}
          />
        </Col>
        <Col sx={24} sm={24} md={8} lg={8}>
          <Card style={{ width: "400px", height: "300px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  marginTop: "-24px",
                  marginLeft: "-24px",
                  width: "80px",
                  backgroundColor: "blue",
                  height: "30px",
                  //   border: "1px solid blue",
                  //   borderRadius: "10px",
                  borderBottomRightRadius: "20px",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                Output
              </div>
              {resUrl ? (
                <img
                  src={`data:image/jpeg;base64,${resUrl}`}
                  height="200px"
                  width="200px"
                  style={{ marginLeft: "80px" }}
                />
              ) : (
                <img src={dummyImageUrl} height="230px" width="350px" />
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[8, 16]} style={{ marginLeft: "50px", marginTop: "30px" }}>
        <Col sx={24} sm={24} md={16} lg={16}>
          <Group
            style={{
              display: "flex",
              width: "400px",
              justifyContent: "space-between",
            }}
            onChange={handleCheckbox}
            defaultValue={radioValue}
          >
            {bodyOptions.map((option, index) => (
              <Radio key={index} value={option}>
                {option}
              </Radio>
            ))}
          </Group>
        </Col>
        <Col sx={24} sm={24} md={8} lg={8}>
          <Button
            type="primary"
            style={{ width: "400px" }}
            onClick={handleClickTryOn}
            loading={loadingTryon}
          >
            Try-On
          </Button>
        </Col>
      </Row>
      <Row gutter={[8, 16]} style={{ marginLeft: "50px", marginTop: "30px" }}>
        <Col sx={24} sm={24} md={8} lg={8}>
          <h6>Examples:</h6>
          <ImageCaraousal
            imageData={humanImages}
            handleSelectImage={handleSelectImage}
          />
        </Col>
        <Col sx={24} sm={24} md={8} lg={8}>
          <h6>Examples:</h6>
          <ImageCaraousal
            imageData={clothImages}
            handleSelectImage={handleSelectGarmentImage}
          />
        </Col>
      </Row>

      <Row gutter={[8, 16]} style={{ marginLeft: "50px", marginTop: "30px" }}>
        <Col sx={24} sm={24} md={24} lg={24}>
          <CustomCollapse handleSelectImageSearch={handleSelectImageSearch} />
        </Col>
      </Row>
    </>
  );
}
