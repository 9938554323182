import React, { useState, useRef } from "react";
import BreadCrumbs from "../../../../BreadCrumbs";
import { useLocation } from "react-router-dom";
import {
  Card,
  Col,
  Row,
  Button,
  message,
  Input,
  Upload,
  Tabs,
  Select,
  Form,
} from "antd";
import { IP, domain_url } from "../../../../../dynamicIPs";
import axios from "axios";
import Markdown from "react-markdown";
import VideoTour from "../../../../video-guide";

const { TextArea } = Input;
const ResumeRetriever = () => {
  const location = useLocation();
  const [data, setData] = useState(``);
  const [summaryType, setSummaryType] = useState("hybrid");
  const [fetchCount, setFetchCount] = useState(null);
  const [jobDescription, setJobDescription] = useState("");
  const [queryResponseLoading, setQueryResponseLoading] = useState(false);
  const [activeKey, setActiveKey] = useState("1");
  const [isTextEnd, setIsTextEnd] = useState(false);
  const [fillerMessages, setFillerMessages] = useState([
    "Hmm...let me think",
    "Generating Response!",
    "Here is the answer.",
  ]);
  const [currentFillerMessage, setCurrentFillerMessage] = useState("");

  const handleTypeChange = (value) => {
    setSummaryType(value);
  };
  const handleCountChange = (e) => {
    setFetchCount(e.target.value);
  };

  const onChange = (key) => {
    setActiveKey(key);
  };

  const handleQuerySubmit = async () => {
    // setData("");
    setQueryResponseLoading(true);
    const randomIndex = Math.floor(Math.random() * fillerMessages.length);
    setCurrentFillerMessage(fillerMessages[randomIndex]);
    console.log("currentMessageifiller from 1", currentFillerMessage);
    setData(fillerMessages[randomIndex]);
    setData((prev) => prev + " ");
    setActiveKey("2");

    const retrieverObj = {
      job_description: jobDescription,
      search_type: summaryType,
      number_of_resume: parseInt(fetchCount),
    };
    const url = `${domain_url}/resume-retrieve`;
    // const url="http://18.199.250.92:9008/resume-retriever"
    try {
      const response = await fetch(url, {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(retrieverObj),
      });
      if (!response.ok || !response.body) {
        throw response.statusText;
      }

      // Here we start prepping for the streaming response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      const loopRunner = true;

      while (loopRunner) {
        // Here we start reading the stream, until its done.
        const { value, done } = await reader.read();
        if (done) {
          console.log("inside done");
          setIsTextEnd(true);
          break;
        }
        const decodedChunk = decoder.decode(value, { stream: true });
        console.log("realdecodechunk", decodedChunk);
        console.log("currentMessage", currentFillerMessage);

        setData((answer) => answer + decodedChunk);
        setQueryResponseLoading(false);
        setActiveKey("2");
      }
    } catch (error) {
      console.log("error", error);
      setQueryResponseLoading(false);

      message.error(error);
    }
  };
  const items = [
    {
      key: "1",
      label: "Resume Retriever",
      children: (
        <>
          <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
            <Col sx={24} sm={24} md={24} xl={24} lg={24} xxl={24}>
              <TextArea
                placeholder="Enter your job description..."
                onChange={(e) => setJobDescription(e.target.value)}
                value={jobDescription}
                rows={6}
              />
            </Col>
            <Col sx={24} sm={24} md={12} xl={12} lg={12} xxl={12}>
              <Select
                defaultValue={summaryType}
                style={{
                  width: "100%",
                }}
                onChange={handleTypeChange}
                options={[
                  {
                    value: "text",
                    label: "Text",
                  },
                  {
                    value: "vector",
                    label: "Vector",
                  },
                  {
                    value: "hybrid",
                    label: "Hybrid",
                  },
                ]}
              />
            </Col>
            <Col sx={24} sm={24} md={12} xl={12} lg={12} xxl={12}>
              <Input
                placeholder="Number of resumes to fetch"
                type="number"
                name="fetchCount"
                value={fetchCount}
                onChange={handleCountChange}
                min={1}
                style={{
                  width: "100%",
                }}
              />
            </Col>
            <Col span={24} style={{ marginTop: "20px" }}>
              <Button
                type="primary"
                // loading={queryResponseLoading}
                onClick={handleQuerySubmit}
                style={{ width: "100%" }}
              >
                Retrieve Resume
              </Button>
            </Col>
          </Row>
        </>
      ),
    },
    {
      key: "2",
      label: "Retrieved Result",
      disabled: data !== `` ? false : true,
      children: <Markdown>{data}</Markdown>,

      // <span className="pointer">{!isTextEnd?"|":null}</span>
    },
  ];
  return (
    <>
      <BreadCrumbs location={location} />

      <div style={{ marginTop: "20px" }}>
        <Card>
          <Tabs
            size="small"
            onChange={onChange}
            activeKey={activeKey}
            items={items}
          />
        </Card>
      </div>
    </>
  );
};

export default ResumeRetriever;
