export const pdfUrls = {
  "Advertisement_Content_Generator": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Advertisement%20Content%20Generator.pdf",
  "Ask_hr_sample_questions": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Ask_hr_sample_questions.pdf",
  "AutomobAI": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/AutomobAI.pdf",
  "Avatar_Generation": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Avatar%20Generation.pdf",
  "Banking_pal_sample_questions": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Banking_pal_sample_questions.pdf",
  "Contract_Analyzer": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Contract%20Analyzer.pdf",
  "Copy_of_Virtual_Try_on_simplified": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Copy%20of%20Virtual%20Try%20on%20simplified.pdf",
  "Db_Agents": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Db%20Agents.pdf",
  "Gmail_Genai_Toolkit": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Gmail_Genai_Toolkit.pdf",
  "IDP-Prompts": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/IDP-Prompts.pdf",
  "Image_campaign_creation_sample_images": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Image_campaign_creation_sample_images.pdf",
  "ItDashboard": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/ItDashboard.pdf",
  "No_data": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/No%20data.pdf",
  "PPT_maker": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/PPT%20maker.pdf",
  "Queue_Analyzer": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Queue_Analyzer.pdf",
  "Resume_Genie": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Resume%20Genie.pdf",
  "SDLC_Prompts": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/SDLC%20Prompts.pdf",
  "Sales_Chatbot": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Sales%20Chatbot.pdf",
  "VirtualVogue": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/VirtualVogue.pdf",
  "Visualization_Prompts": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Visualization%20Prompts.pdf",
  "YT_Q&A": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/YT%20Q%26A.pdf",
  "YTPL_Holiday_Calendar_2022": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/YTPL_Holiday%20Calendar_2022.pdf",
  "bills_analyzer_prompts": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/bills_analyzer_prompts.pdf",
  "character_creation_prompts": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/character_creation_prompts.pdf",
  "devSecOps": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/devSecOps.pdf",
  "digital_catalogue_summarizer": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/digital%20catalogue%20summarizer.pdf",
  "executorAi": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/executorAi.pdf",
  "financial": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/financial.pdf",
  "financialservices_agent": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/financialservices_agent.pdf",
  "image_editor_prompt": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/image_editor_prompt.pdf",
  "language_translation_sample": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/language_translation_sample.pdf",
  "multillm_questions_to_try": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/multillm_questions_to_try.pdf",
  "pharma_helper": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/pharma_helper.pdf",
  "pose_mimic_prompts": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/pose_mimic_prompts.pdf",
  "sdlc_AI_bot_simplified": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/sdlc%20AI%20bot%20simplified.pdf",
  "semanticSearch": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/semanticSearch.pdf",
  "smartHire": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/smartHire.pdf",
  "user_manual": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/user_manual.pdf",
  "virtualWardRobe": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/virtualWardRobe.pdf",
  "voice_cloning_prompts": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/voice_cloning%20prompts.pdf",
  "wildfire": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/wildfire.pdf",
  "csr":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/csr.pdf",
  "awqYashTechAI":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Aws_Q-YashTechAI.pdf",
  "persona_pdf":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Custom+Persona+GPT.pdf",

  "nutrition":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Nutrition.pdf",
  "travelAgent": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/travelAgent.pdf",
  "sales": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/sales.pdf",
  "trip": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Agentic%20Trip%20advisor%20-sample%20questions.pdf",
  "langchain": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/langchain.pdf",
  "auto_visualization": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/auto_visualization.pdf",
  "ams": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/AmsTicketTool.pdf",
  "impact": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/ImpactSDLC.pdf",
  "idp": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/idp.pdf",
  "gta": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/gta.pdf",
  "csa": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/csa.pdf",
  "code_generator": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/CodeGenerator.pdf",
  "lp": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/lp.pdf",
};
