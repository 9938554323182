import React, { useEffect } from "react";
import BreadCrumbs from "../ui-components/BreadCrumbs";
import { streamlitRoutes } from "./constants";
import VideoTour from "../ui-components/video-guide";
import { useState } from "react";
import { Card } from "antd";
import EnterpriseImage from "../assests/img/enterprise.jpg";
import Meta from "antd/es/card/Meta";
import { Link, useLocation } from "react-router-dom";
import { usecaseData } from "./ExternalData";

const relative_url = {
  "Gmail GenAI Toolkit": streamlitRoutes.GMAIL_GENAI_TOOLKIT,
  "Multilingual, Multi-LLM assistant": streamlitRoutes.MULTI_LLM_ASSISTANT,
  "Financial Report Analyzer": streamlitRoutes.FINANCIAL_REPORT_ANALYZER,
  "Image Campaign Creation": streamlitRoutes.HYPER_PERSONALIZATION,
  "Digital Catalogue Summarizer": streamlitRoutes.DIGITAL_CATALOGUE,
  "Bills Analyzer": streamlitRoutes.BILLS_ANALYZER,
  "Contract Analyzer": streamlitRoutes.CONTRACT_ANALYZER,
  "Data Analytics Dashboard powered by GenAI": streamlitRoutes.IT_DASHBOARD,
  ExecutorAI: streamlitRoutes.EXEXUTOR_AI,
  "SDLC AI": streamlitRoutes.SDLC_AI,
  "Queue Analyser": streamlitRoutes.QUEUE_ANALYZER,
  "Manufacturing Catalyst": streamlitRoutes.MANUFACTURING_CATALYST,
  "Custom Persona GPT": streamlitRoutes.HBF,
  "Enterprise ChatGPT": streamlitRoutes.ENTERPRISE_CHATBOT,
  "SDLC AI": streamlitRoutes.SDLC_AI,
  "Queue Analyser": streamlitRoutes.QUEUE_ANALYZER,
  "Manufacturing Catalyst": streamlitRoutes.MANUFACTURING_CATALYST,
  "HBF Chatbot": streamlitRoutes.HBF,
  "Impact SDLC": streamlitRoutes.IMPACT_SDLC,
  "Virtual Travel Agent": streamlitRoutes.VIRTUAL_TRAVEL_AGENT,
  "Ams Ticket Tool": streamlitRoutes.AMS_TICKET_TOOL,
  "AWS Q on YashTechAI": streamlitRoutes.AWSQ,
  "aws-q-yashTech": streamlitRoutes.AWSQ,
  "CSR Agent": streamlitRoutes.CSR,
  "csr-agent": streamlitRoutes.CSR,
  "bill-analyzer": streamlitRoutes.BILLS_ANALYZER,
  "financial-report-analyzer": streamlitRoutes.FINANCIAL_REPORT_ANALYZER,
  "contract-analyzer": streamlitRoutes.CONTRACT_ANALYZER,
  "mutli-llm-assistance": streamlitRoutes.MULTI_LLM_ASSISTANT,
  smarthire: streamlitRoutes.SMART_HIRE,
  "gmail-genai-toolkit": streamlitRoutes.GMAIL_GENAI_TOOLKIT,
  "digital-catalogue-summarizer": streamlitRoutes.DIGITAL_CATALOGUE,
  "Custom-Persona-GPT": streamlitRoutes.HBF,
  "Executor-AI": streamlitRoutes.EXEXUTOR_AI,
  "Impact-SDLC": streamlitRoutes.IMPACT_SDLC,
  "Impact SDLC": streamlitRoutes.IMPACT_SDLC,

  "Ams Ticket Tool": streamlitRoutes.AMS_TICKET_TOOL,
  "Ams-Ticket-Tool": streamlitRoutes.AMS_TICKET_TOOL,

  "Manufacturing-Catalyst": streamlitRoutes.MANUFACTURING_CATALYST,
  "Queue-Analyzer": streamlitRoutes.QUEUE_ANALYZER,

  "Intelligent Document Processing": streamlitRoutes.IDP,
  "idp-processor": streamlitRoutes.IDP,

  "Conversation Summarizer & Analyzer": streamlitRoutes.CSA,
  "Code Generator": streamlitRoutes.CODE_GENERATOR,
  "Code-Generator": streamlitRoutes.CODE_GENERATOR,
  
  "AWS-Guardrails-Travel-Agent": streamlitRoutes.GTA,

  "Image Campaign Creation":streamlitRoutes.IMAGE_CAMPAIGN_CREATION ,
  "image-campaign-creation":streamlitRoutes.IMAGE_CAMPAIGN_CREATION ,

  "Live-Potrait":streamlitRoutes.LIVE_POTRAIT ,
  "Live Potrait":streamlitRoutes.LIVE_POTRAIT
};

const IframeComponent = () => {
  const location = useLocation();
  const pathname = location.pathname.split("/");
  const [isExternal, setIsExternal] = useState(false);
  const [value, setVlaue] = useState(null);
  const [url, setUrl] = useState(null);
  const [videoUrl, setVideoUrl] = useState(null);
  const [pdfUrl, setPdfurl] = useState(null);
  const [usecaseName, setUsecaseName] = useState("");

  const path = location.pathname;

  useEffect(() => {
    setIsExternal(false);
    const data =
      location?.state?.value !== undefined
        ? location.state.value
        : location?.state?.category;

    setVlaue(data);

    setVideoUrl(data?.videoSrc);
    setPdfurl(data?.pdfSrc);
    setUsecaseName(data?.name);

    let api_url = relative_url[data?.name]
      ? relative_url[data?.name]
      : relative_url[pathname[pathname.length - 1]];
    setUrl(api_url);
    console.log("api url", api_url)
    if (!api_url) {
      setVideoUrl(usecaseData[pathname[pathname.length - 1]]?.videoSrc);
      setPdfurl(usecaseData[pathname[pathname.length - 1]]?.pdfSrc);
      setUsecaseName(pathname[pathname.length - 1]);
    }

    if (
      data?.name === "Impact SDLC" ||
      pathname[pathname.length - 1] === "Impact-SDLC" ||
      data?.name === "AWS Q on YashTechAI" ||
      pathname[pathname.length - 1] === "aws-q-yashTech" ||
      data?.name === "CSR Agent" ||
      pathname[pathname.length - 1] === "csr-agent" ||
      data?.name === "Code Generator" ||
      pathname[pathname.length - 1] === "Code-Generator" ||
      data?.name === "Ams Ticket Tool" ||
      pathname[pathname.length - 1] === "Ams-Ticket-Tool" ||

      data?.name === "AWS Guardrails Travel Agent" ||
      pathname[pathname.length - 1] === "AWS-Guardrails-Travel-Agent" ||

      data?.name === "Intelligent Document Processing" ||
      pathname[pathname.length - 1] === "idp-processor" ||

      data?.name === "Conversation Summarizer & Analyzer" ||
      pathname[pathname.length - 1] === "Conversation-Summarizer-Analyzer" ||

      data?.name === "Image Campaign Creation" ||
      pathname[pathname.length - 1] === "image-campaign-creation" ||

      data?.name === "Live Potrait" ||
      pathname[pathname.length - 1] === "Live-Potrait"

    ) {
      setIsExternal(true);
    }
  }, [path]);

  return (
    <>
      {isExternal ? (
        <>
          <div style={{ marginBottom: "10px" }}>
            <BreadCrumbs location={location} />
            <div style={{ margin: "-25px 0 0 auto", float: "right" }}>
              <VideoTour src={videoUrl} type="video/mp4" pdfSrc={pdfUrl} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Card
              hoverable
              style={{
                width: 440,
              }}
              cover={<img alt="example" src={EnterpriseImage} />}
            >
              <Link
                to={url}
                target="_blank"
                style={{ textDecoration: "none", color: "blue" }}
              >
                <Meta
                  title={`Click here to go to the  ${
                    usecaseName ? usecaseName : pathname[pathname.length - 1]
                  }`}
                  description="Secure AI-powered chatbot tailored to your private data"
                />
              </Link>
            </Card>
          </div>
        </>
      ) : (
        <div style={{ width: "100%", height: "100vh" }}>
          <div style={{ marginBottom: "10px" }}>
            <BreadCrumbs location={location} />
            <div style={{ margin: "-25px 0 0 auto", float: "right" }}>
              <VideoTour src={videoUrl} type="video/mp4" pdfSrc={pdfUrl} />
            </div>
          </div>
          <iframe
            src={url}
            style={{ width: "100%", height: "100%", border: "none" }}
            allowFullScreen
          />
        </div>
      )}
    </>
  );
};

export default IframeComponent;
