import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { DotLoading } from "antd-mobile";
import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Tooltip,
  Typography,
} from "antd";

import logo from "../../../../assests/img/Salesgptlogo.png";
// import ip from "../../../..//assests/img/ip-icon1.png";
import BreadCrumbs from "../../../BreadCrumbs";
import { useLocation } from "react-router-dom";
import { MessageFilled, SendOutlined } from "@ant-design/icons";
import { IP } from "../../../../dynamicIPs";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";
const SalesGpt = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const [query, setQuery] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState(null);
  const [apiMessage, setApiMessage] = useState(null);
  const messageContainerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [addIp, setAddIp] = useState("");
  const [msgData, setMsgData] = useState([]);
  const [firstLoading, setFirstLoading] = useState(true);

  // const [isIpAvailavle, setIsIpAvailable] = useState(false);

  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleIp = (event) => {
  //   localStorage.setItem("ip", event.target.value);
  //   const ip = localStorage.getItem("ip");
  //   setAddIp(ip);
  // };
  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  useEffect(() => {
    firstApiCall();
  }, []);

  const firstApiCall = async () => {
    let url = `http://${IP.GENAI}:8004/conversation`;
    const params = { query_string: null };
    try {
      const response = await axios.get(url, { params });
      setFirstLoading(false);

      setMsgData([...msgData, { text: response.data, isUser: false }]);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [userMessage, apiMessage]);

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setInputMessage(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!query) return;
    setUserMessage(query);
    setInputMessage("");

    setLoading(true);

    try {
      let url = `http://${IP.GENAI}:8004/conversation`;

      const params = { query_string: query };

      const response = await axios.get(url, { params });
      setApiMessage(response.data);

      setMsgData([
        ...msgData,
        {
          text: query,
          isUser: true,
        },
        { text: response.data, isUser: false },
      ]);

      // localStorage.setItem("data", JSON.stringify(msgData));
      setQuery("");
      setLoading(false);
    } catch (error) {
      setUserMessage(query);
      setApiMessage(error.message);
      console.error("Error fetching data:", error);
      msgData.push(
        { text: query, isUser: true },
        { text: error.message, isUser: false }
      );
      setLoading(false);
      setQuery("");
    }
  };

  return (
    <div>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto", float: "right" }}>
        <VideoTour src={videoUrls.Sales_Chatbot} type="video/mp4" pdfSrc={pdfUrls.Sales_Chatbot} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          marginTop: "20px",
        }}
      >
        <div className="chat-bot-modal">
          <div className="chat-header">
            <span>
              <img className="chatbot-logo" src={logo} alt="Logo" />
            </span>
            {/* <Row gutter={[16, 8]}>
              <Tooltip title="Add IP">
                <span>
                  <Button
                    style={{
                      cursor: "pointer",
                      outline: "none",
                      border: "none",
                      background: "none",
                    }}
                    onClick={showModal}
                  >
                    <img src={ip} alt="ip-images" width="25px" height="25px" />
                  </Button>
                </span>
              </Tooltip>
              <Modal
                title="Add IP Modal"
                width="400px"
                open={isModalOpen}
                onCancel={handleCancel}
                footer={[
                  <Button key="back" onClick={handleCancel}>
                    Cancel
                  </Button>,
                  <Button
                    key="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleOk}
                  >
                    Add IP
                  </Button>,
                ]}
              >
                <Input
                  placeholder="Please Enter Ip Address"
                  value={addIp}
                  onChange={handleIp}
                />
              </Modal>
             
            </Row> */}
          </div>

          <div className="sales-messages-box" ref={messageContainerRef}>
            {msgData?.map((message, index) => (
              <div
                key={index}
                className={`message ${message.isUser ? "user" : "api"}`}
              >
                {message.text}
              </div>
            ))}
            {firstLoading ? (
              <div className="message api">
                <DotLoading />
              </div>
            ) : null}
            {loading ? (
              <>
                <div className="user message">{query}</div>
                <div className="message api">
                  <DotLoading />
                </div>
              </>
            ) : null}
          </div>
          <hr style={{ margin: "0px" }} />

          <form onSubmit={handleSubmit} className="input-container">
            <input
              type="text"
              value={inputMessage}
              onChange={handleInputChange}
              placeholder="Ask a question about your documents..."
            />

            {loading ? (
              <></>
            ) : (
              <button
                type="submit"
                style={{
                  border: "none",
                  background: "none",
                  fontSize: "20px",
                  margin: "-4px 0 0 0",

                  color: "black",
                }}
              >
                <SendOutlined />
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default SalesGpt;
