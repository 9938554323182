import React from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../../BreadCrumbs";

import { Button, Card, Col, Row, Select } from "antd";
import CaptureImage from "./CaptureImage/CaptureImage";
import VideoTour from "../../../video-guide";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

export default function CharacterCreation() {
  const location = useLocation();
  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.character_creation} type="video/mp4" pdfSrc={pdfUrls.character_creation_prompts} />
      </div>
      <div style={{ marginTop: "30px" }}>
        <CaptureImage />
      </div>
    </>
  );
}
