import React, { useState } from "react";
import { Layout } from "antd";
import HeaderAdmin from "../layouts/HeaderAdmin";
import SiderMenu from "./SiderMenu";

import moment from "moment";
import { domainRoutes, routes } from "../../util/constants";
import Dashboard from "../Dashboard";

import { useLocation } from "react-router-dom";
import Chatbot from "../Chatbot";
import EmployeeAssistance from "../AllPoc/TextGeneration/EmployeeAssistance/EmployeeAssistance";
import OpenAIChatBot from "../AllPoc/TextGeneration/OpenAiChatBot/OpenAIChatbot";
import PptGeneration from "../AllPoc/ContentGeneration/PptGeneration/PptGeneration";
import VideoCreator from "../AllPoc/VideoAutomation/VideoCreator";
import LanguageTranslator from "../AllPoc/TextGeneration/LanguageTranslator/LanguageTranslator";
import AutomationAnalytics from "../AllPoc/AutomationAnalytics/AutomationAnalytics";
import DataAnalysis from "../AllPoc/DataAnalysis/DataAnalysis";
import SalesGpt from "../AllPoc/TextGeneration/SalesGPT/SalesGpt";
import VoiceCloning from "../AllPoc/VideoGeneration/VoiceCloning/VoiceCloning";
// import MusicGeneration from "../AllPoc/MusicGeneration/MusicGeneration";
import TextGeneration from "../AllPoc/TextGeneration/TextGeneration";
import ImageGeneration from "../AllPoc/ImageGeneration/ImageGeneration";
import VideoGeneration from "../AllPoc/VideoGeneration/VideoGeneration";
import AudioGeneration from "../AllPoc/AudioGeneration/AudioGeneration";
import CodeGeneration from "../AllPoc/CodeGeneration/CodeGeneration";
import ContentGeneration from "../AllPoc/ContentGeneration/ContentGeneration";
import MusicComposition from "../AllPoc/AudioGeneration/MusicComposition/MusicComposition";
import Posemimic from "../AllPoc/ImageGeneration/PoseMimic/Posemimic";
import ImageEnhancement from "../AllPoc/ImageGeneration/ImageEnhancement/ImageEnhancement";
import SpeechToImage from "../AllPoc/ImageGeneration/SpeechToImage/SpeechToImage";
import VirtualTryOn from "../AllPoc/ImageGeneration/VirtualTryOn/VirtualTryOn";
import CharacterCreation from "../AllPoc/ImageGeneration/CharacterCreation/CharacterCreation";
import ImgGenHealth from "../AllPoc/ImageGeneration/ImgGenForHealthcare/ImgGenHealth";
import TextSummarization from "../AllPoc/TextGeneration/TextSummarization/TextSummarization";
import TabularDataGen from "../AllPoc/TextGeneration/TabularDataGen/TabularDataGen";
import YoutubeQABot from "../AllPoc/TextGeneration/YoutubeQABot/YoutubeQABot";
import CodeGenUsingGcp from "../AllPoc/CodeGeneration/CodeGenUsingGcp/CodeGenUsingGcp";
import PersonalizeMarketCamp from "../AllPoc/ContentGeneration/PersonalizedMarket/PersonalizeMarketCamp";
import CaptureImage from "../AllPoc/ImageGeneration/PoseMimic/CaptureImage/CaptureImage";
import SDLC from "../AllPoc/TextGeneration/SDLC";
import ResumeScreeningAssistance from "../AllPoc/TextGeneration/Resume/ResumeScreeningAssistance";
import ResumeLists from "../AllPoc/TextGeneration/Resume/ResumeLists";
import ResumeSummarizer from "../AllPoc/TextGeneration/Resume/Resume Summarizer/ResumeSummarizer";
import ResumeRetriever from "../AllPoc/TextGeneration/Resume/Resume Retriever/ResumeRetriever";
import IBCAssistant from "../AllPoc/TextGeneration/IBC Assistant";
import VisualizationGeneration from "../AllPoc/Visualization Generation";
import HumanResource from "../domains/human-resource/HumanResource";
import Operations from "../domains/operations/Operations";
import SalesAndMarketing from "../domains/sales and marketing/SalesAndMarketing";
import IT from "../domains/IT/IT";
import Finance from "../domains/finance/Finance";
import Others from "../domains/others/Others";
import SearchResults from "../search/SearchResults";
import PharmaHelper from "../AllPoc/TextGeneration/Pharma Helper";
import AutomobileAI from "../AllPoc/TextGeneration/Automobile AI";
import UserManual from "../AllPoc/TextGeneration/UserManual/UserManual";
import LegalDocSummarization from "../AllPoc/TextGeneration/LegalDocSummarization";
import ProductRecommendation from "../AllPoc/TextGeneration/ProductRecommendation";
import TripPlanner from "../AllPoc/TextGeneration/TripPlanner";
import LangchainSqlAgent from "../AllPoc/TextGeneration/LangchainSqlAgent";
import HBF from "../AllPoc/TextGeneration/HBF";
import IframeComponent from "../../util/IframeComponent";
import SalesLeadAutomation from "../AllPoc/TextGeneration/SalesLeadAutomation";
import AutoVisualization from "../AllPoc/TextGeneration/AutoVisualization";
import NutritonFitnessAdvisor from "../AllPoc/TextGeneration/NutritonFitnessAdvisor";
import EnterpriseGpt from "../AllPoc/TextGeneration/EnterpriseGpt";

const { Sider, Content, Footer } = Layout;

export default function AdminDashboard(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [sideCollapsed, setSideCollapsed] = useState(false);
  const [viewToggleBtn, setViewToggleBtn] = useState(true);
  const location = useLocation();

  const toggle = () => {
    setCollapsed(!collapsed);

    setSideCollapsed(false);
  };

  const IframeRoutes = [
    routes.MULTI_LLM_ASSISTANT,
    routes.SMART_HIRE,
    routes.GMAIL_GENAI_TOOLKIT,
    routes.FINANCIAL_REPORT_ANALYZER,
    routes.DIGITAL_CATALOGUE,
    routes.BILLS_ANALYZER,
    routes.CONTRACT_ANALYZER,
    routes.SDLC_AI,
    routes.EXECUTOR_AI,
    routes.DATA_ANALYTICS_DASHBOARD,
    routes.MANUFACTURING_CATALYST,
    routes.QUEUE_ANALYZER,
    routes.VIRTUAL_TRAVEL_AGENT,
    routes.IMPACT_SDLC,
    routes.AMS_TICKET_TOOL,
    routes.CSR,
    routes.AWSQ,
  ];
  // const onSwitchChange = (checked) => {
  //   setSideCollapsed(checked);
  //   if (!sideCollapsed) {
  //     setViewToggleBtn(false);
  //   }
  // };
  // const handleMouseEnter = () => {
  //   setViewToggleBtn(true);
  // };
  // const handleMouseLeave = () => {
  //   setViewToggleBtn(false);
  // };
  const { pathname } = props.location;

  function renderPathName(pathname) {
    console.log("pathname", pathname);
    switch (pathname) {
      case routes.CHATBOT:
        return <Chatbot location={location} />;
      case routes.ASK_HR:
        return <EmployeeAssistance location={location} />;
      case routes.DATABASE_AGENTS:
        return <OpenAIChatBot location={location} />;
      case routes.AI_POWERED_PRESENTATION_CREATION:
        return <PptGeneration location={location} />;
      case routes.ADVERTISEMENT_AND_VIDEO_TRANSLATION_GENERATOR:
        return <VideoCreator location={location} />;
      case routes.LANGUAGE_TRANSLATION_BOT:
        return <LanguageTranslator location={location} />;
      case routes.AUTOMATIONANALYTICS:
        return <AutomationAnalytics location={location} />;
      case routes.DATA_ANALYSIS_BOT:
        return <DataAnalysis location={location} />;
      case routes.SALES_CHATBOT:
        return <SalesGpt location={location} />;
      case routes.VOICE_CLONING_AND_LIP_SYNCHRONIZATION:
        return <VoiceCloning location={location} />;
      // case routes.MUSICGENERATION:
      //   return <MusicGeneration location={location} />;
      case routes.TEXTGENERATION:
        return <TextGeneration location={location} />;
      case routes.IMAGEGENERATION:
        return <ImageGeneration location={location} />;
      case routes.VIDEOGENERATION:
        return <VideoGeneration location={location} />;
      case routes.AUDIOGENERATION:
        return <AudioGeneration location={location} />;
      case routes.CODEGENERATION:
        return <CodeGeneration location={location} />;
      case routes.CONTENTGENERATION:
        return <ContentGeneration location={location} />;
      case routes.MUSICCOMPOSITION:
        return <MusicComposition location={location} />;
      case routes.POSE_MIMIC:
        return <Posemimic location={location} />;
      case routes.IMAGE_EDITING:
        return <ImageEnhancement location={location} />;
      case routes.SPEECHTOIMAGE:
        return <SpeechToImage location={location} />;
      case routes.VIRTUALTRYON:
        return <VirtualTryOn location={location} />;
      case routes.CHARACTER_CREATION:
        return <CharacterCreation location={location} />;
      case routes.IMAGE_GENERATION_FOR_HEALTHCARE:
        return <ImgGenHealth location={location} />;
      case routes.TEXT_SUMMARIZATION:
        return <TextSummarization location={location} />;
      case routes.TABULARDATAGEN:
        return <TabularDataGen location={location} />;
      case routes.YOUTUBE_VIDEO_QA_BOT:
        return <YoutubeQABot location={location} />;
      case routes.CODEGENUSINGGCP:
        return <CodeGenUsingGcp location={location} />;
      case routes.DATA_ANALYSIS_BOT:
        return <DataAnalysis location={location} />;
      case routes.PERSONALIZESMARKETINGCAMPAIGN:
        return <PersonalizeMarketCamp location={location} />;
      case routes.ADVERTISEMENT_AND_VIDEO_TRANSLATION_GENERATOR:
        return <VideoCreator location={location} />;
      case routes.DISNEY_POSE:
        return <CaptureImage location={location} />;
      case routes.REALSTIC_POSE:
        return <CaptureImage location={location} />;
      case routes.VIRTUALTRYON:
        return <VirtualTryOn location={location} />;
      case routes.DATABASE_AGENTS:
        return <OpenAIChatBot location={location} />;
      case routes.AUTOMATIONANALYTICSINCODE:
        return <AutomationAnalytics location={location} />;
      case routes.VOICE_CLONING_AND_LIP_SYNCHRONIZATION:
        return <VoiceCloning location={location} />;
      case routes.DATAANALYSISINTEXT:
        return <DataAnalysis location={location} />;
      case routes.SDLC:
        return <SDLC location={location} />;
      case routes.RESUME_GENIE:
        return <ResumeLists location={location} />;
      case routes.RESUME_SCREENING_ASSISTANCE:
        return <ResumeScreeningAssistance location={location} />;
      case routes.POWERED_RESUME_SUMMARIZAR:
        return <ResumeSummarizer location={location} />;
      case routes.POWERED_RESUME_RETRIEVER:
        return <ResumeRetriever location={location} />;
      case routes.BANKING_PAL:
        return <IBCAssistant location={location} />;
      case routes.PRODUCT_RECOMMENDATION:
        return <ProductRecommendation location={location} />;
      case routes.AGENTIC_TRIP_ADVISOR:
        return <TripPlanner location={location} />;
      case routes.LANGCHAIN_SQL_AGENT:
        return <LangchainSqlAgent location={location} />;
      case routes.SALES_LEAD_AUTOMATION:
        return <SalesLeadAutomation location={location} />;
      case routes.AUTO_VISUALIZATION:
        return <AutoVisualization location={location} />;
      case routes.NUTRITION_AND_FITNESS_ADVISOR:
        return <NutritonFitnessAdvisor location={location} />;
      case routes.VISUALIZATION:
        return <VisualizationGeneration location={location} />;
      //new routes
      case domainRoutes.HUMAN_RESOURCE:
        return <HumanResource location={location} />;
      case domainRoutes.OPERATIONS:
        return <Operations location={location} />;
      case domainRoutes.SALES_AND_MARKETING:
        return <SalesAndMarketing location={location} />;
      case domainRoutes.IT:
        return <IT location={location} />;
      case domainRoutes.FINANCE:
        return <Finance location={location} />;
      case domainRoutes.OTHERS:
        return <Others location={location} />;
      case routes.SEARCH:
        return <SearchResults location={location} />;
      case routes.PHARMA_HELPER:
        return <PharmaHelper location={location} />;
      case routes.AUTOMOBILE_AI:
        return <AutomobileAI location={location} />;
      case routes.USER_MANUAL:
        return <UserManual location={location} />;
      case routes.LEGAL_DOC_SUMMARIZATION:
        return <LegalDocSummarization location={location} />;
      case routes.HBF:
        return <IframeComponent location={location} />;

      case routes.GMAIL_GENAI_TOOLKIT:
        return <IframeComponent location={location} />;
      case routes.MULTI_LLM_ASSISTANT:
        return <IframeComponent location={location} />;
      case routes.SMART_HIRE:
        return <IframeComponent location={location} />;
      case routes.FINANCIAL_REPORT_ANALYZER:
        return <IframeComponent location={location} />;

      case routes.DIGITAL_CATALOGUE:
        return <IframeComponent location={location} />;
      case routes.BILLS_ANALYZER:
        return <IframeComponent location={location} />;
      case routes.CONTRACT_ANALYZER:
        return <IframeComponent location={location} />;
      case routes.SDLC_AI:
        return <IframeComponent location={location} />;
      case routes.EXECUTOR_AI:
        return <IframeComponent location={location} />;
      // case routes.DATA_ANALYTICS_DASHBOARD:
      //   return <IframeComponent location={location} />;
      case routes.MANUFACTURING_CATALYST:
        return <IframeComponent location={location} />;
      case routes.QUEUE_ANALYZER:
        return <IframeComponent location={location} />;
      case routes.IMAGE_CAMPAIGN_CREATION:
        return <IframeComponent location={location} />;
      case routes.ENTERPRISE_CHATBOT:
        return <EnterpriseGpt location={location} />;

      case routes.VIRTUAL_TRAVEL_AGENT:
        return <IframeComponent location={location} />;
      case routes.IMA:
        return <IframeComponent location={location} />;
      case routes.IMPACT_SDLC:
        return <IframeComponent location={location} />;
      case routes.AMS_TICKET_TOOL:
        return <IframeComponent location={location} />;
      case routes.AWSQ:
        return <IframeComponent location={location} />;
      case routes.CSR:
        return <IframeComponent location={location} />;
      case routes.INTELLIGENT_DOCUMENT_PROCESSING:
        return <IframeComponent location={location} />
      case routes.GUARDRAILS_TRAVEL_AGENT:
          return <IframeComponent location={location} />;
      case routes.CONVERSATION_SUMMARIZER_AND_ANALYZER:
            return <IframeComponent location={location} />;
      case routes.CODE_GENERATOR:
          return <IframeComponent location={location} />;
      case routes.LIVE_POTRAIT:
          return <IframeComponent location={location} />;
      default:
        return <Dashboard location={location} />;
    }
  }

  return (
    <>
      <Layout className="admin-dash ">
        {/**<Sider
          width={260}
          breakpoint="sm"
          collapsedWidth={sideCollapsed ? "80" : "0"}
          theme="light"
          trigger={null}
          collapsible
        >
          <SiderMenu
            history={props.history}
            user={props.user}
            collapsed={collapsed}
          ></SiderMenu>
        </Sider> */}
        <Layout className="site-layout">
          <HeaderAdmin
            toggle={toggle}
            collapsed={collapsed}
            user="Amit Kumar"
            signOut={props.signOut}
          />
          <Content
            style={{
              marginTop: "10px",
              marginRight: "10px",
              marginLeft: "10px",
              background: "#F8FAFE",
              // minHeight: "73vh",
              display: "flex",
              flex: "1",
              flexDirection: "column",
            }}
            className="ui-container"
          >
            {renderPathName(pathname)}
          </Content>
          <Footer className="footer">
            Copyright © {moment().year()} YASH Technologies. All Rights
            Reserved.
          </Footer>
        </Layout>
      </Layout>
    </>
  );
}
