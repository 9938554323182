import {
  Button,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import {
  EyeFilled,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ip from "../../../assests/img/ip-icon1.png";

const { Option } = Select;

export default function Config() {
  const location = useLocation();
  const [chatgptDepName, setChatgptDepName] = useState("");
  const [gpt4DepName, setGpt4Depname] = useState("");
  const [azureOpenAiEndPoint, setAzureOpenAiEndPoint] = useState("");
  const [azureOpenAiKey, setAzureOpenAiKey] = useState("");
  const [azureOpenAiKeyVisible, setAzureOpenAiKeyVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ipVal, setIpVal] = useState("");

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleIp = (e) => {
    setIpVal(e.target.value);
  };
  useEffect(() => {
    if (localStorage.getItem("chatgptDepName")) {
      setChatgptDepName(localStorage.getItem("chatgptDepName"));
    }
    if (localStorage.getItem("gpt4DepName")) {
      setGpt4Depname(localStorage.getItem("gpt4DepName"));
    }
    if (localStorage.getItem("azureOpenAiEndPoint")) {
      setAzureOpenAiEndPoint(localStorage.getItem("azureOpenAiEndPoint"));
    }
    if (localStorage.getItem("azureOpenAiKey")) {
      setAzureOpenAiKey(localStorage.getItem("azureOpenAiKey"));
    }
    if (localStorage.getItem("ip")) {
      setIpVal(localStorage.getItem("ip"));
    }
  }, []);

  const handleSaveDetails = () => {
    localStorage.setItem("chatgptDepName", chatgptDepName);
    localStorage.setItem("gpt4DepName", gpt4DepName);
    localStorage.setItem("azureOpenAiEndPoint", azureOpenAiEndPoint);
    localStorage.setItem("azureOpenAiKey", azureOpenAiKey);
    localStorage.setItem("ip", ipVal);
  };
  const togglePasswordVisibility = () => {
    setAzureOpenAiKeyVisible(!azureOpenAiKeyVisible);
  };

  return (
    <div>
      {/* <BreadCrumbs location={location} /> */}
      <div className="main_header">
        <b><p>Azure OpenAI Settings</p></b>
      </div>
      <div className="app-box">
        <div className="app-container">
          <Row>
            <Col style={{ margin: "20px" }} span={22}>
              <div className="ip-button">
                <b><p>ChatGPT deployment name:</p></b>
                {/* <Tooltip title="Add IP">
                  <span>
                    <Button
                      style={{
                        cursor: "pointer",
                        outline: "none",
                        border: "none",
                        background: "none",
                      }}
                      onClick={showModal}
                    >
                      <img
                        src={ip}
                        alt="ip-images"
                        width="25px"
                        height="25px"
                      />
                    </Button>
                  </span>
                </Tooltip>
                <Modal
                  title="Add IP Modal"
                  width="400px"
                  open={isModalOpen}
                  onCancel={handleCancel}
                  footer={[
                    <Button key="back" onClick={handleCancel}>
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleOk}>
                      Add IP
                    </Button>,
                  ]}
                >
                  <Input
                    placeholder="Please Enter Ip Address"
                    value={ipVal}
                    onChange={handleIp}
                  />
                </Modal> */}
              </div>
              <Input
                type="text"
                value={chatgptDepName}
                onChange={(e) => setChatgptDepName(e.target.value)}
              />
              <br />
              <b>
              <p style={{ marginTop: "10px" }}>
                GPT4 deployment name(if not specified,default to ChatGPT's):
              </p>
              </b>
              <Input
                type="text"
                value={gpt4DepName}
                onChange={(e) => setGpt4Depname(e.target.value)}
              />
              <br />
              <b><p style={{ marginTop: "10px" }}>Azure OpenAI Endpoint:</p></b>
              <Input
                type="text"
                value={azureOpenAiEndPoint}
                onChange={(e) => setAzureOpenAiEndPoint(e.target.value)}
              />
              <br />
              <b><p style={{ marginTop: "10px" }}>Azure OpenAI Key</p></b>
              <Input.Password
                value={azureOpenAiKey}
                onChange={(e) => setAzureOpenAiKey(e.target.value)}
                iconRender={(visible) => (
                  <Space>
                    {visible ? (
                      <EyeInvisibleOutlined
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <EyeOutlined onClick={togglePasswordVisibility} />
                    )}
                  </Space>
                )}
              />
            </Col>
          </Row>

          <div className="app-box" style={{ marginTop: "20px" }}>
            <Button
              type="primary"
              className="generate-button"
              // loading={isPptLoading}
              onClick={handleSaveDetails}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
