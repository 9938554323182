import React, { useState } from "react";
import { VideoCameraOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Tooltip } from "antd";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

function VideoTour(props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Tooltip title="Video Tour">
        <Button
          shape="circle"
          icon={<VideoCameraOutlined />}
          onClick={() => setOpen(true)}
        />
      </Tooltip>

      <Modal
        style={{
          top: 20,
        }}
        destroyOnClose={true}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        title="Tour"
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={"100%"}
      >
        <Row gutter={[8, 8]}>
          <Col sx={24} sm={24} md={24} xl={6} lg={6} xxl={6}>
            <video width="100%" height="250" controls autoplay>
              <source src={props.src} type={props.type} />
            </video>
          </Col>
          <Col sx={24} sm={24} md={24} xl={18} lg={18} xxl={18}>
            <div
              style={{
                border: "1px solid rgba(0, 0, 0, 0.3)",
                height: "500px",
                overflow: "scroll",
                width: "100%",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer fileUrl={props.pdfSrc} />
              </Worker>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default VideoTour;
