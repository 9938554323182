import React, { useState, useEffect, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import axios from "axios";
import { DotLoading } from "antd-mobile";
import { NavLink } from "react-router-dom";
import { IP } from "../../../../../dynamicIPs";
// import { routes } from "../../util/constants";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Tooltip,
  message,
  Badge,
  Input,
  Carousel,
} from "antd";
import { DeleteOutlined, EyeFilled, PlusOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const CaptureImage = (props) => {
  const [webcamImages, setWebcamImages] = useState([]);
  const [responseLoading, setResponseLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [imageUrls, setImageUrls] = useState([]);

  const webcamRef = useRef(null);

  const [imageFile, setimageFile] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  // const handleSubmitTraining = () => {h}

  const handleSubmit = async () => {
    setResponseLoading(true);
    setImageUrls([]);
    const url = `http://${IP.DREAMBOOTH}:8010/dreambooth`;
    const params = {
      query_string: inputValue,
    };
    try {
      const res = await axios.get(url, { params });
      setResponseLoading(false);
      setImageUrls(res.data.data);
    } catch (error) {
      setResponseLoading(false);
      message.error(error.message);
    }
  };

  return (
    <div>
      {/* <Card>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            style={{ marginRight: "50px" }}
            type="primary"
            onClick={() => {
              setOpen(true);
              setWebcamImages([]);
            }}
          >
            Upload 10-15 Good Quality Images
          </Button>

          <Button type="primary" onClick={showModalWebcam}>
            Capture Through Webcam
          </Button>
        </div>
      </Card>
      {imageUrls.length > 0 ? (
        <Card
          style={{
            marginTop: "30px",
          }}
        >
          <Carousel autoplay>
            {imageUrls.map((v) => (
              <div>
                <img src={v} height={400} width="100%" controls />
              </div>
            ))}
          </Carousel>
        </Card>
      ) : null}
      {webcamImages.length > 0 ? (
        <Card
          style={{
            marginTop: "30px",
          }}
        >
          <Carousel autoplay>
            {webcamImages.map((v) => (
              <div>
                <img src={v} height={400} width="100%" controls />
              </div>
            ))}
          </Carousel>
        </Card>
      ) : null} */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <b>
          <p>USING OUR FINETUNED MODEL GENERATING SOME IMAGES USING TEXT</p>
        </b>
      </div>
      <Card>
        <p>
          <b>Example Prompts</b>:Portrait Shot of (Yuvraj12sd person:1.5)
          Wearing Sunglasses,Photo Realistic,best Quality,Shot With DSLR
        </p>
      </Card>
      <Card style={{ marginTop: "30px" }}>
        <Col span={24}>
          <p>Enter Your Prompt:</p>
          <Input
            type="text"
            onChange={handleInputChange}
            value={inputValue}
            placeholder="make sure you have yuvraj12sd person in your prompt"
          />
        </Col>
      </Card>
      <Row gutter={[8, 8]} style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Button
            type="primary"
            style={{ width: "100%", marginTop: "20px" }}
            onClick={handleSubmit}
            loading={responseLoading}
          >
            Generate Image
          </Button>
        </Col>
      </Row>
      {imageUrls.length > 0 ? (
        <Card
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <h2>hello</h2> */}
          <Row gutter={[8, 8]} style={{ margin: "40px" }}>
            {imageUrls.map((url, index) => (
              <Col
                span={12}
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  width={478}
                  height={400}
                  src={`http://${IP.DREAMBOOTH}:8010/file-response?file_path=${
                    url.path
                  }&n=${Math.random()}`}
                  alt="image"
                />
              </Col>
            ))}
          </Row>
        </Card>
      ) : null}
    </div>
  );
};

export default CaptureImage;
