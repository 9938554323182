import {
  ArrowRightOutlined,
  CaretRightOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  vscDarkPlus,
  solarizedlight,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  Row,
  Select,
  Space,
  Table,
  message,
} from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Plotly from "plotly.js/lib/core";
import createPlotlyComponent from "react-plotly.js/factory";
import { json } from "react-router-dom";
import { object } from "prop-types";
import { IP } from "../../../dynamicIPs";

export default function Application() {
  const [selectApp, setSelectApp] = useState("");
  const [selectedSqlEngine, setSelectedSqlEngine] = useState("");
  const [gptModal, setGptModal] = useState("");
  const [showCodeCheckbox, setShowCodeCheckbox] = useState(false);
  const [showPromptCheckbox, setPromtCheckbox] = useState(false);
  const [faqsValue, setFaqsValue] = useState("");
  const [sqlPasswordToggle, setSqlpasswordToggle] = useState(false);
  const [tableResponse, setTableResponse] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [isTableResponse, setIsTableResponse] = useState(false);
  const [sqlCode, setSqlCode] = useState([]);
  const [promptCode, setPromtCode] = useState([]);
  const [secondSqlCode, setSecondSqlCode] = useState([]);
  const [secondPrompt, setSecondPrompt] = useState([]);

  const [allGraphData, setAllGrpahData] = useState([]);
  const [allGraphLayout, setAllGraphLayout] = useState([]);
  const [secondTableColumn, setSecondTableColumn] = useState([]);
  const [secondTableData, setSecondTableData] = useState([]);
  const [allJsonImages, setAllJsonImages] = useState([]);
  const [allTought, setAllThought] = useState([]);
  const [thoughtKey, setThoughtKey] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [allActions, setAllActions] = useState([]);

  const [sqlCredential, setSqlCredential] = useState({
    sqlServerValue: "",
    databaseName: "",
    user: "",
    password: "",
  });
  const { Option } = Select;
  const Plot = createPlotlyComponent(Plotly);

  const gptModalOptions = ["ChatGPT"];
  const fqaOptionforOption1 =
    gptModal === "ChatGPT"
      ? [
          "Show me revenue by product in ascending order",
          "Show me top 10 most expensive products",
          "Show me net revenue by year. Revenue time is based on shipped date.",
          "For each category, get the list of products sold and the total sales amount",
          "Find Quarterly Orders by Product. First column is Product Name, then year then four other columns, each for a quarter. The amount is order amount after discount",
        ]
      : [
          "Pick top 20 customers generated most revenue in 2016 and for each customer show 3 products that they purchased most",

          "Which products have most seasonality in sales quantity in 2016?",
        ];
  const fqaOptionforOption2 =
    gptModal === "ChatGPT"
      ? [
          "Show me daily revenue trends in 2016 per region",

          "Is that true that top 20% customers generate 80% revenue in 2016? What's their percentage of revenue contribution?",

          "Which products have most seasonality in sales quantity in 2016?",

          "Which customers are most likely to churn?",

          "What is the impact of discount on sales? What's optimal discount rate?",

          "Predict monthly revenue for next 6 months starting from June-2018. Show the prediction in a chart together with historical data for comparison.",
        ]
      : [
          "Predict monthly revenue for next 6 months starting from June-2018. Do not use Prophet. Show the prediction in a chart together with historical data for comparison.",

          "What is the impact of discount on sales? What's optimal discount rate?",
        ];

  const fqaOptionforOption3 =
    gptModal === "ChatGPT"
      ? [
          "How does customer purchasing behavior vary across different countries or regions?",

          "Which customer segments are the most valuable in terms of overall revenue?",

          "Are there specific regions where a particular customer segment is more prevalent?",

          "What are the most frequently ordered products by each customer segment?",

          "Which customer segments have the highest average order values?",
        ]
      : [
          "What is the distribution of recency, frequency, and monetary value scores across different customer segments?",

          "Can you identify potential cross-selling opportunities based on the purchasing patterns of different segements",
        ];
  useEffect(() => {
    if (localStorage.getItem("sqlServerValue")) {
      setSqlCredential({
        ...sqlCredential,
        sqlServerValue: localStorage.getItem("sqlServerValue"),
        databaseName: localStorage.getItem("databasename"),
        user: localStorage.getItem("user"),
        password: localStorage.getItem("password"),
      });
    }
  }, []);
  const ImageRenderer = ({ plotlyImageJson, data, layout }) => {
    return (
      <div>
        <Plot
          data={data}
          layout={layout}
          config={plotlyImageJson}
          useResizeHandler
          style={{ width: "100%", height: "auto" }}
        />
      </div>
    );
  };
  const finalFqaOptions =
    selectApp === "SQL_Query_Writing_Assistant"
      ? fqaOptionforOption1
      : selectApp === "Data_Analysis_Assistant_(Advanced_Analytics)"
      ? fqaOptionforOption2
      : fqaOptionforOption3;

  useEffect(() => {
    if (Object.keys(tableResponse).length > 0) {
      const output = [];

      const entries = Object.entries(tableResponse);

      const keySize = Object.keys(entries[0][1]).length;

      for (let i = 0; i < keySize; i++) {
        const obj = {};
        for (const [key, value] of entries) {
          obj[key] = value[i];
        }
        output.push(obj);
      }

      setTableData(output);
      const keys = Object.keys(tableResponse);

      const columns = keys.map((columnName) => ({
        title: columnName,
        dataIndex: columnName,
        key: columnName,
      }));

      setTableColumns(columns);
    }
  }, [tableResponse]);

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
      return true;
    } catch (error) {
      return false;
    }
  };
  const handleSubmit = async () => {
    setIsTableResponse(true);
    setSubmitLoading(true);
    setTableData([]);
    setTableColumns([]);
    setAllGraphLayout([]);
    setAllGrpahData([]);
    setSecondTableColumn([]);
    setSecondTableData([]);
    setAllThought([]);
    setAllJsonImages([]);
    setAllActions([]);
    setSecondPrompt([]);

    setSqlCode("");
    const body = {
      index: selectApp,
      gpt_engine: gptModal,
      question: faqsValue,
      show_code: showCodeCheckbox,
      show_prompt: showPromptCheckbox,
      sqlengine: selectedSqlEngine,
      sqlserver: sqlCredential.sqlServerValue,
      sqldatabase: sqlCredential.databaseName,
      sqluser: sqlCredential.user,
      sqlpassword: sqlCredential.password,
    };
    const url = `http://${IP.GENAI}:8003/automation-analytics`;
    try {
      const res = await axios.post(url, body);
      setSubmitLoading(false);

      // setPlotlyImageJson(res.data[1]);
      if (selectApp === "SQL_Query_Writing_Assistant") {
        const data = JSON.parse(res.data[1]);

        setTableResponse(data);
        setIsTableResponse(false);
        setSqlCode(res.data[0]["SQL Code"]);
        setPromtCode(res.data[0]["Prompt"]);
      } else {
        const len = res.data.length;
        const output = res.data;
        const total_graphdata = [];
        const total_layout = [];
        const all_jsonImages = [];
        const all_thought = [];
        const keys_output = [];

        for (let i = 0; i < len; i++) {
          if (isJsonString(output[i])) {
            const parseData = JSON.parse(output[i]);

            if (parseData.hasOwnProperty("data")) {
              total_graphdata.push(parseData["data"]);
              total_layout.push(parseData["layout"]);
              all_jsonImages.push(output[i]);
            }
          } else {
            if (
              typeof output[i] !== "string" &&
              Object.keys(output[i]).length > 1
            ) {
              const keys = Object.keys(output[i]);
              const secondTableColumn = keys.map((columnName) => ({
                title: columnName,
                dataIndex: columnName,
                key: columnName,
              }));
              setSecondTableColumn(secondTableColumn);
              const final_table_output = [];

              const entries = Object.entries(output[i]);

              const keySize = Object.keys(entries[0][1]).length;
              for (let i = 0; i < keySize; i++) {
                const obj = {};
                for (const [key, value] of entries) {
                  obj[key] = value[i];
                }
                final_table_output.push(obj);
              }
              setSecondTableData(final_table_output);
            } else if (
              typeof output[i] !== "string" &&
              Object.keys(output[i]).length === 1 &&
              Object.keys(output[i])[0] !== "Prompt"
            ) {
              if (Object.keys(output[i])[0] === "Action:") {
                setAllActions(output[i]["Action:"]);
              } else {
                all_thought.push(output[i]);
              }
            } else {
              const prompt = output[i]["Prompt"] ? output[i]["Prompt"] : [];
              setSecondPrompt(prompt);
            }
          }
        }
        setAllGrpahData(total_graphdata);
        setAllGraphLayout(total_layout);
        setAllJsonImages(all_jsonImages);
        setAllThought(all_thought);
        for (let j = 0; j < all_thought.length; j++) {
          keys_output.push(Object.keys(all_thought[j])[0]);
        }
        setThoughtKey(keys_output);
      }
    } catch (error) {
      message.error(error.message);
      setSubmitLoading(false);
    }
  };

  const handleShowCodeCheckbox = (e) => {
    setShowCodeCheckbox(e.target.checked);
  };
  const handleShowPromtCheckbox = (e) => {
    setPromtCheckbox(e.target.checked);
  };

  const handleSqlEngineChange = (e) => {
    setSelectedSqlEngine(e.target.value);
    setSqlCredential({
      sqlServerValue: "",
      databaseName: "",
      user: "",
      password: "",
    });
  };
  const handleSqlCredential = (e) => {
    const { name, value } = e.target;
    setSqlCredential({ ...sqlCredential, [name]: value });
  };
  const handleAppChange = (e) => {
    setSelectApp(e.target.value);
  };

  const handleSqlServerCreSave = () => {
    localStorage.setItem("sqlServerValue", sqlCredential.sqlServerValue);
    localStorage.setItem("databasename", sqlCredential.databaseName);
    localStorage.setItem("user", sqlCredential.user);
    localStorage.setItem("password", sqlCredential.password);
  };
  const togglePasswordVisibility = () => {
    setSqlpasswordToggle(!sqlPasswordToggle);
  };

  return (
    <>
      <Card style={{ borderRadius: "10px" }}>
        <p>
          <b>Choose the Application:</b>
        </p>
        <Row gutter={[8, 8]}>
          <Col sx={24} sm={24} md={8} xl={8} lg={8}>
            <Card style={{ borderRadius: "10px" }}>
              <label style={{ padding: "10px" }}>
                <input
                  type="radio"
                  value="SQL_Query_Writing_Assistant"
                  checked={selectApp === "SQL_Query_Writing_Assistant"}
                  onChange={handleAppChange}
                />
                <span style={{ marginLeft: "10px" }}>
                  SQL Query Writing Assistant
                </span>
              </label>
            </Card>
          </Col>

          <Col sx={24} sm={24} md={10} xl={10} lg={10}>
            <Card style={{ borderRadius: "10px" }}>
              <label style={{ padding: "10px" }}>
                <input
                  type="radio"
                  value="Data_Analysis_Assistant_(Advanced_Analytics)"
                  checked={
                    selectApp === "Data_Analysis_Assistant_(Advanced_Analytics)"
                  }
                  onChange={handleAppChange}
                />
                <span style={{ marginLeft: "10px" }}>
                  Data Analysis Assistant(Advanced Analytics)
                </span>
              </label>
            </Card>
          </Col>
          <Col sx={24} sm={24} md={6} xl={6} lg={6}>
            <Card style={{ borderRadius: "10px" }}>
              <label style={{ padding: "10px" }}>
                <input
                  type="radio"
                  value="Customer_Segmentation"
                  checked={selectApp === "Customer_Segmentation"}
                  onChange={handleAppChange}
                />
                <span style={{ marginLeft: "10px" }}>
                  Customer Segmentation
                </span>
              </label>
            </Card>
          </Col>
        </Row>
      </Card>
      <Card style={{ borderRadius: "10px", marginTop: "20px" }}>
        <b>
          <p>SQL Engine:</p>
        </b>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Card style={{ borderRadius: "10px" }}>
              <label style={{ padding: "10px" }}>
                <input
                  type="radio"
                  value="sqlite"
                  checked={selectedSqlEngine === "sqlite"}
                  onChange={handleSqlEngineChange}
                />

                <span style={{ marginLeft: "10px" }}>SQLite</span>
              </label>
            </Card>
          </Col>

          <Col span={12}>
            <Card style={{ borderRadius: "10px" }}>
              <label style={{ padding: "10px" }}>
                <input
                  type="radio"
                  value="sqlserver"
                  checked={selectedSqlEngine === "sqlserver"}
                  onChange={handleSqlEngineChange}
                />
                <span style={{ marginLeft: "10px" }}>SQL Server</span>
              </label>
            </Card>
          </Col>
        </Row>
      </Card>
      {selectedSqlEngine === "sqlserver" ? (
        <Card style={{ marginTop: "20px", borderRadius: "10px" }}>
          <div>
            <p>SQL Server:</p>
            <Input
              type="text"
              name="sqlServerValue"
              value={sqlCredential.sqlServerValue}
              onChange={handleSqlCredential}
              style={{ borderRadius: "10px" }}
            />
            <p>Database:</p>
            <Input
              type="text"
              name="databaseName"
              value={sqlCredential.databaseName}
              onChange={handleSqlCredential}
              style={{ borderRadius: "10px" }}
            />
            <p>Username:</p>
            <Input
              type="text"
              value={sqlCredential.user}
              name="user"
              onChange={handleSqlCredential}
              style={{ borderRadius: "10px" }}
            />
            <p>Password:</p>

            <Input.Password
              value={sqlCredential.password}
              onChange={handleSqlCredential}
              style={{ borderRadius: "10px" }}
              name="password"
              iconRender={(visible) => (
                <Space>
                  {visible ? (
                    <EyeInvisibleOutlined onClick={togglePasswordVisibility} />
                  ) : (
                    <EyeOutlined onClick={togglePasswordVisibility} />
                  )}
                </Space>
              )}
            />
          </div>
          <br />
          <Button
            type="primary"
            onClick={handleSqlServerCreSave}
            style={{ width: "100%" }}
          >
            Save
          </Button>
        </Card>
      ) : (
        <></>
      )}
      <Card style={{ marginTop: "20px", borderRadius: "10px" }}>
        <b>
          <p>GPT Model:</p>
        </b>
        <Select
          onChange={(select) => {
            setGptModal(select);
            setFaqsValue("");
          }}
          className="dropdown-child"
          style={{ width: "100%", textAlign: "center", borderRadius: "10px" }}
        >
          {gptModalOptions.map((item) => (
            <Option key={item} value={item} style={{ textAlign: "center" }}>
              {item}
            </Option>
          ))}
        </Select>
      </Card>
      <Card style={{ marginTop: "10px", borderRadius: "10px" }}>
        <b>
          <p>FAQs:</p>
        </b>
        <Select
          onChange={(select) => setFaqsValue(select)}
          // value={faqsValue}
          className="dropdown-child"
          style={{ width: "100%", textAlign: "center", borderRadius: "10px" }}
        >
          {finalFqaOptions.map((item) => (
            <Option key={item} value={item} style={{ textAlign: "center" }}>
              {item}
            </Option>
          ))}
        </Select>
        <div style={{ marginTop: "10px" }}>
          <Checkbox onChange={handleShowCodeCheckbox}>Show Code</Checkbox>
          <Checkbox onChange={handleShowPromtCheckbox}>Show Prompt</Checkbox>
        </div>
      </Card>
      <Card style={{ marginTop: "20px", borderRadius: "10px" }}>
        <b>
          <p>Ask me a question:</p>
        </b>
        <Input
          // autoSize={{ minRows: "2", maxRows: "10" }}
          value={faqsValue}
          onChange={(e) => setFaqsValue(e.target.value)}
          style={{ borderRadius: "10px" }}
        />
      </Card>
      <Button
        type="primary"
        style={{ width: "100%", marginTop: "20px", borderRadius: "10px" }}
        onClick={handleSubmit}
        loading={submitLoading}
      >
        Submit
      </Button>
      {sqlCode.length > 0 ? (
        <Card style={{ marginTop: "20px" }}>
          <SyntaxHighlighter language={"sql"} style={vscDarkPlus}>
            {sqlCode[0]}
          </SyntaxHighlighter>
          <div>
            <CaretRightOutlined />
            {sqlCode[1]}
          </div>
          {promptCode.length > 0 ? (
            <SyntaxHighlighter language="json" style={vscDarkPlus}>
              {JSON.stringify(promptCode, null, 2)}
            </SyntaxHighlighter>
          ) : null}
        </Card>
      ) : null}
      {tableData.length > 0 ? (
        <Card style={{ marginTop: "30px" }}>
          <Table dataSource={tableData} columns={tableColumns} />
        </Card>
      ) : null}
      {allTought.length > 0 ? (
        <Card style={{ marginTop: "30px" }}>
          {allTought.map((thought, index) => (
            <>
              <h5>{thoughtKey[index]}</h5>
              <p>{thought[`${thoughtKey[index]}`]}</p>
            </>
          ))}
        </Card>
      ) : null}
      {secondPrompt.length > 0 ? (
        <Card style={{ marginTop: "30px" }}>
          <h5>Promp:</h5>
          <SyntaxHighlighter language="json" style={vscDarkPlus}>
            {JSON.stringify(secondPrompt, null, 2)}
          </SyntaxHighlighter>
        </Card>
      ) : null}
      {allActions.length > 0 ? (
        <Card style={{ marginTop: "30px" }}>
          <h4>Actions:</h4>
          <SyntaxHighlighter language={"sql"} style={vscDarkPlus}>
            {allActions}
          </SyntaxHighlighter>
        </Card>
      ) : null}
      {secondTableColumn.length > 0 ? (
        <Card style={{ marginTop: "30px" }}>
          <Table
            columns={secondTableColumn}
            dataSource={secondTableData}
            // loading={respo}
            pagination={{ pageSize: 10 }}
            scroll={{ y: 450 }}
          />
        </Card>
      ) : null}
      <div>
        {allJsonImages.length > 0
          ? allJsonImages.map((jsonImage, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Row gutter={[16, 16]}>
                  <Col style={{ marginTop: "20px" }}>
                    <ImageRenderer
                      plotlyImageJson={jsonImage}
                      data={allGraphData[index]}
                      layout={allGraphLayout[index]}
                    />
                  </Col>
                </Row>
              </div>
            ))
          : null}
      </div>

      {/* {firstResponse ? (
        <Card>
          <Table
            columns={tableColumns}
            dataSource={tableResponse}
            // loading={respo}
            pagination={{ pageSize: 10 }}
            scroll={{ y: 450 }}
          />
        </Card>
      ) : null} */}
    </>
  );
}
