export const videoUrls = {
  "Advertisement_Content_Generator_recording": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Advertisement%20Content%20Generator%20recording.mp4",
  "AutomobAI": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/AutomobAI.mp4",
  "Avatar": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Avatar.mp4",
  "Banking_pal": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Banking_pal.mp4",
  "DB_agents_recording": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/DB%20agents%20recording.mp4",
  "Dashboard_Recording": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Dashboard%20Recording.mp4",
  "Delay": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Delay.mp4",
  "Demo_Queue_Analyser": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Demo_Queue_Analyser.mp4",
  "Financial_services_agent": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Financial%20services%20agent.mp4",
  "Financial_report_analyzer-recording": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Financial_report_analyzer-recording.mp4",
  "IDP": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/IDP.mp4",
  "Image_campaign_creation": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Image%20campaign%20creation.mp4",
  "Invoice_Analyzer": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Invoice%20Analyzer.mp4",
  "KYC-Recording": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/KYC-Recording.mp4",
  "Language_translation": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Language_translation.mp4",
  "Manufacturing_Catalyst": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Manufacturing%20Catalyst.mp4",
  "PPT_maker": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/PPT%20maker.mp4",
  "Pharma_helper": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Pharma%20helper.mp4",
  "Resume_Genie_recording": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Resume%20Genie%20recording.mp4",
  "SDLC_AI_usecase_simplified2": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/SDLC%20AI%20usecase%20simplified2.mp4",
  "SDLC": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/SDLC.mp4",
  "Sales_Chatbot": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Sales%20Chatbot.mp4",
  "Semantic_Search": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Semantic%20Search.mp4",
  "Smart_HIRE_walkthrough": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Smart%20HIRE%20walkthrough.mp4",
  "VirtualVogue": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/VirtualVogue.mp4",
  "Visualization": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Visualization.mp4",
  "YT_Q&A": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/YT%20Q%26A.mp4",
  "ask_hr_video": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/ask_hr_video.mp4",
  "character_creation": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/character_creation.mp4",
  "cloning_and_lip_sync": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/cloning%20and%20lip%20sync.mp4",
  "code_executor": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/code%20executor.mp4",
  "contract_analyzer": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/contract%20analyzer.mp4",
  "devsecops_recording": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/devsecops_recording.mp4",
  "digital_catalogue_summarizer": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/digital%20catalogue%20summarizer.mp4",
  "gmail_genai_toolkit": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/gmail_genai_toolkit.mp4",
  "image_editor_recording": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/image_editor_recording.mp4",
  "multilingual_multillm_video": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/multilingual_multillm_video.mp4",
  "pose_mimic": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/pose_mimic.mp4",
  "usermanual_usecase_recording": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/usermanual_usecase_recording.mp4",
  "virtualTryOn": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/virtualTryOn.mp4",
  "wildfire_rec": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/wildfire_rec.mp4",
  "ibc": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/ibc_app_record_90sec+1.mp4",
  "auto_visualization": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Auto-Visualization-use-case.mp4",
  "langchain": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/LangchainSQLAgent-usecase.mp4",
  "sales_lead_automation": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/SalesLeadAutomation-use-case+1.mp4",
  "trip_planner": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Trip%20advisor%201.mp4",
  "impact":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Impact+SDLC+Application+Demo.mp4",
  "custom_persona":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Custom+Persona+GPT.mp4",
  "csr":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/csr.mp4",
  "awsqYashTechAI":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/awsq.mp4",

  "ams":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Ams_Ticket_Tool.mp4",
  "nutrition":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Nutrition.mp4",
  "travelAgent": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/TravelAgent.mp4",

  "idp":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/idp.mp4",
  "gta":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/gta.mp4",
  "csa": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/csa.mp4",
  "code_generator": "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/CodeGenerator.mp4",
  "awqYashTechAI":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/awsYashTechAI.mp4",
  "lp":"https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/lp.mp4"
};
