import resumeAssistance from "../../../../assests/img/resume-assistance.jpg";
import resumeSummaryImg from "../../../../assests/img/resume-summary.jpg";
import resumeRetrieverImg from "../../../../assests/img/resume-retriever.jpg";
import { routes } from "../../../../util/constants";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

export const resumeData = [
  {
    name: "AI Powered Resume Retriever",
    icon: resumeRetrieverImg,
    url: routes.POWERED_RESUME_RETRIEVER,
    videoSrc:`${videoUrls.Resume_Genie_recording}`,
    pdfSrc: `${pdfUrls.Resume_Genie}`,
    awsVideo: true,
    tag: "Virtual Assistant",
    desc: "This innovative tool, powered by cutting-edge technology, takes the guesswork out of recruitment. Simply provide a job description, and let our AI easily identify the most relevant candidates from a vast pool of resumes in few seconds!",
  },
  {
    name: "AI Powered Resume Summarizer",
    icon: resumeSummaryImg,
    url: routes.POWERED_RESUME_SUMMARIZAR,
    videoSrc: `${videoUrls.Resume_Genie_recording}`,
    pdfSrc: `${pdfUrls.Resume_Genie}`,
    awsVideo: true,
    tag: "Virtual Assistant",
    desc: "Manually reviewing numerous resumes during the recruitment process can be time-consuming. This application automates resume summarization, improving hiring efficiency. It leverages powerful language models to extract key information and present it concisely.",
  },
  {
    name: "Resume Screening Assistance",
    icon: resumeAssistance,
    url: routes.RESUME_SCREENING_ASSISTANCE,
    videoSrc: `${videoUrls.Resume_Genie_recording}`,
    pdfSrc: `${pdfUrls.Resume_Genie}`,
    awsVideo: true,
    tag: "Virtual Assistant",
    desc: "Leverage our LLM tool which is developed to assist with HR resume screening by summarizing resumes and determining their suitability for a specific job description.",
  },
];
