import React, { useState } from "react";
import { Tabs, Input, Tooltip, Button, Modal } from "antd";
import ContentAutomation from "./Video-Creator/ContentAutomation";
import Config from "./Video-Creator/Config";
import ip from "../../../assests/img/ip-icon1.png";
import { useLocation } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import VideoTour from "../../video-guide";
import { videoUrls } from "../../../util/videoData";
import { pdfUrls } from "../../../util/pdfData";

const { TabPane } = Tabs;

export default function VideoCreator() {
  const location = useLocation();
  const [ipVal, setIpVal] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleIp = (event) => {
    localStorage.setItem("ip", event.target.value);
    const ip = localStorage.getItem("ip");
    setIpVal(ip);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <BreadCrumbs location={location} />
      <div style={{ margin: "-25px 0 0 auto" }}>
        <VideoTour src={videoUrls.Advertisement_Content_Generator_recording} type="video/mp4" pdfSrc={pdfUrls.Advertisement_Content_Generator} />
      </div>
      <div className="main-container">
        <div className="header-ai">
          <b className="heading-ai">
            <p style={{ marginBottom: "0px" }}>
              Advertisement Content Generator
            </p>
          </b>

          {/* <Tooltip title="Add IP">
            <span>
              <Button
                style={{
                  cursor: "pointer",
                  outline: "none",
                  border: "none",
                  background: "none",
                }}
                onClick={showModal}
              >
                <img src={ip} alt="ip-images" width="25px" height="25px" />
              </Button>
            </span>
          </Tooltip>
          <Modal
            title="Add IP Modal"
            width="400px"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={handleOk}>
                Add IP
              </Button>,
            ]}
          >
            <Input
              placeholder="Please Enter Ip Address"
              value={ipVal}
              onChange={handleIp}
            />
          </Modal> */}
        </div>
        <div className="app-tab">
          <Tabs
            defaultActiveKey="content-automation"
            type="card"
            tabBarGutter={12}
            size={"large"}
          >
            <TabPane
              tab={<span>Content-Automation</span>}
              key="content-automation"
            >
              <ContentAutomation />
            </TabPane>
            {/* <TabPane tab={<span>Config</span>} key="config">
              <Config />
            </TabPane> */}
          </Tabs>
        </div>
      </div>
    </>
  );
}
