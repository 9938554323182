import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

const ImageCaraousal = ({
  imageData = [],
  handleSelectImage,
  isBase64,
  isFullWidth,
}) => {
  const [currentImages, setCurrentImages] = useState([]);
  const [pageSize, setPageSize] = useState(isBase64 ? 5 : 4);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  useEffect(() => {
    const currentImg = imageData.slice(0, pageSize);
    const total = Math.ceil(imageData.length / pageSize);
    setTotalPage(total);
    setCurrentImages(currentImg);
  }, []);

  const handleClickNext = () => {
    setCurrentPage((prev) => prev + 1);

    let startIndex = currentPage * pageSize;
    let endIndex =
      currentPage * pageSize + (pageSize + 1) > imageData.length
        ? imageData.length
        : currentPage * pageSize + pageSize;
    const currentData = imageData.slice(startIndex, endIndex);
    setCurrentImages(currentData);
  };
  const handleClickPrev = () => {
    setCurrentPage((prev) => prev - 1);
    let endIndex = (currentPage - 1) * pageSize;
    let startIndex = endIndex - pageSize;
    const currentData = imageData.slice(startIndex, endIndex);
    setCurrentImages(currentData);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: isBase64 ? "center" : "flex-start",
        alignItems: "center",
        width: isFullWidth ? "100%" : "400px",
        gap: isBase64 ? "100px" : "26px",
      }}
    >
      {currentPage !== 1 && (
        <LeftOutlined style={{ cursor: "pointer" }} onClick={handleClickPrev} />
      )}
      {currentImages.map((image, index) =>
        isBase64 ? (
          <span
            key={index}
            onClick={() => handleSelectImage(`data:image/jpeg;base64,${image}`)}
          >
            <img
              src={`data:image/jpeg;base64,${image}`}
              alt="human image"
              width={150}
              height={200}
              style={{ cursor: "pointer" }}
            />
          </span>
        ) : (
          <span key={index} onClick={() => handleSelectImage(image)}>
            <img
              src={image.url}
              alt="human image"
              width={60}
              height={60}
              style={{ cursor: "pointer" }}
            />
          </span>
        )
      )}
      {currentPage !== totalPage && (
        <RightOutlined
          style={{ cursor: "pointer" }}
          onClick={handleClickNext}
        />
      )}
    </div>
  );
};

export default ImageCaraousal;
