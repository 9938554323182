import { routes, streamlitRoutes } from "../../../util/constants";
// import genAiChatbotImg from "../../../assests/icon/Database Agents.svg";
// import TextSumImg from "../../../assests/icon/Text Summarization.svg";
import QA from "../../../assests/img/Q&A.jpg";
// import dataanalImg from "../../../assests/icon/Data Analysis Bot.svg";
// import musicImg from "../../../assests/icon/Music Generation Bot.svg";
import posemimic from "../../../assests/icon/Pose Mimic.svg";
import character from "../../../assests/icon/Character Creation.svg";
import pharma from "../../../assests/img/pharma-ai.jpg";
import automobAI from "../../../assests/img/automob-ai.jpg";
import genAI from "../../../assests/img/Gen-AI.jpg";
import manufacturing from "../../../assests/img/manufacturing.jpg";
import wildfire from "../../../assests/img/forest 1.png";
import nutrition from "../../../assests/img/nutrition.jpg";
import yashtechai from "../../../assests/img/yashtechai.jpg";
import csr from "../../../assests/img/csr.jpg";
import { videoUrls } from "../../../util/videoData";
import { pdfUrls } from "../../../util/pdfData";
import ticket from "../../../assests/img/ticket.jpg";
import idpp from "../../../assests/img/idpp.jpg";
import lp from "../../../assests/img/lp.jpg";

export const othersData = [
  // {
  //   name: "Text Summarization",
  //   icon: TextSumImg,
  //   url: routes.TEXT_SUMMARIZATION,
  //   tag: "Productivity",
  //   desc: "Tired of shifting through lengthy customer and agent chats? Our tool extracts the crucial points and provides concise 3-point summaries, saving you time and effort.",
  // },
  {
    name: "Youtube Video Q&A Bot",
    icon: QA,
    // url: routes.YOUTUBE_VIDEO_QA_BOT,
    awsVideo: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/YT%20Q%26A.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/YT%20Q%26A.pdf",
    tag: "Productivity",
    desc: "Educators and HR professionals, elevate your learning & development programs! We offer a suite of AI-powered video tools designed to - resynthesize videos in language of your choice with captioning and variety of voices.",
  },
  // {
  //   name: "Data Analysis Bot",
  //   icon: dataanalImg,
  //   url: routes.DATA_ANALYSIS_BOT,
  //   tag: "Productivity",
  //   desc: "OpenAI is an AI research and deployment company. Our mission is to ensure that artificial general intelligence benefits all of humanity.",
  // },
  // {
  //   name: "Music Generation Bot",
  //   icon: musicImg,
  //   url: routes.MUSICCOMPOSITION,
  //   tag: "Creativity",
  //   desc: "OpenAI is an AI research and deployment company. Our mission is to ensure that artificial general intelligence benefits all of humanity.",
  // },
  {
    name: "Pose Mimic",
    icon: posemimic,
    // url: routes.POSE_MIMIC,
    awsVideo: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/pose_mimic.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/pose_mimic_prompts.pdf",
    tag: "Creativity",
    desc: "Witness the prowess of controlling the image generation with your poses and natural language prompts to create Disney Chars and Real humans for different applications.",
  },
  {
    name: "Character Creation",
    icon: character,
    // url: routes.CHARACTER_CREATION,
    awsVideo: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/character_creation.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/character_creation_prompts.pdf",
    tag: "Creativity",
    desc: "Create custom characters and let massive image generation model re-synthesis your subject with natural language prompts. Image generation with endless possibilities.",
  },
  {
    name: "Pharma Helper",
    icon: pharma,
    // url: routes.PHARMA_HELPER,
    awsVideo: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Pharma%20helper.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/pharma_helper.pdf",
    tag: "Productivity",
    desc: "Your friendly Medical assistant. Seeing is believing, feeling is understanding. Ask our image-savvy AI assistant any health question and get insightful information based on your picture. We guide you, not diagnose, for a healthier, more informed you.",
  },
  {
    name: "Automob-AI",
    icon: automobAI,
    // url: routes.AUTOMOBILE_AI,
    awsVideo: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/AutomobAI.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/AutomobAI.pdf",
    tag: "Productivity",
    desc: "Your friend for automobiles. Forget searching manuals, our AI mechanic speaks your language! Just snap a pic of your car and ask any question. We'll diagnose the issue, suggest fixes, and keep your ride running smooth. It's like having a pocket-sized expert by your side.",
  },
  {
    name: "Ams Ticket Tool",
    icon: ticket,
    url: routes.AMS_TICKET_TOOL,
    videoSrc: videoUrls.ams,
    pdfSrc: pdfUrls.ams,
    awsVideo: true,
    tag: "ChatBot",
    desc: "AMS tickets often contain repetitive issues that can be resolved by referencing historical data. By using Combination of Azure OpenAI GPT model and RAG, the system can generate list of all possible contextually relevant solutions by retrieving similar past cases and angulating the generated response for incoming new ticket.",
  },
  {
    name: "Gen AI Agents",
    icon: genAI,
    // url: streamlitRoutes.GEN_AI_AGENTS,
    // streamlit: true,
    awsVideo: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Financial%20services%20agent.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/financialservices_agent.pdf",
    tag: "MISC",
    desc: "AI agent helps users find account info, apply for loans, and answer natural language questions with sources. Developers can use this tech to build chatbots, assistants, and more.",
  },
  // {
  //   name: "Wildfire Detection",
  //   icon: wildfire,
  //   url: streamlitRoutes.WILDFIRE_DETECTION,
  //   streamlit: true,
  //   awsVideo: true,
  //   videoSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/wildfire_rec.mp4",
  //   pdfSrc:
  //     "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/wildfire.pdf",
  //   tag: "MISC",
  //   desc: "A solution for accurately identifying forest fire using stationary cameras mounted at a safe location. we achieve early detection of forest fires, protecting both precious ecosystems and human lives.",
  // },
  {
    name: "Manufacturing Catalyst",
    icon: manufacturing,
    url: routes.MANUFACTURING_CATALYST,
    streamlit: true,
    awsVideo: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Manufacturing%20Catalyst.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/ManufacturingCatalyst.pdf",
    tag: "Productivity",
    desc: "Seamlessly extract insights from uploaded manufacturing machinery fault videos, streamlining fault detection and troubleshooting processes with advanced AI-driven analysis. Improve efficiency and accuracy in diagnosing issues, enhancing overall production reliability and minimizing downtime.",
  },
  {
    name: "Queue Analyser",
    icon: manufacturing,
    // url: routes.QUEUE_ANALYZER,
    streamlit: true,
    awsVideo: true,
    videoSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Demo_Queue_Analyser.mp4",
    pdfSrc:
      "https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Queue_Analyzer.pdf",
    tag: "MISC",
    desc: "The Queue Analyser is an application designed to streamline queue management and enhance customer experience in various settings, from retail stores to healthcare facilities. It empowers businesses to improve operational efficiency, reduce wait times",
  },
  {
    name: "Nutrition & Fitness Advisor",
    icon: nutrition,
    url: routes.NUTRITION_AND_FITNESS_ADVISOR,
    streamlit: true,
    awsVideo: true,
    videoSrc: videoUrls.nutrition,
    pdfSrc: pdfUrls.nutrition,
    tag: "Chatbot",
    desc: "This use case leverages AWS Bedrock and Autogen to deliver personalized nutrition and fitness plans, using AI to tailor recommendations based on user preferences, goals.",
  },

  {
    name: "CSR Agent",
    icon: csr,
    url: routes.CSR,
    streamlit: true,
    awsVideo: true,
    videoSrc: videoUrls.csr,
    pdfSrc: pdfUrls.csr,
    tag: "MISC",
    desc: "CSR Agent Assist provides two key modes:Conversation Mode and Incident Mode, each offering several functionalities to enhance call center operations.",
  },
  {
    name: "Intelligent Document Processing",
    icon: idpp,
    url: routes.INTELLIGENT_DOCUMENT_PROCESSING,
    videoSrc: videoUrls.idp,
    pdfSrc: pdfUrls.idp,
    awsVideo: true,
    tag: "ChatBot",
    desc: "Streamline your document processing with our AI-powered solution. Effortlessly analyze, translate, and extract insights from PDFs and web content while ensuring data security and multilingual support.",
  },

  {
    name: "AWS Q on YashTechAI",
    icon: yashtechai,
    url: routes.AWSQ,
    streamlit: true,
    awsVideo: true,
    videoSrc: videoUrls.awqYashTechAI,
    pdfSrc: pdfUrls.awqYashTechAI,
    tag: "MISC",
    desc: "Our new accelerator, powered by AWS Q, enables interactive, AI-driven chat on yashtech.ai. It crawls all content, allowing users to access information through natural conversations, enhancing user experience and making information more accessible.",
  },

  {
    name: "Live Potrait",
    icon: lp,
    url: routes.LIVE_POTRAIT,
    // streamlit: true,
    videoSrc: videoUrls.lp,
    pdfSrc: pdfUrls.lp,
    awsVideo: true,
    tag: "MISC",
    desc: "Efficient Portrait Animation with Stitching and Retargeting Control",
  },
];
