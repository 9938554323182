import React, { useState, useRef, useEffect } from "react";
import Chatbot from "./Chatbot";
import BreadCrumbs from "../../../BreadCrumbs";
import {
  Card,
  Select,
  Row,
  Col,
  Button,
  message,
  Typography,
  Badge,
  Popover,
} from "antd";
import { useLocation } from "react-router";
import { DotLoading } from "antd-mobile";
import {
  IdcardOutlined,
  SendOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { IP } from "../../../../dynamicIPs";
import axios from "axios";
import VideoTour from "../../../video-guide";
import { api_url, domain_url } from "../../../../dynamicIPs";
import { videoUrls } from "../../../../util/videoData";
import { pdfUrls } from "../../../../util/pdfData";

function ProductRecommendation(props) {
  const location = useLocation();
  const { Title, Text, Paragraph } = Typography;
  const messageContainerRef = useRef(null);
  const [category, setCategory] = useState("General");
  const [msgData, setMsgData] = useState([]);
  const [initialMessage, setInitialMessage] = useState(
    "Hello! \n How can I assist you today with your purchase history or any product-related questions?"
  );
  const [query, setQuery] = useState("");
  const [inputMessage, setInputMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [userMessage, setUserMessage] = useState(null);
  const [apiMessage, setApiMessage] = useState(null);
  const [links, setLinks] = useState([]);
  const [fillerMessages, setFillerMessages] = useState([
    "Hmm...let me think",
    "Generating Response!",
    "Here is the answer.",
  ]);

  // const messagesEndRef = useRef(null);
  // const scrollToBottom = () => {
  //   messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  // };
  useEffect(() => {
    if (messageContainerRef.current) {
      const containerRef = messageContainerRef.current;
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  }, [userMessage, apiMessage]);
  const [currentFillerMessage, setCurrentFillerMessage] =
    useState("Hmm...let me think");

  const handleInputChange = (event) => {
    setQuery(event.target.value);
    setInputMessage(event.target.value);
  };

  const content = () => {
    return links.map((link, index) => <div>{`${index + 1}. ${link}`}</div>);
  };

  const TextWithLinks = (text) => {
    const handleLinkClick = (link) => {
      const product_name = link.split("/")[4];

      setLinks((prev) => [...prev, product_name]);
    };

    // Function to extract and replace links with icons
    const replaceLinksWithIcons = (line) => {
      // Regular expression to match URLs
      const urlRegex = /(https?:\/\/[^\s]+)/g;

      return line.split(urlRegex).map((part, index) => {
        // Check if the part is a URL
        if (urlRegex.test(part)) {
          return (
            // <div
            //   key={index}
              // role="button"
              // onClick={() => handleLinkClick(part)}
            //   style={{
            //     display: "inline-flex",
            //     alignItems: "center",
            //     cursor: "pointer",
            //   }}
            // >
            //   <IdcardOutlined style={{ color: "blue", marginRight: "4px" }} />
            // </div>
            <span   role="button"  onClick={()=>handleLinkClick(part)} >
              <IdcardOutlined style={{ color: "blue", marginRight: "4px" }} />
            </span>
          );
        }

        // If it's not a URL, return it as normal text
        return <span key={index}>{part}</span>;
      });
    };

    // Split the text into lines by new lines or bullet points
    const lines = text.split(/\n+/); // Split by new line

    return (
      <div>
        {lines.map((line, index) => (
          <div key={index} style={{ marginBottom: "8px" }}>
            {replaceLinksWithIcons(line)}
          </div>
        ))}
      </div>
    );
  };

  const handleClearHistroy = () => {
    localStorage.removeItem("history");
    setMsgData([{ text: "Hi, How may I help you?", isUser: false }]);
  };
  const handleSubmit = async (event) => {
    const randomIndex = Math.floor(Math.random() * fillerMessages.length);
    setCurrentFillerMessage(fillerMessages[randomIndex]);
    setMsgData([
      ...msgData,
      {
        text: query,
        isUser: true,
      },
      {
        text: currentFillerMessage,
        isUser: false,
      },
    ]);
    // setMsgData([...msgData, ,]);
    event.preventDefault();

    if (!query) return;
    setUserMessage(query);
    setInputMessage("");

    setLoading(true);
    try {
      let url = `${api_url}/product-reccomendation`;
      let data = {
        user_query: query,
      };
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("response:", response);
      const links = TextWithLinks(response.data);

      setMsgData([
        ...msgData,
        {
          text: query,
          isUser: true,
        },
        { text: links, isUser: false },
      ]);

      setQuery("");
      setLoading(false);
    } catch (error) {
      setUserMessage(query);
      setApiMessage(error.message);
      console.error("Error fetching data:", error);
      setMsgData([
        ...msgData,
        {
          text: query,
          isUser: true,
        },
        { text: error.message, isUser: false },
      ]);

      setLoading(false);
      setQuery("");
    }
  };
  return (
    <div
      style={{ margin: "10px 40px 0 40px" }}
      className={msgData.length === 0 ? "user-manual-container" : ""}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <BreadCrumbs location={location} />
        <div>
          <span style={{ marginRight: "30px" }} role="button">
            <Popover content={content} title="Product Name" trigger="click">
              <Badge count={links.length}>
                <ShoppingCartOutlined style={{ fontSize: "24px" }} />
              </Badge>
            </Popover>
          </span>

          <VideoTour
            src="https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/video/Product_reccomendation_and_support_Recording.mp4"
            type="video/mp4"
            pdfSrc="https://yash-genai-solution-accelerator.s3.us-east-2.amazonaws.com/pdf/Product%20Reccomendation%20and%20support%20Sample%20Questions.pdf"
          />
        </div>
      </div>

      {msgData.length == 0 && (
        <div style={{ textAlign: "center", marginTop: "10%" }}>
          <Text style={{ margin: "20px 0", fontSize: "xxx-large" }}>
            Hello!
          </Text>
          <br />
          <Text style={{ margin: "20px 0", fontSize: "xx-large" }}>
            How can I assist you today with your purchase history or any
            product-related questions?
          </Text>
        </div>
      )}

      <div
        className="user-manual-chatbot"
        style={{
          marginTop: "10px",
          position: "absolute",
          bottom: "16px",
          width: "92%",
        }}
      >
        <div
          ref={messageContainerRef}
          style={{
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            maxHeight: "500px",
            overflowY: "auto",
          }}
        >
          {msgData.map((message, index) => (
            <Chatbot key={index} text={message.text} isUser={message.isUser} />
          ))}
        </div>
        <div className="text-area" style={{ marginTop: "10px" }}>
          <form
            onSubmit={handleSubmit}
            className="input-container-user-manual"
            style={{ width: "100%" }}
          >
            <input
              disabled={loading}
              type="text"
              value={inputMessage}
              className="input-user-manual"
              onChange={handleInputChange}
              placeholder="Type a new question..."
            />
            {loading ? (
              <></>
            ) : (
              <>
                <Button
                  style={{
                    border: "none",
                    background: "none",
                    boxShadow: "none",
                    alignSelf: "center",
                    color: "black",
                  }}
                  type="primary"
                  icon={<SendOutlined />}
                  size="small"
                  onClick={handleSubmit}
                />
              </>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default ProductRecommendation;
